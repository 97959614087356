@charset "UTF-8";
/**
* Users backend
*/
/* Cubic Bezier Transition */
/***
Rounded corners reset
***/
/* Reset rounded corners for all elements */
div,
svg,
input,
select,
textarea,
span,
img,
table,
label,
td,
th,
p,
a,
button,
ul,
code,
pre,
li {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/* except below bootstrap elements */
.img-circle {
  border-radius: 50% !important;
}

.img-rounded {
  border-radius: 6px !important;
}

/***
General reset
***/
/* Set default body */
body {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  direction: "ltr";
  font-size: 14px;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't 
 properly apply the media queries in Bootstrap's CSS. To address this, 
 you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, 
 and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied 
*/
@-ms-viewport {
  width: auto !important;
}

.md-shadow-z-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.md-shadow-z-1-i {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18) !important;
}

.md-shadow-z-1-hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 1px 2px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2-i {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-2-hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-3 {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow-z-3-i {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.24) !important;
}

.md-shadow-z-3-hover {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-4-i {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-5-i {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-none {
  box-shadow: none !important;
}

.md-click-circle {
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}

.md-click-circle.md-click-animate {
  -webkit-animation: mdClickEffect 0.65s linear;
  -moz-animation: mdClickEffect 0.65s linear;
  animation: mdClickEffect 0.65s linear;
}

@-webkit-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    -webkit-transform: scale(2.5);
  }
}

@-moz-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    -moz-transform: scale(2.5);
  }
}

@keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    transform: scale(2.5);
  }
}

.form-group.form-md-line-input {
  position: relative;
  margin: 0 0 35px 0;
  padding-top: 20px;
}

.form-group.form-md-line-input:last-child {
  margin-bottom: 10px;
}

.form-horizontal .form-group.form-md-line-input {
  padding-top: 10px;
  margin-bottom: 20px;
  margin: 0 -15px 20px -15px;
}

.form-horizontal .form-group.form-md-line-input > label {
  padding-top: 5px;
  font-size: 14px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-group.form-md-line-input .form-control {
  background: none;
  border: 0;
  border-bottom: 1px solid #c2cad8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #555555;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.form-group.form-md-line-input .form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-group.form-md-line-input .form-control:-ms-input-placeholder {
  color: #999;
}

.form-group.form-md-line-input .form-control::-webkit-input-placeholder {
  color: #999;
}

.form-group.form-md-line-input .form-control.form-control-static {
  border-bottom: 0;
}

.form-group.form-md-line-input .form-control.input-sm {
  font-size: 14px;
  padding: 6px 0;
}

.form-group.form-md-line-input .form-control.input-lg {
  font-size: 20px;
  padding: 14px 0;
}

.form-group.form-md-line-input .form-control ~ label,
.form-group.form-md-line-input .form-control ~ .form-control-focus {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.form-horizontal .form-group.form-md-line-input .form-control ~ label, .form-horizontal
.form-group.form-md-line-input .form-control ~ .form-control-focus {
  width: auto;
  left: 15px;
  right: 15px;
}

.form-group.form-md-line-input .form-control ~ label:after,
.form-group.form-md-line-input .form-control ~ .form-control-focus:after {
  content: '';
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  width: 0;
  visibility: hidden;
  transition: 0.2s ease all;
}

.form-group.form-md-line-input .form-control ~ label {
  top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-group.form-md-line-input .form-control ~ .help-block-error {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .form-control-focus {
  color: #888888;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  visibility: visible;
  left: 0;
  width: 100%;
  background: #36c6d3;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .help-block {
  color: #36c6d3;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .help-block-error, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .help-block-error, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .help-block-error {
  opacity: 0 ;
  filter: alpha(opacity=0) ;
}

.form-group.form-md-line-input .form-control[readonly], .form-group.form-md-line-input .form-control[disabled],
fieldset[disabled] .form-group.form-md-line-input .form-control {
  background: none;
  cursor: not-allowed;
  border-bottom: 1px dashed #c2cad8;
}

.form-group.form-md-line-input.form-md-floating-label .form-control ~ label {
  font-size: 16px;
  top: 25px;
  transition: 0.2s ease all;
  color: #999;
}

.form-group.form-md-line-input.form-md-floating-label .form-control[readonly] ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.edited ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.form-control-static ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm ~ label {
  font-size: 14px;
  top: 24px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm[readonly] ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.form-control-static ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.edited ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm:focus:not([readonly]) ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg ~ label {
  font-size: 20px;
  top: 30px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg[readonly] ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.edited ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.form-control-static ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg:focus:not([readonly]) ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}

.form-group.form-md-line-input.form-md-floating-label .input-icon > label {
  padding-left: 34px;
}

.form-group.form-md-line-input.form-md-floating-label .input-icon.right > label {
  padding-left: 0;
  padding-right: 34px;
}

.form-group.form-md-line-input.form-md-floating-label .input-group.left-addon label {
  padding-left: 34px;
}

.form-group.form-md-line-input.form-md-floating-label .input-group.right-addon label {
  padding-right: 34px;
}

.form-group.form-md-line-input + .input-icon {
  padding-top: 0;
}

.form-group.form-md-line-input .help-block {
  position: absolute;
  margin: 2px 0 0 0;
  opacity: 0 ;
  filter: alpha(opacity=0) ;
  font-size: 13px;
}

.form-group.form-md-line-input > .input-icon > i {
  left: 0;
  bottom: 0;
  margin: 9px 2px 10px 10px;
  color: #888888;
}

.form-group.form-md-line-input > .input-icon.input-icon-lg > i {
  top: 6px;
}

.form-group.form-md-line-input > .input-icon.input-icon-sm > i {
  top: -1px;
}

.form-group.form-md-line-input > .input-icon .form-control {
  padding-left: 34px;
}

.form-group.form-md-line-input > .input-icon > label {
  margin-top: -20px;
}

.form-group.form-md-line-input > .input-icon.right .form-control {
  padding-left: 0;
  padding-right: 34px;
}

.form-group.form-md-line-input > .input-icon.right > i {
  left: auto;
  right: 8px;
  margin: 11px 2px 10px 10px;
}

.form-group.form-md-line-input + .input-group {
  padding-top: 0;
}

.form-group.form-md-line-input .input-group {
  padding-top: 0;
}

.form-group.form-md-line-input .input-group > label {
  margin-top: -20px;
}

.form-group.form-md-line-input .input-group .input-group-addon {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background: none;
  border: 0;
  border-bottom: 1px solid #c2cad8;
}

.form-group.form-md-line-input .input-group + .input-group-control {
  padding-top: 0;
}

.form-group.form-md-line-input .input-group .input-group-control {
  padding-top: 0;
  position: relative;
  display: table-cell;
  vertical-align: bottom;
}

.form-group.form-md-line-input .input-group .input-group-control > label {
  margin-top: -20px;
}

.form-group.form-md-line-input .input-group .input-group-btn .btn {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.form-group.form-md-line-input .input-group .input-group-btn.btn-left .btn {
  margin-right: 10px;
}

.form-group.form-md-line-input .input-group .input-group-btn.btn-right .btn {
  margin-left: 10px;
}

.form-group.form-md-line-input .input-group .help-block {
  margin-top: 35px;
}

.form-group.form-md-line-input .input-group.input-group-sm .help-block {
  margin-top: 30px;
}

.form-group.form-md-line-input .input-group.input-group-lg .help-block {
  margin-top: 47px;
}

.form-group.form-md-line-input.has-success .form-control {
  border-bottom: 1px solid #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ label {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ i {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ .help-block {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-success .input-group-addon {
  color: #27a4b0;
  border-bottom: 1px solid #27a4b0;
}

.form-group.form-md-line-input.has-success label {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-warning .form-control {
  border-bottom: 1px solid #927608;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #927608;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ label {
  color: #927608;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ i {
  color: #927608;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ .help-block {
  color: #927608;
}

.form-group.form-md-line-input.has-warning .input-group-addon {
  color: #927608;
  border-bottom: 1px solid #927608;
}

.form-group.form-md-line-input.has-warning label {
  color: #927608;
}

.form-group.form-md-line-input.has-error .form-control {
  border-bottom: 1px solid #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ i {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .help-block {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-error .input-group-addon {
  color: #e73d4a;
  border-bottom: 1px solid #e73d4a;
}

.form-group.form-md-line-input.has-error label {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-info .form-control {
  border-bottom: 1px solid #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ label {
  color: #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ i {
  color: #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ .help-block {
  color: #327ad5;
}

.form-group.form-md-line-input.has-info .input-group-addon {
  color: #327ad5;
  border-bottom: 1px solid #327ad5;
}

.form-group.form-md-line-input.has-info label {
  color: #327ad5;
}

.form-inline .form-md-line-input {
  margin: 0;
  padding-top: 0;
  margin-right: 20px;
}

.form-inline .form-md-line-input > .input-icon {
  padding: 0;
}

.form-horizontal .form-group.form-md-line-input .input-icon .form-control {
  padding-left: 33px;
}

.form-horizontal .form-group.form-md-line-input .input-icon > i {
  top: 0;
}

.form-horizontal .form-group.form-md-line-input .input-icon.right .form-control {
  padding-left: 0;
  padding-right: 33px;
}

.form-horizontal .form-group.form-md-line-input .input-icon > .form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.form-horizontal .form-group.form-md-line-input .input-group {
  padding-top: 0;
}

.form-horizontal .form-group.form-md-line-input .input-group > .input-group-btn .btn {
  margin-bottom: 0 !important;
}

.form-horizontal .form-group.form-md-line-input .input-group > .input-group-control {
  padding-top: 0;
}

.form-horizontal .form-group.form-md-line-input .input-group > .input-group-control > .form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.form-horizontal .form-group.form-md-line-input .input-group > .form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.md-checkbox {
  position: relative;
  /* handling click events */
  /* when checkbox is checked */
}

.md-checkbox.md-checkbox-inline {
  display: inline-block;
}

.form-inline .md-checkbox.md-checkbox-inline {
  margin-right: 20px;
  top: 3px;
}

.md-checkbox input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}

.md-checkbox label {
  cursor: pointer;
  padding-left: 30px;
}

.md-checkbox label > span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.md-checkbox label > span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}

.md-checkbox label > .box {
  top: 0px;
  border: 2px solid #666;
  height: 20px;
  width: 20px;
  z-index: 5;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.md-checkbox label > .check {
  top: -4px;
  left: 6px;
  width: 10px;
  height: 20px;
  border: 2px solid #36c6d3;
  border-top: none;
  border-left: none;
  opacity: 0;
  z-index: 5;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.md-checkbox label > span.inc {
  -webkit-animation: growCircle 0.3s ease;
  -moz-animation: growCircle 0.3s ease;
  animation: growCircle 0.3s ease;
}

.md-checkbox input[type=checkbox]:checked ~ label > .box {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
}

.md-checkbox input[type=checkbox]:checked ~ label > .check {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
  -moz-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}

.md-checkbox input[type=checkbox]:disabled ~ label,
.md-checkbox input[type=checkbox][disabled] ~ label {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}

.md-checkbox input[type=checkbox]:disabled ~ label > .box,
.md-checkbox input[type=checkbox][disabled] ~ label > .box {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}

.md-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
.md-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}

.has-error .md-checkbox label,
.has-error.md-checkbox label {
  color: #fbe1e3;
}

.has-error .md-checkbox label > .box,
.has-error.md-checkbox label > .box {
  border-color: #e73d4a;
}

.has-error .md-checkbox label > .check,
.has-error.md-checkbox label > .check {
  border-color: #e73d4a;
}

.has-success .md-checkbox label,
.has-success.md-checkbox label {
  color: #abe7ed;
}

.has-success .md-checkbox label > .box,
.has-success.md-checkbox label > .box {
  border-color: #27a4b0;
}

.has-success .md-checkbox label > .check,
.has-success.md-checkbox label > .check {
  border-color: #27a4b0;
}

.has-warning .md-checkbox label,
.has-warning.md-checkbox label {
  color: #f9e8a0;
}

.has-warning .md-checkbox label > .box,
.has-warning.md-checkbox label > .box {
  border-color: #927608;
}

.has-warning .md-checkbox label > .check,
.has-warning.md-checkbox label > .check {
  border-color: #927608;
}

.has-info .md-checkbox label,
.has-info.md-checkbox label {
  color: #e0ebf9;
}

.has-info .md-checkbox label > .box,
.has-info.md-checkbox label > .box {
  border-color: #327ad5;
}

.has-info .md-checkbox label > .check,
.has-info.md-checkbox label > .check {
  border-color: #327ad5;
}

.form-md-checkboxes {
  padding-top: 5px;
}

.form-md-checkboxes > label {
  font-size: 14px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-md-checkboxes.has-error label {
  color: #ed6b75;
}

.form-md-checkboxes.has-info label {
  color: #659be0;
}

.form-md-checkboxes.has-success label {
  color: #36c6d3;
}

.form-md-checkboxes.has-warning label {
  color: #F1C40F;
}

.md-checkbox-list {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-checkbox-list {
  margin-top: 5px;
}

.md-checkbox-list .md-checkbox {
  display: block;
  margin-bottom: 10px;
}

.md-checkbox-list .md-checkbox:last-child {
  margin-bottom: 0;
}

.md-checkbox-inline {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-checkbox-inline {
  margin-top: 7px;
}

.md-checkbox-inline .md-checkbox {
  display: inline-block;
  margin-right: 20px;
}

.md-checkbox-inline .md-checkbox:last-child {
  margin-right: 0;
}

/* bubble animation */
@-webkit-keyframes growCircle {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25);
  }
}

@-moz-keyframes growCircle {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -moz-transform: scale(1.25);
  }
}

@keyframes growCircle {
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    transform: scale(1.25);
  }
}

.md-radio {
  position: relative;
  /* handling click events */
  /* when radio is checked */
}

.md-radio input[type=radio] {
  visibility: hidden;
  position: absolute;
}

.md-radio label {
  cursor: pointer;
  padding-left: 30px;
}

.md-radio label > span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.md-radio label > span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}

.md-radio label > .box {
  top: 0px;
  border: 2px solid #666;
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  z-index: 5;
}

.md-radio label > .check {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #36c6d3;
  opacity: 0;
  z-index: 6;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}

.md-radio label > span.inc {
  -webkit-animation: growCircleRadio 0.3s ease;
  -moz-animation: growCircleRadio 0.3s ease;
  animation: growCircleRadio 0.3s ease;
}

.md-radio input[type=radio]:checked ~ label > .check {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.md-radio input[type=radio]:disabled ~ label,
.md-radio input[type=radio][disabled] ~ label {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}

.md-radio input[type=radio]:disabled ~ label > .box,
.md-radio input[type=radio][disabled] ~ label > .box {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}

.md-radio input[type=radio]:disabled:checked ~ label > .check,
.md-radio input[type=radio][disabled]:checked ~ label > .check {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}

.has-error .md-radio label,
.has-error.md-radio label {
  color: #fbe1e3;
}

.has-error .md-radio label > .box,
.has-error.md-radio label > .box {
  border-color: #e73d4a;
}

.has-error .md-radio label > .check,
.has-error.md-radio label > .check {
  background: #e73d4a;
}

.has-success .md-radio label,
.has-success.md-radio label {
  color: #abe7ed;
}

.has-success .md-radio label > .box,
.has-success.md-radio label > .box {
  border-color: #27a4b0;
}

.has-success .md-radio label > .check,
.has-success.md-radio label > .check {
  background: #27a4b0;
}

.has-warning .md-radio label,
.has-warning.md-radio label {
  color: #f9e8a0;
}

.has-warning .md-radio label > .box,
.has-warning.md-radio label > .box {
  border-color: #927608;
}

.has-warning .md-radio label > .check,
.has-warning.md-radio label > .check {
  background: #927608;
}

.has-info .md-radio label,
.has-info.md-radio label {
  color: #e0ebf9;
}

.has-info .md-radio label > .box,
.has-info.md-radio label > .box {
  border-color: #327ad5;
}

.has-info .md-radio label > .check,
.has-info.md-radio label > .check {
  background: #327ad5;
}

.form-md-radios {
  padding-top: 5px;
}

.form-md-radios > label {
  font-size: 14px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.form-md-radios.has-error label {
  color: #ed6b75;
}

.form-md-radios.has-info label {
  color: #659be0;
}

.form-md-radios.has-success label {
  color: #36c6d3;
}

.form-md-radios.has-warning label {
  color: #F1C40F;
}

.md-radio-list {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-radio-list {
  margin-top: 5px;
}

.md-radio-list .md-radio {
  display: block;
  margin-bottom: 10px;
}

.md-radio-inline {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-radio-inline {
  margin-top: 7px;
}

.md-radio-inline .md-radio {
  display: inline-block;
  margin-right: 20px;
}

.md-radio-inline .md-radio:last-child {
  margin-right: 0;
}

/* bubble animation */
@-webkit-keyframes growCircleRadio {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25);
  }
}

@-moz-keyframes growCircleRadio {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -moz-transform: scale(1.25);
  }
}

@keyframes growCircleRadio {
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    transform: scale(1.25);
  }
}

/***
General typography
***/
/* Links */
a:hover {
  cursor: pointer;
}

/* Primary Link */
.primary-link {
  color: #65A0D0;
  font-weight: 600;
}

.primary-link:hover {
  color: #5194ca;
}

p {
  margin: 20px 0;
}

label {
  font-weight: normal;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

/* Headings helper text */
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444444;
}

/* Block headings */
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Links */
a {
  text-shadow: none;
  color: #337ab7;
}

a:hover {
  color: #23527c;
}

a:focus, a:hover, a:active {
  outline: 0;
}

/* Horizontal break */
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 0;
}

/* Unstyled List */
.list-unstyled li > .list-unstyled {
  margin-left: 25px;
}

/* Code */
code {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Disabled Navigation Link */
.disabled-link.disable-target,
.disabled-link .disable-target {
  opacity: 0.6 !important;
  filter: alpha(opacity=60) !important;
}

.disabled-link.disable-target:hover,
.disabled-link .disable-target:hover {
  cursor: not-allowed !important;
}

.disabled-link:hover {
  cursor: not-allowed !important;
}

/***
Utilities
***/
/* Rounded Element */
.rounded-2 {
  border-radius: 2px !important;
}

.rounded-3 {
  border-radius: 3px !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

/* Circle Element */
.circle {
  border-radius: 25px !important;
}

.circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.circle-left {
  border-radius: 25px 0 0 25px !important;
}

.circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.rounded {
  border-radius: 50% !important;
}

/* General utilities */
.display-none,
.display-hide {
  display: none;
}

.hidden {
  display: none !important;
}

.primary-font {
  font-family: "Open Sans", sans-serif !important;
}

.bold {
  font-weight: 700 !important;
}

.bold.btn {
  letter-spacing: 0;
}

.sbold {
  font-weight: 600 !important;
}

.sbold.btn {
  letter-spacing: 0;
}

.thin {
  font-weight: 300 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.fix-margin {
  margin-left: 0px !important;
}

.border {
  border: 1px solid red;
}

.font-hg {
  font-size: 23px;
}

.font-lg {
  font-size: 18px;
}

.font-md {
  font-size: 14px;
}

.font-sm {
  font-size: 13px;
}

.font-xs {
  font-size: 11px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.text-align-reverse {
  text-align: right;
}

/* Margin and padding utilities */
.no-space {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margin {
  margin: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

/* IE8 & IE9 mode utilities */
.visible-ie8 {
  display: none;
}

.ie8 .visible-ie8 {
  display: inherit !important;
}

.visible-ie9 {
  display: none;
}

.ie9 .visible-ie9 {
  display: inherit !important;
}

.hidden-ie8 {
  display: inherit;
}

.ie8 .hidden-ie8 {
  display: none !important;
}

.hidden-ie9 {
  display: inherit;
}

.ie9 .hidden-ie9 {
  display: none !important;
}

/***
Responsive Utils
***/
@media (max-width: 1024px) {
  .hidden-1024 {
    display: none;
  }
}

@media (max-width: 480px) {
  .hidden-480 {
    display: none;
  }
}

@media (max-width: 320px) {
  .hidden-320 {
    display: none;
  }
}

/***
Demo Utils
***/
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto;
}

.util-btn-margin-bottom-5 .btn {
  margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
  margin-bottom: 5px !important;
}

.padding-tb-10 {
  padding: 10px 0;
}

.padding-tb-15 {
  padding: 10px 0;
}

.padding-tb-20 {
  padding: 20px 0;
}

/***
Users
***/
.user-info {
  margin-bottom: 10px !important;
}

.user-info img {
  float: left;
  margin-right: 5px;
}

.user-info .details {
  display: inline-block;
}

.user-info .label {
  font-weight: 300;
  font-size: 11px;
}

/***
Custom vertical inline menu
***/
.ver-inline-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ver-inline-menu li {
  position: relative;
  margin-bottom: 1px;
}

.ver-inline-menu li i {
  width: 37px;
  height: 37px;
  display: inline-block;
  color: #b9cbd5;
  font-size: 15px;
  padding: 12px 10px 10px 8px;
  margin: 0 8px 0 0;
  text-align: center;
  background: #e0eaf0 !important;
}

.ver-inline-menu li a {
  font-size: 14px;
  font-weight: 300;
  color: #557386;
  display: block;
  background: #f0f6fa;
  border-left: solid 2px #c4d5df;
}

.ver-inline-menu li:hover a {
  background: #e0eaf0;
  text-decoration: none;
}

.ver-inline-menu li:hover i {
  color: #fff;
  background: #c4d5df !important;
}

.ver-inline-menu li.active a {
  border-left: solid 2px #0c91e5;
}

.ver-inline-menu li.active i {
  background: #0c91e5 !important;
}

.ver-inline-menu li.active a,
.ver-inline-menu li.active i {
  color: #fff;
  background: #169ef4;
  text-decoration: none;
}

.ver-inline-menu li.active a,
.ver-inline-menu li:hover a {
  font-size: 14px;
}

.ver-inline-menu li.active:after {
  content: '';
  display: inline-block;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid #169ef4;
  position: absolute;
  top: 12px;
  right: -5px;
}

@media (max-width: 767px) {
  .ver-inline-menu > li.active:after {
    display: none;
  }
}

/***
Separated List
***/
.list-separated {
  margin-top: 10px;
  margin-bottom: 15px;
}

.list-separated > div:last-child {
  border-right: 0;
}

@media (max-width: 767px) {
  .list-separated {
    /* 767px */
  }
  .list-separated > div {
    margin-bottom: 20px;
  }
}

/***
Number & Chart Stats
***/
.number-stats {
  margin: 10px 0;
}

.number-stats .stat-number {
  display: inline-block;
  margin: 0 5px;
}

.number-stats .stat-number .title {
  font-size: 13px;
  margin-bottom: 3px;
  color: #B8C3C7;
}

.number-stats .stat-number .number {
  font-size: 27px;
  line-height: 27px;
  color: #7D8C9D;
}

.number-stats .stat-chart {
  display: inline-block;
  margin: 0 5px;
}

.number-stats > div {
  border-right: 1px solid #f5f5f5;
}

.number-stats > div:last-child {
  border-right: 0;
}

.number-stats .stat-left {
  float: right;
}

.number-stats .stat-left .stat-number {
  float: right;
  text-align: right;
}

.number-stats .stat-left .stat-chart {
  float: right;
}

.number-stats .stat-right {
  float: left !important;
}

.number-stats .stat-right .stat-number {
  float: left;
  text-align: left;
}

.number-stats .stat-right .stat-chart {
  float: left;
}

.number-stats .stat-number {
  float: left;
  text-align: left;
}

.number-stats .stat-chart {
  float: left;
}

/***
General User Record Listing
***/
.general-item-list > .item {
  padding: 10px 0;
  border-bottom: 1px solid #F1F4F7;
}

.general-item-list > .item:last-child {
  border-bottom: 0;
}

.general-item-list > .item > .item-head {
  margin-bottom: 5px;
}

.general-item-list > .item > .item-head:before, .general-item-list > .item > .item-head:after {
  content: " ";
  display: table;
}

.general-item-list > .item > .item-head:after {
  clear: both;
}

.general-item-list > .item > .item-head > .item-details {
  display: inline-block;
  float: left;
}

.general-item-list > .item > .item-head > .item-details > .item-pic {
  height: 35px;
  margin-right: 10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.general-item-list > .item > .item-head > .item-details > .item-name {
  display: inline-block;
  margin-right: 10px;
}

.general-item-list > .item > .item-head > .item-details > .item-label {
  color: #C0C9CC;
}

.general-item-list > .item > .item-head > .item-status {
  color: #C0C9CC;
  top: 10px;
  position: relative;
  display: inline-block;
  float: right;
}

.general-item-list > .item > .item-head > .item-status > .badge {
  margin-top: -2px;
}

.general-item-list > .item > .item-body {
  color: #96a5aa;
}

/***
File dropzone
***/
.file-drop-zone {
  border: 2px dashed #ddd;
  padding: 30px;
  text-align: center;
}

.file-drop-zone.file-drop-zone-over {
  border-color: #aaa;
}

/***
Fontawesome Icons
***/
[class^="fa-"]:not(.fa-stack),
[class^="glyphicon-"],
[class^="icon-"],
[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"] {
  display: inline-block;
  margin-right: .3em;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
}

/* Make font awesome icons fixed width */
li [class^="fa-"],
li [class^="glyphicon-"],
li [class^="icon-"],
li [class*=" fa-"],
li [class*=" glyphicon-"],
li [class*=" icon-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

li [class^="glyphicon-"],
li [class*=" glyphicon-"] {
  top: 2px;
}

li [class^="icon-"],
li [class*=" icon-"] {
  top: 1px;
  position: relative;
}

li [class^="fa-"].icon-large,
li [class^="glyphicon-"].icon-large,
li [class^="icon-"].icon-large,
li [class*=" fa-"].icon-large,
li [class*=" glyphicon-"].icon-large,
li [class*=" icon-"].icon-large {
  /* increased font size for icon-large */
  width: 1.5625em;
}

/* Icon states */
.icon-state-default {
  color: #bac3d0;
}

.icon-state-success {
  color: #36c6d3;
}

.icon-state-info {
  color: #659be0;
}

.icon-state-warning {
  color: #F1C40F;
}

.icon-state-danger {
  color: #ed6b75;
}

/***
Font Awesome 4.x Demo
***/
.fa-item {
  font-size: 14px;
  padding: 10px 10px 10px 20px;
}

.fa-item i {
  font-size: 16px;
  display: inline-block;
  width: 20px;
  color: #333;
}

.fa-item:hover {
  cursor: pointer;
  background: #eee;
}

/***
Simple Line Icons Demo
***/
.simplelineicons-demo .item-box {
  display: inline-block;
  font-size: 16px;
  margin: 0 -0.22em 1em 0;
  padding-left: 1em;
  width: 100%;
}

.simplelineicons-demo .item-box .item {
  background-color: #fff;
  color: #33383e;
  border-radius: 8px;
  display: inline-block;
  padding: 10px;
  width: 100%;
}

.simplelineicons-demo .item-box .item span {
  font-size: 22px;
}

@media only screen and (min-width: 768px) {
  .simplelineicons-demo .item-box {
    width: 33.333%;
  }
}

/*** 
Glyphicons Demo
***/
.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.bs-glyphicon-class {
  text-align: center;
}

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px;
  color: #444;
}

.glyphicons-demo ul li:hover {
  background-color: rgba(86, 61, 124, 0.1);
}

@media (min-width: 768px) {
  .glyphicons-demo ul li {
    width: 12.5%;
  }
}

/***
Customized Bootstrap Alerts
***/
.alert {
  border-width: 1px;
}

.alert > p {
  margin: 0;
}

.alert.alert-borderless {
  border: 0;
}

/***
Custom buttons based on bootstrap SASS
***/
.btn {
  outline: none !important;
  box-shadow: none !important;
}

.btn:hover {
  transition: all 0.3s;
}

.btn:not(.btn-sm):not(.btn-lg) {
  line-height: 1.44;
}

/***
Custom color buttons 
***/
.btn.white:not(.btn-outline) {
  color: #666;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.white:not(.btn-outline):focus, .btn.white:not(.btn-outline).focus {
  color: #666;
  background-color: #e6e6e6;
  border-color: #bfbfbf;
}

.btn.white:not(.btn-outline):hover {
  color: #666;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.btn.white:not(.btn-outline):active, .btn.white:not(.btn-outline).active,
.open > .btn.white:not(.btn-outline).dropdown-toggle {
  color: #666;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.btn.white:not(.btn-outline):active:hover, .btn.white:not(.btn-outline):active:focus, .btn.white:not(.btn-outline):active.focus, .btn.white:not(.btn-outline).active:hover, .btn.white:not(.btn-outline).active:focus, .btn.white:not(.btn-outline).active.focus,
.open > .btn.white:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.white:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.white:not(.btn-outline).dropdown-toggle.focus {
  color: #666;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}

.btn.white:not(.btn-outline):active, .btn.white:not(.btn-outline).active,
.open > .btn.white:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.white:not(.btn-outline).disabled:hover, .btn.white:not(.btn-outline).disabled:focus, .btn.white:not(.btn-outline).disabled.focus, .btn.white:not(.btn-outline)[disabled]:hover, .btn.white:not(.btn-outline)[disabled]:focus, .btn.white:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.white:not(.btn-outline):hover,
fieldset[disabled] .btn.white:not(.btn-outline):focus,
fieldset[disabled] .btn.white:not(.btn-outline).focus {
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.white:not(.btn-outline) .badge {
  color: #ffffff;
  background-color: #666;
}

.btn.btn-outline.white {
  border-color: #ffffff;
  color: #ffffff;
  background: none;
}

.btn.btn-outline.white:hover, .btn.btn-outline.white:active, .btn.btn-outline.white:active:hover, .btn.btn-outline.white:active:focus, .btn.btn-outline.white:focus, .btn.btn-outline.white.active {
  border-color: #ffffff;
  color: #666;
  background-color: #ffffff;
}

.btn.white-stripe {
  border-left: 4px solid #ffffff !important;
}

.btn.default:not(.btn-outline) {
  color: #666;
  background-color: #e1e5ec;
  border-color: #e1e5ec;
}

.btn.default:not(.btn-outline):focus, .btn.default:not(.btn-outline).focus {
  color: #666;
  background-color: #c2cad8;
  border-color: #93a1bb;
}

.btn.default:not(.btn-outline):hover {
  color: #666;
  background-color: #c2cad8;
  border-color: #bcc5d4;
}

.btn.default:not(.btn-outline):active, .btn.default:not(.btn-outline).active,
.open > .btn.default:not(.btn-outline).dropdown-toggle {
  color: #666;
  background-color: #c2cad8;
  border-color: #bcc5d4;
}

.btn.default:not(.btn-outline):active:hover, .btn.default:not(.btn-outline):active:focus, .btn.default:not(.btn-outline):active.focus, .btn.default:not(.btn-outline).active:hover, .btn.default:not(.btn-outline).active:focus, .btn.default:not(.btn-outline).active.focus,
.open > .btn.default:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.default:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.default:not(.btn-outline).dropdown-toggle.focus {
  color: #666;
  background-color: #acb7ca;
  border-color: #93a1bb;
}

.btn.default:not(.btn-outline):active, .btn.default:not(.btn-outline).active,
.open > .btn.default:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.default:not(.btn-outline).disabled:hover, .btn.default:not(.btn-outline).disabled:focus, .btn.default:not(.btn-outline).disabled.focus, .btn.default:not(.btn-outline)[disabled]:hover, .btn.default:not(.btn-outline)[disabled]:focus, .btn.default:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.default:not(.btn-outline):hover,
fieldset[disabled] .btn.default:not(.btn-outline):focus,
fieldset[disabled] .btn.default:not(.btn-outline).focus {
  background-color: #e1e5ec;
  border-color: #e1e5ec;
}

.btn.default:not(.btn-outline) .badge {
  color: #e1e5ec;
  background-color: #666;
}

.btn.btn-outline.default {
  border-color: #e1e5ec;
  color: #e1e5ec;
  background: none;
}

.btn.btn-outline.default:hover, .btn.btn-outline.default:active, .btn.btn-outline.default:active:hover, .btn.btn-outline.default:active:focus, .btn.btn-outline.default:focus, .btn.btn-outline.default.active {
  border-color: #e1e5ec;
  color: #666;
  background-color: #e1e5ec;
}

.btn.default-stripe {
  border-left: 4px solid #e1e5ec !important;
}

.btn.dark:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #2f353b;
  border-color: #2f353b;
}

.btn.dark:not(.btn-outline):focus, .btn.dark:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #181c1f;
  border-color: black;
}

.btn.dark:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #181c1f;
  border-color: #141619;
}

.btn.dark:not(.btn-outline):active, .btn.dark:not(.btn-outline).active,
.open > .btn.dark:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #181c1f;
  border-color: #141619;
}

.btn.dark:not(.btn-outline):active:hover, .btn.dark:not(.btn-outline):active:focus, .btn.dark:not(.btn-outline):active.focus, .btn.dark:not(.btn-outline).active:hover, .btn.dark:not(.btn-outline).active:focus, .btn.dark:not(.btn-outline).active.focus,
.open > .btn.dark:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.dark:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.dark:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #090a0b;
  border-color: black;
}

.btn.dark:not(.btn-outline):active, .btn.dark:not(.btn-outline).active,
.open > .btn.dark:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.dark:not(.btn-outline).disabled:hover, .btn.dark:not(.btn-outline).disabled:focus, .btn.dark:not(.btn-outline).disabled.focus, .btn.dark:not(.btn-outline)[disabled]:hover, .btn.dark:not(.btn-outline)[disabled]:focus, .btn.dark:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.dark:not(.btn-outline):hover,
fieldset[disabled] .btn.dark:not(.btn-outline):focus,
fieldset[disabled] .btn.dark:not(.btn-outline).focus {
  background-color: #2f353b;
  border-color: #2f353b;
}

.btn.dark:not(.btn-outline) .badge {
  color: #2f353b;
  background-color: #FFFFFF;
}

.btn.btn-outline.dark {
  border-color: #2f353b;
  color: #2f353b;
  background: none;
}

.btn.btn-outline.dark:hover, .btn.btn-outline.dark:active, .btn.btn-outline.dark:active:hover, .btn.btn-outline.dark:active:focus, .btn.btn-outline.dark:focus, .btn.btn-outline.dark.active {
  border-color: #2f353b;
  color: #FFFFFF;
  background-color: #2f353b;
}

.btn.dark-stripe {
  border-left: 4px solid #2f353b !important;
}

.btn.blue:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #3598dc;
  border-color: #3598dc;
}

.btn.blue:not(.btn-outline):focus, .btn.blue:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #217ebd;
  border-color: #15527c;
}

.btn.blue:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #217ebd;
  border-color: #1f78b5;
}

.btn.blue:not(.btn-outline):active, .btn.blue:not(.btn-outline).active,
.open > .btn.blue:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #217ebd;
  border-color: #1f78b5;
}

.btn.blue:not(.btn-outline):active:hover, .btn.blue:not(.btn-outline):active:focus, .btn.blue:not(.btn-outline):active.focus, .btn.blue:not(.btn-outline).active:hover, .btn.blue:not(.btn-outline).active:focus, .btn.blue:not(.btn-outline).active.focus,
.open > .btn.blue:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1c699f;
  border-color: #15527c;
}

.btn.blue:not(.btn-outline):active, .btn.blue:not(.btn-outline).active,
.open > .btn.blue:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue:not(.btn-outline).disabled:hover, .btn.blue:not(.btn-outline).disabled:focus, .btn.blue:not(.btn-outline).disabled.focus, .btn.blue:not(.btn-outline)[disabled]:hover, .btn.blue:not(.btn-outline)[disabled]:focus, .btn.blue:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue:not(.btn-outline):hover,
fieldset[disabled] .btn.blue:not(.btn-outline):focus,
fieldset[disabled] .btn.blue:not(.btn-outline).focus {
  background-color: #3598dc;
  border-color: #3598dc;
}

.btn.blue:not(.btn-outline) .badge {
  color: #3598dc;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue {
  border-color: #3598dc;
  color: #3598dc;
  background: none;
}

.btn.btn-outline.blue:hover, .btn.btn-outline.blue:active, .btn.btn-outline.blue:active:hover, .btn.btn-outline.blue:active:focus, .btn.btn-outline.blue:focus, .btn.btn-outline.blue.active {
  border-color: #3598dc;
  color: #FFFFFF;
  background-color: #3598dc;
}

.btn.blue-stripe {
  border-left: 4px solid #3598dc !important;
}

.btn.blue-madison:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #578ebe;
  border-color: #578ebe;
}

.btn.blue-madison:not(.btn-outline):focus, .btn.blue-madison:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3f74a3;
  border-color: #2a4d6c;
}

.btn.blue-madison:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3f74a3;
  border-color: #3c6f9c;
}

.btn.blue-madison:not(.btn-outline):active, .btn.blue-madison:not(.btn-outline).active,
.open > .btn.blue-madison:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3f74a3;
  border-color: #3c6f9c;
}

.btn.blue-madison:not(.btn-outline):active:hover, .btn.blue-madison:not(.btn-outline):active:focus, .btn.blue-madison:not(.btn-outline):active.focus, .btn.blue-madison:not(.btn-outline).active:hover, .btn.blue-madison:not(.btn-outline).active:focus, .btn.blue-madison:not(.btn-outline).active.focus,
.open > .btn.blue-madison:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-madison:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-madison:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #356289;
  border-color: #2a4d6c;
}

.btn.blue-madison:not(.btn-outline):active, .btn.blue-madison:not(.btn-outline).active,
.open > .btn.blue-madison:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-madison:not(.btn-outline).disabled:hover, .btn.blue-madison:not(.btn-outline).disabled:focus, .btn.blue-madison:not(.btn-outline).disabled.focus, .btn.blue-madison:not(.btn-outline)[disabled]:hover, .btn.blue-madison:not(.btn-outline)[disabled]:focus, .btn.blue-madison:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-madison:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-madison:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-madison:not(.btn-outline).focus {
  background-color: #578ebe;
  border-color: #578ebe;
}

.btn.blue-madison:not(.btn-outline) .badge {
  color: #578ebe;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-madison {
  border-color: #578ebe;
  color: #578ebe;
  background: none;
}

.btn.btn-outline.blue-madison:hover, .btn.btn-outline.blue-madison:active, .btn.btn-outline.blue-madison:active:hover, .btn.btn-outline.blue-madison:active:focus, .btn.btn-outline.blue-madison:focus, .btn.btn-outline.blue-madison.active {
  border-color: #578ebe;
  color: #FFFFFF;
  background-color: #578ebe;
}

.btn.blue-madison-stripe {
  border-left: 4px solid #578ebe !important;
}

.btn.blue-chambray:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.btn.blue-chambray:not(.btn-outline):focus, .btn.blue-chambray:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #1a252f;
  border-color: black;
}

.btn.blue-chambray:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #1a252f;
  border-color: #161f29;
}

.btn.blue-chambray:not(.btn-outline):active, .btn.blue-chambray:not(.btn-outline).active,
.open > .btn.blue-chambray:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #1a252f;
  border-color: #161f29;
}

.btn.blue-chambray:not(.btn-outline):active:hover, .btn.blue-chambray:not(.btn-outline):active:focus, .btn.blue-chambray:not(.btn-outline):active.focus, .btn.blue-chambray:not(.btn-outline).active:hover, .btn.blue-chambray:not(.btn-outline).active:focus, .btn.blue-chambray:not(.btn-outline).active.focus,
.open > .btn.blue-chambray:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-chambray:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-chambray:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0d1318;
  border-color: black;
}

.btn.blue-chambray:not(.btn-outline):active, .btn.blue-chambray:not(.btn-outline).active,
.open > .btn.blue-chambray:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-chambray:not(.btn-outline).disabled:hover, .btn.blue-chambray:not(.btn-outline).disabled:focus, .btn.blue-chambray:not(.btn-outline).disabled.focus, .btn.blue-chambray:not(.btn-outline)[disabled]:hover, .btn.blue-chambray:not(.btn-outline)[disabled]:focus, .btn.blue-chambray:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-chambray:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-chambray:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-chambray:not(.btn-outline).focus {
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.btn.blue-chambray:not(.btn-outline) .badge {
  color: #2C3E50;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-chambray {
  border-color: #2C3E50;
  color: #2C3E50;
  background: none;
}

.btn.btn-outline.blue-chambray:hover, .btn.btn-outline.blue-chambray:active, .btn.btn-outline.blue-chambray:active:hover, .btn.btn-outline.blue-chambray:active:focus, .btn.btn-outline.blue-chambray:focus, .btn.btn-outline.blue-chambray.active {
  border-color: #2C3E50;
  color: #FFFFFF;
  background-color: #2C3E50;
}

.btn.blue-chambray-stripe {
  border-left: 4px solid #2C3E50 !important;
}

.btn.blue-ebonyclay:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #22313F;
  border-color: #22313F;
}

.btn.blue-ebonyclay:not(.btn-outline):focus, .btn.blue-ebonyclay:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #10171e;
  border-color: black;
}

.btn.blue-ebonyclay:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #10171e;
  border-color: #0d1217;
}

.btn.blue-ebonyclay:not(.btn-outline):active, .btn.blue-ebonyclay:not(.btn-outline).active,
.open > .btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #10171e;
  border-color: #0d1217;
}

.btn.blue-ebonyclay:not(.btn-outline):active:hover, .btn.blue-ebonyclay:not(.btn-outline):active:focus, .btn.blue-ebonyclay:not(.btn-outline):active.focus, .btn.blue-ebonyclay:not(.btn-outline).active:hover, .btn.blue-ebonyclay:not(.btn-outline).active:focus, .btn.blue-ebonyclay:not(.btn-outline).active.focus,
.open > .btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #040507;
  border-color: black;
}

.btn.blue-ebonyclay:not(.btn-outline):active, .btn.blue-ebonyclay:not(.btn-outline).active,
.open > .btn.blue-ebonyclay:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-ebonyclay:not(.btn-outline).disabled:hover, .btn.blue-ebonyclay:not(.btn-outline).disabled:focus, .btn.blue-ebonyclay:not(.btn-outline).disabled.focus, .btn.blue-ebonyclay:not(.btn-outline)[disabled]:hover, .btn.blue-ebonyclay:not(.btn-outline)[disabled]:focus, .btn.blue-ebonyclay:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-ebonyclay:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-ebonyclay:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-ebonyclay:not(.btn-outline).focus {
  background-color: #22313F;
  border-color: #22313F;
}

.btn.blue-ebonyclay:not(.btn-outline) .badge {
  color: #22313F;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-ebonyclay {
  border-color: #22313F;
  color: #22313F;
  background: none;
}

.btn.btn-outline.blue-ebonyclay:hover, .btn.btn-outline.blue-ebonyclay:active, .btn.btn-outline.blue-ebonyclay:active:hover, .btn.btn-outline.blue-ebonyclay:active:focus, .btn.btn-outline.blue-ebonyclay:focus, .btn.btn-outline.blue-ebonyclay.active {
  border-color: #22313F;
  color: #FFFFFF;
  background-color: #22313F;
}

.btn.blue-ebonyclay-stripe {
  border-left: 4px solid #22313F !important;
}

.btn.blue-hoki:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #67809F;
  border-color: #67809F;
}

.btn.blue-hoki:not(.btn-outline):focus, .btn.blue-hoki:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #526781;
  border-color: #344252;
}

.btn.blue-hoki:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #526781;
  border-color: #4e627b;
}

.btn.blue-hoki:not(.btn-outline):active, .btn.blue-hoki:not(.btn-outline).active,
.open > .btn.blue-hoki:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #526781;
  border-color: #4e627b;
}

.btn.blue-hoki:not(.btn-outline):active:hover, .btn.blue-hoki:not(.btn-outline):active:focus, .btn.blue-hoki:not(.btn-outline):active.focus, .btn.blue-hoki:not(.btn-outline).active:hover, .btn.blue-hoki:not(.btn-outline).active:focus, .btn.blue-hoki:not(.btn-outline).active.focus,
.open > .btn.blue-hoki:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-hoki:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-hoki:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #44566b;
  border-color: #344252;
}

.btn.blue-hoki:not(.btn-outline):active, .btn.blue-hoki:not(.btn-outline).active,
.open > .btn.blue-hoki:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-hoki:not(.btn-outline).disabled:hover, .btn.blue-hoki:not(.btn-outline).disabled:focus, .btn.blue-hoki:not(.btn-outline).disabled.focus, .btn.blue-hoki:not(.btn-outline)[disabled]:hover, .btn.blue-hoki:not(.btn-outline)[disabled]:focus, .btn.blue-hoki:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-hoki:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-hoki:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-hoki:not(.btn-outline).focus {
  background-color: #67809F;
  border-color: #67809F;
}

.btn.blue-hoki:not(.btn-outline) .badge {
  color: #67809F;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-hoki {
  border-color: #67809F;
  color: #67809F;
  background: none;
}

.btn.btn-outline.blue-hoki:hover, .btn.btn-outline.blue-hoki:active, .btn.btn-outline.blue-hoki:active:hover, .btn.btn-outline.blue-hoki:active:focus, .btn.btn-outline.blue-hoki:focus, .btn.btn-outline.blue-hoki.active {
  border-color: #67809F;
  color: #FFFFFF;
  background-color: #67809F;
}

.btn.blue-hoki-stripe {
  border-left: 4px solid #67809F !important;
}

.btn.blue-steel:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #4B77BE;
  border-color: #4B77BE;
}

.btn.blue-steel:not(.btn-outline):focus, .btn.blue-steel:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #395f9d;
  border-color: #243d65;
}

.btn.blue-steel:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #395f9d;
  border-color: #365b96;
}

.btn.blue-steel:not(.btn-outline):active, .btn.blue-steel:not(.btn-outline).active,
.open > .btn.blue-steel:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #395f9d;
  border-color: #365b96;
}

.btn.blue-steel:not(.btn-outline):active:hover, .btn.blue-steel:not(.btn-outline):active:focus, .btn.blue-steel:not(.btn-outline):active.focus, .btn.blue-steel:not(.btn-outline).active:hover, .btn.blue-steel:not(.btn-outline).active:focus, .btn.blue-steel:not(.btn-outline).active.focus,
.open > .btn.blue-steel:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-steel:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-steel:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2f4f83;
  border-color: #243d65;
}

.btn.blue-steel:not(.btn-outline):active, .btn.blue-steel:not(.btn-outline).active,
.open > .btn.blue-steel:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-steel:not(.btn-outline).disabled:hover, .btn.blue-steel:not(.btn-outline).disabled:focus, .btn.blue-steel:not(.btn-outline).disabled.focus, .btn.blue-steel:not(.btn-outline)[disabled]:hover, .btn.blue-steel:not(.btn-outline)[disabled]:focus, .btn.blue-steel:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-steel:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-steel:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-steel:not(.btn-outline).focus {
  background-color: #4B77BE;
  border-color: #4B77BE;
}

.btn.blue-steel:not(.btn-outline) .badge {
  color: #4B77BE;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-steel {
  border-color: #4B77BE;
  color: #4B77BE;
  background: none;
}

.btn.btn-outline.blue-steel:hover, .btn.btn-outline.blue-steel:active, .btn.btn-outline.blue-steel:active:hover, .btn.btn-outline.blue-steel:active:focus, .btn.btn-outline.blue-steel:focus, .btn.btn-outline.blue-steel.active {
  border-color: #4B77BE;
  color: #FFFFFF;
  background-color: #4B77BE;
}

.btn.blue-steel-stripe {
  border-left: 4px solid #4B77BE !important;
}

.btn.blue-soft:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #4c87b9;
  border-color: #4c87b9;
}

.btn.blue-soft:not(.btn-outline):focus, .btn.blue-soft:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3b6d97;
  border-color: #264560;
}

.btn.blue-soft:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3b6d97;
  border-color: #386890;
}

.btn.blue-soft:not(.btn-outline):active, .btn.blue-soft:not(.btn-outline).active,
.open > .btn.blue-soft:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3b6d97;
  border-color: #386890;
}

.btn.blue-soft:not(.btn-outline):active:hover, .btn.blue-soft:not(.btn-outline):active:focus, .btn.blue-soft:not(.btn-outline):active.focus, .btn.blue-soft:not(.btn-outline).active:hover, .btn.blue-soft:not(.btn-outline).active:focus, .btn.blue-soft:not(.btn-outline).active.focus,
.open > .btn.blue-soft:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-soft:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #315a7d;
  border-color: #264560;
}

.btn.blue-soft:not(.btn-outline):active, .btn.blue-soft:not(.btn-outline).active,
.open > .btn.blue-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-soft:not(.btn-outline).disabled:hover, .btn.blue-soft:not(.btn-outline).disabled:focus, .btn.blue-soft:not(.btn-outline).disabled.focus, .btn.blue-soft:not(.btn-outline)[disabled]:hover, .btn.blue-soft:not(.btn-outline)[disabled]:focus, .btn.blue-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-soft:not(.btn-outline).focus {
  background-color: #4c87b9;
  border-color: #4c87b9;
}

.btn.blue-soft:not(.btn-outline) .badge {
  color: #4c87b9;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-soft {
  border-color: #4c87b9;
  color: #4c87b9;
  background: none;
}

.btn.btn-outline.blue-soft:hover, .btn.btn-outline.blue-soft:active, .btn.btn-outline.blue-soft:active:hover, .btn.btn-outline.blue-soft:active:focus, .btn.btn-outline.blue-soft:focus, .btn.btn-outline.blue-soft.active {
  border-color: #4c87b9;
  color: #FFFFFF;
  background-color: #4c87b9;
}

.btn.blue-soft-stripe {
  border-left: 4px solid #4c87b9 !important;
}

.btn.blue-dark:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #5e738b;
  border-color: #5e738b;
}

.btn.blue-dark:not(.btn-outline):focus, .btn.blue-dark:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #495a6d;
  border-color: #2b343f;
}

.btn.blue-dark:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #495a6d;
  border-color: #455566;
}

.btn.blue-dark:not(.btn-outline):active, .btn.blue-dark:not(.btn-outline).active,
.open > .btn.blue-dark:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #495a6d;
  border-color: #455566;
}

.btn.blue-dark:not(.btn-outline):active:hover, .btn.blue-dark:not(.btn-outline):active:focus, .btn.blue-dark:not(.btn-outline):active.focus, .btn.blue-dark:not(.btn-outline).active:hover, .btn.blue-dark:not(.btn-outline).active:focus, .btn.blue-dark:not(.btn-outline).active.focus,
.open > .btn.blue-dark:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-dark:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-dark:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #3b4857;
  border-color: #2b343f;
}

.btn.blue-dark:not(.btn-outline):active, .btn.blue-dark:not(.btn-outline).active,
.open > .btn.blue-dark:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-dark:not(.btn-outline).disabled:hover, .btn.blue-dark:not(.btn-outline).disabled:focus, .btn.blue-dark:not(.btn-outline).disabled.focus, .btn.blue-dark:not(.btn-outline)[disabled]:hover, .btn.blue-dark:not(.btn-outline)[disabled]:focus, .btn.blue-dark:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-dark:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-dark:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-dark:not(.btn-outline).focus {
  background-color: #5e738b;
  border-color: #5e738b;
}

.btn.blue-dark:not(.btn-outline) .badge {
  color: #5e738b;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-dark {
  border-color: #5e738b;
  color: #5e738b;
  background: none;
}

.btn.btn-outline.blue-dark:hover, .btn.btn-outline.blue-dark:active, .btn.btn-outline.blue-dark:active:hover, .btn.btn-outline.blue-dark:active:focus, .btn.btn-outline.blue-dark:focus, .btn.btn-outline.blue-dark.active {
  border-color: #5e738b;
  color: #FFFFFF;
  background-color: #5e738b;
}

.btn.blue-dark-stripe {
  border-left: 4px solid #5e738b !important;
}

.btn.blue-sharp:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #5C9BD1;
  border-color: #5C9BD1;
}

.btn.blue-sharp:not(.btn-outline):focus, .btn.blue-sharp:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3782c3;
  border-color: #265a87;
}

.btn.blue-sharp:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3782c3;
  border-color: #357dbb;
}

.btn.blue-sharp:not(.btn-outline):active, .btn.blue-sharp:not(.btn-outline).active,
.open > .btn.blue-sharp:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3782c3;
  border-color: #357dbb;
}

.btn.blue-sharp:not(.btn-outline):active:hover, .btn.blue-sharp:not(.btn-outline):active:focus, .btn.blue-sharp:not(.btn-outline):active.focus, .btn.blue-sharp:not(.btn-outline).active:hover, .btn.blue-sharp:not(.btn-outline).active:focus, .btn.blue-sharp:not(.btn-outline).active.focus,
.open > .btn.blue-sharp:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.blue-sharp:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.blue-sharp:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2f70a7;
  border-color: #265a87;
}

.btn.blue-sharp:not(.btn-outline):active, .btn.blue-sharp:not(.btn-outline).active,
.open > .btn.blue-sharp:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-sharp:not(.btn-outline).disabled:hover, .btn.blue-sharp:not(.btn-outline).disabled:focus, .btn.blue-sharp:not(.btn-outline).disabled.focus, .btn.blue-sharp:not(.btn-outline)[disabled]:hover, .btn.blue-sharp:not(.btn-outline)[disabled]:focus, .btn.blue-sharp:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-sharp:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-sharp:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-sharp:not(.btn-outline).focus {
  background-color: #5C9BD1;
  border-color: #5C9BD1;
}

.btn.blue-sharp:not(.btn-outline) .badge {
  color: #5C9BD1;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-sharp {
  border-color: #5C9BD1;
  color: #5C9BD1;
  background: none;
}

.btn.btn-outline.blue-sharp:hover, .btn.btn-outline.blue-sharp:active, .btn.btn-outline.blue-sharp:active:hover, .btn.btn-outline.blue-sharp:active:focus, .btn.btn-outline.blue-sharp:focus, .btn.btn-outline.blue-sharp.active {
  border-color: #5C9BD1;
  color: #FFFFFF;
  background-color: #5C9BD1;
}

.btn.blue-sharp-stripe {
  border-left: 4px solid #5C9BD1 !important;
}

.btn.green:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #32c5d2;
  border-color: #32c5d2;
}

.btn.green:not(.btn-outline):focus, .btn.green:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #26a1ab;
  border-color: #18666d;
}

.btn.green:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #26a1ab;
  border-color: #2499a3;
}

.btn.green:not(.btn-outline):active, .btn.green:not(.btn-outline).active,
.open > .btn.green:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #26a1ab;
  border-color: #2499a3;
}

.btn.green:not(.btn-outline):active:hover, .btn.green:not(.btn-outline):active:focus, .btn.green:not(.btn-outline):active.focus, .btn.green:not(.btn-outline).active:hover, .btn.green:not(.btn-outline).active:focus, .btn.green:not(.btn-outline).active.focus,
.open > .btn.green:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1f858e;
  border-color: #18666d;
}

.btn.green:not(.btn-outline):active, .btn.green:not(.btn-outline).active,
.open > .btn.green:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green:not(.btn-outline).disabled:hover, .btn.green:not(.btn-outline).disabled:focus, .btn.green:not(.btn-outline).disabled.focus, .btn.green:not(.btn-outline)[disabled]:hover, .btn.green:not(.btn-outline)[disabled]:focus, .btn.green:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green:not(.btn-outline):hover,
fieldset[disabled] .btn.green:not(.btn-outline):focus,
fieldset[disabled] .btn.green:not(.btn-outline).focus {
  background-color: #32c5d2;
  border-color: #32c5d2;
}

.btn.green:not(.btn-outline) .badge {
  color: #32c5d2;
  background-color: #FFFFFF;
}

.btn.btn-outline.green {
  border-color: #32c5d2;
  color: #32c5d2;
  background: none;
}

.btn.btn-outline.green:hover, .btn.btn-outline.green:active, .btn.btn-outline.green:active:hover, .btn.btn-outline.green:active:focus, .btn.btn-outline.green:focus, .btn.btn-outline.green.active {
  border-color: #32c5d2;
  color: #FFFFFF;
  background-color: #32c5d2;
}

.btn.green-stripe {
  border-left: 4px solid #32c5d2 !important;
}

.btn.green-meadow:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #1BBC9B;
  border-color: #1BBC9B;
}

.btn.green-meadow:not(.btn-outline):focus, .btn.green-meadow:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #158f76;
  border-color: #0b4d3f;
}

.btn.green-meadow:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #158f76;
  border-color: #13866f;
}

.btn.green-meadow:not(.btn-outline):active, .btn.green-meadow:not(.btn-outline).active,
.open > .btn.green-meadow:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #158f76;
  border-color: #13866f;
}

.btn.green-meadow:not(.btn-outline):active:hover, .btn.green-meadow:not(.btn-outline):active:focus, .btn.green-meadow:not(.btn-outline):active.focus, .btn.green-meadow:not(.btn-outline).active:hover, .btn.green-meadow:not(.btn-outline).active:focus, .btn.green-meadow:not(.btn-outline).active.focus,
.open > .btn.green-meadow:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-meadow:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-meadow:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #10705c;
  border-color: #0b4d3f;
}

.btn.green-meadow:not(.btn-outline):active, .btn.green-meadow:not(.btn-outline).active,
.open > .btn.green-meadow:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-meadow:not(.btn-outline).disabled:hover, .btn.green-meadow:not(.btn-outline).disabled:focus, .btn.green-meadow:not(.btn-outline).disabled.focus, .btn.green-meadow:not(.btn-outline)[disabled]:hover, .btn.green-meadow:not(.btn-outline)[disabled]:focus, .btn.green-meadow:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-meadow:not(.btn-outline):hover,
fieldset[disabled] .btn.green-meadow:not(.btn-outline):focus,
fieldset[disabled] .btn.green-meadow:not(.btn-outline).focus {
  background-color: #1BBC9B;
  border-color: #1BBC9B;
}

.btn.green-meadow:not(.btn-outline) .badge {
  color: #1BBC9B;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-meadow {
  border-color: #1BBC9B;
  color: #1BBC9B;
  background: none;
}

.btn.btn-outline.green-meadow:hover, .btn.btn-outline.green-meadow:active, .btn.btn-outline.green-meadow:active:hover, .btn.btn-outline.green-meadow:active:focus, .btn.btn-outline.green-meadow:focus, .btn.btn-outline.green-meadow.active {
  border-color: #1BBC9B;
  color: #FFFFFF;
  background-color: #1BBC9B;
}

.btn.green-meadow-stripe {
  border-left: 4px solid #1BBC9B !important;
}

.btn.green-seagreen:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #1BA39C;
  border-color: #1BA39C;
}

.btn.green-seagreen:not(.btn-outline):focus, .btn.green-seagreen:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #147772;
  border-color: #093633;
}

.btn.green-seagreen:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #147772;
  border-color: #126e6a;
}

.btn.green-seagreen:not(.btn-outline):active, .btn.green-seagreen:not(.btn-outline).active,
.open > .btn.green-seagreen:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #147772;
  border-color: #126e6a;
}

.btn.green-seagreen:not(.btn-outline):active:hover, .btn.green-seagreen:not(.btn-outline):active:focus, .btn.green-seagreen:not(.btn-outline):active.focus, .btn.green-seagreen:not(.btn-outline).active:hover, .btn.green-seagreen:not(.btn-outline).active:focus, .btn.green-seagreen:not(.btn-outline).active.focus,
.open > .btn.green-seagreen:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-seagreen:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-seagreen:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0f5955;
  border-color: #093633;
}

.btn.green-seagreen:not(.btn-outline):active, .btn.green-seagreen:not(.btn-outline).active,
.open > .btn.green-seagreen:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-seagreen:not(.btn-outline).disabled:hover, .btn.green-seagreen:not(.btn-outline).disabled:focus, .btn.green-seagreen:not(.btn-outline).disabled.focus, .btn.green-seagreen:not(.btn-outline)[disabled]:hover, .btn.green-seagreen:not(.btn-outline)[disabled]:focus, .btn.green-seagreen:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-seagreen:not(.btn-outline):hover,
fieldset[disabled] .btn.green-seagreen:not(.btn-outline):focus,
fieldset[disabled] .btn.green-seagreen:not(.btn-outline).focus {
  background-color: #1BA39C;
  border-color: #1BA39C;
}

.btn.green-seagreen:not(.btn-outline) .badge {
  color: #1BA39C;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-seagreen {
  border-color: #1BA39C;
  color: #1BA39C;
  background: none;
}

.btn.btn-outline.green-seagreen:hover, .btn.btn-outline.green-seagreen:active, .btn.btn-outline.green-seagreen:active:hover, .btn.btn-outline.green-seagreen:active:focus, .btn.btn-outline.green-seagreen:focus, .btn.btn-outline.green-seagreen.active {
  border-color: #1BA39C;
  color: #FFFFFF;
  background-color: #1BA39C;
}

.btn.green-seagreen-stripe {
  border-left: 4px solid #1BA39C !important;
}

.btn.green-turquoise:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #36D7B7;
  border-color: #36D7B7;
}

.btn.green-turquoise:not(.btn-outline):focus, .btn.green-turquoise:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #24b699;
  border-color: #177663;
}

.btn.green-turquoise:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #24b699;
  border-color: #22ad92;
}

.btn.green-turquoise:not(.btn-outline):active, .btn.green-turquoise:not(.btn-outline).active,
.open > .btn.green-turquoise:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #24b699;
  border-color: #22ad92;
}

.btn.green-turquoise:not(.btn-outline):active:hover, .btn.green-turquoise:not(.btn-outline):active:focus, .btn.green-turquoise:not(.btn-outline):active.focus, .btn.green-turquoise:not(.btn-outline).active:hover, .btn.green-turquoise:not(.btn-outline).active:focus, .btn.green-turquoise:not(.btn-outline).active.focus,
.open > .btn.green-turquoise:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-turquoise:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-turquoise:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1e9880;
  border-color: #177663;
}

.btn.green-turquoise:not(.btn-outline):active, .btn.green-turquoise:not(.btn-outline).active,
.open > .btn.green-turquoise:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-turquoise:not(.btn-outline).disabled:hover, .btn.green-turquoise:not(.btn-outline).disabled:focus, .btn.green-turquoise:not(.btn-outline).disabled.focus, .btn.green-turquoise:not(.btn-outline)[disabled]:hover, .btn.green-turquoise:not(.btn-outline)[disabled]:focus, .btn.green-turquoise:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-turquoise:not(.btn-outline):hover,
fieldset[disabled] .btn.green-turquoise:not(.btn-outline):focus,
fieldset[disabled] .btn.green-turquoise:not(.btn-outline).focus {
  background-color: #36D7B7;
  border-color: #36D7B7;
}

.btn.green-turquoise:not(.btn-outline) .badge {
  color: #36D7B7;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-turquoise {
  border-color: #36D7B7;
  color: #36D7B7;
  background: none;
}

.btn.btn-outline.green-turquoise:hover, .btn.btn-outline.green-turquoise:active, .btn.btn-outline.green-turquoise:active:hover, .btn.btn-outline.green-turquoise:active:focus, .btn.btn-outline.green-turquoise:focus, .btn.btn-outline.green-turquoise.active {
  border-color: #36D7B7;
  color: #FFFFFF;
  background-color: #36D7B7;
}

.btn.green-turquoise-stripe {
  border-left: 4px solid #36D7B7 !important;
}

.btn.green-haze:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #44b6ae;
  border-color: #44b6ae;
}

.btn.green-haze:not(.btn-outline):focus, .btn.green-haze:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #36918b;
  border-color: #215955;
}

.btn.green-haze:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #36918b;
  border-color: #338983;
}

.btn.green-haze:not(.btn-outline):active, .btn.green-haze:not(.btn-outline).active,
.open > .btn.green-haze:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #36918b;
  border-color: #338983;
}

.btn.green-haze:not(.btn-outline):active:hover, .btn.green-haze:not(.btn-outline):active:focus, .btn.green-haze:not(.btn-outline):active.focus, .btn.green-haze:not(.btn-outline).active:hover, .btn.green-haze:not(.btn-outline).active:focus, .btn.green-haze:not(.btn-outline).active.focus,
.open > .btn.green-haze:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-haze:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-haze:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2c7772;
  border-color: #215955;
}

.btn.green-haze:not(.btn-outline):active, .btn.green-haze:not(.btn-outline).active,
.open > .btn.green-haze:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-haze:not(.btn-outline).disabled:hover, .btn.green-haze:not(.btn-outline).disabled:focus, .btn.green-haze:not(.btn-outline).disabled.focus, .btn.green-haze:not(.btn-outline)[disabled]:hover, .btn.green-haze:not(.btn-outline)[disabled]:focus, .btn.green-haze:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-haze:not(.btn-outline):hover,
fieldset[disabled] .btn.green-haze:not(.btn-outline):focus,
fieldset[disabled] .btn.green-haze:not(.btn-outline).focus {
  background-color: #44b6ae;
  border-color: #44b6ae;
}

.btn.green-haze:not(.btn-outline) .badge {
  color: #44b6ae;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-haze {
  border-color: #44b6ae;
  color: #44b6ae;
  background: none;
}

.btn.btn-outline.green-haze:hover, .btn.btn-outline.green-haze:active, .btn.btn-outline.green-haze:active:hover, .btn.btn-outline.green-haze:active:focus, .btn.btn-outline.green-haze:focus, .btn.btn-outline.green-haze.active {
  border-color: #44b6ae;
  color: #FFFFFF;
  background-color: #44b6ae;
}

.btn.green-haze-stripe {
  border-left: 4px solid #44b6ae !important;
}

.btn.green-jungle:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #26C281;
  border-color: #26C281;
}

.btn.green-jungle:not(.btn-outline):focus, .btn.green-jungle:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #1e9765;
  border-color: #11573a;
}

.btn.green-jungle:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #1e9765;
  border-color: #1c8f5f;
}

.btn.green-jungle:not(.btn-outline):active, .btn.green-jungle:not(.btn-outline).active,
.open > .btn.green-jungle:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #1e9765;
  border-color: #1c8f5f;
}

.btn.green-jungle:not(.btn-outline):active:hover, .btn.green-jungle:not(.btn-outline):active:focus, .btn.green-jungle:not(.btn-outline):active.focus, .btn.green-jungle:not(.btn-outline).active:hover, .btn.green-jungle:not(.btn-outline).active:focus, .btn.green-jungle:not(.btn-outline).active.focus,
.open > .btn.green-jungle:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-jungle:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-jungle:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #187a51;
  border-color: #11573a;
}

.btn.green-jungle:not(.btn-outline):active, .btn.green-jungle:not(.btn-outline).active,
.open > .btn.green-jungle:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-jungle:not(.btn-outline).disabled:hover, .btn.green-jungle:not(.btn-outline).disabled:focus, .btn.green-jungle:not(.btn-outline).disabled.focus, .btn.green-jungle:not(.btn-outline)[disabled]:hover, .btn.green-jungle:not(.btn-outline)[disabled]:focus, .btn.green-jungle:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-jungle:not(.btn-outline):hover,
fieldset[disabled] .btn.green-jungle:not(.btn-outline):focus,
fieldset[disabled] .btn.green-jungle:not(.btn-outline).focus {
  background-color: #26C281;
  border-color: #26C281;
}

.btn.green-jungle:not(.btn-outline) .badge {
  color: #26C281;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-jungle {
  border-color: #26C281;
  color: #26C281;
  background: none;
}

.btn.btn-outline.green-jungle:hover, .btn.btn-outline.green-jungle:active, .btn.btn-outline.green-jungle:active:hover, .btn.btn-outline.green-jungle:active:focus, .btn.btn-outline.green-jungle:focus, .btn.btn-outline.green-jungle.active {
  border-color: #26C281;
  color: #FFFFFF;
  background-color: #26C281;
}

.btn.green-jungle-stripe {
  border-left: 4px solid #26C281 !important;
}

.btn.green-soft:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #3faba4;
  border-color: #3faba4;
}

.btn.green-soft:not(.btn-outline):focus, .btn.green-soft:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #318680;
  border-color: #1d4e4b;
}

.btn.green-soft:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #318680;
  border-color: #2f7e79;
}

.btn.green-soft:not(.btn-outline):active, .btn.green-soft:not(.btn-outline).active,
.open > .btn.green-soft:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #318680;
  border-color: #2f7e79;
}

.btn.green-soft:not(.btn-outline):active:hover, .btn.green-soft:not(.btn-outline):active:focus, .btn.green-soft:not(.btn-outline):active.focus, .btn.green-soft:not(.btn-outline).active:hover, .btn.green-soft:not(.btn-outline).active:focus, .btn.green-soft:not(.btn-outline).active.focus,
.open > .btn.green-soft:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-soft:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #286c67;
  border-color: #1d4e4b;
}

.btn.green-soft:not(.btn-outline):active, .btn.green-soft:not(.btn-outline).active,
.open > .btn.green-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-soft:not(.btn-outline).disabled:hover, .btn.green-soft:not(.btn-outline).disabled:focus, .btn.green-soft:not(.btn-outline).disabled.focus, .btn.green-soft:not(.btn-outline)[disabled]:hover, .btn.green-soft:not(.btn-outline)[disabled]:focus, .btn.green-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.green-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.green-soft:not(.btn-outline).focus {
  background-color: #3faba4;
  border-color: #3faba4;
}

.btn.green-soft:not(.btn-outline) .badge {
  color: #3faba4;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-soft {
  border-color: #3faba4;
  color: #3faba4;
  background: none;
}

.btn.btn-outline.green-soft:hover, .btn.btn-outline.green-soft:active, .btn.btn-outline.green-soft:active:hover, .btn.btn-outline.green-soft:active:focus, .btn.btn-outline.green-soft:focus, .btn.btn-outline.green-soft.active {
  border-color: #3faba4;
  color: #FFFFFF;
  background-color: #3faba4;
}

.btn.green-soft-stripe {
  border-left: 4px solid #3faba4 !important;
}

.btn.green-dark:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #4DB3A2;
  border-color: #4DB3A2;
}

.btn.green-dark:not(.btn-outline):focus, .btn.green-dark:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3d9082;
  border-color: #265a51;
}

.btn.green-dark:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3d9082;
  border-color: #3a897b;
}

.btn.green-dark:not(.btn-outline):active, .btn.green-dark:not(.btn-outline).active,
.open > .btn.green-dark:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3d9082;
  border-color: #3a897b;
}

.btn.green-dark:not(.btn-outline):active:hover, .btn.green-dark:not(.btn-outline):active:focus, .btn.green-dark:not(.btn-outline):active.focus, .btn.green-dark:not(.btn-outline).active:hover, .btn.green-dark:not(.btn-outline).active:focus, .btn.green-dark:not(.btn-outline).active.focus,
.open > .btn.green-dark:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-dark:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-dark:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #33776b;
  border-color: #265a51;
}

.btn.green-dark:not(.btn-outline):active, .btn.green-dark:not(.btn-outline).active,
.open > .btn.green-dark:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-dark:not(.btn-outline).disabled:hover, .btn.green-dark:not(.btn-outline).disabled:focus, .btn.green-dark:not(.btn-outline).disabled.focus, .btn.green-dark:not(.btn-outline)[disabled]:hover, .btn.green-dark:not(.btn-outline)[disabled]:focus, .btn.green-dark:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-dark:not(.btn-outline):hover,
fieldset[disabled] .btn.green-dark:not(.btn-outline):focus,
fieldset[disabled] .btn.green-dark:not(.btn-outline).focus {
  background-color: #4DB3A2;
  border-color: #4DB3A2;
}

.btn.green-dark:not(.btn-outline) .badge {
  color: #4DB3A2;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-dark {
  border-color: #4DB3A2;
  color: #4DB3A2;
  background: none;
}

.btn.btn-outline.green-dark:hover, .btn.btn-outline.green-dark:active, .btn.btn-outline.green-dark:active:hover, .btn.btn-outline.green-dark:active:focus, .btn.btn-outline.green-dark:focus, .btn.btn-outline.green-dark.active {
  border-color: #4DB3A2;
  color: #FFFFFF;
  background-color: #4DB3A2;
}

.btn.green-dark-stripe {
  border-left: 4px solid #4DB3A2 !important;
}

.btn.green-sharp:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #2ab4c0;
  border-color: #2ab4c0;
}

.btn.green-sharp:not(.btn-outline):focus, .btn.green-sharp:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #218d96;
  border-color: #135257;
}

.btn.green-sharp:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #218d96;
  border-color: #1f858e;
}

.btn.green-sharp:not(.btn-outline):active, .btn.green-sharp:not(.btn-outline).active,
.open > .btn.green-sharp:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #218d96;
  border-color: #1f858e;
}

.btn.green-sharp:not(.btn-outline):active:hover, .btn.green-sharp:not(.btn-outline):active:focus, .btn.green-sharp:not(.btn-outline):active.focus, .btn.green-sharp:not(.btn-outline).active:hover, .btn.green-sharp:not(.btn-outline).active:focus, .btn.green-sharp:not(.btn-outline).active.focus,
.open > .btn.green-sharp:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.green-sharp:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.green-sharp:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1a7179;
  border-color: #135257;
}

.btn.green-sharp:not(.btn-outline):active, .btn.green-sharp:not(.btn-outline).active,
.open > .btn.green-sharp:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-sharp:not(.btn-outline).disabled:hover, .btn.green-sharp:not(.btn-outline).disabled:focus, .btn.green-sharp:not(.btn-outline).disabled.focus, .btn.green-sharp:not(.btn-outline)[disabled]:hover, .btn.green-sharp:not(.btn-outline)[disabled]:focus, .btn.green-sharp:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-sharp:not(.btn-outline):hover,
fieldset[disabled] .btn.green-sharp:not(.btn-outline):focus,
fieldset[disabled] .btn.green-sharp:not(.btn-outline).focus {
  background-color: #2ab4c0;
  border-color: #2ab4c0;
}

.btn.green-sharp:not(.btn-outline) .badge {
  color: #2ab4c0;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-sharp {
  border-color: #2ab4c0;
  color: #2ab4c0;
  background: none;
}

.btn.btn-outline.green-sharp:hover, .btn.btn-outline.green-sharp:active, .btn.btn-outline.green-sharp:active:hover, .btn.btn-outline.green-sharp:active:focus, .btn.btn-outline.green-sharp:focus, .btn.btn-outline.green-sharp.active {
  border-color: #2ab4c0;
  color: #FFFFFF;
  background-color: #2ab4c0;
}

.btn.green-sharp-stripe {
  border-left: 4px solid #2ab4c0 !important;
}

.btn.grey:not(.btn-outline) {
  color: #333333;
  background-color: #E5E5E5;
  border-color: #E5E5E5;
}

.btn.grey:not(.btn-outline):focus, .btn.grey:not(.btn-outline).focus {
  color: #333333;
  background-color: #cccccc;
  border-color: #a5a5a5;
}

.btn.grey:not(.btn-outline):hover {
  color: #333333;
  background-color: #cccccc;
  border-color: #c6c6c6;
}

.btn.grey:not(.btn-outline):active, .btn.grey:not(.btn-outline).active,
.open > .btn.grey:not(.btn-outline).dropdown-toggle {
  color: #333333;
  background-color: #cccccc;
  border-color: #c6c6c6;
}

.btn.grey:not(.btn-outline):active:hover, .btn.grey:not(.btn-outline):active:focus, .btn.grey:not(.btn-outline):active.focus, .btn.grey:not(.btn-outline).active:hover, .btn.grey:not(.btn-outline).active:focus, .btn.grey:not(.btn-outline).active.focus,
.open > .btn.grey:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey:not(.btn-outline).dropdown-toggle.focus {
  color: #333333;
  background-color: #bababa;
  border-color: #a5a5a5;
}

.btn.grey:not(.btn-outline):active, .btn.grey:not(.btn-outline).active,
.open > .btn.grey:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey:not(.btn-outline).disabled:hover, .btn.grey:not(.btn-outline).disabled:focus, .btn.grey:not(.btn-outline).disabled.focus, .btn.grey:not(.btn-outline)[disabled]:hover, .btn.grey:not(.btn-outline)[disabled]:focus, .btn.grey:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey:not(.btn-outline):hover,
fieldset[disabled] .btn.grey:not(.btn-outline):focus,
fieldset[disabled] .btn.grey:not(.btn-outline).focus {
  background-color: #E5E5E5;
  border-color: #E5E5E5;
}

.btn.grey:not(.btn-outline) .badge {
  color: #E5E5E5;
  background-color: #333333;
}

.btn.btn-outline.grey {
  border-color: #E5E5E5;
  color: #E5E5E5;
  background: none;
}

.btn.btn-outline.grey:hover, .btn.btn-outline.grey:active, .btn.btn-outline.grey:active:hover, .btn.btn-outline.grey:active:focus, .btn.btn-outline.grey:focus, .btn.btn-outline.grey.active {
  border-color: #E5E5E5;
  color: #333333;
  background-color: #E5E5E5;
}

.btn.grey-stripe {
  border-left: 4px solid #E5E5E5 !important;
}

.btn.grey-steel:not(.btn-outline) {
  color: #80898e;
  background-color: #e9edef;
  border-color: #e9edef;
}

.btn.grey-steel:not(.btn-outline):focus, .btn.grey-steel:not(.btn-outline).focus {
  color: #80898e;
  background-color: #cbd5da;
  border-color: #9fb1b9;
}

.btn.grey-steel:not(.btn-outline):hover {
  color: #80898e;
  background-color: #cbd5da;
  border-color: #c6d0d5;
}

.btn.grey-steel:not(.btn-outline):active, .btn.grey-steel:not(.btn-outline).active,
.open > .btn.grey-steel:not(.btn-outline).dropdown-toggle {
  color: #80898e;
  background-color: #cbd5da;
  border-color: #c6d0d5;
}

.btn.grey-steel:not(.btn-outline):active:hover, .btn.grey-steel:not(.btn-outline):active:focus, .btn.grey-steel:not(.btn-outline):active.focus, .btn.grey-steel:not(.btn-outline).active:hover, .btn.grey-steel:not(.btn-outline).active:focus, .btn.grey-steel:not(.btn-outline).active.focus,
.open > .btn.grey-steel:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-steel:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-steel:not(.btn-outline).dropdown-toggle.focus {
  color: #80898e;
  background-color: #b7c4ca;
  border-color: #9fb1b9;
}

.btn.grey-steel:not(.btn-outline):active, .btn.grey-steel:not(.btn-outline).active,
.open > .btn.grey-steel:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-steel:not(.btn-outline).disabled:hover, .btn.grey-steel:not(.btn-outline).disabled:focus, .btn.grey-steel:not(.btn-outline).disabled.focus, .btn.grey-steel:not(.btn-outline)[disabled]:hover, .btn.grey-steel:not(.btn-outline)[disabled]:focus, .btn.grey-steel:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-steel:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-steel:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-steel:not(.btn-outline).focus {
  background-color: #e9edef;
  border-color: #e9edef;
}

.btn.grey-steel:not(.btn-outline) .badge {
  color: #e9edef;
  background-color: #80898e;
}

.btn.btn-outline.grey-steel {
  border-color: #e9edef;
  color: #e9edef;
  background: none;
}

.btn.btn-outline.grey-steel:hover, .btn.btn-outline.grey-steel:active, .btn.btn-outline.grey-steel:active:hover, .btn.btn-outline.grey-steel:active:focus, .btn.btn-outline.grey-steel:focus, .btn.btn-outline.grey-steel.active {
  border-color: #e9edef;
  color: #80898e;
  background-color: #e9edef;
}

.btn.grey-steel-stripe {
  border-left: 4px solid #e9edef !important;
}

.btn.grey-cararra:not(.btn-outline) {
  color: #333333;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn.grey-cararra:not(.btn-outline):focus, .btn.grey-cararra:not(.btn-outline).focus {
  color: #333333;
  background-color: #e1e1e1;
  border-color: #bababa;
}

.btn.grey-cararra:not(.btn-outline):hover {
  color: #333333;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}

.btn.grey-cararra:not(.btn-outline):active, .btn.grey-cararra:not(.btn-outline).active,
.open > .btn.grey-cararra:not(.btn-outline).dropdown-toggle {
  color: #333333;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}

.btn.grey-cararra:not(.btn-outline):active:hover, .btn.grey-cararra:not(.btn-outline):active:focus, .btn.grey-cararra:not(.btn-outline):active.focus, .btn.grey-cararra:not(.btn-outline).active:hover, .btn.grey-cararra:not(.btn-outline).active:focus, .btn.grey-cararra:not(.btn-outline).active.focus,
.open > .btn.grey-cararra:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-cararra:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-cararra:not(.btn-outline).dropdown-toggle.focus {
  color: #333333;
  background-color: #cfcfcf;
  border-color: #bababa;
}

.btn.grey-cararra:not(.btn-outline):active, .btn.grey-cararra:not(.btn-outline).active,
.open > .btn.grey-cararra:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-cararra:not(.btn-outline).disabled:hover, .btn.grey-cararra:not(.btn-outline).disabled:focus, .btn.grey-cararra:not(.btn-outline).disabled.focus, .btn.grey-cararra:not(.btn-outline)[disabled]:hover, .btn.grey-cararra:not(.btn-outline)[disabled]:focus, .btn.grey-cararra:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-cararra:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-cararra:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-cararra:not(.btn-outline).focus {
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn.grey-cararra:not(.btn-outline) .badge {
  color: #fafafa;
  background-color: #333333;
}

.btn.btn-outline.grey-cararra {
  border-color: #fafafa;
  color: #fafafa;
  background: none;
}

.btn.btn-outline.grey-cararra:hover, .btn.btn-outline.grey-cararra:active, .btn.btn-outline.grey-cararra:active:hover, .btn.btn-outline.grey-cararra:active:focus, .btn.btn-outline.grey-cararra:focus, .btn.btn-outline.grey-cararra.active {
  border-color: #fafafa;
  color: #333333;
  background-color: #fafafa;
}

.btn.grey-cararra-stripe {
  border-left: 4px solid #fafafa !important;
}

.btn.grey-gallery:not(.btn-outline) {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}

.btn.grey-gallery:not(.btn-outline):focus, .btn.grey-gallery:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #3c3c3c;
  border-color: #151515;
}

.btn.grey-gallery:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #3c3c3c;
  border-color: #363636;
}

.btn.grey-gallery:not(.btn-outline):active, .btn.grey-gallery:not(.btn-outline).active,
.open > .btn.grey-gallery:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #3c3c3c;
  border-color: #363636;
}

.btn.grey-gallery:not(.btn-outline):active:hover, .btn.grey-gallery:not(.btn-outline):active:focus, .btn.grey-gallery:not(.btn-outline):active.focus, .btn.grey-gallery:not(.btn-outline).active:hover, .btn.grey-gallery:not(.btn-outline).active:focus, .btn.grey-gallery:not(.btn-outline).active.focus,
.open > .btn.grey-gallery:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-gallery:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-gallery:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #2a2a2a;
  border-color: #151515;
}

.btn.grey-gallery:not(.btn-outline):active, .btn.grey-gallery:not(.btn-outline).active,
.open > .btn.grey-gallery:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-gallery:not(.btn-outline).disabled:hover, .btn.grey-gallery:not(.btn-outline).disabled:focus, .btn.grey-gallery:not(.btn-outline).disabled.focus, .btn.grey-gallery:not(.btn-outline)[disabled]:hover, .btn.grey-gallery:not(.btn-outline)[disabled]:focus, .btn.grey-gallery:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-gallery:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-gallery:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-gallery:not(.btn-outline).focus {
  background-color: #555555;
  border-color: #555555;
}

.btn.grey-gallery:not(.btn-outline) .badge {
  color: #555555;
  background-color: #ffffff;
}

.btn.btn-outline.grey-gallery {
  border-color: #555555;
  color: #555555;
  background: none;
}

.btn.btn-outline.grey-gallery:hover, .btn.btn-outline.grey-gallery:active, .btn.btn-outline.grey-gallery:active:hover, .btn.btn-outline.grey-gallery:active:focus, .btn.btn-outline.grey-gallery:focus, .btn.btn-outline.grey-gallery.active {
  border-color: #555555;
  color: #ffffff;
  background-color: #555555;
}

.btn.grey-gallery-stripe {
  border-left: 4px solid #555555 !important;
}

.btn.grey-cascade:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #95A5A6;
  border-color: #95A5A6;
}

.btn.grey-cascade:not(.btn-outline):focus, .btn.grey-cascade:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #798d8f;
  border-color: #566566;
}

.btn.grey-cascade:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #798d8f;
  border-color: #74898a;
}

.btn.grey-cascade:not(.btn-outline):active, .btn.grey-cascade:not(.btn-outline).active,
.open > .btn.grey-cascade:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #798d8f;
  border-color: #74898a;
}

.btn.grey-cascade:not(.btn-outline):active:hover, .btn.grey-cascade:not(.btn-outline):active:focus, .btn.grey-cascade:not(.btn-outline):active.focus, .btn.grey-cascade:not(.btn-outline).active:hover, .btn.grey-cascade:not(.btn-outline).active:focus, .btn.grey-cascade:not(.btn-outline).active.focus,
.open > .btn.grey-cascade:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-cascade:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-cascade:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #687b7c;
  border-color: #566566;
}

.btn.grey-cascade:not(.btn-outline):active, .btn.grey-cascade:not(.btn-outline).active,
.open > .btn.grey-cascade:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-cascade:not(.btn-outline).disabled:hover, .btn.grey-cascade:not(.btn-outline).disabled:focus, .btn.grey-cascade:not(.btn-outline).disabled.focus, .btn.grey-cascade:not(.btn-outline)[disabled]:hover, .btn.grey-cascade:not(.btn-outline)[disabled]:focus, .btn.grey-cascade:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-cascade:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-cascade:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-cascade:not(.btn-outline).focus {
  background-color: #95A5A6;
  border-color: #95A5A6;
}

.btn.grey-cascade:not(.btn-outline) .badge {
  color: #95A5A6;
  background-color: #FFFFFF;
}

.btn.btn-outline.grey-cascade {
  border-color: #95A5A6;
  color: #95A5A6;
  background: none;
}

.btn.btn-outline.grey-cascade:hover, .btn.btn-outline.grey-cascade:active, .btn.btn-outline.grey-cascade:active:hover, .btn.btn-outline.grey-cascade:active:focus, .btn.btn-outline.grey-cascade:focus, .btn.btn-outline.grey-cascade.active {
  border-color: #95A5A6;
  color: #FFFFFF;
  background-color: #95A5A6;
}

.btn.grey-cascade-stripe {
  border-left: 4px solid #95A5A6 !important;
}

.btn.grey-silver:not(.btn-outline) {
  color: #FAFCFB;
  background-color: #BFBFBF;
  border-color: #BFBFBF;
}

.btn.grey-silver:not(.btn-outline):focus, .btn.grey-silver:not(.btn-outline).focus {
  color: #FAFCFB;
  background-color: #a6a6a6;
  border-color: #7f7f7f;
}

.btn.grey-silver:not(.btn-outline):hover {
  color: #FAFCFB;
  background-color: #a6a6a6;
  border-color: #a0a0a0;
}

.btn.grey-silver:not(.btn-outline):active, .btn.grey-silver:not(.btn-outline).active,
.open > .btn.grey-silver:not(.btn-outline).dropdown-toggle {
  color: #FAFCFB;
  background-color: #a6a6a6;
  border-color: #a0a0a0;
}

.btn.grey-silver:not(.btn-outline):active:hover, .btn.grey-silver:not(.btn-outline):active:focus, .btn.grey-silver:not(.btn-outline):active.focus, .btn.grey-silver:not(.btn-outline).active:hover, .btn.grey-silver:not(.btn-outline).active:focus, .btn.grey-silver:not(.btn-outline).active.focus,
.open > .btn.grey-silver:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-silver:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-silver:not(.btn-outline).dropdown-toggle.focus {
  color: #FAFCFB;
  background-color: #949494;
  border-color: #7f7f7f;
}

.btn.grey-silver:not(.btn-outline):active, .btn.grey-silver:not(.btn-outline).active,
.open > .btn.grey-silver:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-silver:not(.btn-outline).disabled:hover, .btn.grey-silver:not(.btn-outline).disabled:focus, .btn.grey-silver:not(.btn-outline).disabled.focus, .btn.grey-silver:not(.btn-outline)[disabled]:hover, .btn.grey-silver:not(.btn-outline)[disabled]:focus, .btn.grey-silver:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-silver:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-silver:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-silver:not(.btn-outline).focus {
  background-color: #BFBFBF;
  border-color: #BFBFBF;
}

.btn.grey-silver:not(.btn-outline) .badge {
  color: #BFBFBF;
  background-color: #FAFCFB;
}

.btn.btn-outline.grey-silver {
  border-color: #BFBFBF;
  color: #BFBFBF;
  background: none;
}

.btn.btn-outline.grey-silver:hover, .btn.btn-outline.grey-silver:active, .btn.btn-outline.grey-silver:active:hover, .btn.btn-outline.grey-silver:active:focus, .btn.btn-outline.grey-silver:focus, .btn.btn-outline.grey-silver.active {
  border-color: #BFBFBF;
  color: #FAFCFB;
  background-color: #BFBFBF;
}

.btn.grey-silver-stripe {
  border-left: 4px solid #BFBFBF !important;
}

.btn.grey-salsa:not(.btn-outline) {
  color: #FAFCFB;
  background-color: #ACB5C3;
  border-color: #ACB5C3;
}

.btn.grey-salsa:not(.btn-outline):focus, .btn.grey-salsa:not(.btn-outline).focus {
  color: #FAFCFB;
  background-color: #8e9bae;
  border-color: #64748b;
}

.btn.grey-salsa:not(.btn-outline):hover {
  color: #FAFCFB;
  background-color: #8e9bae;
  border-color: #8895a9;
}

.btn.grey-salsa:not(.btn-outline):active, .btn.grey-salsa:not(.btn-outline).active,
.open > .btn.grey-salsa:not(.btn-outline).dropdown-toggle {
  color: #FAFCFB;
  background-color: #8e9bae;
  border-color: #8895a9;
}

.btn.grey-salsa:not(.btn-outline):active:hover, .btn.grey-salsa:not(.btn-outline):active:focus, .btn.grey-salsa:not(.btn-outline):active.focus, .btn.grey-salsa:not(.btn-outline).active:hover, .btn.grey-salsa:not(.btn-outline).active:focus, .btn.grey-salsa:not(.btn-outline).active.focus,
.open > .btn.grey-salsa:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-salsa:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-salsa:not(.btn-outline).dropdown-toggle.focus {
  color: #FAFCFB;
  background-color: #7a889f;
  border-color: #64748b;
}

.btn.grey-salsa:not(.btn-outline):active, .btn.grey-salsa:not(.btn-outline).active,
.open > .btn.grey-salsa:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-salsa:not(.btn-outline).disabled:hover, .btn.grey-salsa:not(.btn-outline).disabled:focus, .btn.grey-salsa:not(.btn-outline).disabled.focus, .btn.grey-salsa:not(.btn-outline)[disabled]:hover, .btn.grey-salsa:not(.btn-outline)[disabled]:focus, .btn.grey-salsa:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-salsa:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-salsa:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-salsa:not(.btn-outline).focus {
  background-color: #ACB5C3;
  border-color: #ACB5C3;
}

.btn.grey-salsa:not(.btn-outline) .badge {
  color: #ACB5C3;
  background-color: #FAFCFB;
}

.btn.btn-outline.grey-salsa {
  border-color: #ACB5C3;
  color: #ACB5C3;
  background: none;
}

.btn.btn-outline.grey-salsa:hover, .btn.btn-outline.grey-salsa:active, .btn.btn-outline.grey-salsa:active:hover, .btn.btn-outline.grey-salsa:active:focus, .btn.btn-outline.grey-salsa:focus, .btn.btn-outline.grey-salsa.active {
  border-color: #ACB5C3;
  color: #FAFCFB;
  background-color: #ACB5C3;
}

.btn.grey-salsa-stripe {
  border-left: 4px solid #ACB5C3 !important;
}

.btn.grey-salt:not(.btn-outline) {
  color: #FAFCFB;
  background-color: #bfcad1;
  border-color: #bfcad1;
}

.btn.grey-salt:not(.btn-outline):focus, .btn.grey-salt:not(.btn-outline).focus {
  color: #FAFCFB;
  background-color: #a1b1bc;
  border-color: #758d9c;
}

.btn.grey-salt:not(.btn-outline):hover {
  color: #FAFCFB;
  background-color: #a1b1bc;
  border-color: #9badb7;
}

.btn.grey-salt:not(.btn-outline):active, .btn.grey-salt:not(.btn-outline).active,
.open > .btn.grey-salt:not(.btn-outline).dropdown-toggle {
  color: #FAFCFB;
  background-color: #a1b1bc;
  border-color: #9badb7;
}

.btn.grey-salt:not(.btn-outline):active:hover, .btn.grey-salt:not(.btn-outline):active:focus, .btn.grey-salt:not(.btn-outline):active.focus, .btn.grey-salt:not(.btn-outline).active:hover, .btn.grey-salt:not(.btn-outline).active:focus, .btn.grey-salt:not(.btn-outline).active.focus,
.open > .btn.grey-salt:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-salt:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-salt:not(.btn-outline).dropdown-toggle.focus {
  color: #FAFCFB;
  background-color: #8da0ad;
  border-color: #758d9c;
}

.btn.grey-salt:not(.btn-outline):active, .btn.grey-salt:not(.btn-outline).active,
.open > .btn.grey-salt:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-salt:not(.btn-outline).disabled:hover, .btn.grey-salt:not(.btn-outline).disabled:focus, .btn.grey-salt:not(.btn-outline).disabled.focus, .btn.grey-salt:not(.btn-outline)[disabled]:hover, .btn.grey-salt:not(.btn-outline)[disabled]:focus, .btn.grey-salt:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-salt:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-salt:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-salt:not(.btn-outline).focus {
  background-color: #bfcad1;
  border-color: #bfcad1;
}

.btn.grey-salt:not(.btn-outline) .badge {
  color: #bfcad1;
  background-color: #FAFCFB;
}

.btn.btn-outline.grey-salt {
  border-color: #bfcad1;
  color: #bfcad1;
  background: none;
}

.btn.btn-outline.grey-salt:hover, .btn.btn-outline.grey-salt:active, .btn.btn-outline.grey-salt:active:hover, .btn.btn-outline.grey-salt:active:focus, .btn.btn-outline.grey-salt:focus, .btn.btn-outline.grey-salt.active {
  border-color: #bfcad1;
  color: #FAFCFB;
  background-color: #bfcad1;
}

.btn.grey-salt-stripe {
  border-left: 4px solid #bfcad1 !important;
}

.btn.grey-mint:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #525e64;
  border-color: #525e64;
}

.btn.grey-mint:not(.btn-outline):focus, .btn.grey-mint:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3b4448;
  border-color: #191c1e;
}

.btn.grey-mint:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3b4448;
  border-color: #363e42;
}

.btn.grey-mint:not(.btn-outline):active, .btn.grey-mint:not(.btn-outline).active,
.open > .btn.grey-mint:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3b4448;
  border-color: #363e42;
}

.btn.grey-mint:not(.btn-outline):active:hover, .btn.grey-mint:not(.btn-outline):active:focus, .btn.grey-mint:not(.btn-outline):active.focus, .btn.grey-mint:not(.btn-outline).active:hover, .btn.grey-mint:not(.btn-outline).active:focus, .btn.grey-mint:not(.btn-outline).active.focus,
.open > .btn.grey-mint:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.grey-mint:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.grey-mint:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2b3134;
  border-color: #191c1e;
}

.btn.grey-mint:not(.btn-outline):active, .btn.grey-mint:not(.btn-outline).active,
.open > .btn.grey-mint:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.grey-mint:not(.btn-outline).disabled:hover, .btn.grey-mint:not(.btn-outline).disabled:focus, .btn.grey-mint:not(.btn-outline).disabled.focus, .btn.grey-mint:not(.btn-outline)[disabled]:hover, .btn.grey-mint:not(.btn-outline)[disabled]:focus, .btn.grey-mint:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.grey-mint:not(.btn-outline):hover,
fieldset[disabled] .btn.grey-mint:not(.btn-outline):focus,
fieldset[disabled] .btn.grey-mint:not(.btn-outline).focus {
  background-color: #525e64;
  border-color: #525e64;
}

.btn.grey-mint:not(.btn-outline) .badge {
  color: #525e64;
  background-color: #FFFFFF;
}

.btn.btn-outline.grey-mint {
  border-color: #525e64;
  color: #525e64;
  background: none;
}

.btn.btn-outline.grey-mint:hover, .btn.btn-outline.grey-mint:active, .btn.btn-outline.grey-mint:active:hover, .btn.btn-outline.grey-mint:active:focus, .btn.btn-outline.grey-mint:focus, .btn.btn-outline.grey-mint.active {
  border-color: #525e64;
  color: #FFFFFF;
  background-color: #525e64;
}

.btn.grey-mint-stripe {
  border-left: 4px solid #525e64 !important;
}

.btn.red:not(.btn-outline) {
  color: #ffffff;
  background-color: #e7505a;
  border-color: #e7505a;
}

.btn.red:not(.btn-outline):focus, .btn.red:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #e12330;
  border-color: #a1161f;
}

.btn.red:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #e12330;
  border-color: #dc1e2b;
}

.btn.red:not(.btn-outline):active, .btn.red:not(.btn-outline).active,
.open > .btn.red:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #e12330;
  border-color: #dc1e2b;
}

.btn.red:not(.btn-outline):active:hover, .btn.red:not(.btn-outline):active:focus, .btn.red:not(.btn-outline):active.focus, .btn.red:not(.btn-outline).active:hover, .btn.red:not(.btn-outline).active:focus, .btn.red:not(.btn-outline).active.focus,
.open > .btn.red:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #c51b26;
  border-color: #a1161f;
}

.btn.red:not(.btn-outline):active, .btn.red:not(.btn-outline).active,
.open > .btn.red:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red:not(.btn-outline).disabled:hover, .btn.red:not(.btn-outline).disabled:focus, .btn.red:not(.btn-outline).disabled.focus, .btn.red:not(.btn-outline)[disabled]:hover, .btn.red:not(.btn-outline)[disabled]:focus, .btn.red:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red:not(.btn-outline):hover,
fieldset[disabled] .btn.red:not(.btn-outline):focus,
fieldset[disabled] .btn.red:not(.btn-outline).focus {
  background-color: #e7505a;
  border-color: #e7505a;
}

.btn.red:not(.btn-outline) .badge {
  color: #e7505a;
  background-color: #ffffff;
}

.btn.btn-outline.red {
  border-color: #e7505a;
  color: #e7505a;
  background: none;
}

.btn.btn-outline.red:hover, .btn.btn-outline.red:active, .btn.btn-outline.red:active:hover, .btn.btn-outline.red:active:focus, .btn.btn-outline.red:focus, .btn.btn-outline.red.active {
  border-color: #e7505a;
  color: #ffffff;
  background-color: #e7505a;
}

.btn.red-stripe {
  border-left: 4px solid #e7505a !important;
}

.btn.red-pink:not(.btn-outline) {
  color: #ffffff;
  background-color: #E08283;
  border-color: #E08283;
}

.btn.red-pink:not(.btn-outline):focus, .btn.red-pink:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #d6595a;
  border-color: #b52d2e;
}

.btn.red-pink:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #d6595a;
  border-color: #d45152;
}

.btn.red-pink:not(.btn-outline):active, .btn.red-pink:not(.btn-outline).active,
.open > .btn.red-pink:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #d6595a;
  border-color: #d45152;
}

.btn.red-pink:not(.btn-outline):active:hover, .btn.red-pink:not(.btn-outline):active:focus, .btn.red-pink:not(.btn-outline):active.focus, .btn.red-pink:not(.btn-outline).active:hover, .btn.red-pink:not(.btn-outline).active:focus, .btn.red-pink:not(.btn-outline).active.focus,
.open > .btn.red-pink:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-pink:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-pink:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #cf3d3e;
  border-color: #b52d2e;
}

.btn.red-pink:not(.btn-outline):active, .btn.red-pink:not(.btn-outline).active,
.open > .btn.red-pink:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-pink:not(.btn-outline).disabled:hover, .btn.red-pink:not(.btn-outline).disabled:focus, .btn.red-pink:not(.btn-outline).disabled.focus, .btn.red-pink:not(.btn-outline)[disabled]:hover, .btn.red-pink:not(.btn-outline)[disabled]:focus, .btn.red-pink:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-pink:not(.btn-outline):hover,
fieldset[disabled] .btn.red-pink:not(.btn-outline):focus,
fieldset[disabled] .btn.red-pink:not(.btn-outline).focus {
  background-color: #E08283;
  border-color: #E08283;
}

.btn.red-pink:not(.btn-outline) .badge {
  color: #E08283;
  background-color: #ffffff;
}

.btn.btn-outline.red-pink {
  border-color: #E08283;
  color: #E08283;
  background: none;
}

.btn.btn-outline.red-pink:hover, .btn.btn-outline.red-pink:active, .btn.btn-outline.red-pink:active:hover, .btn.btn-outline.red-pink:active:focus, .btn.btn-outline.red-pink:focus, .btn.btn-outline.red-pink.active {
  border-color: #E08283;
  color: #ffffff;
  background-color: #E08283;
}

.btn.red-pink-stripe {
  border-left: 4px solid #E08283 !important;
}

.btn.red-sunglo:not(.btn-outline) {
  color: #ffffff;
  background-color: #E26A6A;
  border-color: #E26A6A;
}

.btn.red-sunglo:not(.btn-outline):focus, .btn.red-sunglo:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #da3f3f;
  border-color: #ab2121;
}

.btn.red-sunglo:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #da3f3f;
  border-color: #d83737;
}

.btn.red-sunglo:not(.btn-outline):active, .btn.red-sunglo:not(.btn-outline).active,
.open > .btn.red-sunglo:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #da3f3f;
  border-color: #d83737;
}

.btn.red-sunglo:not(.btn-outline):active:hover, .btn.red-sunglo:not(.btn-outline):active:focus, .btn.red-sunglo:not(.btn-outline):active.focus, .btn.red-sunglo:not(.btn-outline).active:hover, .btn.red-sunglo:not(.btn-outline).active:focus, .btn.red-sunglo:not(.btn-outline).active.focus,
.open > .btn.red-sunglo:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-sunglo:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-sunglo:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #cd2828;
  border-color: #ab2121;
}

.btn.red-sunglo:not(.btn-outline):active, .btn.red-sunglo:not(.btn-outline).active,
.open > .btn.red-sunglo:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-sunglo:not(.btn-outline).disabled:hover, .btn.red-sunglo:not(.btn-outline).disabled:focus, .btn.red-sunglo:not(.btn-outline).disabled.focus, .btn.red-sunglo:not(.btn-outline)[disabled]:hover, .btn.red-sunglo:not(.btn-outline)[disabled]:focus, .btn.red-sunglo:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-sunglo:not(.btn-outline):hover,
fieldset[disabled] .btn.red-sunglo:not(.btn-outline):focus,
fieldset[disabled] .btn.red-sunglo:not(.btn-outline).focus {
  background-color: #E26A6A;
  border-color: #E26A6A;
}

.btn.red-sunglo:not(.btn-outline) .badge {
  color: #E26A6A;
  background-color: #ffffff;
}

.btn.btn-outline.red-sunglo {
  border-color: #E26A6A;
  color: #E26A6A;
  background: none;
}

.btn.btn-outline.red-sunglo:hover, .btn.btn-outline.red-sunglo:active, .btn.btn-outline.red-sunglo:active:hover, .btn.btn-outline.red-sunglo:active:focus, .btn.btn-outline.red-sunglo:focus, .btn.btn-outline.red-sunglo.active {
  border-color: #E26A6A;
  color: #ffffff;
  background-color: #E26A6A;
}

.btn.red-sunglo-stripe {
  border-left: 4px solid #E26A6A !important;
}

.btn.red-intense:not(.btn-outline) {
  color: #ffffff;
  background-color: #e35b5a;
  border-color: #e35b5a;
}

.btn.red-intense:not(.btn-outline):focus, .btn.red-intense:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #dc302e;
  border-color: #a21c1b;
}

.btn.red-intense:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #dc302e;
  border-color: #da2726;
}

.btn.red-intense:not(.btn-outline):active, .btn.red-intense:not(.btn-outline).active,
.open > .btn.red-intense:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #dc302e;
  border-color: #da2726;
}

.btn.red-intense:not(.btn-outline):active:hover, .btn.red-intense:not(.btn-outline):active:focus, .btn.red-intense:not(.btn-outline):active.focus, .btn.red-intense:not(.btn-outline).active:hover, .btn.red-intense:not(.btn-outline).active:focus, .btn.red-intense:not(.btn-outline).active.focus,
.open > .btn.red-intense:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-intense:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-intense:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #c52321;
  border-color: #a21c1b;
}

.btn.red-intense:not(.btn-outline):active, .btn.red-intense:not(.btn-outline).active,
.open > .btn.red-intense:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-intense:not(.btn-outline).disabled:hover, .btn.red-intense:not(.btn-outline).disabled:focus, .btn.red-intense:not(.btn-outline).disabled.focus, .btn.red-intense:not(.btn-outline)[disabled]:hover, .btn.red-intense:not(.btn-outline)[disabled]:focus, .btn.red-intense:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-intense:not(.btn-outline):hover,
fieldset[disabled] .btn.red-intense:not(.btn-outline):focus,
fieldset[disabled] .btn.red-intense:not(.btn-outline).focus {
  background-color: #e35b5a;
  border-color: #e35b5a;
}

.btn.red-intense:not(.btn-outline) .badge {
  color: #e35b5a;
  background-color: #ffffff;
}

.btn.btn-outline.red-intense {
  border-color: #e35b5a;
  color: #e35b5a;
  background: none;
}

.btn.btn-outline.red-intense:hover, .btn.btn-outline.red-intense:active, .btn.btn-outline.red-intense:active:hover, .btn.btn-outline.red-intense:active:focus, .btn.btn-outline.red-intense:focus, .btn.btn-outline.red-intense.active {
  border-color: #e35b5a;
  color: #ffffff;
  background-color: #e35b5a;
}

.btn.red-intense-stripe {
  border-left: 4px solid #e35b5a !important;
}

.btn.red-thunderbird:not(.btn-outline) {
  color: #ffffff;
  background-color: #D91E18;
  border-color: #D91E18;
}

.btn.red-thunderbird:not(.btn-outline):focus, .btn.red-thunderbird:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #ab1813;
  border-color: #660e0b;
}

.btn.red-thunderbird:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #ab1813;
  border-color: #a21612;
}

.btn.red-thunderbird:not(.btn-outline):active, .btn.red-thunderbird:not(.btn-outline).active,
.open > .btn.red-thunderbird:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #ab1813;
  border-color: #a21612;
}

.btn.red-thunderbird:not(.btn-outline):active:hover, .btn.red-thunderbird:not(.btn-outline):active:focus, .btn.red-thunderbird:not(.btn-outline):active.focus, .btn.red-thunderbird:not(.btn-outline).active:hover, .btn.red-thunderbird:not(.btn-outline).active:focus, .btn.red-thunderbird:not(.btn-outline).active.focus,
.open > .btn.red-thunderbird:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-thunderbird:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-thunderbird:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #8b130f;
  border-color: #660e0b;
}

.btn.red-thunderbird:not(.btn-outline):active, .btn.red-thunderbird:not(.btn-outline).active,
.open > .btn.red-thunderbird:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-thunderbird:not(.btn-outline).disabled:hover, .btn.red-thunderbird:not(.btn-outline).disabled:focus, .btn.red-thunderbird:not(.btn-outline).disabled.focus, .btn.red-thunderbird:not(.btn-outline)[disabled]:hover, .btn.red-thunderbird:not(.btn-outline)[disabled]:focus, .btn.red-thunderbird:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-thunderbird:not(.btn-outline):hover,
fieldset[disabled] .btn.red-thunderbird:not(.btn-outline):focus,
fieldset[disabled] .btn.red-thunderbird:not(.btn-outline).focus {
  background-color: #D91E18;
  border-color: #D91E18;
}

.btn.red-thunderbird:not(.btn-outline) .badge {
  color: #D91E18;
  background-color: #ffffff;
}

.btn.btn-outline.red-thunderbird {
  border-color: #D91E18;
  color: #D91E18;
  background: none;
}

.btn.btn-outline.red-thunderbird:hover, .btn.btn-outline.red-thunderbird:active, .btn.btn-outline.red-thunderbird:active:hover, .btn.btn-outline.red-thunderbird:active:focus, .btn.btn-outline.red-thunderbird:focus, .btn.btn-outline.red-thunderbird.active {
  border-color: #D91E18;
  color: #ffffff;
  background-color: #D91E18;
}

.btn.red-thunderbird-stripe {
  border-left: 4px solid #D91E18 !important;
}

.btn.red-flamingo:not(.btn-outline) {
  color: #ffffff;
  background-color: #EF4836;
  border-color: #EF4836;
}

.btn.red-flamingo:not(.btn-outline):focus, .btn.red-flamingo:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #e02612;
  border-color: #991a0c;
}

.btn.red-flamingo:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #e02612;
  border-color: #d72411;
}

.btn.red-flamingo:not(.btn-outline):active, .btn.red-flamingo:not(.btn-outline).active,
.open > .btn.red-flamingo:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #e02612;
  border-color: #d72411;
}

.btn.red-flamingo:not(.btn-outline):active:hover, .btn.red-flamingo:not(.btn-outline):active:focus, .btn.red-flamingo:not(.btn-outline):active.focus, .btn.red-flamingo:not(.btn-outline).active:hover, .btn.red-flamingo:not(.btn-outline).active:focus, .btn.red-flamingo:not(.btn-outline).active.focus,
.open > .btn.red-flamingo:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-flamingo:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-flamingo:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #bf200f;
  border-color: #991a0c;
}

.btn.red-flamingo:not(.btn-outline):active, .btn.red-flamingo:not(.btn-outline).active,
.open > .btn.red-flamingo:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-flamingo:not(.btn-outline).disabled:hover, .btn.red-flamingo:not(.btn-outline).disabled:focus, .btn.red-flamingo:not(.btn-outline).disabled.focus, .btn.red-flamingo:not(.btn-outline)[disabled]:hover, .btn.red-flamingo:not(.btn-outline)[disabled]:focus, .btn.red-flamingo:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-flamingo:not(.btn-outline):hover,
fieldset[disabled] .btn.red-flamingo:not(.btn-outline):focus,
fieldset[disabled] .btn.red-flamingo:not(.btn-outline).focus {
  background-color: #EF4836;
  border-color: #EF4836;
}

.btn.red-flamingo:not(.btn-outline) .badge {
  color: #EF4836;
  background-color: #ffffff;
}

.btn.btn-outline.red-flamingo {
  border-color: #EF4836;
  color: #EF4836;
  background: none;
}

.btn.btn-outline.red-flamingo:hover, .btn.btn-outline.red-flamingo:active, .btn.btn-outline.red-flamingo:active:hover, .btn.btn-outline.red-flamingo:active:focus, .btn.btn-outline.red-flamingo:focus, .btn.btn-outline.red-flamingo.active {
  border-color: #EF4836;
  color: #ffffff;
  background-color: #EF4836;
}

.btn.red-flamingo-stripe {
  border-left: 4px solid #EF4836 !important;
}

.btn.red-soft:not(.btn-outline) {
  color: #ffffff;
  background-color: #d05454;
  border-color: #d05454;
}

.btn.red-soft:not(.btn-outline):focus, .btn.red-soft:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #bd3434;
  border-color: #812323;
}

.btn.red-soft:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #bd3434;
  border-color: #b53232;
}

.btn.red-soft:not(.btn-outline):active, .btn.red-soft:not(.btn-outline).active,
.open > .btn.red-soft:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #bd3434;
  border-color: #b53232;
}

.btn.red-soft:not(.btn-outline):active:hover, .btn.red-soft:not(.btn-outline):active:focus, .btn.red-soft:not(.btn-outline):active.focus, .btn.red-soft:not(.btn-outline).active:hover, .btn.red-soft:not(.btn-outline).active:focus, .btn.red-soft:not(.btn-outline).active.focus,
.open > .btn.red-soft:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-soft:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #a12c2c;
  border-color: #812323;
}

.btn.red-soft:not(.btn-outline):active, .btn.red-soft:not(.btn-outline).active,
.open > .btn.red-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-soft:not(.btn-outline).disabled:hover, .btn.red-soft:not(.btn-outline).disabled:focus, .btn.red-soft:not(.btn-outline).disabled.focus, .btn.red-soft:not(.btn-outline)[disabled]:hover, .btn.red-soft:not(.btn-outline)[disabled]:focus, .btn.red-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.red-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.red-soft:not(.btn-outline).focus {
  background-color: #d05454;
  border-color: #d05454;
}

.btn.red-soft:not(.btn-outline) .badge {
  color: #d05454;
  background-color: #ffffff;
}

.btn.btn-outline.red-soft {
  border-color: #d05454;
  color: #d05454;
  background: none;
}

.btn.btn-outline.red-soft:hover, .btn.btn-outline.red-soft:active, .btn.btn-outline.red-soft:active:hover, .btn.btn-outline.red-soft:active:focus, .btn.btn-outline.red-soft:focus, .btn.btn-outline.red-soft.active {
  border-color: #d05454;
  color: #ffffff;
  background-color: #d05454;
}

.btn.red-soft-stripe {
  border-left: 4px solid #d05454 !important;
}

.btn.red-haze:not(.btn-outline) {
  color: #ffffff;
  background-color: #f36a5a;
  border-color: #f36a5a;
}

.btn.red-haze:not(.btn-outline):focus, .btn.red-haze:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #f03f2a;
  border-color: #c0210e;
}

.btn.red-haze:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #f03f2a;
  border-color: #ef3621;
}

.btn.red-haze:not(.btn-outline):active, .btn.red-haze:not(.btn-outline).active,
.open > .btn.red-haze:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #f03f2a;
  border-color: #ef3621;
}

.btn.red-haze:not(.btn-outline):active:hover, .btn.red-haze:not(.btn-outline):active:focus, .btn.red-haze:not(.btn-outline):active.focus, .btn.red-haze:not(.btn-outline).active:hover, .btn.red-haze:not(.btn-outline).active:focus, .btn.red-haze:not(.btn-outline).active.focus,
.open > .btn.red-haze:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-haze:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-haze:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #e62711;
  border-color: #c0210e;
}

.btn.red-haze:not(.btn-outline):active, .btn.red-haze:not(.btn-outline).active,
.open > .btn.red-haze:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-haze:not(.btn-outline).disabled:hover, .btn.red-haze:not(.btn-outline).disabled:focus, .btn.red-haze:not(.btn-outline).disabled.focus, .btn.red-haze:not(.btn-outline)[disabled]:hover, .btn.red-haze:not(.btn-outline)[disabled]:focus, .btn.red-haze:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-haze:not(.btn-outline):hover,
fieldset[disabled] .btn.red-haze:not(.btn-outline):focus,
fieldset[disabled] .btn.red-haze:not(.btn-outline).focus {
  background-color: #f36a5a;
  border-color: #f36a5a;
}

.btn.red-haze:not(.btn-outline) .badge {
  color: #f36a5a;
  background-color: #ffffff;
}

.btn.btn-outline.red-haze {
  border-color: #f36a5a;
  color: #f36a5a;
  background: none;
}

.btn.btn-outline.red-haze:hover, .btn.btn-outline.red-haze:active, .btn.btn-outline.red-haze:active:hover, .btn.btn-outline.red-haze:active:focus, .btn.btn-outline.red-haze:focus, .btn.btn-outline.red-haze.active {
  border-color: #f36a5a;
  color: #ffffff;
  background-color: #f36a5a;
}

.btn.red-haze-stripe {
  border-left: 4px solid #f36a5a !important;
}

.btn.red-mint:not(.btn-outline) {
  color: #ffffff;
  background-color: #e43a45;
  border-color: #e43a45;
}

.btn.red-mint:not(.btn-outline):focus, .btn.red-mint:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #cf1c28;
  border-color: #8b131b;
}

.btn.red-mint:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #cf1c28;
  border-color: #c61b26;
}

.btn.red-mint:not(.btn-outline):active, .btn.red-mint:not(.btn-outline).active,
.open > .btn.red-mint:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #cf1c28;
  border-color: #c61b26;
}

.btn.red-mint:not(.btn-outline):active:hover, .btn.red-mint:not(.btn-outline):active:focus, .btn.red-mint:not(.btn-outline):active.focus, .btn.red-mint:not(.btn-outline).active:hover, .btn.red-mint:not(.btn-outline).active:focus, .btn.red-mint:not(.btn-outline).active.focus,
.open > .btn.red-mint:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.red-mint:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.red-mint:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #af1822;
  border-color: #8b131b;
}

.btn.red-mint:not(.btn-outline):active, .btn.red-mint:not(.btn-outline).active,
.open > .btn.red-mint:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-mint:not(.btn-outline).disabled:hover, .btn.red-mint:not(.btn-outline).disabled:focus, .btn.red-mint:not(.btn-outline).disabled.focus, .btn.red-mint:not(.btn-outline)[disabled]:hover, .btn.red-mint:not(.btn-outline)[disabled]:focus, .btn.red-mint:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-mint:not(.btn-outline):hover,
fieldset[disabled] .btn.red-mint:not(.btn-outline):focus,
fieldset[disabled] .btn.red-mint:not(.btn-outline).focus {
  background-color: #e43a45;
  border-color: #e43a45;
}

.btn.red-mint:not(.btn-outline) .badge {
  color: #e43a45;
  background-color: #ffffff;
}

.btn.btn-outline.red-mint {
  border-color: #e43a45;
  color: #e43a45;
  background: none;
}

.btn.btn-outline.red-mint:hover, .btn.btn-outline.red-mint:active, .btn.btn-outline.red-mint:active:hover, .btn.btn-outline.red-mint:active:focus, .btn.btn-outline.red-mint:focus, .btn.btn-outline.red-mint.active {
  border-color: #e43a45;
  color: #ffffff;
  background-color: #e43a45;
}

.btn.red-mint-stripe {
  border-left: 4px solid #e43a45 !important;
}

.btn.yellow:not(.btn-outline) {
  color: #ffffff;
  background-color: #c49f47;
  border-color: #c49f47;
}

.btn.yellow:not(.btn-outline):focus, .btn.yellow:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #a48334;
  border-color: #6a5422;
}

.btn.yellow:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #a48334;
  border-color: #9c7c32;
}

.btn.yellow:not(.btn-outline):active, .btn.yellow:not(.btn-outline).active,
.open > .btn.yellow:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #a48334;
  border-color: #9c7c32;
}

.btn.yellow:not(.btn-outline):active:hover, .btn.yellow:not(.btn-outline):active:focus, .btn.yellow:not(.btn-outline):active.focus, .btn.yellow:not(.btn-outline).active:hover, .btn.yellow:not(.btn-outline).active:focus, .btn.yellow:not(.btn-outline).active.focus,
.open > .btn.yellow:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #896d2c;
  border-color: #6a5422;
}

.btn.yellow:not(.btn-outline):active, .btn.yellow:not(.btn-outline).active,
.open > .btn.yellow:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow:not(.btn-outline).disabled:hover, .btn.yellow:not(.btn-outline).disabled:focus, .btn.yellow:not(.btn-outline).disabled.focus, .btn.yellow:not(.btn-outline)[disabled]:hover, .btn.yellow:not(.btn-outline)[disabled]:focus, .btn.yellow:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow:not(.btn-outline).focus {
  background-color: #c49f47;
  border-color: #c49f47;
}

.btn.yellow:not(.btn-outline) .badge {
  color: #c49f47;
  background-color: #ffffff;
}

.btn.btn-outline.yellow {
  border-color: #c49f47;
  color: #c49f47;
  background: none;
}

.btn.btn-outline.yellow:hover, .btn.btn-outline.yellow:active, .btn.btn-outline.yellow:active:hover, .btn.btn-outline.yellow:active:focus, .btn.btn-outline.yellow:focus, .btn.btn-outline.yellow.active {
  border-color: #c49f47;
  color: #ffffff;
  background-color: #c49f47;
}

.btn.yellow-stripe {
  border-left: 4px solid #c49f47 !important;
}

.btn.yellow-gold:not(.btn-outline) {
  color: #ffffff;
  background-color: #E87E04;
  border-color: #E87E04;
}

.btn.yellow-gold:not(.btn-outline):focus, .btn.yellow-gold:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #b66303;
  border-color: #6b3a02;
}

.btn.yellow-gold:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #b66303;
  border-color: #ac5d03;
}

.btn.yellow-gold:not(.btn-outline):active, .btn.yellow-gold:not(.btn-outline).active,
.open > .btn.yellow-gold:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #b66303;
  border-color: #ac5d03;
}

.btn.yellow-gold:not(.btn-outline):active:hover, .btn.yellow-gold:not(.btn-outline):active:focus, .btn.yellow-gold:not(.btn-outline):active.focus, .btn.yellow-gold:not(.btn-outline).active:hover, .btn.yellow-gold:not(.btn-outline).active:focus, .btn.yellow-gold:not(.btn-outline).active.focus,
.open > .btn.yellow-gold:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-gold:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-gold:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #935003;
  border-color: #6b3a02;
}

.btn.yellow-gold:not(.btn-outline):active, .btn.yellow-gold:not(.btn-outline).active,
.open > .btn.yellow-gold:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-gold:not(.btn-outline).disabled:hover, .btn.yellow-gold:not(.btn-outline).disabled:focus, .btn.yellow-gold:not(.btn-outline).disabled.focus, .btn.yellow-gold:not(.btn-outline)[disabled]:hover, .btn.yellow-gold:not(.btn-outline)[disabled]:focus, .btn.yellow-gold:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-gold:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-gold:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-gold:not(.btn-outline).focus {
  background-color: #E87E04;
  border-color: #E87E04;
}

.btn.yellow-gold:not(.btn-outline) .badge {
  color: #E87E04;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-gold {
  border-color: #E87E04;
  color: #E87E04;
  background: none;
}

.btn.btn-outline.yellow-gold:hover, .btn.btn-outline.yellow-gold:active, .btn.btn-outline.yellow-gold:active:hover, .btn.btn-outline.yellow-gold:active:focus, .btn.btn-outline.yellow-gold:focus, .btn.btn-outline.yellow-gold.active {
  border-color: #E87E04;
  color: #ffffff;
  background-color: #E87E04;
}

.btn.yellow-gold-stripe {
  border-left: 4px solid #E87E04 !important;
}

.btn.yellow-casablanca:not(.btn-outline) {
  color: #ffffff;
  background-color: #f2784b;
  border-color: #f2784b;
}

.btn.yellow-casablanca:not(.btn-outline):focus, .btn.yellow-casablanca:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #ef541b;
  border-color: #b1390d;
}

.btn.yellow-casablanca:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #ef541b;
  border-color: #ee4d12;
}

.btn.yellow-casablanca:not(.btn-outline):active, .btn.yellow-casablanca:not(.btn-outline).active,
.open > .btn.yellow-casablanca:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #ef541b;
  border-color: #ee4d12;
}

.btn.yellow-casablanca:not(.btn-outline):active:hover, .btn.yellow-casablanca:not(.btn-outline):active:focus, .btn.yellow-casablanca:not(.btn-outline):active.focus, .btn.yellow-casablanca:not(.btn-outline).active:hover, .btn.yellow-casablanca:not(.btn-outline).active:focus, .btn.yellow-casablanca:not(.btn-outline).active.focus,
.open > .btn.yellow-casablanca:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-casablanca:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-casablanca:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #d74510;
  border-color: #b1390d;
}

.btn.yellow-casablanca:not(.btn-outline):active, .btn.yellow-casablanca:not(.btn-outline).active,
.open > .btn.yellow-casablanca:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-casablanca:not(.btn-outline).disabled:hover, .btn.yellow-casablanca:not(.btn-outline).disabled:focus, .btn.yellow-casablanca:not(.btn-outline).disabled.focus, .btn.yellow-casablanca:not(.btn-outline)[disabled]:hover, .btn.yellow-casablanca:not(.btn-outline)[disabled]:focus, .btn.yellow-casablanca:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-casablanca:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-casablanca:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-casablanca:not(.btn-outline).focus {
  background-color: #f2784b;
  border-color: #f2784b;
}

.btn.yellow-casablanca:not(.btn-outline) .badge {
  color: #f2784b;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-casablanca {
  border-color: #f2784b;
  color: #f2784b;
  background: none;
}

.btn.btn-outline.yellow-casablanca:hover, .btn.btn-outline.yellow-casablanca:active, .btn.btn-outline.yellow-casablanca:active:hover, .btn.btn-outline.yellow-casablanca:active:focus, .btn.btn-outline.yellow-casablanca:focus, .btn.btn-outline.yellow-casablanca.active {
  border-color: #f2784b;
  color: #ffffff;
  background-color: #f2784b;
}

.btn.yellow-casablanca-stripe {
  border-left: 4px solid #f2784b !important;
}

.btn.yellow-crusta:not(.btn-outline) {
  color: #ffffff;
  background-color: #f3c200;
  border-color: #f3c200;
}

.btn.yellow-crusta:not(.btn-outline):focus, .btn.yellow-crusta:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #c09900;
  border-color: #745c00;
}

.btn.yellow-crusta:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #c09900;
  border-color: #b69100;
}

.btn.yellow-crusta:not(.btn-outline):active, .btn.yellow-crusta:not(.btn-outline).active,
.open > .btn.yellow-crusta:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #c09900;
  border-color: #b69100;
}

.btn.yellow-crusta:not(.btn-outline):active:hover, .btn.yellow-crusta:not(.btn-outline):active:focus, .btn.yellow-crusta:not(.btn-outline):active.focus, .btn.yellow-crusta:not(.btn-outline).active:hover, .btn.yellow-crusta:not(.btn-outline).active:focus, .btn.yellow-crusta:not(.btn-outline).active.focus,
.open > .btn.yellow-crusta:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-crusta:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-crusta:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #9c7d00;
  border-color: #745c00;
}

.btn.yellow-crusta:not(.btn-outline):active, .btn.yellow-crusta:not(.btn-outline).active,
.open > .btn.yellow-crusta:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-crusta:not(.btn-outline).disabled:hover, .btn.yellow-crusta:not(.btn-outline).disabled:focus, .btn.yellow-crusta:not(.btn-outline).disabled.focus, .btn.yellow-crusta:not(.btn-outline)[disabled]:hover, .btn.yellow-crusta:not(.btn-outline)[disabled]:focus, .btn.yellow-crusta:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-crusta:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-crusta:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-crusta:not(.btn-outline).focus {
  background-color: #f3c200;
  border-color: #f3c200;
}

.btn.yellow-crusta:not(.btn-outline) .badge {
  color: #f3c200;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-crusta {
  border-color: #f3c200;
  color: #f3c200;
  background: none;
}

.btn.btn-outline.yellow-crusta:hover, .btn.btn-outline.yellow-crusta:active, .btn.btn-outline.yellow-crusta:active:hover, .btn.btn-outline.yellow-crusta:active:focus, .btn.btn-outline.yellow-crusta:focus, .btn.btn-outline.yellow-crusta.active {
  border-color: #f3c200;
  color: #ffffff;
  background-color: #f3c200;
}

.btn.yellow-crusta-stripe {
  border-left: 4px solid #f3c200 !important;
}

.btn.yellow-lemon:not(.btn-outline) {
  color: #ffffff;
  background-color: #F7CA18;
  border-color: #F7CA18;
}

.btn.yellow-lemon:not(.btn-outline):focus, .btn.yellow-lemon:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #d5ab07;
  border-color: #8b7005;
}

.btn.yellow-lemon:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #d5ab07;
  border-color: #cba307;
}

.btn.yellow-lemon:not(.btn-outline):active, .btn.yellow-lemon:not(.btn-outline).active,
.open > .btn.yellow-lemon:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #d5ab07;
  border-color: #cba307;
}

.btn.yellow-lemon:not(.btn-outline):active:hover, .btn.yellow-lemon:not(.btn-outline):active:focus, .btn.yellow-lemon:not(.btn-outline):active.focus, .btn.yellow-lemon:not(.btn-outline).active:hover, .btn.yellow-lemon:not(.btn-outline).active:focus, .btn.yellow-lemon:not(.btn-outline).active.focus,
.open > .btn.yellow-lemon:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-lemon:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-lemon:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #b28f06;
  border-color: #8b7005;
}

.btn.yellow-lemon:not(.btn-outline):active, .btn.yellow-lemon:not(.btn-outline).active,
.open > .btn.yellow-lemon:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-lemon:not(.btn-outline).disabled:hover, .btn.yellow-lemon:not(.btn-outline).disabled:focus, .btn.yellow-lemon:not(.btn-outline).disabled.focus, .btn.yellow-lemon:not(.btn-outline)[disabled]:hover, .btn.yellow-lemon:not(.btn-outline)[disabled]:focus, .btn.yellow-lemon:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-lemon:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-lemon:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-lemon:not(.btn-outline).focus {
  background-color: #F7CA18;
  border-color: #F7CA18;
}

.btn.yellow-lemon:not(.btn-outline) .badge {
  color: #F7CA18;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-lemon {
  border-color: #F7CA18;
  color: #F7CA18;
  background: none;
}

.btn.btn-outline.yellow-lemon:hover, .btn.btn-outline.yellow-lemon:active, .btn.btn-outline.yellow-lemon:active:hover, .btn.btn-outline.yellow-lemon:active:focus, .btn.btn-outline.yellow-lemon:focus, .btn.btn-outline.yellow-lemon.active {
  border-color: #F7CA18;
  color: #ffffff;
  background-color: #F7CA18;
}

.btn.yellow-lemon-stripe {
  border-left: 4px solid #F7CA18 !important;
}

.btn.yellow-saffron:not(.btn-outline) {
  color: #ffffff;
  background-color: #F4D03F;
  border-color: #F4D03F;
}

.btn.yellow-saffron:not(.btn-outline):focus, .btn.yellow-saffron:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #f1c40f;
  border-color: #aa8a0a;
}

.btn.yellow-saffron:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #f1c40f;
  border-color: #e8bd0d;
}

.btn.yellow-saffron:not(.btn-outline):active, .btn.yellow-saffron:not(.btn-outline).active,
.open > .btn.yellow-saffron:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #f1c40f;
  border-color: #e8bd0d;
}

.btn.yellow-saffron:not(.btn-outline):active:hover, .btn.yellow-saffron:not(.btn-outline):active:focus, .btn.yellow-saffron:not(.btn-outline):active.focus, .btn.yellow-saffron:not(.btn-outline).active:hover, .btn.yellow-saffron:not(.btn-outline).active:focus, .btn.yellow-saffron:not(.btn-outline).active.focus,
.open > .btn.yellow-saffron:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-saffron:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-saffron:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #d0a90c;
  border-color: #aa8a0a;
}

.btn.yellow-saffron:not(.btn-outline):active, .btn.yellow-saffron:not(.btn-outline).active,
.open > .btn.yellow-saffron:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-saffron:not(.btn-outline).disabled:hover, .btn.yellow-saffron:not(.btn-outline).disabled:focus, .btn.yellow-saffron:not(.btn-outline).disabled.focus, .btn.yellow-saffron:not(.btn-outline)[disabled]:hover, .btn.yellow-saffron:not(.btn-outline)[disabled]:focus, .btn.yellow-saffron:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-saffron:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-saffron:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-saffron:not(.btn-outline).focus {
  background-color: #F4D03F;
  border-color: #F4D03F;
}

.btn.yellow-saffron:not(.btn-outline) .badge {
  color: #F4D03F;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-saffron {
  border-color: #F4D03F;
  color: #F4D03F;
  background: none;
}

.btn.btn-outline.yellow-saffron:hover, .btn.btn-outline.yellow-saffron:active, .btn.btn-outline.yellow-saffron:active:hover, .btn.btn-outline.yellow-saffron:active:focus, .btn.btn-outline.yellow-saffron:focus, .btn.btn-outline.yellow-saffron.active {
  border-color: #F4D03F;
  color: #ffffff;
  background-color: #F4D03F;
}

.btn.yellow-saffron-stripe {
  border-left: 4px solid #F4D03F !important;
}

.btn.yellow-soft:not(.btn-outline) {
  color: #ffffff;
  background-color: #c8d046;
  border-color: #c8d046;
}

.btn.yellow-soft:not(.btn-outline):focus, .btn.yellow-soft:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #adb52e;
  border-color: #73781e;
}

.btn.yellow-soft:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #adb52e;
  border-color: #a5ad2c;
}

.btn.yellow-soft:not(.btn-outline):active, .btn.yellow-soft:not(.btn-outline).active,
.open > .btn.yellow-soft:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #adb52e;
  border-color: #a5ad2c;
}

.btn.yellow-soft:not(.btn-outline):active:hover, .btn.yellow-soft:not(.btn-outline):active:focus, .btn.yellow-soft:not(.btn-outline):active.focus, .btn.yellow-soft:not(.btn-outline).active:hover, .btn.yellow-soft:not(.btn-outline).active:focus, .btn.yellow-soft:not(.btn-outline).active.focus,
.open > .btn.yellow-soft:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-soft:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #929927;
  border-color: #73781e;
}

.btn.yellow-soft:not(.btn-outline):active, .btn.yellow-soft:not(.btn-outline).active,
.open > .btn.yellow-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-soft:not(.btn-outline).disabled:hover, .btn.yellow-soft:not(.btn-outline).disabled:focus, .btn.yellow-soft:not(.btn-outline).disabled.focus, .btn.yellow-soft:not(.btn-outline)[disabled]:hover, .btn.yellow-soft:not(.btn-outline)[disabled]:focus, .btn.yellow-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-soft:not(.btn-outline).focus {
  background-color: #c8d046;
  border-color: #c8d046;
}

.btn.yellow-soft:not(.btn-outline) .badge {
  color: #c8d046;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-soft {
  border-color: #c8d046;
  color: #c8d046;
  background: none;
}

.btn.btn-outline.yellow-soft:hover, .btn.btn-outline.yellow-soft:active, .btn.btn-outline.yellow-soft:active:hover, .btn.btn-outline.yellow-soft:active:focus, .btn.btn-outline.yellow-soft:focus, .btn.btn-outline.yellow-soft.active {
  border-color: #c8d046;
  color: #ffffff;
  background-color: #c8d046;
}

.btn.yellow-soft-stripe {
  border-left: 4px solid #c8d046 !important;
}

.btn.yellow-haze:not(.btn-outline) {
  color: #ffffff;
  background-color: #c5bf66;
  border-color: #c5bf66;
}

.btn.yellow-haze:not(.btn-outline):focus, .btn.yellow-haze:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #b4ad44;
  border-color: #7c772f;
}

.btn.yellow-haze:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #b4ad44;
  border-color: #aca641;
}

.btn.yellow-haze:not(.btn-outline):active, .btn.yellow-haze:not(.btn-outline).active,
.open > .btn.yellow-haze:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #b4ad44;
  border-color: #aca641;
}

.btn.yellow-haze:not(.btn-outline):active:hover, .btn.yellow-haze:not(.btn-outline):active:focus, .btn.yellow-haze:not(.btn-outline):active.focus, .btn.yellow-haze:not(.btn-outline).active:hover, .btn.yellow-haze:not(.btn-outline).active:focus, .btn.yellow-haze:not(.btn-outline).active.focus,
.open > .btn.yellow-haze:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-haze:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-haze:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #9a943a;
  border-color: #7c772f;
}

.btn.yellow-haze:not(.btn-outline):active, .btn.yellow-haze:not(.btn-outline).active,
.open > .btn.yellow-haze:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-haze:not(.btn-outline).disabled:hover, .btn.yellow-haze:not(.btn-outline).disabled:focus, .btn.yellow-haze:not(.btn-outline).disabled.focus, .btn.yellow-haze:not(.btn-outline)[disabled]:hover, .btn.yellow-haze:not(.btn-outline)[disabled]:focus, .btn.yellow-haze:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-haze:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-haze:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-haze:not(.btn-outline).focus {
  background-color: #c5bf66;
  border-color: #c5bf66;
}

.btn.yellow-haze:not(.btn-outline) .badge {
  color: #c5bf66;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-haze {
  border-color: #c5bf66;
  color: #c5bf66;
  background: none;
}

.btn.btn-outline.yellow-haze:hover, .btn.btn-outline.yellow-haze:active, .btn.btn-outline.yellow-haze:active:hover, .btn.btn-outline.yellow-haze:active:focus, .btn.btn-outline.yellow-haze:focus, .btn.btn-outline.yellow-haze.active {
  border-color: #c5bf66;
  color: #ffffff;
  background-color: #c5bf66;
}

.btn.yellow-haze-stripe {
  border-left: 4px solid #c5bf66 !important;
}

.btn.yellow-mint:not(.btn-outline) {
  color: #ffffff;
  background-color: #c5b96b;
  border-color: #c5b96b;
}

.btn.yellow-mint:not(.btn-outline):focus, .btn.yellow-mint:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #b6a747;
  border-color: #7f7532;
}

.btn.yellow-mint:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #b6a747;
  border-color: #aea044;
}

.btn.yellow-mint:not(.btn-outline):active, .btn.yellow-mint:not(.btn-outline).active,
.open > .btn.yellow-mint:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #b6a747;
  border-color: #aea044;
}

.btn.yellow-mint:not(.btn-outline):active:hover, .btn.yellow-mint:not(.btn-outline):active:focus, .btn.yellow-mint:not(.btn-outline):active.focus, .btn.yellow-mint:not(.btn-outline).active:hover, .btn.yellow-mint:not(.btn-outline).active:focus, .btn.yellow-mint:not(.btn-outline).active.focus,
.open > .btn.yellow-mint:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.yellow-mint:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.yellow-mint:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #9c8f3d;
  border-color: #7f7532;
}

.btn.yellow-mint:not(.btn-outline):active, .btn.yellow-mint:not(.btn-outline).active,
.open > .btn.yellow-mint:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-mint:not(.btn-outline).disabled:hover, .btn.yellow-mint:not(.btn-outline).disabled:focus, .btn.yellow-mint:not(.btn-outline).disabled.focus, .btn.yellow-mint:not(.btn-outline)[disabled]:hover, .btn.yellow-mint:not(.btn-outline)[disabled]:focus, .btn.yellow-mint:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-mint:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-mint:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-mint:not(.btn-outline).focus {
  background-color: #c5b96b;
  border-color: #c5b96b;
}

.btn.yellow-mint:not(.btn-outline) .badge {
  color: #c5b96b;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-mint {
  border-color: #c5b96b;
  color: #c5b96b;
  background: none;
}

.btn.btn-outline.yellow-mint:hover, .btn.btn-outline.yellow-mint:active, .btn.btn-outline.yellow-mint:active:hover, .btn.btn-outline.yellow-mint:active:focus, .btn.btn-outline.yellow-mint:focus, .btn.btn-outline.yellow-mint.active {
  border-color: #c5b96b;
  color: #ffffff;
  background-color: #c5b96b;
}

.btn.yellow-mint-stripe {
  border-left: 4px solid #c5b96b !important;
}

.btn.purple:not(.btn-outline) {
  color: #ffffff;
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple:not(.btn-outline):focus, .btn.purple:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #703688;
  border-color: #432051;
}

.btn.purple:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple:not(.btn-outline):active, .btn.purple:not(.btn-outline).active,
.open > .btn.purple:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple:not(.btn-outline):active:hover, .btn.purple:not(.btn-outline):active:focus, .btn.purple:not(.btn-outline):active.focus, .btn.purple:not(.btn-outline).active:hover, .btn.purple:not(.btn-outline).active:focus, .btn.purple:not(.btn-outline).active.focus,
.open > .btn.purple:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5b2c6f;
  border-color: #432051;
}

.btn.purple:not(.btn-outline):active, .btn.purple:not(.btn-outline).active,
.open > .btn.purple:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple:not(.btn-outline).disabled:hover, .btn.purple:not(.btn-outline).disabled:focus, .btn.purple:not(.btn-outline).disabled.focus, .btn.purple:not(.btn-outline)[disabled]:hover, .btn.purple:not(.btn-outline)[disabled]:focus, .btn.purple:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple:not(.btn-outline):hover,
fieldset[disabled] .btn.purple:not(.btn-outline):focus,
fieldset[disabled] .btn.purple:not(.btn-outline).focus {
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple:not(.btn-outline) .badge {
  color: #8E44AD;
  background-color: #ffffff;
}

.btn.btn-outline.purple {
  border-color: #8E44AD;
  color: #8E44AD;
  background: none;
}

.btn.btn-outline.purple:hover, .btn.btn-outline.purple:active, .btn.btn-outline.purple:active:hover, .btn.btn-outline.purple:active:focus, .btn.btn-outline.purple:focus, .btn.btn-outline.purple.active {
  border-color: #8E44AD;
  color: #ffffff;
  background-color: #8E44AD;
}

.btn.purple-stripe {
  border-left: 4px solid #8E44AD !important;
}

.btn.purple-plum:not(.btn-outline) {
  color: #ffffff;
  background-color: #8775a7;
  border-color: #8775a7;
}

.btn.purple-plum:not(.btn-outline):focus, .btn.purple-plum:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #493d60;
}

.btn.purple-plum:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #685788;
}

.btn.purple-plum:not(.btn-outline):active, .btn.purple-plum:not(.btn-outline).active,
.open > .btn.purple-plum:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #685788;
}

.btn.purple-plum:not(.btn-outline):active:hover, .btn.purple-plum:not(.btn-outline):active:focus, .btn.purple-plum:not(.btn-outline):active.focus, .btn.purple-plum:not(.btn-outline).active:hover, .btn.purple-plum:not(.btn-outline).active:focus, .btn.purple-plum:not(.btn-outline).active.focus,
.open > .btn.purple-plum:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-plum:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-plum:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5d4d78;
  border-color: #493d60;
}

.btn.purple-plum:not(.btn-outline):active, .btn.purple-plum:not(.btn-outline).active,
.open > .btn.purple-plum:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-plum:not(.btn-outline).disabled:hover, .btn.purple-plum:not(.btn-outline).disabled:focus, .btn.purple-plum:not(.btn-outline).disabled.focus, .btn.purple-plum:not(.btn-outline)[disabled]:hover, .btn.purple-plum:not(.btn-outline)[disabled]:focus, .btn.purple-plum:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-plum:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-plum:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-plum:not(.btn-outline).focus {
  background-color: #8775a7;
  border-color: #8775a7;
}

.btn.purple-plum:not(.btn-outline) .badge {
  color: #8775a7;
  background-color: #ffffff;
}

.btn.btn-outline.purple-plum {
  border-color: #8775a7;
  color: #8775a7;
  background: none;
}

.btn.btn-outline.purple-plum:hover, .btn.btn-outline.purple-plum:active, .btn.btn-outline.purple-plum:active:hover, .btn.btn-outline.purple-plum:active:focus, .btn.btn-outline.purple-plum:focus, .btn.btn-outline.purple-plum.active {
  border-color: #8775a7;
  color: #ffffff;
  background-color: #8775a7;
}

.btn.purple-plum-stripe {
  border-left: 4px solid #8775a7 !important;
}

.btn.purple-medium:not(.btn-outline) {
  color: #ffffff;
  background-color: #BF55EC;
  border-color: #BF55EC;
}

.btn.purple-medium:not(.btn-outline):focus, .btn.purple-medium:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #ae27e7;
  border-color: #8013ae;
}

.btn.purple-medium:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #ae27e7;
  border-color: #aa1ee6;
}

.btn.purple-medium:not(.btn-outline):active, .btn.purple-medium:not(.btn-outline).active,
.open > .btn.purple-medium:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #ae27e7;
  border-color: #aa1ee6;
}

.btn.purple-medium:not(.btn-outline):active:hover, .btn.purple-medium:not(.btn-outline):active:focus, .btn.purple-medium:not(.btn-outline):active.focus, .btn.purple-medium:not(.btn-outline).active:hover, .btn.purple-medium:not(.btn-outline).active:focus, .btn.purple-medium:not(.btn-outline).active.focus,
.open > .btn.purple-medium:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-medium:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-medium:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #9b18d3;
  border-color: #8013ae;
}

.btn.purple-medium:not(.btn-outline):active, .btn.purple-medium:not(.btn-outline).active,
.open > .btn.purple-medium:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-medium:not(.btn-outline).disabled:hover, .btn.purple-medium:not(.btn-outline).disabled:focus, .btn.purple-medium:not(.btn-outline).disabled.focus, .btn.purple-medium:not(.btn-outline)[disabled]:hover, .btn.purple-medium:not(.btn-outline)[disabled]:focus, .btn.purple-medium:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-medium:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-medium:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-medium:not(.btn-outline).focus {
  background-color: #BF55EC;
  border-color: #BF55EC;
}

.btn.purple-medium:not(.btn-outline) .badge {
  color: #BF55EC;
  background-color: #ffffff;
}

.btn.btn-outline.purple-medium {
  border-color: #BF55EC;
  color: #BF55EC;
  background: none;
}

.btn.btn-outline.purple-medium:hover, .btn.btn-outline.purple-medium:active, .btn.btn-outline.purple-medium:active:hover, .btn.btn-outline.purple-medium:active:focus, .btn.btn-outline.purple-medium:focus, .btn.btn-outline.purple-medium.active {
  border-color: #BF55EC;
  color: #ffffff;
  background-color: #BF55EC;
}

.btn.purple-medium-stripe {
  border-left: 4px solid #BF55EC !important;
}

.btn.purple-studio:not(.btn-outline) {
  color: #ffffff;
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple-studio:not(.btn-outline):focus, .btn.purple-studio:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #703688;
  border-color: #432051;
}

.btn.purple-studio:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple-studio:not(.btn-outline):active, .btn.purple-studio:not(.btn-outline).active,
.open > .btn.purple-studio:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple-studio:not(.btn-outline):active:hover, .btn.purple-studio:not(.btn-outline):active:focus, .btn.purple-studio:not(.btn-outline):active.focus, .btn.purple-studio:not(.btn-outline).active:hover, .btn.purple-studio:not(.btn-outline).active:focus, .btn.purple-studio:not(.btn-outline).active.focus,
.open > .btn.purple-studio:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-studio:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-studio:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5b2c6f;
  border-color: #432051;
}

.btn.purple-studio:not(.btn-outline):active, .btn.purple-studio:not(.btn-outline).active,
.open > .btn.purple-studio:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-studio:not(.btn-outline).disabled:hover, .btn.purple-studio:not(.btn-outline).disabled:focus, .btn.purple-studio:not(.btn-outline).disabled.focus, .btn.purple-studio:not(.btn-outline)[disabled]:hover, .btn.purple-studio:not(.btn-outline)[disabled]:focus, .btn.purple-studio:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-studio:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-studio:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-studio:not(.btn-outline).focus {
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple-studio:not(.btn-outline) .badge {
  color: #8E44AD;
  background-color: #ffffff;
}

.btn.btn-outline.purple-studio {
  border-color: #8E44AD;
  color: #8E44AD;
  background: none;
}

.btn.btn-outline.purple-studio:hover, .btn.btn-outline.purple-studio:active, .btn.btn-outline.purple-studio:active:hover, .btn.btn-outline.purple-studio:active:focus, .btn.btn-outline.purple-studio:focus, .btn.btn-outline.purple-studio.active {
  border-color: #8E44AD;
  color: #ffffff;
  background-color: #8E44AD;
}

.btn.purple-studio-stripe {
  border-left: 4px solid #8E44AD !important;
}

.btn.purple-wisteria:not(.btn-outline) {
  color: #ffffff;
  background-color: #9B59B6;
  border-color: #9B59B6;
}

.btn.purple-wisteria:not(.btn-outline):focus, .btn.purple-wisteria:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #804399;
  border-color: #532c64;
}

.btn.purple-wisteria:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #804399;
  border-color: #7a4092;
}

.btn.purple-wisteria:not(.btn-outline):active, .btn.purple-wisteria:not(.btn-outline).active,
.open > .btn.purple-wisteria:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #804399;
  border-color: #7a4092;
}

.btn.purple-wisteria:not(.btn-outline):active:hover, .btn.purple-wisteria:not(.btn-outline):active:focus, .btn.purple-wisteria:not(.btn-outline):active.focus, .btn.purple-wisteria:not(.btn-outline).active:hover, .btn.purple-wisteria:not(.btn-outline).active:focus, .btn.purple-wisteria:not(.btn-outline).active.focus,
.open > .btn.purple-wisteria:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-wisteria:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-wisteria:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #6b3880;
  border-color: #532c64;
}

.btn.purple-wisteria:not(.btn-outline):active, .btn.purple-wisteria:not(.btn-outline).active,
.open > .btn.purple-wisteria:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-wisteria:not(.btn-outline).disabled:hover, .btn.purple-wisteria:not(.btn-outline).disabled:focus, .btn.purple-wisteria:not(.btn-outline).disabled.focus, .btn.purple-wisteria:not(.btn-outline)[disabled]:hover, .btn.purple-wisteria:not(.btn-outline)[disabled]:focus, .btn.purple-wisteria:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-wisteria:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-wisteria:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-wisteria:not(.btn-outline).focus {
  background-color: #9B59B6;
  border-color: #9B59B6;
}

.btn.purple-wisteria:not(.btn-outline) .badge {
  color: #9B59B6;
  background-color: #ffffff;
}

.btn.btn-outline.purple-wisteria {
  border-color: #9B59B6;
  color: #9B59B6;
  background: none;
}

.btn.btn-outline.purple-wisteria:hover, .btn.btn-outline.purple-wisteria:active, .btn.btn-outline.purple-wisteria:active:hover, .btn.btn-outline.purple-wisteria:active:focus, .btn.btn-outline.purple-wisteria:focus, .btn.btn-outline.purple-wisteria.active {
  border-color: #9B59B6;
  color: #ffffff;
  background-color: #9B59B6;
}

.btn.purple-wisteria-stripe {
  border-left: 4px solid #9B59B6 !important;
}

.btn.purple-seance:not(.btn-outline) {
  color: #ffffff;
  background-color: #9A12B3;
  border-color: #9A12B3;
}

.btn.purple-seance:not(.btn-outline):focus, .btn.purple-seance:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #720d85;
  border-color: #36063f;
}

.btn.purple-seance:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #720d85;
  border-color: #6a0c7b;
}

.btn.purple-seance:not(.btn-outline):active, .btn.purple-seance:not(.btn-outline).active,
.open > .btn.purple-seance:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #720d85;
  border-color: #6a0c7b;
}

.btn.purple-seance:not(.btn-outline):active:hover, .btn.purple-seance:not(.btn-outline):active:focus, .btn.purple-seance:not(.btn-outline):active.focus, .btn.purple-seance:not(.btn-outline).active:hover, .btn.purple-seance:not(.btn-outline).active:focus, .btn.purple-seance:not(.btn-outline).active.focus,
.open > .btn.purple-seance:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-seance:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-seance:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #560a64;
  border-color: #36063f;
}

.btn.purple-seance:not(.btn-outline):active, .btn.purple-seance:not(.btn-outline).active,
.open > .btn.purple-seance:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-seance:not(.btn-outline).disabled:hover, .btn.purple-seance:not(.btn-outline).disabled:focus, .btn.purple-seance:not(.btn-outline).disabled.focus, .btn.purple-seance:not(.btn-outline)[disabled]:hover, .btn.purple-seance:not(.btn-outline)[disabled]:focus, .btn.purple-seance:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-seance:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-seance:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-seance:not(.btn-outline).focus {
  background-color: #9A12B3;
  border-color: #9A12B3;
}

.btn.purple-seance:not(.btn-outline) .badge {
  color: #9A12B3;
  background-color: #ffffff;
}

.btn.btn-outline.purple-seance {
  border-color: #9A12B3;
  color: #9A12B3;
  background: none;
}

.btn.btn-outline.purple-seance:hover, .btn.btn-outline.purple-seance:active, .btn.btn-outline.purple-seance:active:hover, .btn.btn-outline.purple-seance:active:focus, .btn.btn-outline.purple-seance:focus, .btn.btn-outline.purple-seance.active {
  border-color: #9A12B3;
  color: #ffffff;
  background-color: #9A12B3;
}

.btn.purple-seance-stripe {
  border-left: 4px solid #9A12B3 !important;
}

.btn.purple-intense:not(.btn-outline) {
  color: #ffffff;
  background-color: #8775a7;
  border-color: #8775a7;
}

.btn.purple-intense:not(.btn-outline):focus, .btn.purple-intense:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #493d60;
}

.btn.purple-intense:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #685788;
}

.btn.purple-intense:not(.btn-outline):active, .btn.purple-intense:not(.btn-outline).active,
.open > .btn.purple-intense:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #685788;
}

.btn.purple-intense:not(.btn-outline):active:hover, .btn.purple-intense:not(.btn-outline):active:focus, .btn.purple-intense:not(.btn-outline):active.focus, .btn.purple-intense:not(.btn-outline).active:hover, .btn.purple-intense:not(.btn-outline).active:focus, .btn.purple-intense:not(.btn-outline).active.focus,
.open > .btn.purple-intense:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-intense:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-intense:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5d4d78;
  border-color: #493d60;
}

.btn.purple-intense:not(.btn-outline):active, .btn.purple-intense:not(.btn-outline).active,
.open > .btn.purple-intense:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-intense:not(.btn-outline).disabled:hover, .btn.purple-intense:not(.btn-outline).disabled:focus, .btn.purple-intense:not(.btn-outline).disabled.focus, .btn.purple-intense:not(.btn-outline)[disabled]:hover, .btn.purple-intense:not(.btn-outline)[disabled]:focus, .btn.purple-intense:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-intense:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-intense:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-intense:not(.btn-outline).focus {
  background-color: #8775a7;
  border-color: #8775a7;
}

.btn.purple-intense:not(.btn-outline) .badge {
  color: #8775a7;
  background-color: #ffffff;
}

.btn.btn-outline.purple-intense {
  border-color: #8775a7;
  color: #8775a7;
  background: none;
}

.btn.btn-outline.purple-intense:hover, .btn.btn-outline.purple-intense:active, .btn.btn-outline.purple-intense:active:hover, .btn.btn-outline.purple-intense:active:focus, .btn.btn-outline.purple-intense:focus, .btn.btn-outline.purple-intense.active {
  border-color: #8775a7;
  color: #ffffff;
  background-color: #8775a7;
}

.btn.purple-intense-stripe {
  border-left: 4px solid #8775a7 !important;
}

.btn.purple-sharp:not(.btn-outline) {
  color: #ffffff;
  background-color: #796799;
  border-color: #796799;
}

.btn.purple-sharp:not(.btn-outline):focus, .btn.purple-sharp:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #61527b;
  border-color: #3d344d;
}

.btn.purple-sharp:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #61527b;
  border-color: #5c4e75;
}

.btn.purple-sharp:not(.btn-outline):active, .btn.purple-sharp:not(.btn-outline).active,
.open > .btn.purple-sharp:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #61527b;
  border-color: #5c4e75;
}

.btn.purple-sharp:not(.btn-outline):active:hover, .btn.purple-sharp:not(.btn-outline):active:focus, .btn.purple-sharp:not(.btn-outline):active.focus, .btn.purple-sharp:not(.btn-outline).active:hover, .btn.purple-sharp:not(.btn-outline).active:focus, .btn.purple-sharp:not(.btn-outline).active.focus,
.open > .btn.purple-sharp:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-sharp:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-sharp:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #504465;
  border-color: #3d344d;
}

.btn.purple-sharp:not(.btn-outline):active, .btn.purple-sharp:not(.btn-outline).active,
.open > .btn.purple-sharp:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-sharp:not(.btn-outline).disabled:hover, .btn.purple-sharp:not(.btn-outline).disabled:focus, .btn.purple-sharp:not(.btn-outline).disabled.focus, .btn.purple-sharp:not(.btn-outline)[disabled]:hover, .btn.purple-sharp:not(.btn-outline)[disabled]:focus, .btn.purple-sharp:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-sharp:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-sharp:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-sharp:not(.btn-outline).focus {
  background-color: #796799;
  border-color: #796799;
}

.btn.purple-sharp:not(.btn-outline) .badge {
  color: #796799;
  background-color: #ffffff;
}

.btn.btn-outline.purple-sharp {
  border-color: #796799;
  color: #796799;
  background: none;
}

.btn.btn-outline.purple-sharp:hover, .btn.btn-outline.purple-sharp:active, .btn.btn-outline.purple-sharp:active:hover, .btn.btn-outline.purple-sharp:active:focus, .btn.btn-outline.purple-sharp:focus, .btn.btn-outline.purple-sharp.active {
  border-color: #796799;
  color: #ffffff;
  background-color: #796799;
}

.btn.purple-sharp-stripe {
  border-left: 4px solid #796799 !important;
}

.btn.purple-soft:not(.btn-outline) {
  color: #ffffff;
  background-color: #8877a9;
  border-color: #8877a9;
}

.btn.purple-soft:not(.btn-outline):focus, .btn.purple-soft:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #6e5c91;
  border-color: #4a3e62;
}

.btn.purple-soft:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #6e5c91;
  border-color: #69588b;
}

.btn.purple-soft:not(.btn-outline):active, .btn.purple-soft:not(.btn-outline).active,
.open > .btn.purple-soft:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #6e5c91;
  border-color: #69588b;
}

.btn.purple-soft:not(.btn-outline):active:hover, .btn.purple-soft:not(.btn-outline):active:focus, .btn.purple-soft:not(.btn-outline):active.focus, .btn.purple-soft:not(.btn-outline).active:hover, .btn.purple-soft:not(.btn-outline).active:focus, .btn.purple-soft:not(.btn-outline).active.focus,
.open > .btn.purple-soft:not(.btn-outline).dropdown-toggle:hover,
.open > .btn.purple-soft:not(.btn-outline).dropdown-toggle:focus,
.open > .btn.purple-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5d4e7b;
  border-color: #4a3e62;
}

.btn.purple-soft:not(.btn-outline):active, .btn.purple-soft:not(.btn-outline).active,
.open > .btn.purple-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-soft:not(.btn-outline).disabled:hover, .btn.purple-soft:not(.btn-outline).disabled:focus, .btn.purple-soft:not(.btn-outline).disabled.focus, .btn.purple-soft:not(.btn-outline)[disabled]:hover, .btn.purple-soft:not(.btn-outline)[disabled]:focus, .btn.purple-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-soft:not(.btn-outline).focus {
  background-color: #8877a9;
  border-color: #8877a9;
}

.btn.purple-soft:not(.btn-outline) .badge {
  color: #8877a9;
  background-color: #ffffff;
}

.btn.btn-outline.purple-soft {
  border-color: #8877a9;
  color: #8877a9;
  background: none;
}

.btn.btn-outline.purple-soft:hover, .btn.btn-outline.purple-soft:active, .btn.btn-outline.purple-soft:active:hover, .btn.btn-outline.purple-soft:active:focus, .btn.btn-outline.purple-soft:focus, .btn.btn-outline.purple-soft.active {
  border-color: #8877a9;
  color: #ffffff;
  background-color: #8877a9;
}

.btn.purple-soft-stripe {
  border-left: 4px solid #8877a9 !important;
}

/* Circle Buttons */
.btn-circle {
  border-radius: 25px !important;
}

.btn-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
  height: 34px;
  width: 34px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.btn-icon-only > [class^="icon-"],
.btn-icon-only > i {
  text-align: center;
  margin-top: 2px;
}

.btn-group.btn-group-circle > .btn:first-child {
  border-radius: 25px 0 0 25px !important;
}

.btn-group.btn-group-circle > .btn:last-child {
  border-radius: 0 25px 25px 0 !important;
}

.btn-group.btn-group-devided > .btn {
  margin-right: 5px;
}

.btn-group.btn-group-devided > .btn:last-child {
  margin-right: 0;
}

.btn-group-vertical.btn-group-vertical-circle > .btn:first-child {
  border-radius: 25px 25px 0 0 !important;
}

.btn-group-vertical.btn-group-vertical-circle > .btn:last-child {
  border-radius: 0 0 25px 25px !important;
}

/***
Customized Bootstrap Close Icon 
***/
.close {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  outline: none;
  background-image: url("../images/remove-icon-small.png") !important;
}

/***
Customized Bootstrap Dropdowns
***/
/***
Dropdowns
***/
.dropup.open > .dropdown-toggle,
.dropdown.open > .dropdown-toggle {
  border-color: #ddd;
}

/***
Dropdown Menu
***/
/*rtl:ignore*/
.dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}

.dropdown-menu {
  left: 0/*rtl:auto*/;
  min-width: 175px;
  position: absolute;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  background-color: #ffffff;
  margin: 10px  0px 0px 0px;
  border: 1px solid #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.dropdown-menu li.divider {
  background: #f1f3f6;
}

.dropdown-menu li.header {
  padding: 8px 14px 2px 14px;
}

.dropdown-menu > li > a {
  padding: 8px 16px;
  color: #6f6f6f;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}

.dropdown-menu > li > a > [class^="fa-"],
.dropdown-menu > li > a > [class*=" fa-"] {
  color: #888;
}

.dropdown-menu > li > a > [class^="icon-"],
.dropdown-menu > li > a > [class*=" icon-"] {
  color: #666;
}

.dropdown-menu > li > a > [class^="glyphicon-"],
.dropdown-menu > li > a > [class*=" glyphicon-"] {
  color: #888;
}

.dropdown-menu > li:hover > a, .dropdown-menu > li.active > a, .dropdown-menu > li.active:hover > a {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  filter: none;
}

.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.dropdown-menu.bottom-up:before, .dropdown-menu.bottom-up:after {
  display: none;
}

.dropdown-menu > li:first-child:hover > a {
  border-radius: 4px 4px 0px 0px;
}

.dropdown-menu > li:last-child:hover > a {
  border-radius: 0px 0px 4px 4px;
}

.dropdown-hover:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-menu,
.dropdown-toggle > .dropdown-menu,
.btn-group > .dropdown-menu {
  margin-top: 10px;
}

.dropdown > .dropdown-menu:before,
.dropdown-toggle > .dropdown-menu:before,
.btn-group > .dropdown-menu:before {
  position: absolute;
  top: -8px;
  left: 9px;
  right: auto;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e0e0e0;
  border-left: 8px solid transparent;
  content: '';
}

.dropdown > .dropdown-menu:after,
.dropdown-toggle > .dropdown-menu:after,
.btn-group > .dropdown-menu:after {
  position: absolute;
  top: -7px;
  left: 10px;
  right: auto;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: '';
}

.dropdown > .dropdown-menu.pull-left:before,
.dropdown-toggle > .dropdown-menu.pull-left:before,
.btn-group > .dropdown-menu.pull-left:before {
  left: auto;
  right: 9px;
}

.dropdown > .dropdown-menu.pull-left:after,
.dropdown-toggle > .dropdown-menu.pull-left:after,
.btn-group > .dropdown-menu.pull-left:after {
  left: auto;
  right: 10px;
}

.dropdown > .dropdown-menu.pull-right:before,
.dropdown-toggle > .dropdown-menu.pull-right:before,
.btn-group > .dropdown-menu.pull-right:before {
  left: auto;
  right: 9px;
}

.dropdown > .dropdown-menu.pull-right:after,
.dropdown-toggle > .dropdown-menu.pull-right:after,
.btn-group > .dropdown-menu.pull-right:after {
  left: auto;
  right: 10px;
}

.dropdown.dropup > .dropdown-menu,
.dropdown-toggle.dropup > .dropdown-menu,
.btn-group.dropup > .dropdown-menu {
  margin-top: 0px;
  margin-bottom: 10px;
}

.dropdown.dropup > .dropdown-menu:after, .dropdown.dropup > .dropdown-menu:before,
.dropdown-toggle.dropup > .dropdown-menu:after,
.dropdown-toggle.dropup > .dropdown-menu:before,
.btn-group.dropup > .dropdown-menu:after,
.btn-group.dropup > .dropdown-menu:before {
  display: none !important;
}

.dropdown-toggle:after {
  display: none;
}

/* Dropdown submenu support for Bootsrap 3 */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 5px;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu > a:after {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  right: 7px;
  top: 7px;
  font-family: FontAwesome;
  height: auto;
  content: "\f105";
  font-weight: 300;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
}

.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.nav.pull-right > li > .dropdown-menu:before,
.nav > li > .dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}

.nav.pull-right > li > .dropdown-menu:after,
.nav > li > .dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}

.nav.pull-right > li > .dropdown-menu .dropdown-menu,
.nav > li > .dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
}

@media (max-width: 767px) {
  /* 767px */
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    width: auto;
    margin-top: 0;
    background-color: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 6px 0 6px 13px;
    color: #333;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:active {
    background-color: #eee;
  }
}

/***
Dropdown Checkboxes
***/
.dropdown-content {
  padding: 10px;
}

.dropdown-content form {
  margin: 0;
}

.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-radiobuttons,
.dropdown-checkboxes {
  padding: 5px;
}

.dropdown-radiobuttons label,
.dropdown-checkboxes label {
  display: block;
  font-weight: 300;
  color: #333;
  margin-bottom: 4px;
  margin-top: 4px;
}

.dropdown-radiobuttons label .radio,
.dropdown-checkboxes label .radio {
  margin-right: 3px;
}

/*--------------------------------------------------
    [Dropdown Menu v2]
----------------------------------------------------*/
.dropdown-menu-v2 {
  top: 100%;
  right: 0;
  z-index: 1001;
  display: none;
  float: left;
  min-width: 225px;
  padding: 20px 0;
  margin: 25px 0 0;
  font-size: 13px;
  text-align: left;
  list-style: none;
  position: absolute;
  background: #242b31;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.dropdown-menu-v2:before {
  top: -10px;
  right: 0;
  width: 0;
  height: 0;
  content: ' ';
  position: absolute;
  border-style: solid;
  display: inline-block;
  border-width: 0 0 15px 15px;
  border-color: transparent transparent #242b31 transparent;
}

.dropdown-menu-v2 > li > a {
  clear: both;
  color: #606e7a;
  display: block;
  font-weight: 600;
  padding: 8px 20px;
  position: relative;
  white-space: nowrap;
  line-height: 1.42857143;
  text-transform: uppercase;
}

.dropdown-menu-v2 > li > a:hover, .dropdown-menu-v2 > li > a:focus {
  background: #21282e;
  text-decoration: none;
  color: #009dc7;
}

.dropdown-menu-v2 .active > a,
.dropdown-menu-v2 .active > a:hover,
.dropdown-menu-v2 .active > a:focus,
.dropdown-menu-v2 .active > a,
.dropdown-menu-v2 .active > a:hover,
.dropdown-menu-v2 .active > a:focus,
.dropdown-menu-v2 .active > a,
.dropdown-menu-v2 .active > a:hover,
.dropdown-menu-v2 .active > a:focus {
  outline: 0;
  text-decoration: none;
  background-color: #21282e;
  color: #009dc7;
}

.dropdown-menu-v2 .disabled > a,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus,
.dropdown-menu-v2 .disabled > a,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus,
.dropdown-menu-v2 .disabled > a,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus {
  color: #009dc7;
}

.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu-v2 .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background: #2e353c;
}

.dropdown-menu-v2 .badge {
  top: 8px;
  right: 10px;
  position: absolute;
}

.dropdown-menu-v2.pull-right {
  right: 0;
  left: auto;
}

.open > .dropdown-menu-v2 {
  display: block;
}

.pull-right > .dropdown-menu-v2 {
  right: 0;
  left: auto;
}

/***
Form Layouts
****/
/* Static info */
.static-info {
  margin-bottom: 10px;
}

.static-info .name {
  font-size: 14px;
}

.static-info .value {
  font-size: 14px;
  font-weight: 600;
}

.static-info.align-reverse .name,
.static-info.align-reverse .value {
  text-align: right;
}

/* Help blocks */
.help-block {
  margin-top: 5px;
  margin-bottom: 5px;
}

.help-inline {
  font-size: 13px;
  color: #737373;
  display: inline-block;
  padding: 5px;
}

.form-inline input {
  margin-bottom: 0px !important;
}

/* Control Label */
.control-label {
  margin-top: 1px;
  font-weight: normal;
}

.control-label .required,
.form-group .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

.form {
  padding: 0 !important;
}

.portlet-form .form-body,
.form .form-body {
  padding: 20px;
}

.portlet.light .portlet-form .form-body, .portlet.light
.form .form-body {
  padding-left: 0;
  padding-right: 0;
}

.portlet-form .form-actions,
.form .form-actions {
  padding: 20px 20px;
  margin: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e7ecf1;
  *zoom: 1;
}

.portlet.light .portlet-form .form-actions, .portlet.light
.form .form-actions {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

.portlet-form .form-actions.noborder,
.form .form-actions.noborder {
  border-top: 0;
}

.portlet .portlet-form .form-actions, .portlet
.form .form-actions {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.portlet-form .form-actions:before, .portlet-form .form-actions:after,
.form .form-actions:before,
.form .form-actions:after {
  content: " ";
  display: table;
}

.portlet-form .form-actions:after,
.form .form-actions:after {
  clear: both;
}

.portlet-form .form-actions.right,
.form .form-actions.right {
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}

.portlet.light .portlet-form .form-actions.right, .portlet.light
.form .form-actions.right {
  padding-right: 0;
}

.portlet-form .form-actions.left,
.form .form-actions.left {
  padding-left: 20px;
  padding-right: 0;
  text-align: left;
}

.portlet.light .portlet-form .form-actions.left, .portlet.light
.form .form-actions.left {
  padding-left: 0;
}

.portlet-form .form-actions.nobg,
.form .form-actions.nobg {
  background-color: transparent;
}

.portlet-form .form-actions.top,
.form .form-actions.top {
  margin-top: 0;
  margin-bottom: 20px;
  border-top: 0;
  border-bottom: 1px solid #e7ecf1;
}

.portlet.light .portlet-form .form-actions.top, .portlet.light
.form .form-actions.top {
  background: none;
}

.portlet-form .form-actions .btn-set,
.form .form-actions .btn-set {
  display: inline-block;
}

@media (max-width: 767px) {
  .portlet-form .form-actions,
  .form .form-actions {
    /* 767px */
  }
  .portlet-form .form-actions .btn-set,
  .form .form-actions .btn-set {
    margin-bottom: 3px;
    margin-top: 3px;
    float: left !important;
  }
}

.portlet-form .form-section,
.form .form-section {
  margin: 30px 0px 30px 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e7ecf1;
}

.form-fit .portlet-form .form-section, .form-fit
.form .form-section {
  margin-left: 20px;
  margin-right: 20px;
}

/* Checkboxes */
.checkbox,
.form-horizontal .checkbox {
  padding: 0;
}

.checkbox > label,
.form-horizontal .checkbox > label {
  padding-left: 0;
}

.checkbox-list > label {
  display: block;
}

.checkbox-list > label.checkbox-inline {
  display: inline-block;
}

.checkbox-list > label.checkbox-inline:first-child {
  padding-left: 0;
}

/* Radio buttons */
.radio-list > label {
  display: block;
}

.radio-list > label.radio-inline {
  display: inline-block;
}

.radio-list > label.radio-inline:first-child {
  padding-left: 0;
}

/* Radio buttons in horizontal forms */
.form-horizontal .radio-list .radio {
  padding-top: 1px;
}

.form-horizontal .radio-list > label {
  margin-bottom: 0;
}

.form-horizontal .radio > span {
  margin-top: 2px;
}

/* Rows seperated form layout */
.form .form-row-seperated .portlet-body {
  padding: 0;
}

.form .form-row-seperated .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
  padding: 15px 0;
}

.form .form-row-seperated .form-group.last {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 13px;
}

.form .form-row-seperated .form-group .help-block {
  margin-bottom: 0;
}

.form .form-row-seperated .form-body {
  padding: 0;
}

.form .form-row-seperated .form-actions {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* Form bordered */
.form .form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
}

.form .form-bordered .form-group > div {
  padding: 15px;
  border-left: 1px solid #efefef;
}

@media (max-width: 991px) {
  .form .form-bordered .form-group > div {
    /* 991px */
    border-left: 0;
  }
}

.form .form-bordered .form-group.last {
  border-bottom: 0;
}

.form .form-bordered .form-group .control-label {
  padding-top: 20px;
}

@media (max-width: 991px) {
  .form .form-bordered .form-group .control-label {
    /* 991px */
    padding-top: 10px;
  }
}

.form .form-bordered .form-group .help-block {
  margin-bottom: 0;
}

.form .form-bordered .form-group .form-control {
  margin: 0;
}

.form .form-bordered .form-body {
  margin: 0;
  padding: 0;
}

.form .form-bordered .form-actions {
  margin-top: 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media (max-width: 991px) {
  .form .form-bordered .form-actions {
    /* 991px */
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* Horizontal bordered form */
.form .form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

.form .form-horizontal.form-bordered.form-row-stripped .form-control {
  background: #fff !important;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) > div {
  background-color: #ffffff;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

/***
Forms
***/
.form-control {
  outline: none !important;
  box-shadow: none !important;
}

.form-control.height-auto {
  height: auto;
}

.input-group-addon > i {
  color: #c5cdda;
}

/***
Custom color buttons 
***/
.form-control.white {
  border-color: #ffffff;
}

.form-control.white:focus {
  border-color: #e6e6e6;
}

.form-control.focus-white:focus {
  border-color: #ffffff;
}

.form-control.default {
  border-color: #e1e5ec;
}

.form-control.default:focus {
  border-color: #c2cad8;
}

.form-control.focus-default:focus {
  border-color: #e1e5ec;
}

.form-control.dark {
  border-color: #2f353b;
}

.form-control.dark:focus {
  border-color: #181c1f;
}

.form-control.focus-dark:focus {
  border-color: #2f353b;
}

.form-control.blue {
  border-color: #3598dc;
}

.form-control.blue:focus {
  border-color: #217ebd;
}

.form-control.focus-blue:focus {
  border-color: #3598dc;
}

.form-control.blue-madison {
  border-color: #578ebe;
}

.form-control.blue-madison:focus {
  border-color: #3f74a3;
}

.form-control.focus-blue-madison:focus {
  border-color: #578ebe;
}

.form-control.blue-chambray {
  border-color: #2C3E50;
}

.form-control.blue-chambray:focus {
  border-color: #1a252f;
}

.form-control.focus-blue-chambray:focus {
  border-color: #2C3E50;
}

.form-control.blue-ebonyclay {
  border-color: #22313F;
}

.form-control.blue-ebonyclay:focus {
  border-color: #10171e;
}

.form-control.focus-blue-ebonyclay:focus {
  border-color: #22313F;
}

.form-control.blue-hoki {
  border-color: #67809F;
}

.form-control.blue-hoki:focus {
  border-color: #526781;
}

.form-control.focus-blue-hoki:focus {
  border-color: #67809F;
}

.form-control.blue-steel {
  border-color: #4B77BE;
}

.form-control.blue-steel:focus {
  border-color: #395f9d;
}

.form-control.focus-blue-steel:focus {
  border-color: #4B77BE;
}

.form-control.blue-soft {
  border-color: #4c87b9;
}

.form-control.blue-soft:focus {
  border-color: #3b6d97;
}

.form-control.focus-blue-soft:focus {
  border-color: #4c87b9;
}

.form-control.blue-dark {
  border-color: #5e738b;
}

.form-control.blue-dark:focus {
  border-color: #495a6d;
}

.form-control.focus-blue-dark:focus {
  border-color: #5e738b;
}

.form-control.blue-sharp {
  border-color: #5C9BD1;
}

.form-control.blue-sharp:focus {
  border-color: #3782c3;
}

.form-control.focus-blue-sharp:focus {
  border-color: #5C9BD1;
}

.form-control.green {
  border-color: #32c5d2;
}

.form-control.green:focus {
  border-color: #26a1ab;
}

.form-control.focus-green:focus {
  border-color: #32c5d2;
}

.form-control.green-meadow {
  border-color: #1BBC9B;
}

.form-control.green-meadow:focus {
  border-color: #158f76;
}

.form-control.focus-green-meadow:focus {
  border-color: #1BBC9B;
}

.form-control.green-seagreen {
  border-color: #1BA39C;
}

.form-control.green-seagreen:focus {
  border-color: #147772;
}

.form-control.focus-green-seagreen:focus {
  border-color: #1BA39C;
}

.form-control.green-turquoise {
  border-color: #36D7B7;
}

.form-control.green-turquoise:focus {
  border-color: #24b699;
}

.form-control.focus-green-turquoise:focus {
  border-color: #36D7B7;
}

.form-control.green-haze {
  border-color: #44b6ae;
}

.form-control.green-haze:focus {
  border-color: #36918b;
}

.form-control.focus-green-haze:focus {
  border-color: #44b6ae;
}

.form-control.green-jungle {
  border-color: #26C281;
}

.form-control.green-jungle:focus {
  border-color: #1e9765;
}

.form-control.focus-green-jungle:focus {
  border-color: #26C281;
}

.form-control.green-soft {
  border-color: #3faba4;
}

.form-control.green-soft:focus {
  border-color: #318680;
}

.form-control.focus-green-soft:focus {
  border-color: #3faba4;
}

.form-control.green-dark {
  border-color: #4DB3A2;
}

.form-control.green-dark:focus {
  border-color: #3d9082;
}

.form-control.focus-green-dark:focus {
  border-color: #4DB3A2;
}

.form-control.green-sharp {
  border-color: #2ab4c0;
}

.form-control.green-sharp:focus {
  border-color: #218d96;
}

.form-control.focus-green-sharp:focus {
  border-color: #2ab4c0;
}

.form-control.grey {
  border-color: #E5E5E5;
}

.form-control.grey:focus {
  border-color: #cccccc;
}

.form-control.focus-grey:focus {
  border-color: #E5E5E5;
}

.form-control.grey-steel {
  border-color: #e9edef;
}

.form-control.grey-steel:focus {
  border-color: #cbd5da;
}

.form-control.focus-grey-steel:focus {
  border-color: #e9edef;
}

.form-control.grey-cararra {
  border-color: #fafafa;
}

.form-control.grey-cararra:focus {
  border-color: #e1e1e1;
}

.form-control.focus-grey-cararra:focus {
  border-color: #fafafa;
}

.form-control.grey-gallery {
  border-color: #555555;
}

.form-control.grey-gallery:focus {
  border-color: #3c3c3c;
}

.form-control.focus-grey-gallery:focus {
  border-color: #555555;
}

.form-control.grey-cascade {
  border-color: #95A5A6;
}

.form-control.grey-cascade:focus {
  border-color: #798d8f;
}

.form-control.focus-grey-cascade:focus {
  border-color: #95A5A6;
}

.form-control.grey-silver {
  border-color: #BFBFBF;
}

.form-control.grey-silver:focus {
  border-color: #a6a6a6;
}

.form-control.focus-grey-silver:focus {
  border-color: #BFBFBF;
}

.form-control.grey-salsa {
  border-color: #ACB5C3;
}

.form-control.grey-salsa:focus {
  border-color: #8e9bae;
}

.form-control.focus-grey-salsa:focus {
  border-color: #ACB5C3;
}

.form-control.grey-salt {
  border-color: #bfcad1;
}

.form-control.grey-salt:focus {
  border-color: #a1b1bc;
}

.form-control.focus-grey-salt:focus {
  border-color: #bfcad1;
}

.form-control.grey-mint {
  border-color: #525e64;
}

.form-control.grey-mint:focus {
  border-color: #3b4448;
}

.form-control.focus-grey-mint:focus {
  border-color: #525e64;
}

.form-control.red {
  border-color: #e7505a;
}

.form-control.red:focus {
  border-color: #e12330;
}

.form-control.focus-red:focus {
  border-color: #e7505a;
}

.form-control.red-pink {
  border-color: #E08283;
}

.form-control.red-pink:focus {
  border-color: #d6595a;
}

.form-control.focus-red-pink:focus {
  border-color: #E08283;
}

.form-control.red-sunglo {
  border-color: #E26A6A;
}

.form-control.red-sunglo:focus {
  border-color: #da3f3f;
}

.form-control.focus-red-sunglo:focus {
  border-color: #E26A6A;
}

.form-control.red-intense {
  border-color: #e35b5a;
}

.form-control.red-intense:focus {
  border-color: #dc302e;
}

.form-control.focus-red-intense:focus {
  border-color: #e35b5a;
}

.form-control.red-thunderbird {
  border-color: #D91E18;
}

.form-control.red-thunderbird:focus {
  border-color: #ab1813;
}

.form-control.focus-red-thunderbird:focus {
  border-color: #D91E18;
}

.form-control.red-flamingo {
  border-color: #EF4836;
}

.form-control.red-flamingo:focus {
  border-color: #e02612;
}

.form-control.focus-red-flamingo:focus {
  border-color: #EF4836;
}

.form-control.red-soft {
  border-color: #d05454;
}

.form-control.red-soft:focus {
  border-color: #bd3434;
}

.form-control.focus-red-soft:focus {
  border-color: #d05454;
}

.form-control.red-haze {
  border-color: #f36a5a;
}

.form-control.red-haze:focus {
  border-color: #f03f2a;
}

.form-control.focus-red-haze:focus {
  border-color: #f36a5a;
}

.form-control.red-mint {
  border-color: #e43a45;
}

.form-control.red-mint:focus {
  border-color: #cf1c28;
}

.form-control.focus-red-mint:focus {
  border-color: #e43a45;
}

.form-control.yellow {
  border-color: #c49f47;
}

.form-control.yellow:focus {
  border-color: #a48334;
}

.form-control.focus-yellow:focus {
  border-color: #c49f47;
}

.form-control.yellow-gold {
  border-color: #E87E04;
}

.form-control.yellow-gold:focus {
  border-color: #b66303;
}

.form-control.focus-yellow-gold:focus {
  border-color: #E87E04;
}

.form-control.yellow-casablanca {
  border-color: #f2784b;
}

.form-control.yellow-casablanca:focus {
  border-color: #ef541b;
}

.form-control.focus-yellow-casablanca:focus {
  border-color: #f2784b;
}

.form-control.yellow-crusta {
  border-color: #f3c200;
}

.form-control.yellow-crusta:focus {
  border-color: #c09900;
}

.form-control.focus-yellow-crusta:focus {
  border-color: #f3c200;
}

.form-control.yellow-lemon {
  border-color: #F7CA18;
}

.form-control.yellow-lemon:focus {
  border-color: #d5ab07;
}

.form-control.focus-yellow-lemon:focus {
  border-color: #F7CA18;
}

.form-control.yellow-saffron {
  border-color: #F4D03F;
}

.form-control.yellow-saffron:focus {
  border-color: #f1c40f;
}

.form-control.focus-yellow-saffron:focus {
  border-color: #F4D03F;
}

.form-control.yellow-soft {
  border-color: #c8d046;
}

.form-control.yellow-soft:focus {
  border-color: #adb52e;
}

.form-control.focus-yellow-soft:focus {
  border-color: #c8d046;
}

.form-control.yellow-haze {
  border-color: #c5bf66;
}

.form-control.yellow-haze:focus {
  border-color: #b4ad44;
}

.form-control.focus-yellow-haze:focus {
  border-color: #c5bf66;
}

.form-control.yellow-mint {
  border-color: #c5b96b;
}

.form-control.yellow-mint:focus {
  border-color: #b6a747;
}

.form-control.focus-yellow-mint:focus {
  border-color: #c5b96b;
}

.form-control.purple {
  border-color: #8E44AD;
}

.form-control.purple:focus {
  border-color: #703688;
}

.form-control.focus-purple:focus {
  border-color: #8E44AD;
}

.form-control.purple-plum {
  border-color: #8775a7;
}

.form-control.purple-plum:focus {
  border-color: #6d5b8e;
}

.form-control.focus-purple-plum:focus {
  border-color: #8775a7;
}

.form-control.purple-medium {
  border-color: #BF55EC;
}

.form-control.purple-medium:focus {
  border-color: #ae27e7;
}

.form-control.focus-purple-medium:focus {
  border-color: #BF55EC;
}

.form-control.purple-studio {
  border-color: #8E44AD;
}

.form-control.purple-studio:focus {
  border-color: #703688;
}

.form-control.focus-purple-studio:focus {
  border-color: #8E44AD;
}

.form-control.purple-wisteria {
  border-color: #9B59B6;
}

.form-control.purple-wisteria:focus {
  border-color: #804399;
}

.form-control.focus-purple-wisteria:focus {
  border-color: #9B59B6;
}

.form-control.purple-seance {
  border-color: #9A12B3;
}

.form-control.purple-seance:focus {
  border-color: #720d85;
}

.form-control.focus-purple-seance:focus {
  border-color: #9A12B3;
}

.form-control.purple-intense {
  border-color: #8775a7;
}

.form-control.purple-intense:focus {
  border-color: #6d5b8e;
}

.form-control.focus-purple-intense:focus {
  border-color: #8775a7;
}

.form-control.purple-sharp {
  border-color: #796799;
}

.form-control.purple-sharp:focus {
  border-color: #61527b;
}

.form-control.focus-purple-sharp:focus {
  border-color: #796799;
}

.form-control.purple-soft {
  border-color: #8877a9;
}

.form-control.purple-soft:focus {
  border-color: #6e5c91;
}

.form-control.focus-purple-soft:focus {
  border-color: #8877a9;
}

.input-xxs {
  width: 45px !important;
}

/* Form uneditable input */
.uneditable-input {
  padding: 6px 12px;
  min-width: 206px;
  font-size: 14px;
  font-weight: normal;
  height: 34px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #c2cad8;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control-static {
  display: inline-block;
  margin: 0;
}

/* Form input sizing */
.input-mini {
  width: 45px !important;
}

.input-xsmall {
  width: 80px !important;
}

.input-small {
  width: 145px !important;
}

.input-medium {
  width: 240px !important;
}

.input-large {
  width: 320px !important;
}

.input-xlarge {
  width: 420px !important;
}

@media (max-width: 768px) {
  /* 768px */
  .input-large {
    width: 250px !important;
  }
  .input-xlarge {
    width: 300px !important;
  }
}

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.input-fixed {
  overflow: hidden;
}

.input-fixed {
  overflow: hidden;
}

.form-group .input-inline {
  margin-right: 5px;
}

@media (max-width: 768px) {
  /* 768px */
  .input-lg {
    width: 250px !important;
  }
  .input-xlg {
    width: 300px !important;
  }
}

/* Circle Inputs */
.input-circle {
  border-radius: 25px !important;
}

.input-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.input-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.input-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.input-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

/***
Custom icon buttons
***/
.icon-btn {
  height: 60px;
  min-width: 80px;
  margin: 5px 5px 0 0;
  border: 1px solid #ddd;
  padding: 12px 0px 0px 0px;
  background-color: #fafafa;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  color: #646464;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.icon-btn:hover {
  text-decoration: none;
  border-color: #999;
  color: #444;
  text-shadow: 0 1px 0px white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-btn:hover > .badge {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-btn > div {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #3f444a;
  font-size: 12px;
  font-weight: 300;
}

.icon-btn > .badge {
  position: absolute;
  font-size: 11px;
  font-weight: 300;
  top: -5px;
  right: -5px;
  padding: 3px 6px 3px 6px;
  color: white;
  text-shadow: none;
  border-width: 0;
  border-style: solid;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-btn > i {
  font-size: 18px;
}

.ie8 .icon-btn:hover {
  filter: none;
}

/***
Input icons
***/
.input-icon {
  position: relative;
  left: 0;
}

.input-icon > .form-control {
  padding-left: 33px;
}

.input-group .input-icon > .form-control {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.input-icon > i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  font-size: 16px;
  text-align: center;
  left: 0;
}

.modal .input-icon > i {
  z-index: 10055;
}

.has-success .input-icon > i {
  color: #36c6d3;
}

.has-warning .input-icon > i {
  color: #F1C40F;
}

.has-info .input-icon > i {
  color: #659be0;
}

.has-error .input-icon > i {
  color: #ed6b75;
}

.input-icon.right {
  left: auto;
  right: 0;
}

.input-icon.right > .form-control {
  padding-right: 33px;
  padding-left: 12px;
}

.input-group .input-icon.right > .form-control {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.input-icon.right > i {
  left: auto;
  right: 8px;
  float: right;
}

.input-icon.input-icon-lg > i {
  margin-top: 16px;
}

.input-icon.input-icon-sm > i {
  margin-top: 8px;
  font-size: 13px;
}

/***
Customized Bootstrap Labels
***/
.label {
  text-shadow: none !important;
  font-size: 14px;
  font-weight: 300;
  padding: 3px 6px 3px 6px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.label.label-sm {
  font-size: 13px;
  padding: 2px 5px 2px 5px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
  font-size: 75%;
}

/* Labels variants */
.label-default {
  background-color: #bac3d0;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #9ca8bb;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #36c6d3;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #27a4b0;
}

.label-info {
  background-color: #659be0;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #3a80d7;
}

.label-warning {
  background-color: #F1C40F;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #c29d0b;
}

.label-danger {
  background-color: #ed6b75;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #e73d4a;
}

/***
Iconic labels
***/
.label.label-icon {
  padding: 4px 0px 4px 4px;
  margin-right: 2px;
  text-align: center !important;
}

.label.label-icon > i {
  font-size: 12px;
  text-align: center !important;
}

.ie8 .label.label-icon,
.ie9 .label.label-icon {
  padding: 3px 0px 3px 3px;
}

/***
Text states
***/
.text-default {
  color: #bac3d0;
}

.text-primary {
  color: #337ab7;
}

.text-success {
  color: #36c6d3;
}

.text-info {
  color: #659be0;
}

.text-warning {
  color: #F1C40F;
}

.text-danger {
  color: #ed6b75;
}

/***
Customized List Group
***/
/* Contextual variants */
.list-group > li:first-child {
  border-radius-topleft: 4px;
  border-radius-topright: 4px;
}

.list-group > li:last-child {
  border-radius-bottomleft: 4px;
  border-radius-bottomright: 4px;
}

.list-group .list-group-item-success {
  color: #27a4b0;
  background-color: #abe7ed;
}

.list-group a.list-group-item-success,
.list-group button.list-group-item-success {
  color: #27a4b0;
}

.list-group a.list-group-item-success .list-group-item-heading,
.list-group button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-success:hover, .list-group a.list-group-item-success:focus,
.list-group button.list-group-item-success:hover,
.list-group button.list-group-item-success:focus {
  color: #27a4b0;
  background-color: #96e1e8;
}

.list-group a.list-group-item-success.active, .list-group a.list-group-item-success.active:hover, .list-group a.list-group-item-success.active:focus,
.list-group button.list-group-item-success.active,
.list-group button.list-group-item-success.active:hover,
.list-group button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #27a4b0;
  border-color: #27a4b0;
}

.list-group .list-group-item-info {
  color: #327ad5;
  background-color: #e0ebf9;
}

.list-group a.list-group-item-info,
.list-group button.list-group-item-info {
  color: #327ad5;
}

.list-group a.list-group-item-info .list-group-item-heading,
.list-group button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-info:hover, .list-group a.list-group-item-info:focus,
.list-group button.list-group-item-info:hover,
.list-group button.list-group-item-info:focus {
  color: #327ad5;
  background-color: #caddf4;
}

.list-group a.list-group-item-info.active, .list-group a.list-group-item-info.active:hover, .list-group a.list-group-item-info.active:focus,
.list-group button.list-group-item-info.active,
.list-group button.list-group-item-info.active:hover,
.list-group button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #327ad5;
  border-color: #327ad5;
}

.list-group .list-group-item-warning {
  color: #927608;
  background-color: #f9e8a0;
}

.list-group a.list-group-item-warning,
.list-group button.list-group-item-warning {
  color: #927608;
}

.list-group a.list-group-item-warning .list-group-item-heading,
.list-group button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-warning:hover, .list-group a.list-group-item-warning:focus,
.list-group button.list-group-item-warning:hover,
.list-group button.list-group-item-warning:focus {
  color: #927608;
  background-color: #f8e287;
}

.list-group a.list-group-item-warning.active, .list-group a.list-group-item-warning.active:hover, .list-group a.list-group-item-warning.active:focus,
.list-group button.list-group-item-warning.active,
.list-group button.list-group-item-warning.active:hover,
.list-group button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #927608;
  border-color: #927608;
}

.list-group .list-group-item-danger {
  color: #e73d4a;
  background-color: #fbe1e3;
}

.list-group a.list-group-item-danger,
.list-group button.list-group-item-danger {
  color: #e73d4a;
}

.list-group a.list-group-item-danger .list-group-item-heading,
.list-group button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-danger:hover, .list-group a.list-group-item-danger:focus,
.list-group button.list-group-item-danger:hover,
.list-group button.list-group-item-danger:focus {
  color: #e73d4a;
  background-color: #f8cace;
}

.list-group a.list-group-item-danger.active, .list-group a.list-group-item-danger.active:hover, .list-group a.list-group-item-danger.active:focus,
.list-group button.list-group-item-danger.active,
.list-group button.list-group-item-danger.active:hover,
.list-group button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #e73d4a;
  border-color: #e73d4a;
}

/***
UI Loading
***/
.loading-message {
  display: inline-block;
  min-width: 125px;
  margin-left: -60px;
  padding: 10px;
  margin: 0 auto;
  color: #000 !important;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.loading-message.loading-message-boxed {
  border: 1px solid #ddd;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.loading-message > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-loading {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: -60px;
  margin-top: -30px;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.page-loading > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-spinner-bar {
  position: fixed;
  z-index: 10051;
  width: 100px;
  top: 40%;
  left: 50%;
  margin-left: -55px;
  text-align: center;
}

.page-spinner-bar > div {
  margin: 0 5px;
  width: 18px;
  height: 18px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.page-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.page-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.block-spinner-bar {
  display: inline-block;
  width: 80px;
  text-align: center;
}

.block-spinner-bar > div {
  margin: 0 2px;
  width: 15px;
  height: 15px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.block-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.block-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

/***
Metro icons
***/
[class^="m-icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  line-height: 14px;
  vertical-align: top;
  background-image: url(../images/syncfusion-icons.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

[class^="m-icon-big-"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 6px;
  vertical-align: middle;
  background-image: url(../images/syncfusion-icons.png);
  background-position: 0 0px;
  background-repeat: no-repeat;
}

/* large icons */
.btn.m-icon-big {
  padding: 9px 16px 8px 16px;
}

.btn.m-icon-big.m-icon-only {
  padding: 9px 8px 8px 0px;
}

.btn.m-icon-big [class^="m-icon-big-"] {
  margin: 0 0 0 10px;
}

.btn.m-icon-ony > i {
  margin-left: 0px;
}

/* default icons */
.btn.m-icon {
  padding: 7px 14px 7px 14px;
}

.btn.m-icon [class^="m-icon-"] {
  margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
  padding: 7px 10px 7px 6px;
}

/* white icon */
.m-icon-white {
  background-image: url(../images/syncfusion-icons-white.png);
}

/*  Misc */
.m-icon-swapright {
  background-position: -27px -10px;
}

.m-icon-swapdown {
  background-position: -68px -10px;
}

.m-icon-swapleft {
  background-position: -8px -10px;
}

.m-icon-swapup {
  background-position: -46px -10px;
}

.m-icon-big-swapright {
  background-position: -42px -28px;
}

.m-icon-big-swapdown {
  background-position: -115px -28px;
}

.m-icon-big-swapleft {
  background-position: -6px -28px;
}

.m-icon-big-swapup {
  background-position: -78px -28px;
}

/***
Customized Bootstrap Modal 
***/
.modal {
  z-index: 10050;
  outline: none;
  overflow-y: auto !important;
  /* Fix content shifting to the right on modal open due to scrollbar closed */
}

.page-portlet-fullscreen .modal {
  z-index: 10060;
}

.modal .modal-header {
  border-bottom: 1px solid #EFEFEF;
}

.modal .modal-header h3 {
  font-weight: 300;
}

.modal .modal-header .close {
  margin-top: 0px !important;
}

.modal.draggable-modal .modal-header {
  cursor: move;
}

.modal .modal-dialog {
  z-index: 10051;
}

.modal > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
}

.modal.in .page-loading {
  display: none;
}

.modal-open {
  overflow-y: auto !important;
}

.modal-open-noscroll {
  overflow-y: hidden !important;
}

.modal-backdrop {
  border: 0;
  outline: none;
}

.page-portlet-fullscreen .modal-backdrop {
  z-index: 10059;
}

.modal-backdrop, .modal-backdrop.fade.in {
  background-color: #333 !important;
}

body[ng-controller] .modal-backdrop {
  z-index: 10049 !important;
}

body[ng-controller] .modal {
  z-index: 10050 !important;
}

/* Full width modal */
.modal-full.modal-dialog {
  width: 99%;
}

@media (max-width: 768px) {
  .modal-full.modal-dialog {
    width: auto;
  }
}

/***
Notes
***/
.note {
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  margin-top: 0;
}

.note h1 .close,
.note h2 .close,
.note h3 .close,
.note h4 .close,
.note h5 .close,
.note h6 .close {
  margin-right: -10px;
}

.note p {
  margin: 0;
  font-size: 13px;
}

.note p:last-child {
  margin-bottom: 0;
}

.note code,
.note .highlight {
  background-color: #fff;
}

.note.note-default {
  background-color: white;
  border-color: #b0c1d2;
  color: black;
}

.note.note-default.note-bordered {
  background-color: #eef1f5;
  border-color: #c0cedb;
}

.note.note-default.note-shadow {
  background-color: #f1f4f7;
  border-color: #d1dbe4;
  box-shadow: 5px 5px rgba(212, 221, 230, 0.2);
}

.note.note-success {
  background-color: #c0edf1;
  border-color: #58d0da;
  color: black;
}

.note.note-success.note-bordered {
  background-color: #a7e6ec;
  border-color: #6dd6df;
}

.note.note-success.note-shadow {
  background-color: #abe7ed;
  border-color: #81dbe3;
  box-shadow: 5px 5px rgba(134, 221, 228, 0.2);
}

.note.note-info {
  background-color: #f5f8fd;
  border-color: #8bb4e7;
  color: #010407;
}

.note.note-info.note-bordered {
  background-color: #dbe8f8;
  border-color: #a0c2ec;
}

.note.note-info.note-shadow {
  background-color: #e0ebf9;
  border-color: #b5cff0;
  box-shadow: 5px 5px rgba(185, 210, 241, 0.2);
}

.note.note-warning {
  background-color: #fbedb8;
  border-color: #f4d03f;
  color: black;
}

.note.note-warning.note-bordered {
  background-color: #f9e69b;
  border-color: #f5d657;
}

.note.note-warning.note-shadow {
  background-color: #f9e8a0;
  border-color: #f7dc6f;
  box-shadow: 5px 5px rgba(247, 221, 116, 0.2);
}

.note.note-danger {
  background-color: #fef7f8;
  border-color: #f0868e;
  color: #210406;
}

.note.note-danger.note-bordered {
  background-color: #fbdcde;
  border-color: #f39da3;
}

.note.note-danger.note-shadow {
  background-color: #fbe1e3;
  border-color: #f6b3b8;
  box-shadow: 5px 5px rgba(246, 184, 189, 0.2);
}

/***
Customized Bootstrap Pagination
***/
.pagination {
  margin: 10px 0;
}

.pagination.pagination-circle > li:first-child > a {
  border-radius: 25px 0 0 25px !important;
}

.pagination.pagination-circle > li:last-child > a {
  border-radius: 0 25px 25px 0 !important;
}

/***
Portlets
***/
/* Full Screen portlet mode */
.page-portlet-fullscreen {
  overflow: hidden;
}

/* Basic portlet */
.portlet {
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.portlet.portlet-fullscreen {
  z-index: 10060;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.portlet.portlet-fullscreen > .portlet-body {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}

.portlet.portlet-fullscreen > .portlet-title {
  padding: 0 10px;
}

.portlet > .portlet-title {
  border-bottom: 1px solid #eee;
  padding: 0;
  margin-bottom: 10px;
  min-height: 41px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.portlet > .portlet-title:before, .portlet > .portlet-title:after {
  content: " ";
  display: table;
}

.portlet > .portlet-title:after {
  clear: both;
}

.portlet > .portlet-title > .caption {
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 0;
}

.portlet > .portlet-title > .caption.bold {
  font-weight: 400;
}

.portlet > .portlet-title > .caption > i {
  float: left;
  margin-top: 4px;
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
  color: #666;
}

.portlet > .portlet-title > .caption > i.glyphicon {
  margin-top: 2px;
}

.portlet > .portlet-title > .caption > .caption-helper {
  padding: 0;
  margin: 0;
  line-height: 13px;
  color: #9eacb4;
  font-size: 13px;
  font-weight: 400;
}

.portlet > .portlet-title > .actions {
  float: right;
  display: inline-block;
  padding: 6px 0;
}

.portlet > .portlet-title > .actions > .dropdown-menu i {
  color: #555555;
}

.portlet > .portlet-title > .actions > .btn,
.portlet > .portlet-title > .actions > .btn.btn-sm,
.portlet > .portlet-title > .actions > .btn-group > .btn,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm {
  padding: 4px 10px;
  font-size: 13px;
  line-height: 1.5;
}

.portlet > .portlet-title > .actions > .btn.btn-default,
.portlet > .portlet-title > .actions > .btn.btn-sm.btn-default,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-default,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm.btn-default {
  padding: 3px 9px;
}

.portlet > .portlet-title > .actions > .btn > i,
.portlet > .portlet-title > .actions > .btn.btn-sm > i,
.portlet > .portlet-title > .actions > .btn-group > .btn > i,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm > i {
  font-size: 13px;
}

.portlet > .portlet-title > .actions .btn-icon-only {
  padding: 5px 7px 3px 7px;
}

.portlet > .portlet-title > .actions .btn-icon-only.btn-default {
  padding: 4px 6px 2px 6px;
}

.portlet > .portlet-title > .actions .btn-icon-only.btn-default > i {
  font-size: 14px;
}

.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen {
  font-family: FontAwesome;
  color: #a0a0a0;
  padding-top: 3px;
}

.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen.btn-sm {
  padding: 3px 3px !important;
  height: 27px;
  width: 27px;
}

.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen:before {
  content: "\f065";
}

.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen.on:before {
  content: "\f066";
}

.portlet > .portlet-title > .tools {
  float: right;
  display: inline-block;
  padding: 12px 0 8px 0;
}

.portlet > .portlet-title > .tools > a {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

.portlet > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon.png);
  background-repeat: no-repeat;
  width: 11px;
}

.portlet > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon.png);
  background-repeat: no-repeat;
  width: 12px;
}

.portlet > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon.png);
  width: 13px;
}

.portlet > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon.png);
  width: 14px;
  visibility: visible;
}

.portlet > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon.png);
  width: 14px;
  visibility: visible;
}

.portlet > .portlet-title > .tools > a.fullscreen {
  display: inline-block;
  top: -3px;
  position: relative;
  font-size: 13px;
  font-family: FontAwesome;
  color: #ACACAC;
}

.portlet > .portlet-title > .tools > a.fullscreen:before {
  content: "\f065";
}

.portlet > .portlet-title > .tools > a.fullscreen.on:before {
  content: "\f066";
}

.portlet > .portlet-title > .tools > a:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: 0.8 ;
  filter: alpha(opacity=80) ;
}

.portlet > .portlet-title > .pagination {
  float: right;
  display: inline-block;
  margin: 2px 0 0 0;
  border: 0;
  padding: 4px 0;
}

.portlet > .portlet-title > .nav-tabs {
  background: none;
  margin: 1px 0 0 0;
  float: right;
  display: inline-block;
  border: 0;
}

.portlet > .portlet-title > .nav-tabs > li {
  background: none;
  margin: 0;
  border: 0;
}

.portlet > .portlet-title > .nav-tabs > li > a {
  background: none;
  margin: 5px 0 0 1px;
  border: 0;
  padding: 8px 10px;
  color: #fff;
}

.portlet > .portlet-title > .nav-tabs > li.active > a,
.portlet > .portlet-title > .nav-tabs > li:hover > a {
  color: #333;
  background: #fff;
  border: 0;
}

.portlet > .portlet-body {
  clear: both;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.portlet > .portlet-body p {
  margin-top: 0;
}

.portlet > .portlet-empty {
  min-height: 125px;
}

.portlet.full-height-content {
  margin-bottom: 0;
}

/* Portlet background colors */
/* Side bordered portlet */
.portlet.bordered {
  border-left: 2px solid #e6e9ec !important;
}

.portlet.bordered > .portlet-title {
  border-bottom: 0;
}

/* Solid colored portlet */
.portlet.solid {
  padding: 0 10px 10px 10px;
  border: 0px;
}

.portlet.solid > .portlet-title {
  border-bottom: 0;
  margin-bottom: 10px;
}

.portlet.solid > .portlet-title > .caption {
  padding: 16px 0 2px 0;
}

.portlet.solid > .portlet-title > .actions {
  padding: 12px 0 6px 0;
}

.portlet.solid > .portlet-title > .tools {
  padding: 14px 0 6px 0;
}

/* Solid bordered portlet */
.portlet.solid.bordered > .portlet-title {
  margin-bottom: 10px;
}

/* Box portlet */
.portlet.box {
  padding: 0px !important;
}

.portlet.box > .portlet-title {
  border-bottom: 0;
  padding: 0 10px;
  margin-bottom: 0;
  color: #fff;
}

.portlet.box > .portlet-title > .caption {
  padding: 11px 0 9px 0;
}

.portlet.box > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.box > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box > .portlet-title > .actions {
  padding: 7px 0 5px 0;
}

.portlet.box > .portlet-body {
  background-color: #fff;
  padding: 15px;
}

/* Light Portlet */
.portlet.light {
  padding: 12px 20px 15px 20px;
  background-color: #fff;
}

.portlet.light.bordered {
  border: 1px solid #e7ecf1 !important;
}

.portlet.light.bordered > .portlet-title {
  border-bottom: 1px solid #eef1f5;
}

.portlet.light.bg-inverse {
  background: #f1f4f7;
}

.portlet.light > .portlet-title {
  padding: 0;
  min-height: 48px;
}

.portlet.light > .portlet-title > .caption {
  color: #666;
  padding: 10px 0;
}

.portlet.light > .portlet-title > .caption > .caption-subject {
  font-size: 16px;
}

.portlet.light > .portlet-title > .caption > i {
  color: #777;
  font-size: 15px;
  font-weight: 300;
  margin-top: 3px;
}

.portlet.light > .portlet-title > .caption.caption-md > .caption-subject {
  font-size: 15px;
}

.portlet.light > .portlet-title > .caption.caption-md > i {
  font-size: 14px;
}

.portlet.light > .portlet-title > .actions {
  padding: 6px 0 14px 0;
}

.portlet.light > .portlet-title > .actions .btn-default {
  color: #666;
}

.portlet.light > .portlet-title > .actions .btn-icon-only {
  height: 27px;
  width: 27px;
}

.portlet.light > .portlet-title > .actions .dropdown-menu li > a {
  color: #555;
}

.portlet.light > .portlet-title > .inputs {
  float: right;
  display: inline-block;
  padding: 4px 0;
}

.portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > i {
  font-size: 14px;
  margin-top: 9px;
}

.portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > .form-control {
  height: 30px;
  padding: 2px 26px 3px 10px;
  font-size: 13px;
}

.portlet.light > .portlet-title > .inputs > .portlet-input > .form-control {
  height: 30px;
  padding: 3px 10px;
  font-size: 13px;
}

.portlet.light > .portlet-title > .pagination {
  padding: 2px 0 13px 0;
}

.portlet.light > .portlet-title > .tools {
  padding: 10px 0 13px 0;
  margin-top: 2px;
}

.portlet.light > .portlet-title > .nav-tabs > li {
  margin: 0;
  padding: 0;
}

.portlet.light > .portlet-title > .nav-tabs > li > a {
  margin: 0;
  padding: 12px 13px 13px 13px;
  font-size: 13px;
  color: #666;
}

.portlet.light > .portlet-title > .nav-tabs > li.active > a,
.portlet.light > .portlet-title > .nav-tabs > li:hover > a {
  margin: 0;
  background: none;
  color: #333;
}

.portlet.light.form-fit {
  padding: 0;
}

.portlet.light.form-fit > .portlet-title {
  padding: 17px 20px 10px 20px;
  margin-bottom: 0;
}

.portlet.light .portlet-body {
  padding-top: 8px;
}

.portlet.light.portlet-fullscreen > .portlet-body {
  padding: 8px 0;
}

.portlet.light.portlet-fit {
  padding: 0;
}

.portlet.light.portlet-fit > .portlet-title {
  padding: 15px 20px 10px 20px;
}

.portlet.light.portlet-fit > .portlet-body {
  padding: 10px 20px 20px 20px;
}

.portlet.light.portlet-fit.portlet-form > .portlet-body {
  padding: 0;
}

.portlet.light.portlet-fit.portlet-form > .portlet-body {
  padding: 0;
}

.portlet.light.portlet-fit.portlet-form > .portlet-body .form-actions {
  background: none;
}

.portlet.light.portlet-datatable.portlet-fit > .portlet-body {
  padding-top: 10px;
  padding-bottom: 25px;
}

.tab-pane > p:last-child {
  margin-bottom: 0px;
}

/* Reverse aligned tabs */
.tabs-reversed > li {
  float: right;
  margin-right: 0;
}

.tabs-reversed > li > a {
  margin-right: 0;
}

/* jQuery UI Draggable Portlets */
.portlet-sortable:not(.portlet-fullscreen) > .portlet-title {
  cursor: move;
}

.portlet-sortable-placeholder {
  border: 2px dashed #eee;
  margin-bottom: 25px;
}

.portlet-sortable-empty {
  box-shadow: none !important;
  height: 45px;
}

.portlet-collapsed {
  display: none;
}

@media (max-width: 991px) {
  /* 991px */
  .portlet-collapsed-on-mobile {
    display: none;
  }
}

/***
Custom colored portlets 
***/
.portlet > .portlet-body.white,
.portlet.white {
  background-color: #ffffff;
}

.portlet.solid.white > .portlet-title,
.portlet.solid.white > .portlet-body {
  border: 0;
  color: #666;
}

.portlet.solid.white > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.white > .portlet-title > .caption > i {
  color: #666;
}

.portlet.solid.white > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.white > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.white > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.white > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.white > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.white > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.white {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.white > .portlet-title {
  background-color: #ffffff;
}

.portlet.box.white > .portlet-title > .caption {
  color: #666;
}

.portlet.box.white > .portlet-title > .caption > i {
  color: #666;
}

.portlet.box.white > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.white > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.white > .portlet-title > .actions .btn-default:hover, .portlet.box.white > .portlet-title > .actions .btn-default:focus, .portlet.box.white > .portlet-title > .actions .btn-default:active, .portlet.box.white > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.default,
.portlet.default {
  background-color: #e1e5ec;
}

.portlet.solid.default > .portlet-title,
.portlet.solid.default > .portlet-body {
  border: 0;
  color: #666;
}

.portlet.solid.default > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.default > .portlet-title > .caption > i {
  color: #666;
}

.portlet.solid.default > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.default > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.default > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.default > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.default > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.default > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.default {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.default > .portlet-title {
  background-color: #e1e5ec;
}

.portlet.box.default > .portlet-title > .caption {
  color: #666;
}

.portlet.box.default > .portlet-title > .caption > i {
  color: #666;
}

.portlet.box.default > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.default > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.default > .portlet-title > .actions .btn-default:hover, .portlet.box.default > .portlet-title > .actions .btn-default:focus, .portlet.box.default > .portlet-title > .actions .btn-default:active, .portlet.box.default > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.dark,
.portlet.dark {
  background-color: #2f353b;
}

.portlet.solid.dark > .portlet-title,
.portlet.solid.dark > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.dark > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.dark > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.dark > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.dark > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.dark > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.dark > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.dark > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.dark {
  border: 1px solid #464f57;
  border-top: 0;
}

.portlet.box.dark > .portlet-title {
  background-color: #2f353b;
}

.portlet.box.dark > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.dark > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #616d79;
  color: #6c7a88;
}

.portlet.box.dark > .portlet-title > .actions .btn-default > i {
  color: #738290;
}

.portlet.box.dark > .portlet-title > .actions .btn-default:hover, .portlet.box.dark > .portlet-title > .actions .btn-default:focus, .portlet.box.dark > .portlet-title > .actions .btn-default:active, .portlet.box.dark > .portlet-title > .actions .btn-default.active {
  border: 1px solid #798794;
  color: #8793a0;
}

.portlet > .portlet-body.blue,
.portlet.blue {
  background-color: #3598dc;
}

.portlet.solid.blue > .portlet-title,
.portlet.solid.blue > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue {
  border: 1px solid #60aee4;
  border-top: 0;
}

.portlet.box.blue > .portlet-title {
  background-color: #3598dc;
}

.portlet.box.blue > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #95c9ed;
  color: #aad4f0;
}

.portlet.box.blue > .portlet-title > .actions .btn-default > i {
  color: #b7daf3;
}

.portlet.box.blue > .portlet-title > .actions .btn-default:hover, .portlet.box.blue > .portlet-title > .actions .btn-default:focus, .portlet.box.blue > .portlet-title > .actions .btn-default:active, .portlet.box.blue > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c0dff4;
  color: #d6eaf8;
}

.portlet > .portlet-body.blue-madison,
.portlet.blue-madison {
  background-color: #578ebe;
}

.portlet.solid.blue-madison > .portlet-title,
.portlet.solid.blue-madison > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-madison > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-madison > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-madison > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-madison > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-madison > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-madison > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-madison > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-madison > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-madison {
  border: 1px solid #7ca7cc;
  border-top: 0;
}

.portlet.box.blue-madison > .portlet-title {
  background-color: #578ebe;
}

.portlet.box.blue-madison > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-madison > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-madison > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #a8c4dd;
  color: #bad1e4;
}

.portlet.box.blue-madison > .portlet-title > .actions .btn-default > i {
  color: #c5d8e9;
}

.portlet.box.blue-madison > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-madison > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-madison > .portlet-title > .actions .btn-default:active, .portlet.box.blue-madison > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cdddec;
  color: #dfeaf3;
}

.portlet > .portlet-body.blue-chambray,
.portlet.blue-chambray {
  background-color: #2C3E50;
}

.portlet.solid.blue-chambray > .portlet-title,
.portlet.solid.blue-chambray > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-chambray > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-chambray > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-chambray > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-chambray > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-chambray > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-chambray > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-chambray > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-chambray > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-chambray {
  border: 1px solid #3e5871;
  border-top: 0;
}

.portlet.box.blue-chambray > .portlet-title {
  background-color: #2C3E50;
}

.portlet.box.blue-chambray > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-chambray > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-chambray > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #547698;
  color: #5f83a7;
}

.portlet.box.blue-chambray > .portlet-title > .actions .btn-default > i {
  color: #698bac;
}

.portlet.box.blue-chambray > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default:active, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default.active {
  border: 1px solid #6f90b0;
  color: #809cb9;
}

.portlet > .portlet-body.blue-ebonyclay,
.portlet.blue-ebonyclay {
  background-color: #22313F;
}

.portlet.solid.blue-ebonyclay > .portlet-title,
.portlet.solid.blue-ebonyclay > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-ebonyclay > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-ebonyclay > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-ebonyclay {
  border: 1px solid #344b60;
  border-top: 0;
}

.portlet.box.blue-ebonyclay > .portlet-title {
  background-color: #22313F;
}

.portlet.box.blue-ebonyclay > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-ebonyclay > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #496a88;
  color: #527798;
}

.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default > i {
  color: #587ea2;
}

.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:active, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default.active {
  border: 1px solid #5d83a7;
  color: #6d90b0;
}

.portlet > .portlet-body.blue-hoki,
.portlet.blue-hoki {
  background-color: #67809F;
}

.portlet.solid.blue-hoki > .portlet-title,
.portlet.solid.blue-hoki > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-hoki > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-hoki > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-hoki > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-hoki > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-hoki > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-hoki > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-hoki > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-hoki > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-hoki {
  border: 1px solid #869ab3;
  border-top: 0;
}

.portlet.box.blue-hoki > .portlet-title {
  background-color: #67809F;
}

.portlet.box.blue-hoki > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-hoki > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-hoki > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #acb9ca;
  color: #bbc7d4;
}

.portlet.box.blue-hoki > .portlet-title > .actions .btn-default > i {
  color: #c5ceda;
}

.portlet.box.blue-hoki > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default:active, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cbd4de;
  color: #dbe1e8;
}

.portlet > .portlet-body.blue-steel,
.portlet.blue-steel {
  background-color: #4B77BE;
}

.portlet.solid.blue-steel > .portlet-title,
.portlet.solid.blue-steel > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-steel > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-steel > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-steel > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-steel > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-steel > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-steel > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-steel > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-steel > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-steel {
  border: 1px solid #7093cc;
  border-top: 0;
}

.portlet.box.blue-steel > .portlet-title {
  background-color: #4B77BE;
}

.portlet.box.blue-steel > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-steel > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-steel > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9db5dc;
  color: #b0c3e3;
}

.portlet.box.blue-steel > .portlet-title > .actions .btn-default > i {
  color: #bbcce7;
}

.portlet.box.blue-steel > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-steel > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-steel > .portlet-title > .actions .btn-default:active, .portlet.box.blue-steel > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c3d2e9;
  color: #d6e0f0;
}

.portlet > .portlet-body.blue-soft,
.portlet.blue-soft {
  background-color: #4c87b9;
}

.portlet.solid.blue-soft > .portlet-title,
.portlet.solid.blue-soft > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-soft > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-soft {
  border: 1px solid #71a0c7;
  border-top: 0;
}

.portlet.box.blue-soft > .portlet-title {
  background-color: #4c87b9;
}

.portlet.box.blue-soft > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dbdd9;
  color: #afc9e0;
}

.portlet.box.blue-soft > .portlet-title > .actions .btn-default > i {
  color: #bad1e4;
}

.portlet.box.blue-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-soft > .portlet-title > .actions .btn-default:active, .portlet.box.blue-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c1d6e7;
  color: #d4e2ee;
}

.portlet > .portlet-body.blue-dark,
.portlet.blue-dark {
  background-color: #5e738b;
}

.portlet.solid.blue-dark > .portlet-title,
.portlet.solid.blue-dark > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-dark > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-dark > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-dark > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-dark > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-dark > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-dark > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-dark > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-dark {
  border: 1px solid #788da4;
  border-top: 0;
}

.portlet.box.blue-dark > .portlet-title {
  background-color: #5e738b;
}

.portlet.box.blue-dark > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-dark > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dacbd;
  color: #acb8c7;
}

.portlet.box.blue-dark > .portlet-title > .actions .btn-default > i {
  color: #b5c0cd;
}

.portlet.box.blue-dark > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-dark > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-dark > .portlet-title > .actions .btn-default:active, .portlet.box.blue-dark > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bbc5d1;
  color: #cad2db;
}

.portlet > .portlet-body.blue-sharp,
.portlet.blue-sharp {
  background-color: #5C9BD1;
}

.portlet.solid.blue-sharp > .portlet-title,
.portlet.solid.blue-sharp > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-sharp > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.blue-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.blue-sharp > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.blue-sharp > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.blue-sharp > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.blue-sharp > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.blue-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.blue-sharp > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-sharp {
  border: 1px solid #84b3dc;
  border-top: 0;
}

.portlet.box.blue-sharp > .portlet-title {
  background-color: #5C9BD1;
}

.portlet.box.blue-sharp > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.blue-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.blue-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4d1ea;
  color: #c7ddef;
}

.portlet.box.blue-sharp > .portlet-title > .actions .btn-default > i {
  color: #d3e4f3;
}

.portlet.box.blue-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dbe9f5;
  color: #eff5fb;
}

.portlet > .portlet-body.green,
.portlet.green {
  background-color: #32c5d2;
}

.portlet.solid.green > .portlet-title,
.portlet.solid.green > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green {
  border: 1px solid #5cd1db;
  border-top: 0;
}

.portlet.box.green > .portlet-title {
  background-color: #32c5d2;
}

.portlet.box.green > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #8edfe6;
  color: #a3e5eb;
}

.portlet.box.green > .portlet-title > .actions .btn-default > i {
  color: #afe8ee;
}

.portlet.box.green > .portlet-title > .actions .btn-default:hover, .portlet.box.green > .portlet-title > .actions .btn-default:focus, .portlet.box.green > .portlet-title > .actions .btn-default:active, .portlet.box.green > .portlet-title > .actions .btn-default.active {
  border: 1px solid #b8ebef;
  color: #cdf1f4;
}

.portlet > .portlet-body.green-meadow,
.portlet.green-meadow {
  background-color: #1BBC9B;
}

.portlet.solid.green-meadow > .portlet-title,
.portlet.solid.green-meadow > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-meadow > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-meadow > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-meadow > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-meadow > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-meadow > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-meadow > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-meadow > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-meadow > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-meadow {
  border: 1px solid #2ae0bb;
  border-top: 0;
}

.portlet.box.green-meadow > .portlet-title {
  background-color: #1BBC9B;
}

.portlet.box.green-meadow > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-meadow > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-meadow > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #5fe8cc;
  color: #75ebd3;
}

.portlet.box.green-meadow > .portlet-title > .actions .btn-default > i {
  color: #83edd7;
}

.portlet.box.green-meadow > .portlet-title > .actions .btn-default:hover, .portlet.box.green-meadow > .portlet-title > .actions .btn-default:focus, .portlet.box.green-meadow > .portlet-title > .actions .btn-default:active, .portlet.box.green-meadow > .portlet-title > .actions .btn-default.active {
  border: 1px solid #8ceeda;
  color: #a2f2e1;
}

.portlet > .portlet-body.green-seagreen,
.portlet.green-seagreen {
  background-color: #1BA39C;
}

.portlet.solid.green-seagreen > .portlet-title,
.portlet.solid.green-seagreen > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-seagreen > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-seagreen > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-seagreen > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-seagreen > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-seagreen > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-seagreen > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-seagreen > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-seagreen > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-seagreen {
  border: 1px solid #22cfc6;
  border-top: 0;
}

.portlet.box.green-seagreen > .portlet-title {
  background-color: #1BA39C;
}

.portlet.box.green-seagreen > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-seagreen > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-seagreen > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #4de1da;
  color: #63e5de;
}

.portlet.box.green-seagreen > .portlet-title > .actions .btn-default > i {
  color: #70e7e1;
}

.portlet.box.green-seagreen > .portlet-title > .actions .btn-default:hover, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default:focus, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default:active, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default.active {
  border: 1px solid #78e9e3;
  color: #8eece8;
}

.portlet > .portlet-body.green-turquoise,
.portlet.green-turquoise {
  background-color: #36D7B7;
}

.portlet.solid.green-turquoise > .portlet-title,
.portlet.solid.green-turquoise > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-turquoise > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-turquoise > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-turquoise > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-turquoise > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-turquoise > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-turquoise > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-turquoise > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-turquoise > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-turquoise {
  border: 1px solid #61dfc6;
  border-top: 0;
}

.portlet.box.green-turquoise > .portlet-title {
  background-color: #36D7B7;
}

.portlet.box.green-turquoise > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-turquoise > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-turquoise > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #94ead9;
  color: #a9eee0;
}

.portlet.box.green-turquoise > .portlet-title > .actions .btn-default > i {
  color: #b6f0e5;
}

.portlet.box.green-turquoise > .portlet-title > .actions .btn-default:hover, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default:focus, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default:active, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bef2e8;
  color: #d3f6ef;
}

.portlet > .portlet-body.green-haze,
.portlet.green-haze {
  background-color: #44b6ae;
}

.portlet.solid.green-haze > .portlet-title,
.portlet.solid.green-haze > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-haze > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-haze > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-haze > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-haze > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-haze > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-haze > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-haze > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-haze > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-haze {
  border: 1px solid #67c6bf;
  border-top: 0;
}

.portlet.box.green-haze > .portlet-title {
  background-color: #44b6ae;
}

.portlet.box.green-haze > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-haze > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-haze > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #93d7d2;
  color: #a6deda;
}

.portlet.box.green-haze > .portlet-title > .actions .btn-default > i {
  color: #b1e2de;
}

.portlet.box.green-haze > .portlet-title > .actions .btn-default:hover, .portlet.box.green-haze > .portlet-title > .actions .btn-default:focus, .portlet.box.green-haze > .portlet-title > .actions .btn-default:active, .portlet.box.green-haze > .portlet-title > .actions .btn-default.active {
  border: 1px solid #b9e5e2;
  color: #cbece9;
}

.portlet > .portlet-body.green-jungle,
.portlet.green-jungle {
  background-color: #26C281;
}

.portlet.solid.green-jungle > .portlet-title,
.portlet.solid.green-jungle > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-jungle > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-jungle > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-jungle > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-jungle > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-jungle > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-jungle > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-jungle > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-jungle > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-jungle {
  border: 1px solid #41da9a;
  border-top: 0;
}

.portlet.box.green-jungle > .portlet-title {
  background-color: #26C281;
}

.portlet.box.green-jungle > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-jungle > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-jungle > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #74e4b5;
  color: #8ae8c1;
}

.portlet.box.green-jungle > .portlet-title > .actions .btn-default > i {
  color: #96ebc8;
}

.portlet.box.green-jungle > .portlet-title > .actions .btn-default:hover, .portlet.box.green-jungle > .portlet-title > .actions .btn-default:focus, .portlet.box.green-jungle > .portlet-title > .actions .btn-default:active, .portlet.box.green-jungle > .portlet-title > .actions .btn-default.active {
  border: 1px solid #9feccc;
  color: #b4f0d7;
}

.portlet > .portlet-body.green-soft,
.portlet.green-soft {
  background-color: #3faba4;
}

.portlet.solid.green-soft > .portlet-title,
.portlet.solid.green-soft > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-soft > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-soft {
  border: 1px solid #5bc2bc;
  border-top: 0;
}

.portlet.box.green-soft > .portlet-title {
  background-color: #3faba4;
}

.portlet.box.green-soft > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #87d3ce;
  color: #9adad6;
}

.portlet.box.green-soft > .portlet-title > .actions .btn-default > i {
  color: #a5deda;
}

.portlet.box.green-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.green-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.green-soft > .portlet-title > .actions .btn-default:active, .portlet.box.green-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ade1dd;
  color: #bfe7e5;
}

.portlet > .portlet-body.green-dark,
.portlet.green-dark {
  background-color: #4DB3A2;
}

.portlet.solid.green-dark > .portlet-title,
.portlet.solid.green-dark > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-dark > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-dark > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-dark > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-dark > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-dark > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-dark > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-dark > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-dark {
  border: 1px solid #71c2b5;
  border-top: 0;
}

.portlet.box.green-dark > .portlet-title {
  background-color: #4DB3A2;
}

.portlet.box.green-dark > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-dark > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9cd5cb;
  color: #addcd4;
}

.portlet.box.green-dark > .portlet-title > .actions .btn-default > i {
  color: #b8e1da;
}

.portlet.box.green-dark > .portlet-title > .actions .btn-default:hover, .portlet.box.green-dark > .portlet-title > .actions .btn-default:focus, .portlet.box.green-dark > .portlet-title > .actions .btn-default:active, .portlet.box.green-dark > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bfe4de;
  color: #d1ebe7;
}

.portlet > .portlet-body.green-sharp,
.portlet.green-sharp {
  background-color: #2ab4c0;
}

.portlet.solid.green-sharp > .portlet-title,
.portlet.solid.green-sharp > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-sharp > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.green-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.green-sharp > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.green-sharp > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.green-sharp > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.green-sharp > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.green-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.green-sharp > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-sharp {
  border: 1px solid #46cbd7;
  border-top: 0;
}

.portlet.box.green-sharp > .portlet-title {
  background-color: #2ab4c0;
}

.portlet.box.green-sharp > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.green-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.green-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #79d9e2;
  color: #8edfe6;
}

.portlet.box.green-sharp > .portlet-title > .actions .btn-default > i {
  color: #9ae3e9;
}

.portlet.box.green-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.green-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.green-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.green-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #a2e5eb;
  color: #b7ebef;
}

.portlet > .portlet-body.grey,
.portlet.grey {
  background-color: #E5E5E5;
}

.portlet.solid.grey > .portlet-title,
.portlet.solid.grey > .portlet-body {
  border: 0;
  color: #333333;
}

.portlet.solid.grey > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey > .portlet-title > .caption > i {
  color: #333333;
}

.portlet.solid.grey > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.grey > .portlet-title {
  background-color: #E5E5E5;
}

.portlet.box.grey > .portlet-title > .caption {
  color: #333333;
}

.portlet.box.grey > .portlet-title > .caption > i {
  color: #333333;
}

.portlet.box.grey > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.grey > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.grey > .portlet-title > .actions .btn-default:hover, .portlet.box.grey > .portlet-title > .actions .btn-default:focus, .portlet.box.grey > .portlet-title > .actions .btn-default:active, .portlet.box.grey > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.grey-steel,
.portlet.grey-steel {
  background-color: #e9edef;
}

.portlet.solid.grey-steel > .portlet-title,
.portlet.solid.grey-steel > .portlet-body {
  border: 0;
  color: #80898e;
}

.portlet.solid.grey-steel > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-steel > .portlet-title > .caption > i {
  color: #80898e;
}

.portlet.solid.grey-steel > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-steel > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-steel > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-steel > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-steel > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-steel > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-steel {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.grey-steel > .portlet-title {
  background-color: #e9edef;
}

.portlet.box.grey-steel > .portlet-title > .caption {
  color: #80898e;
}

.portlet.box.grey-steel > .portlet-title > .caption > i {
  color: #80898e;
}

.portlet.box.grey-steel > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.grey-steel > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.grey-steel > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-steel > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-steel > .portlet-title > .actions .btn-default:active, .portlet.box.grey-steel > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.grey-cararra,
.portlet.grey-cararra {
  background-color: #fafafa;
}

.portlet.solid.grey-cararra > .portlet-title,
.portlet.solid.grey-cararra > .portlet-body {
  border: 0;
  color: #333333;
}

.portlet.solid.grey-cararra > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-cararra > .portlet-title > .caption > i {
  color: #333333;
}

.portlet.solid.grey-cararra > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-cararra > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-cararra > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-cararra > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-cararra > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-cararra > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-cararra {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.grey-cararra > .portlet-title {
  background-color: #fafafa;
}

.portlet.box.grey-cararra > .portlet-title > .caption {
  color: #333333;
}

.portlet.box.grey-cararra > .portlet-title > .caption > i {
  color: #333333;
}

.portlet.box.grey-cararra > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.grey-cararra > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.grey-cararra > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default:active, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.grey-gallery,
.portlet.grey-gallery {
  background-color: #555555;
}

.portlet.solid.grey-gallery > .portlet-title,
.portlet.solid.grey-gallery > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.grey-gallery > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-gallery > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.grey-gallery > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-gallery > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-gallery > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-gallery > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-gallery > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-gallery > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-gallery {
  border: 1px solid #6f6f6f;
  border-top: 0;
}

.portlet.box.grey-gallery > .portlet-title {
  background-color: #555555;
}

.portlet.box.grey-gallery > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.grey-gallery > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.grey-gallery > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #8d8d8d;
  color: #9a9a9a;
}

.portlet.box.grey-gallery > .portlet-title > .actions .btn-default > i {
  color: #a2a2a2;
}

.portlet.box.grey-gallery > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default:active, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default.active {
  border: 1px solid #a7a7a7;
  color: #b3b3b3;
}

.portlet > .portlet-body.grey-cascade,
.portlet.grey-cascade {
  background-color: #95A5A6;
}

.portlet.solid.grey-cascade > .portlet-title,
.portlet.solid.grey-cascade > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.grey-cascade > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-cascade > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.grey-cascade > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-cascade > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-cascade > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-cascade > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-cascade > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-cascade > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-cascade {
  border: 1px solid #b1bdbd;
  border-top: 0;
}

.portlet.box.grey-cascade > .portlet-title {
  background-color: #95A5A6;
}

.portlet.box.grey-cascade > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.grey-cascade > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.grey-cascade > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #d2d9d9;
  color: #e0e5e5;
}

.portlet.box.grey-cascade > .portlet-title > .actions .btn-default > i {
  color: #e8ecec;
}

.portlet.box.grey-cascade > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default:active, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default.active {
  border: 1px solid #eef0f0;
  color: #fcfcfc;
}

.portlet > .portlet-body.grey-silver,
.portlet.grey-silver {
  background-color: #BFBFBF;
}

.portlet.solid.grey-silver > .portlet-title,
.portlet.solid.grey-silver > .portlet-body {
  border: 0;
  color: #FAFCFB;
}

.portlet.solid.grey-silver > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-silver > .portlet-title > .caption > i {
  color: #FAFCFB;
}

.portlet.solid.grey-silver > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-silver > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-silver > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-silver > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-silver > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-silver > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-silver {
  border: 1px solid #d9d9d9;
  border-top: 0;
}

.portlet.box.grey-silver > .portlet-title {
  background-color: #BFBFBF;
}

.portlet.box.grey-silver > .portlet-title > .caption {
  color: #FAFCFB;
}

.portlet.box.grey-silver > .portlet-title > .caption > i {
  color: #FAFCFB;
}

.portlet.box.grey-silver > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f7f7f7;
  color: white;
}

.portlet.box.grey-silver > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.grey-silver > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-silver > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-silver > .portlet-title > .actions .btn-default:active, .portlet.box.grey-silver > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.grey-salsa,
.portlet.grey-salsa {
  background-color: #ACB5C3;
}

.portlet.solid.grey-salsa > .portlet-title,
.portlet.solid.grey-salsa > .portlet-body {
  border: 0;
  color: #FAFCFB;
}

.portlet.solid.grey-salsa > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-salsa > .portlet-title > .caption > i {
  color: #FAFCFB;
}

.portlet.solid.grey-salsa > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-salsa > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-salsa > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-salsa > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-salsa > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-salsa > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-salsa {
  border: 1px solid #cacfd8;
  border-top: 0;
}

.portlet.box.grey-salsa > .portlet-title {
  background-color: #ACB5C3;
}

.portlet.box.grey-salsa > .portlet-title > .caption {
  color: #FAFCFB;
}

.portlet.box.grey-salsa > .portlet-title > .caption > i {
  color: #FAFCFB;
}

.portlet.box.grey-salsa > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #edeff2;
  color: #fcfcfd;
}

.portlet.box.grey-salsa > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.grey-salsa > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default:active, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.grey-salt,
.portlet.grey-salt {
  background-color: #bfcad1;
}

.portlet.solid.grey-salt > .portlet-title,
.portlet.solid.grey-salt > .portlet-body {
  border: 0;
  color: #FAFCFB;
}

.portlet.solid.grey-salt > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-salt > .portlet-title > .caption > i {
  color: #FAFCFB;
}

.portlet.solid.grey-salt > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-salt > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-salt > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-salt > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-salt > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-salt > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-salt {
  border: 1px solid #dde3e6;
  border-top: 0;
}

.portlet.box.grey-salt > .portlet-title {
  background-color: #bfcad1;
}

.portlet.box.grey-salt > .portlet-title > .caption {
  color: #FAFCFB;
}

.portlet.box.grey-salt > .portlet-title > .caption > i {
  color: #FAFCFB;
}

.portlet.box.grey-salt > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.grey-salt > .portlet-title > .actions .btn-default > i {
  color: white;
}

.portlet.box.grey-salt > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-salt > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-salt > .portlet-title > .actions .btn-default:active, .portlet.box.grey-salt > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.grey-mint,
.portlet.grey-mint {
  background-color: #525e64;
}

.portlet.solid.grey-mint > .portlet-title,
.portlet.solid.grey-mint > .portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.grey-mint > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.grey-mint > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.solid.grey-mint > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.grey-mint > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.grey-mint > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.grey-mint > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.grey-mint > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.grey-mint > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-mint {
  border: 1px solid #697880;
  border-top: 0;
}

.portlet.box.grey-mint > .portlet-title {
  background-color: #525e64;
}

.portlet.box.grey-mint > .portlet-title > .caption {
  color: #FFFFFF;
}

.portlet.box.grey-mint > .portlet-title > .caption > i {
  color: #FFFFFF;
}

.portlet.box.grey-mint > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #88979e;
  color: #96a3a9;
}

.portlet.box.grey-mint > .portlet-title > .actions .btn-default > i {
  color: #9faab0;
}

.portlet.box.grey-mint > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-mint > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-mint > .portlet-title > .actions .btn-default:active, .portlet.box.grey-mint > .portlet-title > .actions .btn-default.active {
  border: 1px solid #a4afb5;
  color: #b2bcc0;
}

.portlet > .portlet-body.red,
.portlet.red {
  background-color: #e7505a;
}

.portlet.solid.red > .portlet-title,
.portlet.solid.red > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red {
  border: 1px solid #ed7d84;
  border-top: 0;
}

.portlet.box.red > .portlet-title {
  background-color: #e7505a;
}

.portlet.box.red > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f5b3b7;
  color: #f8c9cc;
}

.portlet.box.red > .portlet-title > .actions .btn-default > i {
  color: #f9d7d9;
}

.portlet.box.red > .portlet-title > .actions .btn-default:hover, .portlet.box.red > .portlet-title > .actions .btn-default:focus, .portlet.box.red > .portlet-title > .actions .btn-default:active, .portlet.box.red > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbe0e1;
  color: #fef6f6;
}

.portlet > .portlet-body.red-pink,
.portlet.red-pink {
  background-color: #E08283;
}

.portlet.solid.red-pink > .portlet-title,
.portlet.solid.red-pink > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-pink > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-pink > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-pink > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-pink > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-pink > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-pink > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-pink > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-pink > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-pink {
  border: 1px solid #eaabac;
  border-top: 0;
}

.portlet.box.red-pink > .portlet-title {
  background-color: #E08283;
}

.portlet.box.red-pink > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-pink > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-pink > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f6dcdc;
  color: #fbf0f0;
}

.portlet.box.red-pink > .portlet-title > .actions .btn-default > i {
  color: #fefdfd;
}

.portlet.box.red-pink > .portlet-title > .actions .btn-default:hover, .portlet.box.red-pink > .portlet-title > .actions .btn-default:focus, .portlet.box.red-pink > .portlet-title > .actions .btn-default:active, .portlet.box.red-pink > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet > .portlet-body.red-sunglo,
.portlet.red-sunglo {
  background-color: #E26A6A;
}

.portlet.solid.red-sunglo > .portlet-title,
.portlet.solid.red-sunglo > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-sunglo > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-sunglo > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-sunglo > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-sunglo > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-sunglo > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-sunglo > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-sunglo > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-sunglo > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-sunglo {
  border: 1px solid #ea9595;
  border-top: 0;
}

.portlet.box.red-sunglo > .portlet-title {
  background-color: #E26A6A;
}

.portlet.box.red-sunglo > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-sunglo > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-sunglo > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f4c8c8;
  color: #f8dddd;
}

.portlet.box.red-sunglo > .portlet-title > .actions .btn-default > i {
  color: #fbeaea;
}

.portlet.box.red-sunglo > .portlet-title > .actions .btn-default:hover, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default:focus, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default:active, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdf3f3;
  color: white;
}

.portlet > .portlet-body.red-intense,
.portlet.red-intense {
  background-color: #e35b5a;
}

.portlet.solid.red-intense > .portlet-title,
.portlet.solid.red-intense > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-intense > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-intense > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-intense > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-intense > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-intense > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-intense > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-intense > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-intense > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-intense {
  border: 1px solid #ea8686;
  border-top: 0;
}

.portlet.box.red-intense > .portlet-title {
  background-color: #e35b5a;
}

.portlet.box.red-intense > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-intense > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-intense > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f3baba;
  color: #f7d0d0;
}

.portlet.box.red-intense > .portlet-title > .actions .btn-default > i {
  color: #f9dddd;
}

.portlet.box.red-intense > .portlet-title > .actions .btn-default:hover, .portlet.box.red-intense > .portlet-title > .actions .btn-default:focus, .portlet.box.red-intense > .portlet-title > .actions .btn-default:active, .portlet.box.red-intense > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbe6e6;
  color: #fefbfb;
}

.portlet > .portlet-body.red-thunderbird,
.portlet.red-thunderbird {
  background-color: #D91E18;
}

.portlet.solid.red-thunderbird > .portlet-title,
.portlet.solid.red-thunderbird > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-thunderbird > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-thunderbird > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-thunderbird > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-thunderbird > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-thunderbird > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-thunderbird > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-thunderbird > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-thunderbird > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-thunderbird {
  border: 1px solid #e9403b;
  border-top: 0;
}

.portlet.box.red-thunderbird > .portlet-title {
  background-color: #D91E18;
}

.portlet.box.red-thunderbird > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-thunderbird > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ef7672;
  color: #f28c89;
}

.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default > i {
  color: #f39997;
}

.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:hover, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:focus, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:active, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f4a2a0;
  color: #f7b9b7;
}

.portlet > .portlet-body.red-flamingo,
.portlet.red-flamingo {
  background-color: #EF4836;
}

.portlet.solid.red-flamingo > .portlet-title,
.portlet.solid.red-flamingo > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-flamingo > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-flamingo > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-flamingo > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-flamingo > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-flamingo > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-flamingo > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-flamingo > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-flamingo > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-flamingo {
  border: 1px solid #f37365;
  border-top: 0;
}

.portlet.box.red-flamingo > .portlet-title {
  background-color: #EF4836;
}

.portlet.box.red-flamingo > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-flamingo > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-flamingo > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f7a79e;
  color: #f9bcb6;
}

.portlet.box.red-flamingo > .portlet-title > .actions .btn-default > i {
  color: #fac9c4;
}

.portlet.box.red-flamingo > .portlet-title > .actions .btn-default:hover, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default:focus, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default:active, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbd2cd;
  color: #fde7e5;
}

.portlet > .portlet-body.red-soft,
.portlet.red-soft {
  background-color: #d05454;
}

.portlet.solid.red-soft > .portlet-title,
.portlet.solid.red-soft > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-soft > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-soft > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-soft {
  border: 1px solid #db7c7c;
  border-top: 0;
}

.portlet.box.red-soft > .portlet-title {
  background-color: #d05454;
}

.portlet.box.red-soft > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-soft > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e8acac;
  color: #eec0c0;
}

.portlet.box.red-soft > .portlet-title > .actions .btn-default > i {
  color: #f1cccc;
}

.portlet.box.red-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.red-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.red-soft > .portlet-title > .actions .btn-default:active, .portlet.box.red-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f3d4d4;
  color: #f9e8e8;
}

.portlet > .portlet-body.red-haze,
.portlet.red-haze {
  background-color: #f36a5a;
}

.portlet.solid.red-haze > .portlet-title,
.portlet.solid.red-haze > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-haze > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-haze > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-haze > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-haze > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-haze > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-haze > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-haze > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-haze > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-haze {
  border: 1px solid #f6958a;
  border-top: 0;
}

.portlet.box.red-haze > .portlet-title {
  background-color: #f36a5a;
}

.portlet.box.red-haze > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-haze > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-haze > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fbc8c3;
  color: #fcdeda;
}

.portlet.box.red-haze > .portlet-title > .actions .btn-default > i {
  color: #fdebe9;
}

.portlet.box.red-haze > .portlet-title > .actions .btn-default:hover, .portlet.box.red-haze > .portlet-title > .actions .btn-default:focus, .portlet.box.red-haze > .portlet-title > .actions .btn-default:active, .portlet.box.red-haze > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fef3f2;
  color: white;
}

.portlet > .portlet-body.red-mint,
.portlet.red-mint {
  background-color: #e43a45;
}

.portlet.solid.red-mint > .portlet-title,
.portlet.solid.red-mint > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-mint > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.red-mint > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.red-mint > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.red-mint > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.red-mint > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.red-mint > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.red-mint > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.red-mint > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-mint {
  border: 1px solid #ea676f;
  border-top: 0;
}

.portlet.box.red-mint > .portlet-title {
  background-color: #e43a45;
}

.portlet.box.red-mint > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.red-mint > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.red-mint > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f29da2;
  color: #f5b3b7;
}

.portlet.box.red-mint > .portlet-title > .actions .btn-default > i {
  color: #f6c1c4;
}

.portlet.box.red-mint > .portlet-title > .actions .btn-default:hover, .portlet.box.red-mint > .portlet-title > .actions .btn-default:focus, .portlet.box.red-mint > .portlet-title > .actions .btn-default:active, .portlet.box.red-mint > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f8cacd;
  color: #fbe0e2;
}

.portlet > .portlet-body.yellow,
.portlet.yellow {
  background-color: #c49f47;
}

.portlet.solid.yellow > .portlet-title,
.portlet.solid.yellow > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow {
  border: 1px solid #d0b36e;
  border-top: 0;
}

.portlet.box.yellow > .portlet-title {
  background-color: #c49f47;
}

.portlet.box.yellow > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #dfcb9c;
  color: #e5d5af;
}

.portlet.box.yellow > .portlet-title > .actions .btn-default > i {
  color: #e9dbbb;
}

.portlet.box.yellow > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow > .portlet-title > .actions .btn-default:active, .portlet.box.yellow > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ecdfc3;
  color: #f2ead6;
}

.portlet > .portlet-body.yellow-gold,
.portlet.yellow-gold {
  background-color: #E87E04;
}

.portlet.solid.yellow-gold > .portlet-title,
.portlet.solid.yellow-gold > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-gold > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-gold > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-gold > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-gold > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-gold > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-gold > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-gold > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-gold > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-gold {
  border: 1px solid #fb9724;
  border-top: 0;
}

.portlet.box.yellow-gold > .portlet-title {
  background-color: #E87E04;
}

.portlet.box.yellow-gold > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-gold > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-gold > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fcb460;
  color: #fdbf79;
}

.portlet.box.yellow-gold > .portlet-title > .actions .btn-default > i {
  color: #fdc788;
}

.portlet.box.yellow-gold > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdcb92;
  color: #fed7ab;
}

.portlet > .portlet-body.yellow-casablanca,
.portlet.yellow-casablanca {
  background-color: #f2784b;
}

.portlet.solid.yellow-casablanca > .portlet-title,
.portlet.solid.yellow-casablanca > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-casablanca > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-casablanca > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-casablanca {
  border: 1px solid #f59c7b;
  border-top: 0;
}

.portlet.box.yellow-casablanca > .portlet-title {
  background-color: #f2784b;
}

.portlet.box.yellow-casablanca > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-casablanca > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fac6b4;
  color: #fbd8cb;
}

.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default > i {
  color: #fce3da;
}

.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdeae3;
  color: #fffcfb;
}

.portlet > .portlet-body.yellow-crusta,
.portlet.yellow-crusta {
  background-color: #f3c200;
}

.portlet.solid.yellow-crusta > .portlet-title,
.portlet.solid.yellow-crusta > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-crusta > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-crusta > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-crusta > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-crusta > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-crusta > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-crusta > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-crusta > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-crusta > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-crusta {
  border: 1px solid #ffd327;
  border-top: 0;
}

.portlet.box.yellow-crusta > .portlet-title {
  background-color: #f3c200;
}

.portlet.box.yellow-crusta > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-crusta > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ffe064;
  color: #ffe57e;
}

.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default > i {
  color: #ffe88d;
}

.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ffea97;
  color: #ffefb1;
}

.portlet > .portlet-body.yellow-lemon,
.portlet.yellow-lemon {
  background-color: #F7CA18;
}

.portlet.solid.yellow-lemon > .portlet-title,
.portlet.solid.yellow-lemon > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-lemon > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-lemon > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-lemon > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-lemon > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-lemon > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-lemon > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-lemon > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-lemon > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-lemon {
  border: 1px solid #f9d549;
  border-top: 0;
}

.portlet.box.yellow-lemon > .portlet-title {
  background-color: #F7CA18;
}

.portlet.box.yellow-lemon > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-lemon > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fbe384;
  color: #fce99d;
}

.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default > i {
  color: #fcecac;
}

.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fceeb6;
  color: #fdf4ce;
}

.portlet > .portlet-body.yellow-saffron,
.portlet.yellow-saffron {
  background-color: #F4D03F;
}

.portlet.solid.yellow-saffron > .portlet-title,
.portlet.solid.yellow-saffron > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-saffron > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-saffron > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-saffron > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-saffron > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-saffron > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-saffron > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-saffron > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-saffron > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-saffron {
  border: 1px solid #f7dc6f;
  border-top: 0;
}

.portlet.box.yellow-saffron > .portlet-title {
  background-color: #F4D03F;
}

.portlet.box.yellow-saffron > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-saffron > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #faeaa9;
  color: #fbf0c1;
}

.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default > i {
  color: #fcf3d0;
}

.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdf6d9;
  color: #fefcf1;
}

.portlet > .portlet-body.yellow-soft,
.portlet.yellow-soft {
  background-color: #c8d046;
}

.portlet.solid.yellow-soft > .portlet-title,
.portlet.solid.yellow-soft > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-soft > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-soft > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-soft {
  border: 1px solid #d4da6f;
  border-top: 0;
}

.portlet.box.yellow-soft > .portlet-title {
  background-color: #c8d046;
}

.portlet.box.yellow-soft > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-soft > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e3e79f;
  color: #e9ecb4;
}

.portlet.box.yellow-soft > .portlet-title > .actions .btn-default > i {
  color: #ecefc0;
}

.portlet.box.yellow-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-soft > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #eff1c8;
  color: #f5f6dc;
}

.portlet > .portlet-body.yellow-haze,
.portlet.yellow-haze {
  background-color: #c5bf66;
}

.portlet.solid.yellow-haze > .portlet-title,
.portlet.solid.yellow-haze > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-haze > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-haze > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-haze > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-haze > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-haze > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-haze > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-haze > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-haze > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-haze {
  border: 1px solid #d3ce8b;
  border-top: 0;
}

.portlet.box.yellow-haze > .portlet-title {
  background-color: #c5bf66;
}

.portlet.box.yellow-haze > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-haze > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-haze > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e4e1b7;
  color: #ebe9ca;
}

.portlet.box.yellow-haze > .portlet-title > .actions .btn-default > i {
  color: #efedd5;
}

.portlet.box.yellow-haze > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-haze > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-haze > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-haze > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f2f1dc;
  color: #f9f8ef;
}

.portlet > .portlet-body.yellow-mint,
.portlet.yellow-mint {
  background-color: #c5b96b;
}

.portlet.solid.yellow-mint > .portlet-title,
.portlet.solid.yellow-mint > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-mint > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.yellow-mint > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.yellow-mint > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.yellow-mint > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.yellow-mint > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.yellow-mint > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.yellow-mint > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.yellow-mint > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-mint {
  border: 1px solid #d3ca90;
  border-top: 0;
}

.portlet.box.yellow-mint > .portlet-title {
  background-color: #c5b96b;
}

.portlet.box.yellow-mint > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.yellow-mint > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.yellow-mint > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e5dfbc;
  color: #ece8ce;
}

.portlet.box.yellow-mint > .portlet-title > .actions .btn-default > i {
  color: #f0edd9;
}

.portlet.box.yellow-mint > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-mint > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-mint > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-mint > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f3f0e0;
  color: #faf9f3;
}

.portlet > .portlet-body.purple,
.portlet.purple {
  background-color: #8E44AD;
}

.portlet.solid.purple > .portlet-title,
.portlet.solid.purple > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple {
  border: 1px solid #a563c1;
  border-top: 0;
}

.portlet.box.purple > .portlet-title {
  background-color: #8E44AD;
}

.portlet.box.purple > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}

.portlet.box.purple > .portlet-title > .actions .btn-default > i {
  color: #cfacde;
}

.portlet.box.purple > .portlet-title > .actions .btn-default:hover, .portlet.box.purple > .portlet-title > .actions .btn-default:focus, .portlet.box.purple > .portlet-title > .actions .btn-default:active, .portlet.box.purple > .portlet-title > .actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

.portlet > .portlet-body.purple-plum,
.portlet.purple-plum {
  background-color: #8775a7;
}

.portlet.solid.purple-plum > .portlet-title,
.portlet.solid.purple-plum > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-plum > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-plum > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-plum > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-plum > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-plum > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-plum > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-plum > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-plum > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-plum {
  border: 1px solid #a294bb;
  border-top: 0;
}

.portlet.box.purple-plum > .portlet-title {
  background-color: #8775a7;
}

.portlet.box.purple-plum > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-plum > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-plum > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}

.portlet.box.purple-plum > .portlet-title > .actions .btn-default > i {
  color: #d8d2e3;
}

.portlet.box.purple-plum > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-plum > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-plum > .portlet-title > .actions .btn-default:active, .portlet.box.purple-plum > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

.portlet > .portlet-body.purple-medium,
.portlet.purple-medium {
  background-color: #BF55EC;
}

.portlet.solid.purple-medium > .portlet-title,
.portlet.solid.purple-medium > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-medium > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-medium > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-medium > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-medium > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-medium > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-medium > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-medium > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-medium > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-medium {
  border: 1px solid #d083f1;
  border-top: 0;
}

.portlet.box.purple-medium > .portlet-title {
  background-color: #BF55EC;
}

.portlet.box.purple-medium > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-medium > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-medium > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e5baf7;
  color: #eed1fa;
}

.portlet.box.purple-medium > .portlet-title > .actions .btn-default > i {
  color: #f3dffb;
}

.portlet.box.purple-medium > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-medium > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-medium > .portlet-title > .actions .btn-default:active, .portlet.box.purple-medium > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f6e8fc;
  color: white;
}

.portlet > .portlet-body.purple-studio,
.portlet.purple-studio {
  background-color: #8E44AD;
}

.portlet.solid.purple-studio > .portlet-title,
.portlet.solid.purple-studio > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-studio > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-studio > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-studio > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-studio > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-studio > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-studio > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-studio > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-studio > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-studio {
  border: 1px solid #a563c1;
  border-top: 0;
}

.portlet.box.purple-studio > .portlet-title {
  background-color: #8E44AD;
}

.portlet.box.purple-studio > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-studio > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-studio > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}

.portlet.box.purple-studio > .portlet-title > .actions .btn-default > i {
  color: #cfacde;
}

.portlet.box.purple-studio > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-studio > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-studio > .portlet-title > .actions .btn-default:active, .portlet.box.purple-studio > .portlet-title > .actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

.portlet > .portlet-body.purple-wisteria,
.portlet.purple-wisteria {
  background-color: #9B59B6;
}

.portlet.solid.purple-wisteria > .portlet-title,
.portlet.solid.purple-wisteria > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-wisteria > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-wisteria > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-wisteria > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-wisteria > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-wisteria > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-wisteria > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-wisteria > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-wisteria > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-wisteria {
  border: 1px solid #b07cc6;
  border-top: 0;
}

.portlet.box.purple-wisteria > .portlet-title {
  background-color: #9B59B6;
}

.portlet.box.purple-wisteria > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-wisteria > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #caa7d8;
  color: #d5b9e0;
}

.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default > i {
  color: #dbc3e5;
}

.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:active, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dfcae8;
  color: #eadcf0;
}

.portlet > .portlet-body.purple-seance,
.portlet.purple-seance {
  background-color: #9A12B3;
}

.portlet.solid.purple-seance > .portlet-title,
.portlet.solid.purple-seance > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-seance > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-seance > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-seance > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-seance > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-seance > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-seance > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-seance > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-seance > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-seance {
  border: 1px solid #c217e1;
  border-top: 0;
}

.portlet.box.purple-seance > .portlet-title {
  background-color: #9A12B3;
}

.portlet.box.purple-seance > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-seance > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-seance > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #d349ed;
  color: #d960ef;
}

.portlet.box.purple-seance > .portlet-title > .actions .btn-default > i {
  color: #dc6ef0;
}

.portlet.box.purple-seance > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-seance > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-seance > .portlet-title > .actions .btn-default:active, .portlet.box.purple-seance > .portlet-title > .actions .btn-default.active {
  border: 1px solid #de77f1;
  color: #e48ef4;
}

.portlet > .portlet-body.purple-intense,
.portlet.purple-intense {
  background-color: #8775a7;
}

.portlet.solid.purple-intense > .portlet-title,
.portlet.solid.purple-intense > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-intense > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-intense > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-intense > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-intense > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-intense > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-intense > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-intense > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-intense > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-intense {
  border: 1px solid #a294bb;
  border-top: 0;
}

.portlet.box.purple-intense > .portlet-title {
  background-color: #8775a7;
}

.portlet.box.purple-intense > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-intense > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-intense > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}

.portlet.box.purple-intense > .portlet-title > .actions .btn-default > i {
  color: #d8d2e3;
}

.portlet.box.purple-intense > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-intense > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-intense > .portlet-title > .actions .btn-default:active, .portlet.box.purple-intense > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

.portlet > .portlet-body.purple-sharp,
.portlet.purple-sharp {
  background-color: #796799;
}

.portlet.solid.purple-sharp > .portlet-title,
.portlet.solid.purple-sharp > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-sharp > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-sharp > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-sharp > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-sharp > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-sharp > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-sharp > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-sharp > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-sharp {
  border: 1px solid #9486ad;
  border-top: 0;
}

.portlet.box.purple-sharp > .portlet-title {
  background-color: #796799;
}

.portlet.box.purple-sharp > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-sharp > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4aac6;
  color: #c2b9d0;
}

.portlet.box.purple-sharp > .portlet-title > .actions .btn-default > i {
  color: #cac3d6;
}

.portlet.box.purple-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cfc9db;
  color: #ddd8e5;
}

.portlet > .portlet-body.purple-soft,
.portlet.purple-soft {
  background-color: #8877a9;
}

.portlet.solid.purple-soft > .portlet-title,
.portlet.solid.purple-soft > .portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-soft > .portlet-title > .caption {
  font-weight: 400;
}

.portlet.solid.purple-soft > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.solid.purple-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/portlet-remove-icon-white.png);
}

.portlet.solid.purple-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/portlet-config-icon-white.png);
}

.portlet.solid.purple-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/portlet-reload-icon-white.png);
}

.portlet.solid.purple-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/portlet-expand-icon-white.png);
}

.portlet.solid.purple-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/portlet-collapse-icon-white.png);
}

.portlet.solid.purple-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-soft {
  border: 1px solid #a396bd;
  border-top: 0;
}

.portlet.box.purple-soft > .portlet-title {
  background-color: #8877a9;
}

.portlet.box.purple-soft > .portlet-title > .caption {
  color: #ffffff;
}

.portlet.box.purple-soft > .portlet-title > .caption > i {
  color: #ffffff;
}

.portlet.box.purple-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c4bcd4;
  color: #d2cbde;
}

.portlet.box.purple-soft > .portlet-title > .actions .btn-default > i {
  color: #dad5e4;
}

.portlet.box.purple-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-soft > .portlet-title > .actions .btn-default:active, .portlet.box.purple-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dfdbe8;
  color: #edebf2;
}

/***
Dashboard Stats
***/
.dashboard-stat {
  display: block;
  margin-bottom: 25px;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.dashboard-stat:before, .dashboard-stat:after {
  content: " ";
  display: table;
}

.dashboard-stat:after {
  clear: both;
}

.portlet .dashboard-stat:last-child {
  margin-bottom: 0;
}

.dashboard-stat .visual {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 35px;
}

.dashboard-stat .visual > i {
  margin-left: -35px;
  font-size: 110px;
  line-height: 110px;
}

.dashboard-stat .details {
  position: absolute;
  right: 15px;
  padding-right: 15px;
}

.dashboard-stat .details .number {
  padding-top: 25px;
  text-align: right;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 0px;
  font-weight: 300;
}

.dashboard-stat .details .desc {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 300;
}

.dashboard-stat .more {
  clear: both;
  display: block;
  padding: 6px 10px 6px 10px;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.dashboard-stat .more:hover {
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.dashboard-stat .more > i {
  display: inline-block;
  margin-top: 1px;
  float: right;
}

.dashboard-stat.white {
  background-color: #ffffff;
}

.dashboard-stat.white.dashboard-stat-light:hover {
  background-color: whitesmoke;
}

.dashboard-stat.white .visual > i {
  color: #666;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.white .details .number {
  color: #666;
}

.dashboard-stat.white .details .desc {
  color: #666;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.white .more {
  color: #666;
  background-color: whitesmoke;
}

.dashboard-stat.default {
  background-color: #e1e5ec;
}

.dashboard-stat.default.dashboard-stat-light:hover {
  background-color: #d5dae4;
}

.dashboard-stat.default .visual > i {
  color: #666;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.default .details .number {
  color: #666;
}

.dashboard-stat.default .details .desc {
  color: #666;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.default .more {
  color: #666;
  background-color: #d5dae4;
}

.dashboard-stat.dark {
  background-color: #2f353b;
}

.dashboard-stat.dark.dashboard-stat-light:hover {
  background-color: #262b30;
}

.dashboard-stat.dark .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.dark .details .number {
  color: #FFFFFF;
}

.dashboard-stat.dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.dark .more {
  color: #FFFFFF;
  background-color: #262b30;
}

.dashboard-stat.blue {
  background-color: #3598dc;
}

.dashboard-stat.blue.dashboard-stat-light:hover {
  background-color: #258fd7;
}

.dashboard-stat.blue .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue .more {
  color: #FFFFFF;
  background-color: #258fd7;
}

.dashboard-stat.blue-madison {
  background-color: #578ebe;
}

.dashboard-stat.blue-madison.dashboard-stat-light:hover {
  background-color: #4884b8;
}

.dashboard-stat.blue-madison .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-madison .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-madison .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-madison .more {
  color: #FFFFFF;
  background-color: #4884b8;
}

.dashboard-stat.blue-chambray {
  background-color: #2C3E50;
}

.dashboard-stat.blue-chambray.dashboard-stat-light:hover {
  background-color: #253443;
}

.dashboard-stat.blue-chambray .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-chambray .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-chambray .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-chambray .more {
  color: #FFFFFF;
  background-color: #253443;
}

.dashboard-stat.blue-ebonyclay {
  background-color: #22313F;
}

.dashboard-stat.blue-ebonyclay.dashboard-stat-light:hover {
  background-color: #1b2732;
}

.dashboard-stat.blue-ebonyclay .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-ebonyclay .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-ebonyclay .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-ebonyclay .more {
  color: #FFFFFF;
  background-color: #1b2732;
}

.dashboard-stat.blue-hoki {
  background-color: #67809F;
}

.dashboard-stat.blue-hoki.dashboard-stat-light:hover {
  background-color: #5e7694;
}

.dashboard-stat.blue-hoki .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-hoki .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-hoki .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-hoki .more {
  color: #FFFFFF;
  background-color: #5e7694;
}

.dashboard-stat.blue-steel {
  background-color: #4B77BE;
}

.dashboard-stat.blue-steel.dashboard-stat-light:hover {
  background-color: #416db4;
}

.dashboard-stat.blue-steel .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-steel .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-steel .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-steel .more {
  color: #FFFFFF;
  background-color: #416db4;
}

.dashboard-stat.blue-soft {
  background-color: #4c87b9;
}

.dashboard-stat.blue-soft.dashboard-stat-light:hover {
  background-color: #447dad;
}

.dashboard-stat.blue-soft .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-soft .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-soft .more {
  color: #FFFFFF;
  background-color: #447dad;
}

.dashboard-stat.blue-dark {
  background-color: #5e738b;
}

.dashboard-stat.blue-dark.dashboard-stat-light:hover {
  background-color: #56697f;
}

.dashboard-stat.blue-dark .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-dark .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-dark .more {
  color: #FFFFFF;
  background-color: #56697f;
}

.dashboard-stat.blue-sharp {
  background-color: #5C9BD1;
}

.dashboard-stat.blue-sharp.dashboard-stat-light:hover {
  background-color: #4c91cd;
}

.dashboard-stat.blue-sharp .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-sharp .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-sharp .more {
  color: #FFFFFF;
  background-color: #4c91cd;
}

.dashboard-stat.green {
  background-color: #32c5d2;
}

.dashboard-stat.green.dashboard-stat-light:hover {
  background-color: #2bb8c4;
}

.dashboard-stat.green .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green .more {
  color: #FFFFFF;
  background-color: #2bb8c4;
}

.dashboard-stat.green-meadow {
  background-color: #1BBC9B;
}

.dashboard-stat.green-meadow.dashboard-stat-light:hover {
  background-color: #18aa8c;
}

.dashboard-stat.green-meadow .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-meadow .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-meadow .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-meadow .more {
  color: #FFFFFF;
  background-color: #18aa8c;
}

.dashboard-stat.green-seagreen {
  background-color: #1BA39C;
}

.dashboard-stat.green-seagreen.dashboard-stat-light:hover {
  background-color: #18918b;
}

.dashboard-stat.green-seagreen .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-seagreen .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-seagreen .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-seagreen .more {
  color: #FFFFFF;
  background-color: #18918b;
}

.dashboard-stat.green-turquoise {
  background-color: #36D7B7;
}

.dashboard-stat.green-turquoise.dashboard-stat-light:hover {
  background-color: #29cfae;
}

.dashboard-stat.green-turquoise .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-turquoise .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-turquoise .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-turquoise .more {
  color: #FFFFFF;
  background-color: #29cfae;
}

.dashboard-stat.green-haze {
  background-color: #44b6ae;
}

.dashboard-stat.green-haze.dashboard-stat-light:hover {
  background-color: #3ea7a0;
}

.dashboard-stat.green-haze .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-haze .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-haze .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-haze .more {
  color: #FFFFFF;
  background-color: #3ea7a0;
}

.dashboard-stat.green-jungle {
  background-color: #26C281;
}

.dashboard-stat.green-jungle.dashboard-stat-light:hover {
  background-color: #23b176;
}

.dashboard-stat.green-jungle .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-jungle .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-jungle .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-jungle .more {
  color: #FFFFFF;
  background-color: #23b176;
}

.dashboard-stat.green-soft {
  background-color: #3faba4;
}

.dashboard-stat.green-soft.dashboard-stat-light:hover {
  background-color: #3a9c96;
}

.dashboard-stat.green-soft .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-soft .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-soft .more {
  color: #FFFFFF;
  background-color: #3a9c96;
}

.dashboard-stat.green-dark {
  background-color: #4DB3A2;
}

.dashboard-stat.green-dark.dashboard-stat-light:hover {
  background-color: #46a595;
}

.dashboard-stat.green-dark .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-dark .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-dark .more {
  color: #FFFFFF;
  background-color: #46a595;
}

.dashboard-stat.green-sharp {
  background-color: #2ab4c0;
}

.dashboard-stat.green-sharp.dashboard-stat-light:hover {
  background-color: #26a4af;
}

.dashboard-stat.green-sharp .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-sharp .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-sharp .more {
  color: #FFFFFF;
  background-color: #26a4af;
}

.dashboard-stat.grey {
  background-color: #E5E5E5;
}

.dashboard-stat.grey.dashboard-stat-light:hover {
  background-color: #dbdbdb;
}

.dashboard-stat.grey .visual > i {
  color: #333333;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey .details .number {
  color: #333333;
}

.dashboard-stat.grey .details .desc {
  color: #333333;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey .more {
  color: #333333;
  background-color: #dbdbdb;
}

.dashboard-stat.grey-steel {
  background-color: #e9edef;
}

.dashboard-stat.grey-steel.dashboard-stat-light:hover {
  background-color: #dde3e6;
}

.dashboard-stat.grey-steel .visual > i {
  color: #80898e;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-steel .details .number {
  color: #80898e;
}

.dashboard-stat.grey-steel .details .desc {
  color: #80898e;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-steel .more {
  color: #80898e;
  background-color: #dde3e6;
}

.dashboard-stat.grey-cararra {
  background-color: #fafafa;
}

.dashboard-stat.grey-cararra.dashboard-stat-light:hover {
  background-color: #f0f0f0;
}

.dashboard-stat.grey-cararra .visual > i {
  color: #333333;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-cararra .details .number {
  color: #333333;
}

.dashboard-stat.grey-cararra .details .desc {
  color: #333333;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-cararra .more {
  color: #333333;
  background-color: #f0f0f0;
}

.dashboard-stat.grey-gallery {
  background-color: #555555;
}

.dashboard-stat.grey-gallery.dashboard-stat-light:hover {
  background-color: #4b4b4b;
}

.dashboard-stat.grey-gallery .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-gallery .details .number {
  color: #ffffff;
}

.dashboard-stat.grey-gallery .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-gallery .more {
  color: #ffffff;
  background-color: #4b4b4b;
}

.dashboard-stat.grey-cascade {
  background-color: #95A5A6;
}

.dashboard-stat.grey-cascade.dashboard-stat-light:hover {
  background-color: #8a9c9d;
}

.dashboard-stat.grey-cascade .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-cascade .details .number {
  color: #FFFFFF;
}

.dashboard-stat.grey-cascade .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-cascade .more {
  color: #FFFFFF;
  background-color: #8a9c9d;
}

.dashboard-stat.grey-silver {
  background-color: #BFBFBF;
}

.dashboard-stat.grey-silver.dashboard-stat-light:hover {
  background-color: #b5b5b5;
}

.dashboard-stat.grey-silver .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-silver .details .number {
  color: #FAFCFB;
}

.dashboard-stat.grey-silver .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-silver .more {
  color: #FAFCFB;
  background-color: #b5b5b5;
}

.dashboard-stat.grey-salsa {
  background-color: #ACB5C3;
}

.dashboard-stat.grey-salsa.dashboard-stat-light:hover {
  background-color: #a0aaba;
}

.dashboard-stat.grey-salsa .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-salsa .details .number {
  color: #FAFCFB;
}

.dashboard-stat.grey-salsa .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-salsa .more {
  color: #FAFCFB;
  background-color: #a0aaba;
}

.dashboard-stat.grey-salt {
  background-color: #bfcad1;
}

.dashboard-stat.grey-salt.dashboard-stat-light:hover {
  background-color: #b3c0c8;
}

.dashboard-stat.grey-salt .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-salt .details .number {
  color: #FAFCFB;
}

.dashboard-stat.grey-salt .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-salt .more {
  color: #FAFCFB;
  background-color: #b3c0c8;
}

.dashboard-stat.grey-mint {
  background-color: #525e64;
}

.dashboard-stat.grey-mint.dashboard-stat-light:hover {
  background-color: #495359;
}

.dashboard-stat.grey-mint .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.grey-mint .details .number {
  color: #FFFFFF;
}

.dashboard-stat.grey-mint .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.grey-mint .more {
  color: #FFFFFF;
  background-color: #495359;
}

.dashboard-stat.red {
  background-color: #e7505a;
}

.dashboard-stat.red.dashboard-stat-light:hover {
  background-color: #e53e49;
}

.dashboard-stat.red .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red .details .number {
  color: #ffffff;
}

.dashboard-stat.red .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red .more {
  color: #ffffff;
  background-color: #e53e49;
}

.dashboard-stat.red-pink {
  background-color: #E08283;
}

.dashboard-stat.red-pink.dashboard-stat-light:hover {
  background-color: #dc7273;
}

.dashboard-stat.red-pink .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-pink .details .number {
  color: #ffffff;
}

.dashboard-stat.red-pink .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-pink .more {
  color: #ffffff;
  background-color: #dc7273;
}

.dashboard-stat.red-sunglo {
  background-color: #E26A6A;
}

.dashboard-stat.red-sunglo.dashboard-stat-light:hover {
  background-color: #df5959;
}

.dashboard-stat.red-sunglo .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-sunglo .details .number {
  color: #ffffff;
}

.dashboard-stat.red-sunglo .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-sunglo .more {
  color: #ffffff;
  background-color: #df5959;
}

.dashboard-stat.red-intense {
  background-color: #e35b5a;
}

.dashboard-stat.red-intense.dashboard-stat-light:hover {
  background-color: #e04a49;
}

.dashboard-stat.red-intense .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-intense .details .number {
  color: #ffffff;
}

.dashboard-stat.red-intense .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-intense .more {
  color: #ffffff;
  background-color: #e04a49;
}

.dashboard-stat.red-thunderbird {
  background-color: #D91E18;
}

.dashboard-stat.red-thunderbird.dashboard-stat-light:hover {
  background-color: #c71b16;
}

.dashboard-stat.red-thunderbird .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-thunderbird .details .number {
  color: #ffffff;
}

.dashboard-stat.red-thunderbird .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-thunderbird .more {
  color: #ffffff;
  background-color: #c71b16;
}

.dashboard-stat.red-flamingo {
  background-color: #EF4836;
}

.dashboard-stat.red-flamingo.dashboard-stat-light:hover {
  background-color: #ed3723;
}

.dashboard-stat.red-flamingo .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-flamingo .details .number {
  color: #ffffff;
}

.dashboard-stat.red-flamingo .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-flamingo .more {
  color: #ffffff;
  background-color: #ed3723;
}

.dashboard-stat.red-soft {
  background-color: #d05454;
}

.dashboard-stat.red-soft.dashboard-stat-light:hover {
  background-color: #cc4444;
}

.dashboard-stat.red-soft .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-soft .details .number {
  color: #ffffff;
}

.dashboard-stat.red-soft .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-soft .more {
  color: #ffffff;
  background-color: #cc4444;
}

.dashboard-stat.red-haze {
  background-color: #f36a5a;
}

.dashboard-stat.red-haze.dashboard-stat-light:hover {
  background-color: #f25947;
}

.dashboard-stat.red-haze .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-haze .details .number {
  color: #ffffff;
}

.dashboard-stat.red-haze .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-haze .more {
  color: #ffffff;
  background-color: #f25947;
}

.dashboard-stat.red-mint {
  background-color: #e43a45;
}

.dashboard-stat.red-mint.dashboard-stat-light:hover {
  background-color: #e22834;
}

.dashboard-stat.red-mint .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-mint .details .number {
  color: #ffffff;
}

.dashboard-stat.red-mint .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-mint .more {
  color: #ffffff;
  background-color: #e22834;
}

.dashboard-stat.yellow {
  background-color: #c49f47;
}

.dashboard-stat.yellow.dashboard-stat-light:hover {
  background-color: #bb953c;
}

.dashboard-stat.yellow .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow .more {
  color: #ffffff;
  background-color: #bb953c;
}

.dashboard-stat.yellow-gold {
  background-color: #E87E04;
}

.dashboard-stat.yellow-gold.dashboard-stat-light:hover {
  background-color: #d47304;
}

.dashboard-stat.yellow-gold .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-gold .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-gold .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-gold .more {
  color: #ffffff;
  background-color: #d47304;
}

.dashboard-stat.yellow-casablanca {
  background-color: #f2784b;
}

.dashboard-stat.yellow-casablanca.dashboard-stat-light:hover {
  background-color: #f16a38;
}

.dashboard-stat.yellow-casablanca .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-casablanca .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-casablanca .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-casablanca .more {
  color: #ffffff;
  background-color: #f16a38;
}

.dashboard-stat.yellow-crusta {
  background-color: #f3c200;
}

.dashboard-stat.yellow-crusta.dashboard-stat-light:hover {
  background-color: #dfb200;
}

.dashboard-stat.yellow-crusta .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-crusta .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-crusta .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-crusta .more {
  color: #ffffff;
  background-color: #dfb200;
}

.dashboard-stat.yellow-lemon {
  background-color: #F7CA18;
}

.dashboard-stat.yellow-lemon.dashboard-stat-light:hover {
  background-color: #f2c308;
}

.dashboard-stat.yellow-lemon .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-lemon .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-lemon .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-lemon .more {
  color: #ffffff;
  background-color: #f2c308;
}

.dashboard-stat.yellow-saffron {
  background-color: #F4D03F;
}

.dashboard-stat.yellow-saffron.dashboard-stat-light:hover {
  background-color: #f3cb2c;
}

.dashboard-stat.yellow-saffron .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-saffron .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-saffron .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-saffron .more {
  color: #ffffff;
  background-color: #f3cb2c;
}

.dashboard-stat.yellow-soft {
  background-color: #c8d046;
}

.dashboard-stat.yellow-soft.dashboard-stat-light:hover {
  background-color: #c3cc36;
}

.dashboard-stat.yellow-soft .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-soft .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-soft .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-soft .more {
  color: #ffffff;
  background-color: #c3cc36;
}

.dashboard-stat.yellow-haze {
  background-color: #c5bf66;
}

.dashboard-stat.yellow-haze.dashboard-stat-light:hover {
  background-color: #bfb957;
}

.dashboard-stat.yellow-haze .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-haze .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-haze .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-haze .more {
  color: #ffffff;
  background-color: #bfb957;
}

.dashboard-stat.yellow-mint {
  background-color: #c5b96b;
}

.dashboard-stat.yellow-mint.dashboard-stat-light:hover {
  background-color: #bfb25c;
}

.dashboard-stat.yellow-mint .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-mint .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-mint .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-mint .more {
  color: #ffffff;
  background-color: #bfb25c;
}

.dashboard-stat.purple {
  background-color: #8E44AD;
}

.dashboard-stat.purple.dashboard-stat-light:hover {
  background-color: #823e9e;
}

.dashboard-stat.purple .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple .details .number {
  color: #ffffff;
}

.dashboard-stat.purple .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple .more {
  color: #ffffff;
  background-color: #823e9e;
}

.dashboard-stat.purple-plum {
  background-color: #8775a7;
}

.dashboard-stat.purple-plum.dashboard-stat-light:hover {
  background-color: #7c699f;
}

.dashboard-stat.purple-plum .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-plum .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-plum .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-plum .more {
  color: #ffffff;
  background-color: #7c699f;
}

.dashboard-stat.purple-medium {
  background-color: #BF55EC;
}

.dashboard-stat.purple-medium.dashboard-stat-light:hover {
  background-color: #b843ea;
}

.dashboard-stat.purple-medium .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-medium .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-medium .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-medium .more {
  color: #ffffff;
  background-color: #b843ea;
}

.dashboard-stat.purple-studio {
  background-color: #8E44AD;
}

.dashboard-stat.purple-studio.dashboard-stat-light:hover {
  background-color: #823e9e;
}

.dashboard-stat.purple-studio .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-studio .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-studio .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-studio .more {
  color: #ffffff;
  background-color: #823e9e;
}

.dashboard-stat.purple-wisteria {
  background-color: #9B59B6;
}

.dashboard-stat.purple-wisteria.dashboard-stat-light:hover {
  background-color: #924dae;
}

.dashboard-stat.purple-wisteria .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-wisteria .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-wisteria .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-wisteria .more {
  color: #ffffff;
  background-color: #924dae;
}

.dashboard-stat.purple-seance {
  background-color: #9A12B3;
}

.dashboard-stat.purple-seance.dashboard-stat-light:hover {
  background-color: #8a10a0;
}

.dashboard-stat.purple-seance .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-seance .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-seance .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-seance .more {
  color: #ffffff;
  background-color: #8a10a0;
}

.dashboard-stat.purple-intense {
  background-color: #8775a7;
}

.dashboard-stat.purple-intense.dashboard-stat-light:hover {
  background-color: #7c699f;
}

.dashboard-stat.purple-intense .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-intense .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-intense .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-intense .more {
  color: #ffffff;
  background-color: #7c699f;
}

.dashboard-stat.purple-sharp {
  background-color: #796799;
}

.dashboard-stat.purple-sharp.dashboard-stat-light:hover {
  background-color: #6f5f8d;
}

.dashboard-stat.purple-sharp .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-sharp .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-sharp .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-sharp .more {
  color: #ffffff;
  background-color: #6f5f8d;
}

.dashboard-stat.purple-soft {
  background-color: #8877a9;
}

.dashboard-stat.purple-soft.dashboard-stat-light:hover {
  background-color: #7d6ba1;
}

.dashboard-stat.purple-soft .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-soft .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-soft .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-soft .more {
  color: #ffffff;
  background-color: #7d6ba1;
}

.dashboard-stat-light {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.dashboard-stat-light .details {
  margin-bottom: 5px;
}

.dashboard-stat-light .details .number {
  font-weight: 300;
  margin-bottom: 0px;
}

/***
Dashboard Stats 2
***/
.dashboard-stat2 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 15px 15px 30px 15px;
  margin-bottom: 20px;
}

.dashboard-stat2.bordered {
  border: 1px solid #e7ecf1;
}

.dashboard-stat2 .display {
  margin-bottom: 20px;
}

.dashboard-stat2 .display:before, .dashboard-stat2 .display:after {
  content: " ";
  display: table;
}

.dashboard-stat2 .display:after {
  clear: both;
}

.dashboard-stat2 .display .number {
  float: left;
  display: inline-block;
}

.dashboard-stat2 .display .number h3 {
  margin: 0 0 2px 0;
  padding: 0;
  font-size: 30px;
  font-weight: 400;
}

.dashboard-stat2 .display .number h3 > small {
  font-size: 23px;
}

.dashboard-stat2 .display .number small {
  font-size: 14px;
  color: #AAB5BC;
  font-weight: 600;
  text-transform: uppercase;
}

.dashboard-stat2 .display .icon {
  display: inline-block;
  float: right;
  padding: 7px 0 0 0;
}

.dashboard-stat2 .display .icon > i {
  color: #cbd4e0;
  font-size: 26px;
}

.dashboard-stat2 .progress-info {
  clear: both;
}

.dashboard-stat2 .progress-info .progress {
  margin: 0;
  height: 4px;
  clear: both;
  display: block;
}

.dashboard-stat2 .progress-info .status {
  margin-top: 5px;
  font-size: 11px;
  color: #AAB5BC;
  font-weight: 600;
  text-transform: uppercase;
}

.dashboard-stat2 .progress-info .status .status-title {
  float: left;
  display: inline-block;
}

.dashboard-stat2 .progress-info .status .status-number {
  float: right;
  display: inline-block;
}

/***
Text Stats
***/
.text-stat h3 {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 18px;
}

.text-stat span {
  font-size: 13px !important;
}

@media (max-width: 767px) {
  /* 767px */
  .text-stat {
    margin-top: 20px;
  }
}

/***
Scrollable Tables
***/
.table-scrollable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #e7ecf1;
  margin: 10px 0 !important;
}

.DTS .table-scrollable {
  border: 0;
}

.table-scrollable.table-scrollable-borderless {
  border: 0;
}

.table-scrollable > .table {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0;
  background-color: #fff;
}

.table-scrollable > .table > thead > tr > th,
.table-scrollable > .table > tbody > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > tfoot > tr > td {
  white-space: nowrap;
}

.table-scrollable > .table-bordered {
  border: 0;
}

.table-scrollable > .table-bordered > thead > tr > th:first-child,
.table-scrollable > .table-bordered > tbody > tr > th:first-child,
.table-scrollable > .table-bordered > tfoot > tr > th:first-child,
.table-scrollable > .table-bordered > thead > tr > td:first-child,
.table-scrollable > .table-bordered > tbody > tr > td:first-child,
.table-scrollable > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.table-scrollable > .table-bordered > thead > tr > th:last-child,
.table-scrollable > .table-bordered > tbody > tr > th:last-child,
.table-scrollable > .table-bordered > tfoot > tr > th:last-child,
.table-scrollable > .table-bordered > thead > tr > td:last-child,
.table-scrollable > .table-bordered > tbody > tr > td:last-child,
.table-scrollable > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.table-scrollable > .table-bordered > thead > tr:last-child > th,
.table-scrollable > .table-bordered > tbody > tr:last-child > th,
.table-scrollable > .table-bordered > tfoot > tr:last-child > th,
.table-scrollable > .table-bordered > thead > tr:last-child > td,
.table-scrollable > .table-bordered > tbody > tr:last-child > td,
.table-scrollable > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}

/***
Customized Bootstrap Tables
***/
.table td,
.table th {
  font-size: 14px;
}

.table.table-bordered thead > tr > th {
  border-bottom: 0;
}

.table td .img-responsive {
  width: 100%;
}

/***
Responsive Flip Scroll Tables
***/
.flip-scroll table {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* 768px */
  .flip-scroll .flip-content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .flip-scroll * html .flip-content {
    zoom: 1;
  }
  .flip-scroll *:first-child + html .flip-content {
    zoom: 1;
  }
  .flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .flip-scroll th,
  .flip-scroll td {
    margin: 0;
    vertical-align: top;
  }
  .flip-scroll th {
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    font-size: 13px !important;
    padding: 5px;
    width: auto !important;
  }
  .flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }
  .flip-scroll thead {
    display: block;
    float: left;
  }
  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .flip-scroll .flip-content tbody tr td {
    font-size: 13px;
    line-height: 1.483;
  }
  .flip-scroll .table-bordered.flip-content tbody tr td {
    font-size: 13px;
    line-height: 1.43;
  }
  .flip-scroll thead tr {
    display: block;
  }
  .flip-scroll th {
    display: block;
    text-align: right;
  }
  .flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
  }
  .flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  /* sort out borders */
  .flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }
  .flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .flip-scroll tbody tr {
    border-left: 1px solid #ddd;
  }
  .flip-scroll th:last-child,
  .flip-scroll td:last-child {
    border-bottom: 1px solid #ddd;
  }
}

/***
Custom tables
***/
.table-toolbar {
  margin-bottom: 20px;
}

.table-toolbar:before, .table-toolbar:after {
  content: " ";
  display: table;
}

.table-toolbar:after {
  clear: both;
}

.table.table-full-width {
  width: 100% !important;
}

.table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}

.table thead tr th {
  font-size: 14px;
  font-weight: 600;
}

.table-advance {
  margin-bottom: 10px !important;
}

.table-advance thead {
  color: #3f444a;
}

.table-advance thead tr th {
  background-color: #f1f4f7;
  font-size: 14px;
  font-weight: 400;
  color: #3f444a;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
  position: absolute;
  margin-top: -5px;
  float: left;
  width: 2px;
  height: 30px;
  margin-right: 20px !important;
}

.table-advance tr td {
  border-left-width: 0px;
}

.table-advance tr td:first-child {
  border-left-width: 2px !important;
}

.table-advance tr td.highlight:first-child a {
  margin-left: 15px;
}

.table-advance td.highlight div.primary {
  border-left: 2px solid #337ab7;
}

.table-advance td.highlight div.success {
  border-left: 2px solid #36c6d3;
}

.table-advance td.highlight div.info {
  border-left: 2px solid #659be0;
}

.table-advance td.highlight div.warning {
  border-left: 2px solid #F1C40F;
}

.table-advance td.highlight div.danger {
  border-left: 2px solid #ed6b75;
}

@media (max-width: 767px) {
  /* 767px */
  .table-advance tr > td.highlight:first-child a {
    margin-left: 8px;
  }
}

/***
Light Table
***/
.table.table-light {
  border: 0 !important;
}

.table.table-light > thead > tr:hover > th {
  background: none;
}

.table.table-light > thead > tr.uppercase {
  text-transform: uppercase;
}

.table.table-light > thead > tr > th {
  font-weight: 600;
  font-size: 13px;
  color: #93a2a9;
  font-family: "Open Sans", sans-serif;
  border: 0;
  border-bottom: 1px solid #F2F5F8;
}

.table.table-light > tbody > tr:last-child > td {
  border: 0;
}

.table.table-light > tbody > tr > td {
  border: 0;
  border-bottom: 1px solid #F2F5F8;
  color: #8896a0;
  vertical-align: middle;
}

.table.table-light > tbody > tr > td.fit {
  width: 1px;
  padding-right: 3px;
}

.table.table-light > tbody > tr > td .user-pic {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.table.table-light.table-hover > tbody > tr > td:hover,
.table.table-light.table-hover > tbody > tr > th:hover,
.table.table-light.table-hover > tbody > tr:hover > td,
.table.table-light.table-hover > tbody > tr:hover > th {
  background: #f9fafb;
}

/***
Customized Bootstrap Tabs 
***/
/* Tabs and pills */
.nav-tabs,
.nav-pills {
  margin-bottom: 10px;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  font-size: 14px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a > .badge,
.nav-pills > li > a > .badge {
  margin-top: -6px;
}

.nav-tabs > li .dropdown-menu:before, .nav-tabs > li .dropdown-menu:after,
.nav-pills > li .dropdown-menu:before,
.nav-pills > li .dropdown-menu:after {
  display: none;
}

.nav-tabs.nav-tabs-sm > li > a, .nav-tabs.nav-pills-sm > li > a,
.nav-pills.nav-tabs-sm > li > a,
.nav-pills.nav-pills-sm > li > a {
  font-size: 13px;
}

.nav-tabs .dropdown.open > .dropdown-toggle,
.nav-pills .dropdown.open > .dropdown-toggle {
  background: #eee;
  color: #0d638f;
  border-color: transparent;
}

/* Left and right tabs */
.tabs-right.nav-tabs,
.tabs-left.nav-tabs {
  border-bottom: 0;
}

.tabs-right.nav-tabs > li,
.tabs-left.nav-tabs > li {
  float: none;
}

.tabs-right.nav-tabs > li > a,
.tabs-left.nav-tabs > li > a {
  margin-right: 0;
  margin-bottom: 3px;
}

/* Left tabs */
.tabs-left.nav-tabs {
  border-right: 1px solid #ddd;
}

.tabs-left.nav-tabs > li > a {
  display: block;
  margin-right: -1px;
}

.tabs-left.nav-tabs > li > a:hover, .tabs-left.nav-tabs > li > a:focus {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left.nav-tabs > li.active > a,
.tabs-left.nav-tabs > li.active > a:hover
> li.active > a:focus {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

/* Right tabs */
.tabs-right.nav-tabs {
  border-left: 1px solid #ddd;
}

.tabs-right.nav-tabs > li > a {
  display: block;
  margin-left: -1px;
}

.tabs-right.nav-tabs > li > a:hover, .tabs-right.nav-tabs > li > a:focus {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right.nav-tabs > li.active > a,
.tabs-right.nav-tabs > li.active > a:hover
> li.active > a:focus {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

/* Below tabs */
.tabs-below > .nav-tabs,
.tabs-below > .nav-pills {
  border-bottom: 0;
  margin-bottom: 0px;
  margin-top: 10px;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
  margin-bottom: 0;
  margin-top: 10px;
}

.tabs-below > .nav-tabs > li > a {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > li > a .dropdown-menu {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.tabs-below > .nav-tabs .active a,
.tabs-below > .nav-tabs .active a:hover
.active a:focus {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-color: transparent #ddd #ddd #ddd  !important;
}

/***
Custom tabs
***/
/* In BS3.0.0 tabbable class was removed. We had to added it back */
.tabbable:before, .tabbable:after {
  content: " ";
  display: table;
}

.tabbable:after {
  clear: both;
}

.tabbable-custom {
  margin-bottom: 15px;
  padding: 0px;
  overflow: hidden;
  /* justified tabs */
  /* boxless tabs */
  /* below justified tabs */
  /* full width tabs */
  /* below tabs */
}

.tabbable-custom > .nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-custom > .nav-tabs > li {
  margin-right: 2px;
  border-top: 2px solid transparent;
}

.tabbable-custom > .nav-tabs > li > a {
  margin-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.tabbable-custom > .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}

.tabbable-custom > .nav-tabs > li.active {
  border-top: 3px solid #ed6b75;
  margin-top: 0;
  position: relative;
}

.tabbable-custom > .nav-tabs > li.active > a {
  border-top: none !important;
  font-weight: 400;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.tabbable-custom > .nav-tabs > li.active > a:hover {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border-top: none;
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom > .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabbable-custom.nav-justified > .tab-content {
  margin-top: -1px;
}

.tabbable-custom.boxless > .tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.tabbable-custom.tabs-below.nav-justified .tab-content {
  margin-top: 0px;
  margin-bottom: -2px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.tabbable-custom.tabbable-full-width > .nav-tabs > li > a {
  color: #424242;
  font-size: 15px;
  padding: 9px 15px;
}

.tabbable-custom.tabbable-full-width > .tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.tabbable-custom.tabs-below .nav-tabs > li > a {
  border-top: none;
  border-bottom: 2px solid transparent;
  margin-top: -1px;
}

.tabbable-custom.tabs-below .nav-tabs > li.active {
  border-top: none;
  border-bottom: 3px solid #d12610;
  margin-bottom: 0;
  position: relative;
}

.tabbable-custom.tabs-below .nav-tabs > li.active > a {
  border-bottom: none;
}

.tabbable-custom.tabs-below .nav-tabs > li.active > a:hover {
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom.tabbable-noborder > .nav-tabs > li > a {
  border: 0;
}

.tabbable-custom.tabbable-noborder .tab-content {
  border: 0;
}

.portlet:not(.light) .tabbable-line {
  padding-top: 15px;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-line > .nav-tabs > li {
  margin: 0;
}

.tabbable-line > .nav-tabs > li > a {
  background: none !important;
  border: 0;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  color: #737373;
}

.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.active {
  background: none;
  border-bottom: 4px solid #36c6d3;
  position: relative;
}

.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333;
}

.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}

.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  background: none;
  border-bottom: 4px solid #9fe4ea;
}

.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333;
}

.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}

.tabbable-line > .tab-content {
  margin-top: 0;
  border: 0;
  border-top: 1px solid #eef1f5;
  padding: 30px 0;
}

.page-container-bg-solid .tabbable-line > .tab-content {
  border-top: 1px solid #dae2ea;
}

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: 4px solid transparent;
}

.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}

.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: 4px solid #fbdcde;
}

.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid #ed6b75;
}

.tabbable-line.tabs-below > .tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.portlet .tabbable-bordered {
  margin-top: 20px;
}

.tabbable-bordered .nav-tabs {
  margin-bottom: 0;
  border-bottom: 0;
}

.tabbable-bordered .tab-content {
  padding: 30px 20px 20px 20px;
  border: 1px solid #ddd;
  background: #ffffff;
}

.m-heading-1 {
  margin: 0 0 20px 0;
  background: #ffffff;
  padding-left: 15px;
  border-left: 8px solid #88909a;
}

.m-heading-1 > h3 {
  font-size: 20px;
  color: #3f444a;
  font-weight: 500;
  margin: 0 0 15px 0;
}

.m-heading-1 > h3 > i {
  font-size: 18px;
  color: #88909a;
}

.m-heading-1 > p {
  color: #5c6873;
  margin: 10px 0 0 0;
}

.m-heading-1 > p:first-child {
  margin-top: 0;
}

.m-heading-1.m-bordered {
  border-right: 1px solid #10161c;
  border-top: 1px solid #10161c;
  border-bottom: 1px solid #10161c;
  padding: 15px 15px;
}

.page-container-bg-solid .m-heading-1.m-bordered {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}

.m-heading-1.m-title-md > h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.m-heading-1.m-title-md > h3 > i {
  font-size: 16px;
  color: #88909a;
}

.m-heading-1.m-title-md > p {
  margin: 15px 0;
}

.m-heading-1.m-title-sm > h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.m-heading-1.m-title-sm > h3 > i {
  font-size: 14px;
  color: #88909a;
}

.m-heading-1.m-title-sm > p {
  margin: 10px 0;
}

@media (max-width: 991px) {
  /* 991px */
  .m-heading-1 {
    margin: 0px 0;
  }
}

/***
Timeline 
***/
.timeline {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 30px;
}

.timeline:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: #f5f6fa;
  top: 0px;
  bottom: 0px;
  margin-left: 38px;
}

.timeline .timeline-item {
  margin: 0;
  padding: 0;
}

.timeline .timeline-badge {
  float: left;
  position: relative;
  padding-right: 30px;
  height: 80px;
  width: 80px;
}

.timeline .timeline-badge-userpic {
  width: 80px;
  border: 4px #f5f6fa solid;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.timeline .timeline-badge-userpic img {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  vertical-align: middle !important;
}

.timeline .timeline-icon {
  width: 80px;
  height: 80px;
  background-color: #f5f6fa;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  padding-top: 30px;
  padding-left: 22px;
}

.timeline .timeline-icon i {
  font-size: 34px;
}

.timeline .timeline-body {
  position: relative;
  padding: 20px;
  margin-top: 20px;
  margin-left: 110px;
  background-color: #f5f6fa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.timeline .timeline-body:before, .timeline .timeline-body:after {
  content: " ";
  display: table;
}

.timeline .timeline-body:after {
  clear: both;
}

.timeline .timeline-body-arrow {
  position: absolute;
  top: 30px;
  left: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 14px 14px 0;
  border-color: transparent #f5f6fa transparent transparent;
}

.timeline .timeline-body-head {
  margin-bottom: 10px;
}

.timeline .timeline-body-head-caption {
  float: left;
}

.timeline .timeline-body-title {
  font-size: 16px;
  font-weight: 600;
}

.timeline .timeline-body-alerttitle {
  font-size: 16px;
  font-weight: 600;
}

.timeline .timeline-body-time {
  font-size: 14px;
  margin-left: 10px;
}

.timeline .timeline-body-head-actions {
  float: right;
}

.timeline .timeline-body-head-actions .btn-group {
  margin-top: -2px;
}

.timeline .timeline-body-content {
  font-size: 14px;
  margin-top: 35px;
}

.timeline .timeline-body-img {
  width: 100px;
  height: 100px;
  margin: 5px 20px 0 0px;
}

.timeline.white-bg:before {
  background: #fff;
}

.timeline.white-bg .timeline-badge-userpic {
  border-color: #fff;
}

.timeline.white-bg .timeline-icon {
  background-color: #fff;
}

.timeline.white-bg .timeline-body {
  background-color: #fff;
}

.timeline.white-bg .timeline-body-arrow {
  border-color: transparent #fff transparent transparent;
}

@media (max-width: 768px) {
  .timeline .timeline-body-head-caption {
    width: 100%;
  }
  .timeline .timeline-body-head-actions {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .timeline:before {
    margin-left: 28px;
  }
  .timeline .timeline-badge {
    padding-right: 40px;
    width: 60px;
    height: 60px;
  }
  .timeline .timeline-badge-userpic {
    width: 60px;
  }
  .timeline .timeline-icon {
    width: 60px;
    height: 60px;
    padding-top: 23px;
    padding-left: 18px;
  }
  .timeline .timeline-icon i {
    font-size: 25px;
  }
  .timeline .timeline-body {
    margin-left: 80px;
  }
  .timeline .timeline-body-arrow {
    top: 17px;
  }
}

/***
Tasks Widget 
***/
.tasks-widget:after {
  clear: both;
}

.tasks-widget .task-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tasks-widget .task-list > li {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #F4F6F9;
}

.tasks-widget .task-list > li:hover {
  background: #F4F6F9;
}

.tasks-widget .task-list > li.last-line {
  border-bottom: none;
}

.tasks-widget .task-list > li.task-done {
  background: #f6f6f6;
}

.tasks-widget .task-list > li.task-done:hover {
  background: #f4f4f4;
}

.tasks-widget .task-list > li.task-done .task-title-sp {
  text-decoration: line-through;
}

.tasks-widget .task-list > li > .task-bell {
  margin-left: 10px;
}

.tasks-widget .task-list > li > .task-checkbox {
  float: left;
  width: 30px;
}

.tasks-widget .task-list > li > .task-checkbox input[type="checkbox"] {
  cursor: pointer;
}

.tasks-widget .task-list > li > .task-title {
  color: #838FA1;
  margin-right: 10px;
}

.tasks-widget .task-list > li > .task-title .task-title-sp {
  margin-right: 5px;
}

.tasks-widget .task-list > li .task-config-btn {
  margin-top: -1px;
}

.tasks-widget .task-list > li > .task-config {
  display: none;
  position: absolute;
  top: 7px;
  right: 10px;
}

.tasks-widget .task-list > li:hover > .task-config {
  display: block;
  margin-bottom: 0 !important;
}

.tasks-widget .task-footer {
  margin-top: 5px;
}

.tasks-widget .task-footer:before, .tasks-widget .task-footer:after {
  content: " ";
  display: table;
}

.tasks-widget .task-footer:after {
  clear: both;
}

@media only screen and (max-width: 480px) {
  .tasks-widget .task-config-btn {
    float: inherit;
    display: block;
  }
  .tasks-widget .task-list-projects li > .label {
    margin-bottom: 5px;
  }
}

.mt-comments .mt-comment {
  padding: 10px;
  margin: 0 0 10px 0;
}

.mt-comments .mt-comment .mt-comment-img {
  width: 40px;
  float: left;
}

.mt-comments .mt-comment .mt-comment-img > img {
  border-radius: 50% !important;
}

.mt-comments .mt-comment .mt-comment-body {
  padding-left: 20px;
  position: relative;
  overflow: hidden;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-info:before, .mt-comments .mt-comment .mt-comment-body .mt-comment-info:after {
  content: " ";
  display: table;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-info:after {
  clear: both;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-info .mt-comment-author {
  display: inline-block;
  float: left;
  margin: 0px 0px 10px 0;
  color: #060606;
  font-weight: 600;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-info .mt-comment-date {
  display: inline-block;
  float: right;
  margin: 0px;
  color: #BABABA;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-text {
  color: #a6a6a6;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details {
  margin: 10px 0px 0px 0;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-status {
  text-transform: uppercase;
  float: left;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-status.mt-comment-status-pending {
  color: #B8C0F5;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-status.mt-comment-status-approved {
  color: #6BD873;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-status.mt-comment-status-rejected {
  color: red;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-actions {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-actions > li {
  float: left;
  padding: 0 5px;
  margin: 0;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-actions > li > a {
  text-transform: uppercase;
  color: #999999;
}

.mt-comments .mt-comment .mt-comment-body .mt-comment-details .mt-comment-actions > li > a:hover {
  color: #666666;
  text-decoration: none;
}

.mt-comments .mt-comment:hover {
  background: #f9f9f9;
}

.mt-comments .mt-comment:hover .mt-comment-body .mt-comment-details .mt-comment-actions {
  display: inline-block;
}

/* Cubic Bezier Transition */
@media print {
  body {
    background-color: #fff !important;
  }
  .page-bar {
    display: none;
  }
  .page-sidebar-wrapper {
    display: none;
  }
  .page-quick-sidebar-wrapper {
    display: none;
  }
  .theme-panel {
    display: none;
  }
  .hidden-print {
    display: none;
  }
  .page-footer {
    display: none;
  }
  .no-page-break {
    page-break-after: avoid;
  }
  .page-container {
    margin: 0px !important;
    padding: 0px !important;
  }
  .page-content {
    padding: 0 !important;
    min-height: 300px !important;
    padding: 0px 20px 20px !important;
    margin: 0 !important;
  }
}

/***
Page Header
***/
.page-header.navbar {
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  height: 0px;
  min-height: 0px;
  filter: none;
  background-image: none;
  /* Fixed header */
  /* Header logo */
  /* Search box */
  /* Menu Toggler */
  /* Top menu */
}

.page-header.navbar.navbar-fixed-top {
  z-index: 9995;
}

.page-header.navbar.navbar-static-top {
  z-index: 9995;
}

.page-header.navbar .page-logo {
  float: left;
  display: block;
  width: 235px;
  height: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
  padding: 0;
}

.page-header.navbar .page-logo > .logo-image,
.page-header.navbar .page-logo > a {
  display: inline-block;
  float: left;
}

.page-header.navbar .page-logo .logo-default {
  margin: -7px 0 0 0;
}

.page-header.navbar .page-logo .logo-mini {
  display: none;
  margin-left: 5px;
}

.page-header.navbar .page-logo .text-logo {
  padding-left: 20px;
  padding-top: 12px;
}

.page-header.navbar .search-form {
  display: inline-block;
  width: 0px;
  position: relative;
  float: left;
  transition: all 0.6s;
}

.page-header.navbar .search-form .input-group .form-control {
  height: 0px;
  border: 0;
  background: transparent !important;
  font-size: 13px;
  padding-left: 0;
  margin-left: 12px;
  text-indent: -150000px;
}

.page-header.navbar .search-form .input-group .form-control:hover {
  cursor: pointer;
}

.page-header.navbar .search-form .input-group .input-group-btn {
  height: 0px;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit {
  margin-left: -24px;
  padding: 0;
  width: 0px;
  background: none;
  margin-top: 4px;
  display: block;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  font-size: 15px;
}

.page-header.navbar .search-form.open {
  transition: all 0.6s;
  width: 300px !important;
}

.page-header.navbar .search-form.open .input-group .form-control {
  text-indent: 0;
}

.page-header.navbar .search-form.open .input-group .form-control:hover {
  cursor: text;
}

.page-header.navbar .search-form.open .input-group .input-group-btn .btn.submit {
  margin-left: 0;
}

.page-header.navbar .menu-toggler {
  display: block;
  cursor: pointer;
  opacity: 0.7;
  filter: alpha(opacity=70);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
}

.page-header.navbar .menu-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}

.page-header.navbar .menu-toggler.sidebar-toggler {
  float: right;
  margin: -12px 0 0 0;
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .menu-toggler.sidebar-toggler {
  margin-right: 10.5px;
}

.page-header.navbar .menu-toggler.responsive-toggler {
  display: none;
  float: right;
  margin: -12px 6px 0 6px;
}

.page-header.navbar .top-menu {
  margin: 0;
  padding: 0;
  float: right;
}

.page-header.navbar .top-menu .navbar-nav {
  padding: 0;
  margin-right: 20px;
  display: block;
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Language */
  /* Dark version */
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown {
  margin: 0px;
  padding: 0px 4px;
  height: 0px;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown:last-child {
  padding-right: 0px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle {
  margin: 0px;
  padding: 17px 10px 9px 10px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:last-child {
  padding-right: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > i {
  font-size: 17px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > i.glyphicon {
  font-size: 16px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > .badge {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 300;
  padding: 3px 6px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:focus {
  background: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu {
  margin-top: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #eee;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu > li > a {
  color: #555;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  min-width: 160px;
  max-width: 275px;
  width: 275px;
  z-index: 9995;
  /* header notifications dropdowns */
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
  display: block;
  overflow: hidden;
  padding: 15px 15px;
  letter-spacing: 0.5px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
  margin: 0;
  padding: 0;
  float: left;
  font-size: 13px;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
  display: inline-block;
  padding: 0;
  background: none;
  clear: inherit;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  right: 10px;
  border: 0;
  margin-top: -1px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
  text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list {
  padding-right: 0 !important;
  padding-left: 0;
  list-style: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 20px;
  white-space: normal;
  font-size: 13px;
  padding: 16px 15px 18px;
  text-shadow: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
  text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li:first-child a {
  border-top: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details {
  overflow: hidden;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon {
  margin-right: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon i {
  margin-right: 2px;
  margin-left: 1px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon .badge {
  right: 15px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .time {
  float: right;
  max-width: 75px;
  font-size: 11px;
  font-weight: 400;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
  text-align: right;
  padding: 1px 5px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .photo {
  float: left;
  margin: 0 6px 6px 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .photo img {
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject {
  display: block;
  margin-left: 46px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject .from {
  font-size: 13px;
  font-weight: 600;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject .time {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5 ;
  filter: alpha(opacity=50) ;
  float: right;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .message {
  display: block !important;
  font-size: 12px;
  line-height: 1.3;
  margin-left: 46px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task {
  margin-bottom: 5px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task .desc {
  font-size: 13px;
  font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task .percent {
  float: right;
  font-weight: 600;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .progress {
  display: block;
  height: 8px;
  margin: 8px 0 2px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .progress .progress-bar {
  box-shadow: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
  padding: 14px 6px 12px 8px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > .username {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > img {
  float: left;
  margin-top: -5px;
  margin-right: 5px;
  height: 29px;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > i {
  display: inline-block;
  margin-top: 5px;
  margin: 0;
  font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu {
  width: 175px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a i {
  width: 15px;
  display: inline-block;
  margin-right: 9px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a .badge {
  margin-right: 10px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle {
  padding: 14px 3px 12px 7px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > img {
  margin-bottom: 2px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > i {
  font-size: 14px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-menu > li > a {
  font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-menu > li > a > img {
  margin-bottom: 2px;
  margin-right: 5px;
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu:before {
  border-left: none;
  border-right: none;
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu .dropdown-menu-list > li.external a {
  background: none !important;
  border: none !important;
}

/* Allow expanded search for above 768px */
@media (min-width: 768px) {
  /* 768px */
  .page-header.navbar {
    /* Search box */
  }
  .page-header.navbar .search-form.search-form-expanded {
    width: 200px;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .form-control {
    text-indent: 0;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .form-control:hover {
    cursor: text;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .input-group-btn .btn.submit {
    margin-left: 0;
  }
}

/*** 
Horizontal Menu 
***/
.page-header.navbar {
  /* Header container */
  /* Mega menu */
}

.page-header.navbar .container {
  position: relative;
}

.page-header.navbar .hor-menu {
  margin: 0 0 0 -17px;
  margin: 0;
  float: left;
}

.page-header.navbar .hor-menu .navbar-nav {
  position: static;
  /* Mega menu */
  /* Mega Menu Dropdown */
  /* Classic menu */
}

.page-header.navbar .hor-menu .navbar-nav.navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown {
  position: static;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
  left: auto;
  width: auto;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content {
  font-family: "Open Sans", sans-serif;
  padding: 15px;
  margin: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content.mega-menu-responsive-content {
  padding: 10px 18px 10px 45px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu {
  padding: 0;
  margin: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu:last-child {
  border-right: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li {
  margin: 0 !important;
  list-style: none;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
  margin-top: 5px;
  padding-left: 6px;
  font-size: 15px;
  font-weight: 400;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a {
  display: block;
  white-space: normal;
  font-family: "Open Sans", sans-serif;
  padding: 7px;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a:hover {
  text-decoration: none;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a.iconify {
  padding: 7px 7px 7px 30px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a.iconify > i {
  position: absolute;
  top: auto !important;
  margin-left: -24px;
  font-size: 15px;
  margin-top: 3px !important;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a .badge,
.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a .label {
  margin-left: 5px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown.mega-menu-full .dropdown-menu {
  left: 20px;
  right: 20px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown:hover > .dropdown-menu {
  display: block;
}

.page-header.navbar .hor-menu .navbar-nav > li.menu-dropdown .dropdown-menu:after, .page-header.navbar .hor-menu .navbar-nav > li.menu-dropdown .dropdown-menu:before {
  display: none !important;
}

.page-header.navbar .hor-menu .navbar-nav > li > a {
  font-size: 14px;
  font-weight: 400;
  padding: 13px 13px;
}

.page-header.navbar .hor-menu .navbar-nav > li > a:focus {
  background: none !important;
}

.page-header.navbar .hor-menu .navbar-nav > li.current .selected, .page-header.navbar .hor-menu .navbar-nav > li.active .selected {
  left: 50%;
  bottom: 0;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  display: inline-block;
  margin: 0;
  width: 0;
  height: 0px;
  margin-left: -7px;
  margin-bottom: -6px;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu {
  margin-top: 0;
  border: none;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 9px 10px;
  white-space: normal;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a .label,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a .badge {
  font-weight: 300;
}

.page-header.navbar .hor-menu .navbar-nav > li.classic-menu-dropdown .dropdown-menu {
  min-width: 195px;
  max-width: 235px;
}

.page-header.navbar .hor-menu .navbar-nav > li.classic-menu-dropdown:hover > .dropdown-menu {
  display: block;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > .dropdown-menu {
  top: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > a:after {
  top: 9px;
  right: 10px;
}

/* Form medium devices upto large devices */
@media (min-width: 992px) and (max-width: 1200px) {
  /* 992px 1200px */
  /* Boxed layout */
  .page-boxed .page-header.navbar {
    /* Top menu */
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
    display: none;
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle .langname {
    display: none;
  }
}

@media (min-width: 992px) {
  /* 992px */
  /* Page header */
  .page-header.navbar {
    /* Header logo */
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    padding: 0;
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    width: 45px;
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo .logo-default {
    display: none;
  }
  /* Boxed Layout */
  .page-boxed .page-header.navbar {
    /* Page logo */
    /* Top menu */
  }
  .page-boxed .page-header.navbar .page-logo {
    width: 236px;
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav {
    margin-right: 0px;
  }
  /* Sidebar closed & logo hidden */
  .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar {
    /* Page logo */
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar .page-logo {
    width: 46px;
  }
  /* Boxed layout & page sidebar fixed layout */
  .page-boxed.page-sidebar-fixed .page-header.navbar {
    /* Page logo */
  }
  .page-boxed.page-sidebar-fixed .page-header.navbar .page-logo {
    width: 235px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Page header */
  .page-header.navbar {
    padding: 0 20px 0 20px;
    position: relative;
    clear: both;
    /* Page logo */
    /* Menu Toggler */
    /* Top Menu */
  }
  .page-header.navbar .page-logo {
    width: auto;
    padding: 0;
    margin-right: 10px;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .page-header.navbar .page-logo img {
    margin-left: 4px !important;
  }
  .page-header.navbar .menu-toggler.sidebar-toggler {
    display: none !important;
  }
  .page-header.navbar .menu-toggler.responsive-toggler {
    display: inline-block;
  }
  .page-header.navbar .top-menu .navbar-nav {
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .page-header.navbar .top-menu .navbar-nav > li {
    float: left;
  }
  .page-header.navbar .top-menu .navbar-nav .nav li.dropdown i {
    display: inline-block;
    position: relative;
    top: 1px;
    right: 0px;
  }
  .page-header.navbar .top-menu .navbar-nav .open .dropdown-menu {
    position: absolute;
  }
  /* Fixed header for mobile */
  .page-header-fixed.page-header-fixed-mobile .navbar-fixed-top {
    position: fixed;
  }
  /* Boxed Layout */
  .page-boxed .page-header.navbar > .container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  /* Boxed Layout */
  .page-boxed .page-header.navbar {
    margin: auto !important;
    padding: 0;
  }
  .page-boxed .page-header.navbar > .container {
    margin: auto !important;
  }
}

@media (max-width: 767px) {
  /* 767px */
  /* Page header */
  .page-header.navbar {
    padding: 0 10px 0 10px;
    /* Header logo */
    /* Search box */
    /* Top navigation menu*/
  }
  .page-header.navbar .page-logo {
    width: auto;
  }
  .page-header.navbar .search-form.open {
    z-index: 3;
    left: 10px;
    right: 10px;
    position: absolute;
    width: auto !important;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended > .dropdown-menu {
    max-width: 255px;
    width: 255px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu {
    margin-right: -190px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu:before {
    margin-right: 190px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu {
    margin-right: -150px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu:before {
    margin-right: 150px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu {
    margin-right: -110px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu:before {
    margin-right: 110px;
  }
}

@media (max-width: 580px) {
  /* Page header */
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
    display: none;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle .langname {
    display: none;
  }
}

@media (max-width: 480px) {
  /* 480px */
  /* Fixed header for mobile */
  .page-header-fixed.page-header-fixed-mobile .page-header.navbar {
    height: 80px;
  }
  .page-header.navbar {
    /* Top menu */
  }
  .page-header.navbar .top-menu {
    display: block;
    clear: both;
    float: none;
  }
  .page-header.navbar .top-menu .navbar-nav {
    margin-right: 0;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle {
    padding: 17px 6px 9px 6px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle {
    padding: 14px 4px 12px 2px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    padding: 14px 0px 12px 2px;
  }
}

/***
Pace - Page Progress
***/
.pace .pace-progress {
  z-index: 10005;
  top: 0px;
  height: 2px;
  box-shadow: none;
}

.pace .pace-progress-inner {
  box-shadow: none;
}

.pace .pace-inactive {
  display: none;
}

.pace .pace-activity {
  top: 4px;
  z-index: 10005;
  right: 20px;
  border-radius: 10px !important;
}

@media (max-width: 480px) {
  /* 480px */
  .page-header-fixed .pace .pace-progress {
    top: 0px;
  }
  .page-header-fixed .pace .pace-activity {
    top: 4px;
  }
}

/***
Page container
***/
.page-container {
  margin: 0px;
  padding: 0px;
  position: relative;
  /* Fixed header */
  /* Fixed footer for mobile */
}

.page-container:before, .page-container:after {
  content: " ";
  display: table;
}

.page-container:after {
  clear: both;
}

.page-header-fixed .page-container {
  margin-top: 0px;
}

.page-footer-fixed.page-footer-fixed-mobile .page-container {
  margin-bottom: 20px !important;
}

@media (min-width: 992px) {
  /* Page container in fixed footer */
  .page-footer-fixed .page-container {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 991px) {
  /* Page container */
  .page-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 0px !important;
  }
}

@media (max-width: 480px) {
  /* Page container */
  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 80px !important;
  }
}

/***
Page sidebar
***/
/* Page Sidebar */
.page-sidebar,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  /* Default sidebar menu */
  /* light sidebar menu */
}

.page-sidebar.navbar-collapse,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
  padding: 0;
  box-shadow: none;
}

.page-sidebar .page-sidebar-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  /* 1st level links */
  /* all links */
}

.page-sidebar .page-sidebar-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper {
  border: 0 !important;
}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:before, .page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:after, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:before, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper:after {
  content: " ";
  display: table;
}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:after, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper:after {
  clear: both;
}

.page-sidebar .page-sidebar-menu > li.start > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.start > a {
  border-top-color: transparent !important;
}

.page-sidebar .page-sidebar-menu > li.last > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.last > a {
  border-bottom-color: transparent !important;
}

.page-sidebar .page-sidebar-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar .page-sidebar-menu > li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i {
  font-size: 16px;
  margin-right: 5px;
  text-shadow: none;
}

.page-sidebar .page-sidebar-menu > li > a > i.glyphicon,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i.glyphicon {
  margin-left: 1px;
  margin-right: 4px;
}

.page-sidebar .page-sidebar-menu > li > a > [class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > [class^="icon-"] {
  margin-left: 1px;
  margin-right: 4px;
}

.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-fixed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  transition: all 0.2s ease;
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-reversed.page-sidebar-fixed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  transition: none;
}

.page-sidebar .page-sidebar-menu > li.heading,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading {
  padding: 15px 15px 15px 15px;
}

.page-sidebar .page-sidebar-menu > li.heading > h3,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar .page-sidebar-menu > li.heading + li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading + li > a {
  border-top: 0;
}

.page-sidebar .page-sidebar-menu > li.open > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a {
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
  border: none;
  text-shadow: none;
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu > li.active > a > .selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  display: block;
  background-image: none;
  /* will be set in a theme css file*/
  float: right;
  position: absolute;
  right: 0px;
  top: 8px;
  background: none;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ffffff;
}

.page-sidebar-reversed .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  right: auto;
  left: 0;
  border-right: 0;
  border-left: 8px solid #ffffff;
}

.page-container-bg-solid .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-container-bg-solid
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  border-color: transparent #eef1f5 transparent transparent;
}

.page-container-bg-solid.page-sidebar-reversed .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-container-bg-solid.page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  border-color: transparent transparent transparent #eef1f5;
}

.page-sidebar .page-sidebar-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a {
  position: relative;
}

.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before {
  float: right;
  width: 20px;
  text-align: center;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f105";
  font-weight: 300;
  text-shadow: none;
  position: absolute;
  top: 4px;
  right: 14px;
}

.page-sidebar .page-sidebar-menu li > a > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before {
  content: "\f107";
}

.page-sidebar .page-sidebar-menu li > a > .badge,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .badge {
  float: right;
  margin-top: 1px;
  margin-right: 0px;
  position: absolute;
  right: 14px;
  top: 6px;
}

.page-sidebar .page-sidebar-menu > li > a > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before {
  top: 8px;
}

.page-sidebar .page-sidebar-menu .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
  list-style: none;
  display: none;
  padding: 0;
  margin: 8px 0px 8px 0px;
}

.page-sidebar .page-sidebar-menu .sub-menu li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
  /* 2nd level sub menu */
}

.page-sidebar .page-sidebar-menu .sub-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a {
  display: block;
  margin: 0;
  padding: 6px 15px 6px 43px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  background: none;
}

.page-sidebar .page-sidebar-menu .sub-menu li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a > i {
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu {
  margin: 0;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li {
  /* 3rd level sub menu */
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > a {
  padding-left: 60px;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu {
  margin: 0;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a {
  padding-left: 80px;
}

.page-sidebar .page-sidebar-menu .sub-menu.always-open,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu.always-open {
  display: block;
}

.page-sidebar .page-sidebar-menu li.active > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > .sub-menu {
  display: block;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li > a {
  border: 0;
  margin: 0;
  padding-left: 11px;
  border-left: 4px solid transparent;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
  margin: 0;
  padding: 1px 0;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li > a {
  padding-top: 8px;
  padding-bottom: 8px;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li:first-child,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li:first-child {
  margin-top: 0 !important;
}

.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li > a, .page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li > a {
  padding-left: 15px;
  padding-right: 11px;
  border-left: 0;
  border-right: 4px solid transparent;
}

.page-sidebar .sidebar-toggler,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler {
  display: block;
  cursor: pointer;
  opacity: 0.7;
  filter: alpha(opacity=70);
  width: 30px;
  height: 27px;
  margin-top: 15px;
  margin-right: 19px;
  float: right;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .sidebar-toggler:hover,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}

.page-sidebar .sidebar-search,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search {
  padding: 0;
  margin: 22px 18px 22px 18px;
}

.page-sidebar .sidebar-search .remove,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .remove {
  display: none;
}

.page-sidebar .sidebar-search .remove > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .remove > i {
  font-size: 16px;
}

.page-sidebar .sidebar-search .input-group,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .sidebar-search .input-group .form-control,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control {
  border: 0;
  font-size: 14px;
  padding: 0;
  height: auto;
  line-height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .sidebar-search .input-group .input-group-btn .btn,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn {
  padding: 2px 0 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 100% 3px;
}

.page-sidebar .sidebar-search .input-group .input-group-btn .btn > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn > i {
  font-size: 15px;
}

.page-sidebar .sidebar-search.sidebar-search-bordered,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered {
  margin: 25px 18px 25px 18px;
}

.page-sidebar .sidebar-search.sidebar-search-bordered .input-group .form-control,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group .form-control {
  font-size: 13px;
  padding: 6px 8px;
}

.page-sidebar .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn {
  margin-right: 6px;
}

@media (min-width: 992px) {
  /* 992px */
  .page-sidebar {
    width: 235px;
    float: left;
    position: relative;
    margin-right: -100%;
  }
  .page-full-width .page-sidebar {
    display: none !important;
  }
  .page-sidebar.collapse {
    display: block;
    max-height: none !important;
  }
  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%;
  }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -235px;
  }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right;
  }
  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    margin-left: 0;
    top: 0px;
  }
  .page-sidebar-fixed .page-sidebar-menu > li.last {
    margin-bottom: 15px !important;
  }
  .page-sidebar-fixed .page-sidebar-menu .sub-menu {
    height: auto !important;
  }
  /* Sidebar Closed */
  .page-sidebar-closed .page-sidebar {
    width: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
    width: 45px !important;
    /* sidebar toggler */
    /* sidebar search */
    /* sidebar bordered search */
    /* sidebar search expanded */
    /* sidebar bordered search expanded */
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li {
    /* hide opened sub menu */
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.open > .sub-menu,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
    display: none !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    width: 256px !important;
    position: relative !important;
    z-index: 10000;
    display: block !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    -ms-border-radius: 0 4px 0 0;
    -o-border-radius: 0 4px 0 0;
    border-radius: 0 4px 0 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
    margin-right: 10px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
    display: inline !important;
    padding-left: 15px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .badge {
    display: block !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .selected {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover.heading {
    width: 45px !important;
    box-shadow: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    width: 210px;
    position: absolute;
    z-index: 2000;
    left: 46px;
    margin-top: 0;
    top: 100%;
    display: block !important;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > a {
    padding-left: 15px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > .sub-menu > li > a {
    padding-left: 30px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > .sub-menu > li > .sub-menu > li > a {
    padding-left: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.heading > h3 {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-toggler-wrapper .sidebar-toggler {
    margin-right: 8px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-toggler-wrapper:hover {
    width: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-search-wrapper:hover {
    width: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a {
    padding-left: 11px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a .selected {
    right: -3px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .badge,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .title,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .arrow {
    display: none !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-toggler {
    margin-left: 3px;
    margin-right: 3px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group {
    border-color: transparent;
    margin-left: -4px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group .form-control {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group .input-group-btn .btn {
    display: block;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.sidebar-search-bordered .input-group {
    padding: 5px 0 3px 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open {
    height: 40px;
    margin-top: 15px;
    margin-bottom: 14px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group {
    width: 210px;
    position: relative;
    z-index: 1;
    margin-left: 24px;
    padding: 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .form-control {
    background: none;
    border: 0;
    display: block;
    padding: 8px 8px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .input-group-btn .btn {
    display: block;
    margin-right: 8px;
    margin-top: 1px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .remove {
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin: 10px -5px 8px -7px;
    display: block;
    float: left;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open.sidebar-search-bordered {
    height: 38px;
    margin-top: 23px;
    margin-bottom: 23px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open.sidebar-search-bordered .input-group {
    padding: 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li > a {
    padding-right: 11px;
    padding-left: 7px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar {
    margin-left: -45px;
    width: 45px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
    /* sidebar search */
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
    left: auto;
    right: 46px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    margin-left: -211px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
    -webkit-border-radius: 4px 0 0 0;
    -moz-border-radius: 4px 0 0 0;
    -ms-border-radius: 4px 0 0 0;
    -o-border-radius: 4px 0 0 0;
    border-radius: 4px 0 0 0;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
    padding-left: 0;
    padding-right: 15px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
    margin-right: 0;
    margin-left: 2px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-search-wrapper:hover, .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-toggler-wrapper:hover {
    margin-left: 0;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group {
    margin-left: -227px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .input-group-btn .btn {
    margin-right: 10px !important;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .remove {
    margin: 9px 4px 12px -16px  !important;
    float: right !important;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li > a {
    padding-right: 7px;
    padding-left: 11px;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
    width: 235px !important;
    display: block;
    z-index: 10000;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
    width: 235px !important;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .selected {
    display: none !important;
  }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover {
    width: 235px !important;
    z-index: 10000;
    margin-left: -235px !important;
  }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover .page-sidebar-menu {
    width: 235px !important;
  }
  .page-sidebar-closed.page-sidebar-hide .page-sidebar {
    display: none !important;
  }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu {
    display: none;
    width: 210px;
    z-index: 2000;
    position: absolute;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu > li > a {
    margin: 3px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li.active .sub-menu, .page-sidebar-menu.page-sidebar-menu-hover-submenu li.open .sub-menu {
    display: none !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li a > .arrow {
    display: none;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    margin-top: -20px;
    background: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 12px double transparent;
    border-bottom: 12px double transparent;
    border-left: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:after, .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:before {
    display: none;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    right: auto;
    left: 0;
    border-right: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    display: inline-block !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    z-index: 1;
    right: 0px;
    margin-top: -23px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .selected {
    display: none;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 235px;
    margin-top: -40px;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: -210px !important;
  }
  .page-sidebar-closed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li > a {
    padding-left: 15px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
    margin-left: 210px;
    margin-top: -38px !important;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
    margin-left: -210px !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    margin-top: -41px;
  }
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-hover-submenu li:hover > .sub-menu > li > .sub-menu {
    margin-top: -41px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  .page-sidebar {
    border-top: 0 !important;
    margin: 20px;
  }
  .page-sidebar .sidebar-toggler {
    display: none;
  }
  .page-sidebar .selected {
    display: none !important;
  }
  .page-sidebar.navbar-collapse {
    max-height: none;
    /* set some max height to have a scrollable menu on mobile devices */
  }
  .page-sidebar.navbar-collapse.collapse {
    display: none !important;
  }
  .page-sidebar.navbar-collapse.in {
    border-top: 0 !important;
    margin: 20px;
    position: relative;
    overflow: hidden !important;
    overflow-y: auto !important;
    display: block !important;
  }
  .page-sidebar.navbar-collapse.navbar-no-scroll {
    max-height: none !important;
  }
  .page-sidebar .mega-menu-responsive-content {
    padding: 10px 18px 10px 45px;
  }
  .page-full-width .page-sidebar-menu {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none;
  }
  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}

@media (max-width: 480px) {
  /* 480px */
  /* Page sidebar */
  .page-sidebar,
  .page-sidebar.in {
    margin: 0 10px 10px 10px !important;
  }
  .page-header-fixed.page-header-fixed-mobile .page-sidebar, .page-header-fixed.page-header-fixed-mobile
  .page-sidebar.in {
    margin-top: 10px !important;
  }
}

/***
Page content
***/
/* Page title */
.page-title {
  padding: 12px 0;
  font-size: 2.14em;
  letter-spacing: -1px;
  display: block;
  color: #666;
  margin: 0px 0px 15px 0px;
  font-weight: 300;
  /* subtitle */
}

.page-title small {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 300;
  color: #888;
}

.page-content-white .page-title,
.page-container-bg-solid .page-title {
  color: #666;
  margin-bottom: 20px;
  margin-top: 20px;
}

.page-content-white .page-title small,
.page-container-bg-solid .page-title small {
  color: #666;
}

.page-content-white .page-title {
  margin: 25px 0;
  font-size: 24px;
}

/* Page breadcrumb */
.page-bar {
  padding: 0px;
  background-color: #f1f4f7;
  margin-bottom: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-bar:before, .page-bar:after {
  content: " ";
  display: table;
}

.page-bar:after {
  clear: both;
}

.page-bar .page-breadcrumb {
  display: inline-block;
  float: left;
  padding: 8px;
  margin: 0;
  list-style: none;
}

.page-bar .page-breadcrumb > li {
  display: inline-block;
}

.ie8 .page-bar .page-breadcrumb > li {
  margin-right: 1px;
}

.page-bar .page-breadcrumb > li > a,
.page-bar .page-breadcrumb > li > span {
  color: #888;
  font-size: 14px;
  text-shadow: none;
}

.page-bar .page-breadcrumb > li > i {
  color: #aaa;
  font-size: 14px;
  text-shadow: none;
}

.page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-bar .page-breadcrumb > li > i[class*="icon-"] {
  color: gray;
}

.page-bar .page-toolbar {
  display: inline-block;
  float: right;
  padding: 0;
}

.page-bar .page-toolbar .btn-fit-height {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.page-md .page-bar .page-toolbar .btn-fit-height {
  padding-top: 9px;
  padding-bottom: 9px;
  box-shadow: none !important;
}

.page-content-white .page-bar,
.page-container-bg-solid .page-bar {
  background-color: #ffffff;
  position: relative;
  padding: 0px 20px;
  margin: -25px -20px 0 -20px;
}

.page-content-white .page-bar .page-breadcrumb,
.page-container-bg-solid .page-bar .page-breadcrumb {
  padding: 11px 0;
}

.page-content-white .page-bar .page-breadcrumb > li > a,
.page-content-white .page-bar .page-breadcrumb > li > span,
.page-container-bg-solid .page-bar .page-breadcrumb > li > a,
.page-container-bg-solid .page-bar .page-breadcrumb > li > span {
  color: #888;
}

.page-content-white .page-bar .page-breadcrumb > li > i,
.page-container-bg-solid .page-bar .page-breadcrumb > li > i {
  color: #aaa;
}

.page-content-white .page-bar .page-breadcrumb > li > i.fa-circle,
.page-container-bg-solid .page-bar .page-breadcrumb > li > i.fa-circle {
  font-size: 5px;
  margin: 0 5px;
  position: relative;
  top: -3px;
  opacity: 0.4 ;
  filter: alpha(opacity=40) ;
}

.page-content-white .page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-content-white .page-bar .page-breadcrumb > li > i[class*="icon-"],
.page-container-bg-solid .page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-container-bg-solid .page-bar .page-breadcrumb > li > i[class*="icon-"] {
  color: #8c8c8c;
}

.page-content-white .page-bar .page-toolbar,
.page-container-bg-solid .page-bar .page-toolbar {
  padding: 6px 0;
}

.page-content-white .page-bar .page-toolbar .btn,
.page-container-bg-solid .page-bar .page-toolbar .btn {
  margin-top: -2px;
}

.page-content-white .page-bar .page-toolbar .btn.btn-sm,
.page-container-bg-solid .page-bar .page-toolbar .btn.btn-sm {
  margin-top: 0px;
}

/* Page content */
.page-content {
  margin-top: 0px;
  padding: 0px;
  background-color: #fff;
}

.page-container-bg-solid .page-content {
  background: #eef1f5;
}

.page-content-white .page-content .page-bar {
  border-bottom: 1px solid #e7ecf1;
}

.page-content-white.page-md .page-content .page-bar,
.page-container-bg-solid.page-md .page-content .page-bar {
  border-radius: 0 !important;
}

.page-full-width .page-content {
  margin-left: 0px !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Page content */
  .page-content-wrapper {
    float: left;
    width: 100%;
  }
  .page-content-wrapper .page-content-before {
    margin-left: 235px;
  }
  .page-content-wrapper .page-content {
    margin-left: 235px;
    margin-top: 0px;
    min-height: 600px;
    padding: 15px 20px 10px 20px;
  }
  .page-content-wrapper .page-content.no-min-height {
    min-height: auto;
  }
  .page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
    margin-left: 45px;
  }
  .page-sidebar-reversed .page-content-wrapper .page-content {
    margin-left: 0 !important;
    margin-right: 235px !important;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
    margin-left: 0;
    margin-right: 45px;
  }
  .page-sidebar-reversed.page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 0 !important;
    margin-right: 45px !important;
  }
  .page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 45px !important;
  }
  .page-sidebar-closed.page-sidebar-hide .page-content-wrapper .page-content {
    margin-left: 0 !important;
  }
  .page-sidebar-closed.page-sidebar-reversed.page-sidebar-hide .page-content-wrapper .page-content {
    margin-right: 0 !important;
  }
  .page-full-width .page-content-wrapper .page-content {
    margin-left: 0px !important;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Bg solid content's breadcrumb */
  .page-content-white .page-bar,
  .page-container-bg-solid .page-bar {
    margin-top: -20px;
  }
  /* Boxed page container  */
  .page-boxed > .container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  /* Page content */
  .page-content-wrapper .page-content {
    margin: 0px !important;
    padding: 20px 20px 20px 20px !important;
    min-height: 280px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /*  768px & 991px */
  /* Boxed page container */
  .page-boxed > .container {
    margin: auto !important;
  }
}

@media (max-width: 767px) {
  /* 767px */
  /* Page content */
  .page-content-wrapper .page-content {
    padding: 20px 10px 10px 10px !important;
    overflow: hidden;
    /* Page title */
  }
  .page-content-wrapper .page-content .page-title {
    margin-bottom: 20px;
    font-size: 2.14em;
  }
  .page-content-wrapper .page-content .page-title small {
    font-size: 13px;
    padding-top: 3px;
  }
}

@media (max-width: 480px) {
  /* 480px */
  /* Dashboard date range panel */
  .page-content-wrapper .page-content .page-title small {
    display: block;
    clear: both;
  }
}

/***
Page footer
***/
.page-footer {
  padding: 8px 20px 5px 20px;
  font-size: 13px;
  height: 33px;
}

.page-footer:before, .page-footer:after {
  content: " ";
  display: table;
}

.page-footer:after {
  clear: both;
}

.page-footer .page-footer-inner {
  float: left;
  display: inline-block;
}

.page-footer-fixed.page-footer-fixed-mobile .page-footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}

.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .page-footer {
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Default footer */
  .page-footer {
    clear: left;
  }
  /* Fixed footer */
  .page-footer-fixed .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
  }
  /* Footer with footer sidebar */
  .page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-left: 45px;
  }
  .page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0 !important;
  }
  /* Fixed Sidebar */
  .page-sidebar-fixed .page-footer {
    margin-left: 235px;
    padding: 8px 20px 5px 20px;
  }
  /* Boxed page */
  .page-boxed .page-footer {
    padding: 8px 0 5px 0;
  }
  .page-boxed.page-sidebar-fixed .page-footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  /* Page sidebar reversed */
  .page-sidebar-reversed.page-sidebar-fixed .page-footer {
    margin-left: 0;
    margin-right: 235px;
    padding: 8px 20px 5px 20px;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0;
    margin-right: 0;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-right: 45px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Boxed Layout */
  .page-boxed .page-footer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  /* 767px */
  /* Default footer & boxed footer */
  .page-footer,
  .page-boxed .page-footer {
    padding-left: 10px;
    padding-right: 10px;
  }
  /* Fixed footer */
  .page-footer-fixed .page-footer .container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Scroll Top Top */
.scroll-to-top {
  display: inline-block;
  padding: 1px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  z-index: 10001;
  display: none;
  right: 10px;
}

.scroll-to-top > i {
  display: inline-block;
  color: #687991;
  font-size: 30px;
  opacity: 0.6 ;
  filter: alpha(opacity=60) ;
}

.scroll-to-top:hover {
  cursor: pointer;
}

.scroll-to-top:hover > i {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

@media (min-width: 992px) {
  /* 992px */
  .scroll-to-top {
    right: 20px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  .scroll-to-top {
    bottom: 10px;
    right: 10px;
  }
  .scroll-to-top > i {
    font-size: 28px;
  }
}

/***
Theme Panel
***/
.theme-panel {
  width: 420px;
  margin-top: -13px;
  margin-right: 0px;
  z-index: 100;
  float: right;
  position: relative;
  /* content solid bg color */
}

.theme-panel > .toggler {
  top: 4px;
  right: 0;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  background: #536881 url(../images/icon-color.png) center no-repeat;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.theme-panel > .toggler:hover {
  background-color: #3f4f62 !important;
}

.theme-panel > .toggler-close {
  display: none;
  top: 4px;
  right: 0;
  padding: 20px;
  z-index: 101;
  cursor: pointer;
  position: absolute;
  background: #2b3643 url(../images/icon-color-close.png) center no-repeat !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.theme-panel > .toggler-close:hover {
  background-color: #212933 !important;
}

.theme-panel > .theme-options {
  top: 4px;
  right: 0;
  display: none;
  position: absolute;
  z-index: 100;
  background: #2b3643;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.theme-panel > .theme-options > .theme-option {
  color: #c6cfda;
  padding: 15px;
  border-top: 1px solid #354353;
  margin-top: 0px;
  margin-bottom: 0px;
}

.theme-panel > .theme-options > .theme-option > span {
  text-transform: uppercase;
  display: inline-block;
  width: 145px;
  font-size: 13px;
  font-weight: 300;
}

.theme-panel > .theme-options > .theme-option > select.form-control {
  display: inline;
  width: 135px;
  padding: 2px;
  text-transform: lowercase;
}

.theme-panel > .theme-options > .theme-option.theme-colors {
  border-top: 0;
}

.theme-panel > .theme-options > .theme-option.theme-colors > span {
  display: block;
  width: auto;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul {
  list-style: none;
  padding: 0;
  display: block;
  margin-bottom: 10px !important;
  margin-top: 15px;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li {
  width: 40px;
  height: 40px;
  margin: 0 4px;
  cursor: pointer;
  list-style: none;
  float: left;
  border: solid 1px #707070;
  /* theme colors */
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:first-child {
  margin-left: 0;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:hover, .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.current {
  border: solid 2px #d64635;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-default {
  background: #333438;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-darkblue {
  background: #2b3643;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-blue {
  background: #2D5F8B;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-grey {
  background: #697380;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light {
  background: #F9FAFD;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light2 {
  background: #F1F1F1;
}

.page-content-white .theme-panel,
.page-container-bg-solid .theme-panel {
  position: absolute;
  margin-top: 30px;
  margin-right: 20px;
  right: 0;
}

.page-content-white .theme-panel > .toggler1,
.page-container-bg-solid .theme-panel > .toggler1 {
  background: #BFCAD1 url(../images/icon-color.png) center no-repeat;
}

.page-content-white.page-sidebar-reversed .theme-panel,
.page-container-bg-solid.page-sidebar-reversed .theme-panel {
  margin-right: 255px;
}

.page-content-white.page-sidebar-reversed.page-sidebar-closed .theme-panel,
.page-container-bg-solid.page-sidebar-reversed.page-sidebar-closed .theme-panel {
  margin-right: 65px;
}

/******************
Page Quick Sidebar
******************/
/* Quick sidebar toggler */
.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle {
  padding: 17px 10px 9px 10px !important;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i {
  top: 0px;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i:before {
  content: ""/*rtl:""*/;
}

.page-quick-sidebar-open .page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i:before {
  content: ""/*rtl:""*/;
}

/***
Page Loading
***/
.page-on-load {
  background: #fefefe;
}

.page-on-load .page-header,
.page-on-load .page-container,
.page-on-load .page-footer,
.page-on-load > .clearfix {
  display: none;
  transition: all 2s;
}

/***********
    Page Header
    ***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  background-color: #003254;
  /* Top notification menu/bar */
  /* Header seaech box */
  /* Toggler button for sidebar expand/collapse and responsive sidebar menu */
}

.page-header.navbar .top-menu .navbar-nav {
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Language */
  /* Dark version */
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
  color: #999999;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle .badge.badge-default {
  background-color: #014e9c;
  color: #ffffff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
  background-color: #005087;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover > i {
  color: #bfbfbf;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
  background-color: #005087;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle > i {
  color: #bfbfbf;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  border-color: #e7eaf0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
  border-bottom-color: #eaedf2;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
  background: #eaedf2;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
  color: #62878f;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
  color: #337ab7;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
  color: #23527c;
  text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
  border-bottom: 1px solid #EFF2F6 !important;
  color: #888888;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
  background: #f8f9fa;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a .time {
  background: #f1f1f1;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a:hover .time {
  background: #e4e4e4;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .circle {
  background-color: #014e9c;
  color: #666666;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .corner {
  border-color: transparent transparent transparent #014e9c;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
  color: #5b9bd1;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
  background-color: #dfe2e9;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
  color: #a1d9ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > i {
  color: #a1d9ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
  width: 195px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > .langname {
  color: #a1d9ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
  background: #005087;
  border: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu:after {
  border-bottom-color: #005087;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external {
  background: #00385e;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > h3 {
  color: #5ebeff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > a:hover {
  color: #5496cf;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
  color: #78c8ff;
  border-bottom: 1px solid #0063a6 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i {
  color: #45b4ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
  background: #005d9b;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a {
  border-bottom: 0 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
  background: #0063a6;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a .time {
  background: #00416e;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a:hover .time {
  background: #003254;
}

.page-header.navbar .search-form {
  background: #002640;
}

.page-header.navbar .search-form:hover {
  background: #005087;
}

.page-header.navbar .search-form .input-group .form-control {
  color: #999999;
}

.page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
  color: #969696;
  opacity: 1;
}

.page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
  color: #969696;
}

.page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
  color: #969696;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  color: #999999;
}

.page-header.navbar .search-form.open {
  background: #005087;
}

.page-header.navbar .menu-toggler {
  background-image: url(../images/images/sidebar_toggler_icon_default.png);
}

/* Default Horizontal Menu */
.page-header.navbar {
  /* Default Mega Menu */
  /* Light Mega Menu */
}

.page-header.navbar .hor-menu .navbar-nav {
  /* Mega menu content */
  /* Classic menu */
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
  box-shadow: 5px 5px rgba(0, 80, 135, 0.2);
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
  color: #a1d9ff;
}

.page-header.navbar .hor-menu .navbar-nav > li > a {
  color: #a1d9ff;
}

.page-header.navbar .hor-menu .navbar-nav > li > a > i {
  color: #089bff;
}

.page-header.navbar .hor-menu .navbar-nav > li:hover > a,
.page-header.navbar .hor-menu .navbar-nav > li.open > a,
.page-header.navbar .hor-menu .navbar-nav > li > a:hover {
  color: #bae3ff;
  background: #005087 !important;
}

.page-header.navbar .hor-menu .navbar-nav > li:hover > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.open > a > i,
.page-header.navbar .hor-menu .navbar-nav > li > a:hover > i {
  color: #21a5ff;
}

.page-header.navbar .hor-menu .navbar-nav > li.active > a,
.page-header.navbar .hor-menu .navbar-nav > li.active > a, .page-header.navbar .hor-menu .navbar-nav > li.current > a,
.page-header.navbar .hor-menu .navbar-nav > li.current > a {
  color: white;
  background: #014e9c !important;
}

.page-header.navbar .hor-menu .navbar-nav > li.active > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.active > a > i, .page-header.navbar .hor-menu .navbar-nav > li.current > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.current > a > i {
  color: #089bff;
}

.page-header.navbar .hor-menu .navbar-nav > li.active .selected, .page-header.navbar .hor-menu .navbar-nav > li.current .selected {
  border-top: 6px solid #014e9c;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu {
  box-shadow: 5px 5px rgba(0, 80, 135, 0.2);
  background: #005087;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a {
  color: #87ceff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a > i {
  color: #87ceff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a {
  color: #ceebff;
  background: #005d9b;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a > i {
  color: #ceebff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover, .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover {
  color: #ceebff;
  background: #005d9b;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a > i,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover > i, .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a > i,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover > i {
  color: #ceebff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.divider {
  background-color: #0060a1;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > a:after {
  color: #87ceff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav {
  /* Mega menu content */
  /* Classic menu */
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
  color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a {
  color: #a1d9ff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a > i {
  color: #089bff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover {
  color: #bae3ff;
  background: #005087;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover > i {
  color: #21a5ff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a {
  color: #333 !important;
  background: white !important;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a > i {
  color: #333 !important;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover {
  color: white;
  background: #014e9c;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover > i, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover > i {
  color: #089bff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  background: white;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li > a {
  color: #000;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li > a > i {
  color: #888;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li:hover > a {
  color: #000;
  background: whitesmoke;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li:hover > a > i {
  color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a:hover, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a:hover {
  color: #000;
  background: whitesmoke;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a:hover > i, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a:hover > i {
  color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.divider {
  background-color: whitesmoke;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
  border: 1px solid #f2f2f2;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > .dropdown-menu {
  border-top: 0;
}

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #000000;
  /* Default sidebar */
  /* light sidebar */
  /* Sidebar search */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* 1st level links */
  /* All links */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
.page-sidebar .page-sidebar-menu > li > a {
  border-top: 1px solid #000000;
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i,
.page-sidebar .page-sidebar-menu > li > a > i {
  color: #014e9c;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
  color: #015bb5;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3,
.page-sidebar .page-sidebar-menu > li.heading > h3 {
  color: #c4c4c4;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
.page-sidebar .page-sidebar-menu > li:hover > a,
.page-sidebar .page-sidebar-menu > li.open > a {
  background: #222222;
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu > li.open > a > i {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar .page-sidebar-menu > li.active.open > a {
  background: #014e9c;
  border-top-color: transparent;
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
.page-sidebar .page-sidebar-menu > li.active > a:hover,
.page-sidebar .page-sidebar-menu > li.active.open > a:hover {
  background: #014e9c;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
.page-sidebar .page-sidebar-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu > li.active.open > a > i {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .selected,
.page-sidebar .page-sidebar-menu > li.active > a > .selected,
.page-sidebar .page-sidebar-menu > li.active.open > a > .selected {
  border-right-color: #eef1f5;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active + li > a,
.page-sidebar .page-sidebar-menu > li.active + li > a {
  border-top-color: transparent;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open + li > a,
.page-sidebar .page-sidebar-menu > li.active.open + li > a {
  border-top-color: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:last-child > a,
.page-sidebar .page-sidebar-menu > li:last-child > a {
  border-bottom: 1px solid transparent !important;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:hover .sub-menu, .page-sidebar-closed
.page-sidebar .page-sidebar-menu:hover .sub-menu {
  background-color: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a,
.page-sidebar .page-sidebar-menu .sub-menu > li > a {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
  color: #015bb5;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
  background: #222222 !important;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
  color: #314465;
  color: #0681fd;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.open > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
  background: #050505;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
  background: #0a0a0a;
  border-left: 4px solid #014e9c;
  color: #f1f1f1;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
  border-left: 4px solid #014e9c;
  background: #050505;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > i,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > i {
  color: #eeeeee;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow.open:before {
  color: #eeeeee;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
  background: #050505;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a {
  background: #0a0a0a !important;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler,
.page-sidebar .sidebar-toggler {
  background: url(../images/sidebar_inline_toggler_icon_default.jpg);
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group,
.page-sidebar .sidebar-search .input-group {
  border-bottom: 1px solid black;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control,
.page-sidebar .sidebar-search .input-group .form-control {
  background-color: #000000;
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control::-moz-placeholder,
.page-sidebar .sidebar-search .input-group .form-control::-moz-placeholder {
  color: #141414;
  opacity: 1;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control:-ms-input-placeholder,
.page-sidebar .sidebar-search .input-group .form-control:-ms-input-placeholder {
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control::-webkit-input-placeholder,
.page-sidebar .sidebar-search .input-group .form-control::-webkit-input-placeholder {
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn > i,
.page-sidebar .sidebar-search .input-group .input-group-btn .btn > i {
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group,
.page-sidebar .sidebar-search.sidebar-search-bordered .input-group {
  border: 1px solid black;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.open .input-group, .page-sidebar-closed
.page-sidebar .sidebar-search.open .input-group {
  background-color: #000000;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.open .remove > i, .page-sidebar-closed
.page-sidebar .sidebar-search.open .remove > i {
  color: #141414;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group, .page-sidebar-closed
.page-sidebar .sidebar-search.sidebar-search-solid .input-group {
  background: none;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group,
.page-sidebar .sidebar-search.sidebar-search-solid .input-group {
  border: 1px solid black;
  background: black;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group .form-control,
.page-sidebar .sidebar-search.sidebar-search-solid .input-group .form-control {
  background: black;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid.open .input-group,
.page-sidebar .sidebar-search.sidebar-search-solid.open .input-group {
  border: 1px solid #000000;
  background: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid.open .input-group .form-control,
.page-sidebar .sidebar-search.sidebar-search-solid.open .input-group .form-control {
  background: #000000;
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
  border-left: 0;
  border-right: 4px solid #014e9c;
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
  border-left: 0;
  border-right: 4px solid #014e9c;
}

.page-content {
  background-color: #eef1f5;
}

/******
    Page Footer
    ******/
.page-footer .page-footer-inner {
  color: #666666;
}

.page-footer-fixed .page-footer {
  background-color: black;
}

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(34, 34, 34, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu.sidebar-search-wrapper {
    box-shadow: none;
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(34, 34, 34, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-closed > li:hover.sidebar-search-wrapper {
    box-shadow: none;
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(34, 34, 34, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu.sidebar-search-wrapper {
    box-shadow: none;
  }
  /* Light sidebar menu */
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li.heading {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #000000 !important;
  }
  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer {
    background-color: #fff;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
    color: #333;
  }
  /* Boxed Layout */
  .page-boxed {
    background-color: black !important;
    /* Page container */
    /* Page sidebar */
    /* Page footer */
  }
  .page-boxed .page-container {
    background-color: #000000;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  .page-boxed.page-sidebar-reversed .page-container {
    border-left: 0;
    border-right: 1px solid #000000;
  }
  .page-boxed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-fixed .page-sidebar {
    border-left: 1px solid #000000;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    border-right: 1px solid #000000;
    border-left: 0;
  }
  .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer {
    background-color: black !important;
  }
  .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer .page-footer-inner {
    color: #666666;
  }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #2c2c2c;
  }
  .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-left: 8px solid #2c2c2c;
  }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #2c2c2c !important;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Page sidebar */
  .page-sidebar {
    background-color: black;
    /* light sidebar */
  }
  .page-sidebar .page-sidebar-menu > li > a {
    border-top: 1px solid #000000;
  }
  .page-sidebar .page-sidebar-menu > li:hover > a, .page-sidebar .page-sidebar-menu > li.open > a {
    background: black;
  }
  .page-sidebar .page-sidebar-menu > li:last-child > a {
    border-bottom: 0 !important;
  }
  .page-sidebar .page-sidebar-menu > li .sub-menu {
    background-color: black !important;
  }
  .page-sidebar .page-sidebar-menu .sidebar-search input {
    background-color: black !important;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
    /* 1st level links */
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
    background: #080808;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
    background: #080808;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
    background: #080808;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
    background: black !important;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a {
    background: #080808 !important;
  }
}

@media (max-width: 480px) {
  /* 480px */
  .page-header.navbar {
    /* Top menu */
  }
  .page-header.navbar .top-menu {
    background-color: #000000;
  }
  .page-header-fixed-mobile .page-header.navbar .top-menu {
    background-color: #003254;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    background-color: #00538c;
  }
  .page-header-fixed-mobile .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    background: none;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle:hover {
    background-color: #005087;
  }
}

/****
    Boby
    ****/
body {
  background-color: #000000;
}

/****
 CSS3 Spinner Bar
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #015bb5;
}

.page-sidebar .page-sidebar-menu > li > a {
  background: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* Change sidebar link font weight */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a,
.page-sidebar .page-sidebar-menu li > a {
  font-weight: normal;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > i,
.page-sidebar .page-sidebar-menu li > a > i {
  font-weight: normal;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li .sub-menu li > a,
.page-sidebar .page-sidebar-menu li .sub-menu li > a {
  font-weight: normal;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li .sub-menu li > a > i,
.page-sidebar .page-sidebar-menu li .sub-menu li > a > i {
  font-weight: normal;
}

html {
  height: 100%;
}

body.minimalist {
  background: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body.minimalist {
    height: 100%;
  }
}

body.minimalist header {
  position: static !important;
  display: block;
  background: #000;
  padding: 20px;
  text-align: center;
}

body.minimalist header img {
  max-height: 50px;
}

body.minimalist .wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 30px;
  background: #e6e7e7;
  background-size: cover;
  padding: 40px;
}

@media screen and (max-width: 1024px) {
  body.minimalist .wrapper {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  /* 767px */
  body.minimalist .wrapper {
    padding: 15px;
  }
}
