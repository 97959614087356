@media (max-width: $screen-xs-max) {
    body {
        font-size: 12px;
    }
    .container {
        margin: 0;
    }
}

h1, h1.page-title {
    font-weight: 300;
    font-size: 30px;
    color: $color6;
    padding: 0;
    margin: 0 0 35px;

    small {
        font-size: 24px;
        font-weight: bold;
        display: block;
        padding-top: 25px;
        color: $colorGtf;
    }
}

hr.strong {
    border-color: #333;
}

.header-link {
    float: right;
    margin: 20px;

    a {
        font-size: 14px;
    }
}

.icon {
    font-size: 36px;
    vertical-align: middle;
    color: $brand-main-font-color;

    &:hover {
        color: darken($brand-main-font-color, 20%);
    }

    &.icon-flip-horizontal {
        transform: rotate(180deg);
    }
}

a {
    &:hover {
        text-decoration: none;
    }
}

.page-actions {
    padding: 12px 0;
}

.alert {
    .help-block {
        margin: 0;

        .list-unstyled {
            margin: 0;
        }
    }
}

.btn {
    padding: 6px 30px;
    transition: all 0.3s ease-in-out;

    &:not(.btn-danger):not(.btn-default):not(.btn-success) {
        background-color: $colorGtf;
        color: white;

        &:hover, &:focus {
            background-color: $colorGtfHover;
            color: white;
        }
    }
}

.center {
    text-align: center;
}

.col-center {
    float: none;
    margin: 0 auto;
    text-align: center;
}

.fa-stack, li .fa-stack {
    width: 2em;
    height: 2em;
    color: lighten($brand-main-font-color, 20%);

    .fa-circle-thin {
        display: none !important;
    }

    .fa {
        margin: auto;
        width: 100%;
        line-height: 37px;
    }
}

footer {
    background-color: $bg-color;
    color: darken(#fff, 50%);
    font-size: 11px;
    padding: 10px 0;
    text-align: center;

    ul {
        display: inline-block;
        padding: 0;

        li {
            display: inline-block;

            &:not(:last-child):after {
                content: ' - ';
            }

            a {
                color: darken(#fff, 50%);
            }
        }
    }
}

.tooltip[role="tooltip"] {
    z-index: 9000;
}

.spaced-items {
    color: red;

    > *:not(:first-child) {
        margin-top: $default-margin;
    }
}

.nowrap {
    white-space: nowrap;
}

.not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
}