.infosContrat {
    li {
        span {
            display: inline-block;
            padding-right: 5px;
            width: 19%;
            vertical-align: middle;

            @media (max-width: 991px) {
                width: 100%;
                padding-right: 0;
            }
        }
    }
}

#pref {
    li {
        display: flex;
        justify-content: space-between;

        .inputs {
            display: flex;
            margin: 0 -10px;
            .form-group {
                padding: 0 10px;
                margin: 0;
            }
        }
    }
}

#newsletter {
    margin: $default-margin 0;

    .col-sm-10 {
        padding: 0;
        width: 100%;
    }
    .form-group {
        margin: 0;
        .checkbox-switch {
            width: 100%;
            display: block;
            label {
                float: right;
            }
            .help {
                display: inline-block !important;
                width: calc(100% - 65px);
            }
        }
    }
}

ul.profile-list {
    border: 1px solid $color2;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;

    li {
        padding: 10px 30px;
        line-height: 26px;

        &:not(:last-child) {
            border-bottom: 1px solid $color2;
        }
    }
}

.customer-myInformation {
    &-address {
        height: 100%;
        display: flex;

        &-container {
            margin: auto;
        }
    }
}

@media (max-width: 991px) {
    #pref {
        li {
            flex-direction: column;
        }
    }
}
