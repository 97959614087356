.authentication {
    &-authenticationForm {
        &-title {
            color: #fff;
            font-size: 16px;
            padding: 0 0 10px;
            text-align: left;

            @media (max-width: $screen-sm-max) {
                text-align: center;
            }
        }

        form {
            .form-group {
                vertical-align: top;
                margin-right: 8px;
                width: 36%;

                @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                    width: 33%;
                }
                @media (max-width: $screen-sm-max) {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .input-group {
                    width: 100%;

                    &-addon {
                        background: #fff;
                        padding-right: 0;
                    }
                }
                .input-icon.right {
                    > .form-control {
                        padding-right: 20px;
                        padding-left: 5px;
                    }
                    i {
                        right: 5px;
                    }
                }

                input {
                    display: block;
                    width: 100% !important;
                    border-left: 0;
                    padding-left: 5px;

                }

                .authentication-authenticationForm-passwordLost {
                    display: block;
                    font-size: 12px;
                    margin-top: 8px;
                    white-space: nowrap;
                    font-style: italic;
                    color: white;

                    @media (max-width: $screen-sm-max) {
                        text-align: right;
                    }
                }
            }

            button {
                float: right;
                padding-left: $default-margin/2;
                padding-right: $default-margin/2;

                @media (max-width: $screen-sm-max) {
                    width: 100%;
                    margin: $default-margin 0;
                }

                [class^='icon-'] {
                    margin-left: 8px;
                    font-size: 14px;
                    vertical-align: middle;
                }
            }
        }
    }
}

.presentation {
    padding: 20px 40px;
    h1 {
        font-size: 30px;
        font-weight: 300;
        color: $colorGtf;
        margin: 0 0 25px;
    }
    p {
        margin: 0 0 20px;
        strong {
            color: $colorGtf;
        }
        em {
            color: $color3;
            font-size: 0.857em;
        }
    }
    ul {
        background-color: $color2;
        list-style: none;
        padding: 20px 20px 40px;
        margin: 20px 0 40px;
        width: 100%;
        float: left;
        li {
            float: left;
            text-align: center;
            width: 50%;
            text-transform: uppercase;
            font-size: 11px;
            padding: 20px;
            i {
                color: $colorGtf;
                font-size: 50px;
                display: inline-block;
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        padding: 10px 20px;
        ul {
            li {
                width: 100%;
            }
        }
    }
}

.well {
    background-color: white;
}

.inscription {
    padding: 0;
    .well {
        padding: 20px 70px 40px;
        text-align: center;
        h2 {
            font-size: 30px;
            font-weight: 300;
            color: $color4;
            margin: 0 0 10px;
        }
        h3 {
            font-size: 24px;
            font-weight: 300;
            color: $colorGtf;
            margin: 0 0 45px;
        }
        .visuAccount {
            margin-top: 60px;
            width: 100%;
            max-width: 431px;
        }
        .input-icon {
            &.right {
                border-right: none;
            }
        }

        @media (max-width: $screen-sm-max) {
            padding: 10px 20px 10px;
            margin-bottom: 80px;
        }
        #registrationForm-profileSpecific {
            .col-md-4 {
                padding: 0;
                width: 100%;
            }
        }

    }
}

.emergency {
    background-color: black;
    display: block;
    text-align: center;
    padding: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    font-size: 18px;
    font-weight: 300;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    &:hover, &:focus {
        color: white;
        background-color: $color4;
    }
}

#registrationForm {

    &.editing {
        h2 {
            display: none;
        }

        h3 {
            font-size: 30px;
            margin: 0 0 30px;
        }
    }
}
