.portlet-table {
    &.light {
        padding: 0 $default-portlet-margin;
        margin: 0;

        .portlet-body {
            padding: 0;
            overflow: auto;
        }
    }

    &.table-extended {
        padding: 0 !important;

        table {
            td:first-child, th:first-child {
                padding-left: $default-portlet-margin;
            }
            td:last-child, th:last-child {
                padding-right: $default-portlet-margin;
            }
        }
    }
}

table.table {
    margin: 0;

    caption{
        color: $colorGtf;
        padding:10px 25px;
        position:relative;
        background-color: $colorGtf3;
        text-transform: uppercase;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 5px;
            background-color: $colorGtf;
        }
    }

    tr {
        td, th {
            height: $table-cell-height;
            vertical-align: middle;
            padding-left: 25px;

            &.sortable-column {
                padding-right: 16px;
            }
        }

        th {
            font-weight: bold;
            color:black;
            border-bottom:1px solid $color7;

            a {
                color: black;
            }
        }
        td {
            border-top:1px solid $color7;
        }

        &.pinned {
            color: #fff;
            background: $brand-main-color !important;
            text-transform: uppercase;
        }
    }

    .icon {
        font-size: $table-icon-height;
        margin:0 5px;
    }

    &-hover {
        tbody {
            tr:hover {
                background: lighten($color7, 15);
            }
        }
    }
}

td.right, th.right {
    text-align: right;
}

table.dataTable {
    tr.pinned{
        td {
            background: $brand-main-color !important;
        }
    }
}

