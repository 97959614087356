.accounting-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $default-margin;
    color: $brand-main-color;

    .title {
        font-weight: bold;
        font-size: 1.571em;
    }

    .balance {
        font-size: 1.3em;

        &.debit {
            color: $balance-debit-font-color;
        }

        &.credit {
            color: $balance-credit-font-color;
        }
    }
}

.accounting-filters {
    display: flex;
    align-items: center;
    justify-content: right;

    > .form-group {
        flex: 0 1 700px;
    }
}

.building-outlay {
    .total-row {
        .total {
            font-style: italic;
            text-align: right;
        }

        .debit {
            color: $balance-debit-font-color;
        }

        .credit {
            color: $balance-credit-font-color;
        }
    }

    .debit, .credit {
        text-align: right;
        font-weight: bold;
        white-space: nowrap;
    }
}

#building-list {
    .portlet ~ .accounting-building-title {
        margin-top: $default-margin;
    }
}

.accounting-building-title {
    color: $brand-main-color;
    margin: 0 0 $default-margin / 1.2 6px;
}

#building-accounting {
    .portlet {
        margin-bottom: $default-margin;
    }
}


.export-table {
    white-space: nowrap;

    .help {
        color: $brand-main-font-color;
        margin-right: 1vw;
        font-size: 12px;
    }
}

@media (max-width: $screen-xs-min) {
    .help {
        display: none;
    }
}

#accounting-detail-table {
    border-collapse: collapse;
    overflow: hidden;

    .icon-delete {
        font-size: 3rem;
        position: absolute;
        transform: translate(-100%, 10%);
    }

    tr.document[data-expandable] {
        cursor: pointer;
    }

    td.expandable {
        > i {
            font-size: 2rem;
            transition: transform 0.3s;
        }
    }

    tr.expanded {
        td.expandable {
            > i {
                transform: rotate(180deg);
            }
        }
    }

    tr.pinned.thin > td {
        height: auto;
        padding: 2px 20px;
    }

    tr.child {
        .balance.prefixed {
            font-weight: normal;
        }

        &, & > td {
            height: auto;
            padding: 0;
        }

        & > td {
            border-top-style: none;

            & > div {
                opacity: 1;
                max-height: 50px;
                margin: 8px 10px 8px 25px;
                transition: opacity 0.6s, max-height 0.3s, margin 0.3s;
            }
        }

        &:not(.expanded) {
            & > td {
                & > div {
                    opacity: 0;
                    overflow: hidden;
                    max-height: 0;
                    margin: 0 10px 0 25px;
                    transition: opacity 0.3s, max-height 0.3s, margin 0.3s;
                }
            }
        }
    }
}
