@import '../variables';

.settings {

    .maintenance {

        .maintenance-btn-block {
            text-align: right;

            .btn {

                &.put-offline {
                    background-color: $setting-nok-color;

                    &:hover {
                        background-color: darken($setting-nok-color, 10%);
                    }
                }

                &.put-online  {
                    background-color: $setting-ok-color;

                    &:hover {
                        background-color: darken($setting-ok-color, 10%);
                    }
                }
            }
        }
    }

    .documentType-setting {

        .ok {
            background-color: $setting-ok-color;
        }

        .nok {
            background-color: $setting-nok-color;
        }

        .grab {
            cursor: move;
            color: $color7;
            margin-right: 15px;
        }

        .validity {
            line-height: 35px;

            .validity-editBtn {
                visibility: hidden;
                float: right;
            }

            &:hover {
                .validity-editBtn {
                    visibility: visible;
                }
            }
        }

        &-validity-modal {
            .loader {
                font-size: 40px;
                text-align: center;
                display: block;
            }
        }
    }

    &-export {
        background: #e6e7e8;
        padding: 30px 10px 50px;
        display: block;
    }
}



@media (max-width: $screen-xs-max) {
    .settings {
        .maintenance {
            &-btn-block {
                margin-top: 15px;
                text-align: center;

                .btn {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .documentType-setting {

        .validity {
            .validity-editBtn {
                visibility: visible;
            }
        }
    }
}
