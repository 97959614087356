/* iOS 8 Pure CSS Switch -- */

$switch-ios-height: 26px;
$switch-ios-width: 1.556 * $switch-ios-height;

.checkbox-switch {
    display: flex;
    flex-direction: row;
    align-items: center;

    .help {
        margin: 0 20px 0 0;

        &--right {
            margin: 0 0 0 20px;
        }
    }

    label {
        display: block;
        cursor: pointer;
        width: $switch-ios-width;
        height: $switch-ios-height;
        border-radius: 50px !important;

        margin-bottom: 0;

        box-shadow: inset 0 0 0 2px #E4E4E4;
        transition: all 0.4s;

        &:before {
            content: '';
            width: 0.84 * $switch-ios-height;
            height: 0.84 * $switch-ios-height;
            background: #FFF;
            display: inline-block;
            border-radius: 60px;
            margin-top: 2px;
            margin-left: 2px;
            transition: all 0.3s;
            box-shadow: 0 0 0 1px #E4E4E4, 0 3px 2px rgba(0, 0, 0, 0.25);
            pointer-events: none;
        }
    }

    input {
        display: none;
    }

    input:checked + label:before {
        margin-left: 0.388 * $switch-ios-width;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
    }

    label:active {
        box-shadow: inset 0 0 0 20px #E4E4E4;
    }
    label:active:before {
        width: 0.95 * $switch-ios-height;
    }
    input:checked + label:active:before {
        margin-left: 13px;
    }
    input:checked + label {
        box-shadow: inset 0 0 0 20px #53D769;
        border: none;
    }
}
