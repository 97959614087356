.page-sidebar-wrapper {
    position: fixed;
    top: $header-height;
    bottom: 0;
    overflow: auto;
}

.page-sidebar {

    display: flex;
    min-height: 100%;
    flex-direction: column;

    z-index: 2;

    @media all and (-ms-high-contrast:none)
    {
        height: 100%;
    }

    .page-logo {
        padding: $logo-padding;
        text-align: center;
        img{
            width:100%;
        }
    }

    .user-identity-box {
        padding: 10px 15px;

        .user-identity-content {
            font-weight: bold;
            a {
                color: white;

                &:hover{
                    color: $colorGtf;
                }
            }
        }

        span {
            color: white;
        }
    }

    .sidebar-footer {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;

        .page-sidebar-menu {
            flex: 1;
        }
    }
}


@media (max-width: $screen-sm-max) { /* 991px */
    .page-sidebar-wrapper {
        position: fixed;
        width: 100%;
        z-index: 10;
        top: $header-height-xs;
        overflow: auto;
        bottom: 100%;

        &.open, &.closing {
            transition: 0.4s bottom;
            bottom: 0;
        }

        &.closing {
            bottom: 100%;;
        }
    }

    .page-sidebar {
        margin: 0 !important;

        .page-logo {
            display: none;
        }
    }
}
