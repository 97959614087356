@charset 'UTF-8';

//@import '../vendor/metronic/sass/layouts/layout/_variables'; // theme level variables
@import '_variables';

@import "metronic/layout/layout";
@import "metronic/layout/themes/gtf";
@import 'metronic/layout/colors';

/* Mixins */
@import "mixins/border-left";

/* Layout */
@import "layout/common";
@import "layout/flexbox";
@import "layout/form";
@import "layout/header";
@import "layout/sidebar";

/* Plugins */
@import "plugin/pace";
@import "plugin/cookie";
@import "plugin/grid";
@import "plugin/password_strength";
@import "plugin/datatable";

@import "components/portlet";
@import "components/equalHeight";
@import "components/stat";
@import "components/page_bar";
@import "components/icons";
@import "components/table";
@import "components/balance";
@import "components/switch_ios";
@import "components/bootstrap";
@import "components/sortable";
@import "components/payment";

/* Specific Pages */
@import "page/authentication";
@import "page/dashbaord";
@import "page/document";
@import "page/accounting";
@import "page/news";
@import "page/users";
@import "page/contract";
@import "page/customers";
@import "page/customer";
@import "page/login_front";
@import "page/errors";
@import "page/emergency";
@import "page/settings";
@import "page/contact";
@import "page/contentPage";
@import "page/billing";
@import "page/dematerialization";
@import "page/general-meetings";
@import "page/general-meetings-monitoring";

/* Specific Pages admin*/
@import "page/building";

.row.equal {
    display: flex;
    flex-wrap: wrap;

    > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
}

.select2-dropdown {
    z-index: 9000;
}
