
form {
    fieldset {
        margin-bottom: 20px;
        &.fieldset {
            background-color: white;
            padding: 35px 30px 20px;
            border: 1px solid $color2;
            h2 {
                font-size: 18px;
                font-weight: 400;
                color: $colorGtf;
                margin: 0 0 25px;
            }
        }
        &.files {
            padding: 0 30px 20px;
            .drop_uploader {
                &.drop_zone {
                    padding: 40px 10px;
                    background-color: white;
                    .text_wrapper {
                        width: 90%;
                        max-width: 320px;
                        margin: 0 auto;
                        .fa-cloud-upload {
                            display: none;
                        }
                        .text {
                            font-size: 14px;
                            font-weight: 300;
                            font-family: "Open Sans", sans-serif;
                            color: $color6;
                            line-height: 1.8em;

                            .file_browse {
                                margin-top: 20px;
                                display: block;
                                width: 100%;
                                background-color: transparent;
                                padding: 0;
                                border: 0;
                                i {
                                    margin: 0;
                                    transform: rotate(180deg);
                                }
                                &:hover {
                                    color: $colorGtf;
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                    ul.files.thumb {
                        margin: 0 30px;
                    }
                }
            }
        }
    }
    #contract_mapping_submit {
        float: right;
    }
    #contact_submit_submit {
        display: inline-block;
        font-size: 16px;
    }
}

.form-horizontal {
    .form-group {
        margin-left: 0;
        margin-right: 0;
    }
}

.form-group {
    text-align: left;

    .control-label {
        color: $color5 !important;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 3px;
        &.required {
            &:after {
                content: '*';
                display: inline;
                font-size: 75%;
                line-height: 0;
                position: relative;
                vertical-align: baseline;
                top: -0.5em;
                margin: 0 0.5em;
            }
        }
    }

    .input-group-addon {
        &.tooltips {
            background-color: white;
            border-left-color: white;
            i {
                color: $colorGtf;
            }
        }
    }
}

.g-recaptcha {
    margin-bottom: 20px;
    > div {
        width: 100% !important;
    }
}

div {

    &.radio, &.checkbox {
        display: inline-block;
        margin-right: 15px;

        label {
            padding-left: 30px;
            cursor: pointer;
            position: relative;

            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                z-index: 10;
            }

            &:before {
                left: 0;
                margin-top: -8px;
                width: 16px;
                height: 16px;
                border: 1px solid $color3;
                background: transparent;
            }

            &:after {
                left: 0;
                margin-top: -8px;
                width: 16px;
                height: 16px;
                background-image: radial-gradient(white 30%, $colorGtf 50%, $colorGtfHover 100%);
                opacity: 0;
                transform: scale(0);
                transition: all .3s ease-in-out;
            }
        }

        input {
            position: absolute;
            left: -9999px;
        }

        input:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.checkbox {

        label {
            &:after {
                background: $colorGtfHover;
                box-shadow: inset 0 0 1px 2px #fff;

                border: 1px solid $color3;
            }
        }

        input:disabled + label:after {
            background: lighten($color3, 30%);
        }
    }


    &.radio {
        label {
            &:before, &:after {
                border-radius: 50%;
            }
        }
    }

}

.input-group {
    &.form_date, &.form_datetime {
        .input-group-btn {
            .btn.default:not(.btn-outline) {
                border-left: 0;
                border-color: #c2cad8;
                background-color: white;
                padding: 0 10px;
                line-height: 32px;

                &.date-set {
                    background-color: #014e9c;

                    .icon-calendar {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .has-success {
        &.form_date, &.form_datetime {
            .input-group-btn {
                .btn.default:not(.btn-outline) {
                    border-color: #27a4b0;
                }
            }
        }
    }
    .has-error {
        &.form_date, &.form_datetime {
            .input-group-btn {
                .btn.default:not(.btn-outline) {
                    border-color: #e73d4a;
                }
            }
        }
    }
}

.input-group-addon + .help-block {
    position: absolute;
    left: 0;
    transform: translateY(100%);
}

label.button-like {
    padding: 0.2em 0.5em;
    background: #eee;
    border: 1px solid #222;
    border-radius: 2px !important;
    cursor: pointer;

    &:hover {
        background: #ddd;
    }
}

input[type="radio"][disabled="disabled"] + label {
    opacity: 0.7;
}
