$person-margin: $default-margin / 2;

#building-detail {
    #map {
        display: none;
        height: 300px;
    }

    .building-chooser {
        padding: 12px;
        background: $colorGtf;
        color: $color-white;
        font-weight: bold;

        &:before {
            background: $colorGtf2;
        }

        i.fa {
            color: $color-white !important;
        }

        .dropdown > .dropdown-menu {
            margin-top: 0;
        }
    }

    .building-identity {
        .identity {
            margin-top: $person-margin;

            .name {
                font-size: 1.4em;
                margin-bottom: 5px;
            }
        }
    }
}

.person {
    display: flex;
    align-items: center;

    &.bordered {
        padding: $default-margin 0;

        &:not(:first-child) {
            border-top: 1px solid $content-bg-color;
        }
    }

    .person-avatar {
        width: 50px;
        flex-shrink: 0;
        margin-right: $person-margin;

        i {
            border-radius: 50%;
            background: $brand-main-color;
            padding: 12px;
            color: #fff;
            font-size: 22px;
        }
    }

    .person-identity {
        flex-grow: 1;
        margin-right: $person-margin;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .person-name {
            flex-grow: 1;
            margin-right: $person-margin;
        }

        .council-link {
            margin-top: 5px;
        }
    }

    .person-contact-information {
        flex-grow: 1;
        margin-right: $person-margin;
    }

    .person-contact {
        width: 50px;
        flex-shrink: 0;

        text-align: right;
    }
}

.well{
    &.dashboard{
        position:relative;
        padding : 20px 10px 20px 25px;
        line-height: 32px;
        text-align: left;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 5px;
            background-color: $colorGtf;
        }
        .btn{
            display:inline-block;
        }
        &.doc{
            &:before{
                background-color: $colorGtf2;
            }
            .btn{
                border-color: $colorGtf2;
                background-color: $colorGtf2;
            }
        }
    }

}

#dashboard-push {
    display: flex;
    margin: 0 (-$default-margin / 2);

    > div {
        flex: 1 1 100%;
        margin: 0 $default-margin/2 $default-margin;
    }
}
