.alert {
    margin: 0 auto 20px;

    &.alert-success {
        background-color: rgba(83, 215, 105, 0.36);
        border: none;
        color: #4da251;
    }

    &.alert-notice {
        border: 1px solid #1b809e;
        background: rgba(0, 0, 0, 0.05);

        > i {
            color: #1b809e;
        }
    }

    &.alert-inline {
        display: inline-block;
    }
}
