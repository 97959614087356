@import "../../vendor/metronic/sass/bootstrap/variables";

.grid-search {

    .filter-inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin-left: -($default-margin / 2);
        margin-right: -($default-margin / 2);

        .filter-input {
            flex-basis: 50%;
            flex-grow: 1;
            padding: 4px $default-margin / 2;

            .grid-filter-input {
                display: flex;
                flex-wrap: wrap;
                margin-left: -($default-margin / 2);
                margin-right: -($default-margin / 2);


                > * {
                    flex: 1 1 auto;
                    padding: 0 $default-margin / 2;

                    &.grid-filter-operator {
                        flex-basis: 30%;
                    }
                }
            }
        }
    }

    .grid-search-action {
        text-align: right;
    }

    .form_datetime {
        width: 100%;

        .input-group-btn {
            width: auto;

            button {
                width: 100%;
                padding: 6px;
            }
        }

        .input-icon.right > .form-control {
            padding-right: 0;
        }
    }
}

.grid {
    .grid_header {
        margin-bottom: 10px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .mass-actions {
            flex-grow: 1;
        }

        .exports {
            margin-left: 10px;
        }
    }

    .mass-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin-bottom: -8px;

        > * {
            flex: 1 1 auto;
            padding-bottom: 8px;
        }

        .total {
            color: $brand-main-color;
            display: inline-block;
            margin-right: 6px;
        }

        .grid_massactions {
            display: flex;
            justify-content: flex-end;

            select {
                width: auto;
            }

            .btn {
                margin-left: 10px;
                flex-shrink: 0;
            }
        }
    }

    .align-center {
        text-align: center;
    }

    .grid_body {
        tr.grid-row-filters {
            display: none;
        }

        th.sortable {
            background-image: url(../images/sort_both.png);
            background-repeat: no-repeat;
            background-position: right center;
            padding-right: 30px;

            &.asc {
                background-image: url(../images/sort_asc.png);
            }
            &.desc {
                background-image: url(../images/sort_desc.png);
            }
        }

        .grid-row-actions {
            margin: 0;
            padding: 0;
            list-style-type: none;

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        [class^=grid_boolean] {
            display: inline-block;
            min-width: 40px;
        }
    }

    .grid_boolean_Oui {
        background-color: $brand-secondary-color;
    }

    .grid_boolean_Non {
        background-color: $brand-main-color;
    }
}

.grid_footer {
    margin-top: 20px;

    .dataTables_paginate {
        text-align: right;

        .pagination {
            margin: 0;
        }
    }
}


@media (max-width: $screen-xs-min) {
    .grid-search {
        .filter-inputs {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        .grid-search-action {
            input {
                display: block;
                width: 100%;
                margin: 8px 0;
            }
        }
    }

    .grid {

        .grid_header {

            .mass-actions {
                
                .grid_massactions_helper {
                    width: 100%;
                }

                .grid_massactions {
                    width: 100%;
                    flex-direction: column;

                    .btn {
                        margin-left: 0;
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}
