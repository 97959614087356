#page-bar {
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px $default-margin;
    }

    .others {
        display: none;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            button {
                white-space: normal;
            }
        }
    }

    .actions {
        .action {
            cursor: pointer;
            padding: 0 5px;

            .fa-stack {
                width: 0.75em;
                height: 0.75em;
                color: transparentize(white, 0.5);
                transition: color 0.5s ease-in-out;

                &:hover {
                    color: transparentize(white, 0);
                }

                .fa {
                    line-height: 10px;
                }
            }

            .icon-delete {
                transform: rotate(45deg);
            }
        }
    }
}
