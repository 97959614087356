$cookie-modal-padding: 25px;

// html selector level is needed to prioritize these rules.
html body {
    .edit-cookies-preferences {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .tac_activate {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .tac_float {
            .tarteaucitronAllow {
                margin: 5px;
            }
        }
    }

    .g-recaptcha {
        .tac_activate {
            width: auto;
            margin-top: 10px;
        }
    }

    #tarteaucitronRoot {
        #tarteaucitronPercentage {
            display: none !important;
        }

        div#tarteaucitronAlertBig {
            width: 300px;
            padding: $cookie-modal-padding;
            right: 2vw;
            bottom: 20vh;
            left: auto;
            border: 1px solid $colorGtf;
            border-radius: 4px;
            background-color: $color-white;
            box-shadow: 0 0 9px 0 transparentize($color-black, 0.5) !important;
            transform: none;
            top: auto;
            max-height: calc(85vh - (#{$cookie-modal-padding} * 2));
            overflow: auto;

            @media (max-width: $screen-sm-min) {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }

            &:before {
                font-size: 2.7rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            &, #tarteaucitronPrivacyUrl, #tarteaucitronPrivacyUrlDialog, #tarteaucitronDisclaimerAlert, #tarteaucitronDisclaimerAlert strong {
                color: $color-black;
            }

            span#tarteaucitronDisclaimerAlert {
                font-size: 1.7rem;
                line-height: 1.5;
                font-weight: 300;
                padding: 0;
                margin: 0 0 25px;
                white-space: pre-line;

                a {
                    color: $color-black;
                    text-decoration: underline;
                }
            }

            button + button, br + button {
                margin-top: 10px;
            }

            button {
                text-transform: uppercase;
                font-size: 1.3rem !important;
                padding: 10px;
                display: block;
                width: calc(100% - 25px);
                margin-left: 0;
                color: $color-black;
                background-color: $color-white;
                border: 1px solid $color-black;
                transition: background-color 0.3s, color 0.3s;
                font-weight: 600;

                span {
                    &:before {
                        color: $color-black;
                    }
                }

                &:hover {
                    span {
                        &:before {
                            color: $color-white;
                        }
                    }
                }

                &.tarteaucitronAllow {
                    &:hover {
                        color: $color-white;
                        background-color: $color-success;
                    }
                }

                &.tarteaucitronDeny {
                    &:hover {
                        background-color: $color-error;
                        color: $color-white;
                        font-weight: 700;
                    }
                }
            }

            br {
                display: none;
            }
        }

        .tarteaucitronH1, .tarteaucitronH2 {
            color: $color-black;
            font-size: 1.6rem !important;
            font-weight: bold;
            margin: 0;
        }

        .tarteaucitronH1 {
            text-transform: uppercase;
            margin-right: auto;
        }

        .tarteaucitronH3 {
            font-size: 1.3rem !important;
            font-weight: 500;
        }

        #tarteaucitron {
            max-width: 680px;

            @media (max-width: $screen-md-min) {
                margin: auto !important;
            }

            @media (max-width: $screen-sm-min) {
                width: 90vw !important;
                background: none;
            }

            #tarteaucitronServicesTitle_mandatory {
                .tarteaucitronName {
                    width: 100% !important;
                    max-width: 100% !important;
                    margin: 0 !important;

                    .tarteaucitronH3 {
                        font-size: 1.4rem !important;

                        a {
                            text-decoration: underline;
                            font-size: 1.4rem;
                        }
                    }
                }

                button {
                    display: none;
                }
            }

            #tarteaucitronClosePanel {
                background: transparent;
                color: $color-black;
                top: 34px;
                right: 22px;
                width: auto;
                padding-right: 30px;
                font-weight: 300;
                font-size: 1.4rem;
                height: 20px;
                outline: none;
                z-index: 2;

                &:before, &:after {
                    content: "";
                    width: 18px;
                    height: 20px;
                    border-left: 1px solid $color-black;
                    display: block;
                    margin-left: 5px;
                    position: absolute;
                    right: 0;
                }

                &:before {
                    transform: rotate(-45deg);
                    top: -1px;
                }

                &:after {
                    transform: rotate(45deg);
                    top: 12px;
                }

                &:hover {
                    color: $colorGtf;

                    &:before, &:after {
                        border-color: $colorGtf;
                    }
                }
            }

            #tarteaucitronServices {
                border-radius: 6px;
                background-color: $color-white;
                box-shadow: 0 0 14px 0 $color7;
                padding: 40px $cookie-modal-padding;
                height: auto !important;
                max-height: calc(85vh - (#{$cookie-modal-padding} * 2));
                overflow: auto;

                .tarteaucitronLine {
                    padding: 0;
                    border: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 15px;

                    &, &:hover {
                        background: none;
                    }

                    &.tarteaucitronMainLine {
                        background: none;
                        border: none;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;

                        @media (max-width: $screen-sm-min) {
                            .tarteaucitronName {
                                flex-basis: 100%;
                                text-align: center;
                            }

                            .tarteaucitronAsk {
                                margin-left: 0 !important;
                            }
                        }
                    }

                    &.tarteaucitronIsAllowed {
                        border: none;
                        background: none;
                    }

                    .tarteaucitronName {
                        display: flex;
                        margin-left: 16px;
                        width: auto;
                        max-width: 280px;
                        min-width: 280px;
                        text-align: left;

                        .tarteaucitronListCookies {
                            display: none;
                        }

                        & > * {
                            line-height: 1.4 !important;
                        }
                    }

                    .tarteaucitronAsk {
                        margin: 0 0 auto auto !important;
                        float: none;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;

                        button {
                            width: 125px;
                            border: 1px solid $color7;
                            font-size: 1.3rem !important;
                            padding: 10px;
                            border-radius: 0;
                            opacity: 1 !important;
                            background-color: $color-white;
                            color: $color-black;
                            transition: background-color 0.3s, color 0.3s;
                            font-weight: 600;

                            span {
                                &:before {
                                    color: $color-black;
                                }
                            }

                            &:hover {
                                background-color: $color3;
                                color: $color-white;

                                span {
                                    &:before {
                                        color: $color-white;
                                    }
                                }
                            }

                            &.tarteaucitronAllow {
                                &:hover {
                                    background-color: $color-success;
                                }
                            }

                            &.tarteaucitronDeny {
                                &:hover {
                                    background-color: $color-error;
                                }
                            }
                        }

                        p {
                            margin-left: 17px;
                            font-size: 1.7rem;
                        }

                        a {
                            font-size: 1.6rem;
                            text-decoration: underline;
                        }
                    }

                    &.tarteaucitronIsAllowed {
                        button.tarteaucitronAllow {
                            background-color: $color-success;
                            color: $color-white;

                            span {
                                &:before {
                                    color: $color-white;
                                }
                            }
                        }
                    }

                    &.tarteaucitronIsDenied {
                        button.tarteaucitronDeny {
                            background-color: $color-error;
                            color: $color-white;

                            span {
                                &:before {
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }

                .tarteaucitronBorder {
                    border: none;

                    &, .tarteaucitronSelfLink, .tarteaucitronHidden {
                        background: none;
                    }

                    .tarteaucitronName {
                        margin-right: 10px;
                    }

                    .tarteaucitronTitle {
                        margin-bottom: 10px;

                        button {
                            background: none;
                            color: $color-black;
                            padding: 0;
                            margin: 0;
                            outline: 0;
                            cursor: pointer;
                            display: inline-block;

                            .tarteaucitronPlus {
                                display: none;
                            }
                        }
                    }

                    .tarteaucitronSelfLink {
                        text-transform: none;
                        color: $color-black;
                        font-size: 1.6rem;
                        line-height: 1.4;
                        text-align: left !important;
                        font-weight: 600;
                        padding: 0 !important;
                        text-indent: -18px;
                        overflow: hidden;
                        margin-top: 10px;
                        pointer-events: none;
                        position: inherit;
                        display: none;
                    }

                    > ul > li {
                        margin-top: 21px;
                    }

                    .tarteaucitronDetails {
                        background: none;
                        color: $color-black;
                        line-height: 1.4;
                        padding: 0 0 25px;
                        height: 100%;
                        font-size: 1.4rem;
                        position: static;
                        max-width: none;
                        display: block !important;

                        a {
                            font-size: 1.4rem;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        button#tarteaucitronBack {
            opacity: .2;
        }

        #tarteaucitronInfo {
            margin: 25px 0 !important;
            padding: 0 !important;
            max-width: none !important;
            background: none;
            border: 0;
            color: $color-black;
            font-size: 1.6rem !important;
            font-weight: 300;
            line-height: 1.4;
            text-align: left !important;

            a {
                color: $color-black;
                text-decoration: underline;
                font-size: 1.6rem;
            }
        }
    }

    &:not(.modal-open) {
        #tarteaucitronRoot.tarteaucitronBeforeVisible:before {
            display: none;
        }
    }

    &.modal-open {
        #tarteaucitronRoot {
            div#tarteaucitronAlertBig {
                display: none !important;
            }
        }
    }
}
