.mce-i-iban:before {
  font-family: FontAwesome;
  content: "\f19c";
}

.mce-i-accountant:before {
  font-family: FontAwesome;
  content: "\f098";
}

.mce-i-payment:before {
  font-family: FontAwesome;
  content: "\f09d";
}
