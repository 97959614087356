#add-document {
    #upload-zone {
        background: #fff;
        border: 2px dashed silver;
        border-radius: 4px !important;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 3em;
        line-height: 2em;
        position: relative;

        .text {
            text-align: center;
            margin: auto;
        }

        .preview-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;
            flex-direction: column;

            .dz-preview {
                display: none;

                &.dz-processing {
                    flex: 1 1 auto;
                    display: flex;
                }

                &.dz-complete {
                    display: none;
                }

                > * {
                    display: none;
                }

                .dz-progress {
                    display: block;
                    width: 100%;
                    flex: 1 1 auto;

                    .dz-upload {
                        width: 0;
                        height: 100%;
                        display: block;
                        background: transparentize($brand-main-color, 0.9);
                        transition: .4s width;
                    }
                }
            }
        }
    }

    #step3 {
        .portlet.solid {
            margin-bottom: 8px;
        }
    }

    // Upload Backend
    #step4 {
        ul.files-list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 8px;

                &:not(:first-child) {
                    border-top: 1px solid #818189;
                }

                .files-list-item-description {
                    min-height: $table-cell-height;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }

    .help {
        display: block;
        margin-bottom: 8px;
    }

    .profiles {
        .profile-title {
            background: $user-list-bg-color;
            position: relative;
            margin-bottom: 10px;
            @include border-left($brand-main-color);

            color: $brand-main-color;
            padding: 8px $default-margin;
            display: block;
        }
    }

    .form-submit {
        margin: auto;
    }

    .checkbox {
        label {
            position: relative;
            padding-right: 10px;
            margin-right: $switch-ios-width;

            display: flex;
            align-items: center;

            input {
                position: absolute;
                left: 100%;
                margin: 0;
            }
        }
    }
}

.block {

    .block-expand-btn {
        .block-expand-btn-icon {
            transition-duration: 0.5s;
        }
    }

    .block-content {
        height: 0;
        overflow: hidden;
    }

    &.active {
        .block-content {
            height: auto;
            margin-bottom: $default-margin;
        }

        .block-expand-btn {
            .block-expand-btn-icon {
                transform: rotate(180deg);
            }
        }
    }
}

.document-type {
    cursor: pointer;

    .document-type-expand-btn {
        pointer-events: none;

        .document-type-expand-btn-icon {
            transition-duration: 0.5s;
        }
    }

    &.active {
        .document-type-expand-btn {
            .document-type-expand-btn-icon {
                transform: rotate(180deg);
            }
        }
    }

    .document-type-content {
        table.table {
            margin-bottom: 0;
        }
    }
}

.grid-search .preselected-contract {
    margin-right: 15px;
    display: inline-block;

    &-name, &-contract-number {
        color: $colorGtf;
        font-weight: bold;
        background: $color1;
        border-radius: 10px !important;
        padding: 0 8px;
    }
}

.grid .document-action-columns {
    min-width: 100px;
}

// Front
#documents {
    .portlet {
        margin-bottom: $default-margin;

        .portlet-title {
            padding: $default-margin/4 0;

            > .caption {
                pointer-events: none;
            }

            .actions {
                padding-right: $default-margin / 4;
            }
        }
    }

    .block {
        .block-title {
            color: $brand-main-font-color;
            cursor: pointer;
            margin-bottom: calc(#{$default-margin} / 1.2 - 10px);
            padding: 10px;
            transition: background 0.3s;

            &:hover {
                background: lighten($content-bg-color, 2);
            }
        }

        &.active  {
            .block {
                color: $brand-main-color;
            }
        }
    }

    table {
        a {
            color: $brand-main-font-color;
            margin-left: 8px;
            &:hover {
                text-decoration: none;
                color: darken($brand-main-font-color, 20);
            }
        }

        td.date {
            width: 20%;
        }

        td.actions {
            text-align: right;
            padding-right: 0;
        }
    }

    .filetype.icon {
        font-size: 24px;
    }
}
