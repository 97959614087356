@import "billing/credit_note";
@import "billing/synthesis";

body[data-ctrl="BillingFile"] {
    .ui-pnotify-text {
        word-break: break-word;
    }
}

.billing-file-form-container {
    .input-icon {
        i.fa {
            display: none;
        }
    }

    fieldset.fieldset {
        padding-left: 0;
        padding-right: 0;
    }

    .readonly-info {
        padding: 6px 12px;
        border: 1px solid #c2cad8;
        max-width: 600px;
    }

    .alert {
        max-width: 600px;
        margin-left: 10px;
    }

    .h3 {
        margin-left: 20px;
    }

    fieldset.interverventions {
        padding: 0;

        .no-intervention {
            .alert {
                margin: 0;
            }
        }

        .alert {
            max-width: none;
        }
    }

    .input-group, .form-control {
        max-width: 600px;
    }

    @media (max-width: $screen-lg-min) {
        a.add {
            padding: 5px;
        }
    }

    @media (max-width: $screen-sm-max) {
        table th {
            padding: 8px !important;
        }

        a.add {
            font-size: 0;
            padding: 5px;

            &:after {
                font-size: 16px;
                font-weight: 600;
                content: "+";
            }
        }
    }
}

.form-field-info {
    font-size: 12px;
    font-style: italic;
}

.invoice-building-form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fancybox-wrap {
    .fancybox-inner {
        height: auto !important;
    }
}

.go-back {
    &.need-confirm {
        color: red;
    }
}

.billing-files-list-header {
    .up-to-date {
        margin-left: 10px;

        &--link {
            text-decoration: underline;
        }
    }
}

.billing-file-layout {
    &-list {
        margin-top: 20px;
    }

    &-form-container {
        user-select: none;

        .checkbox-switch {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 7px;
        }

        .input-icon {
            i.fa {
                display: none;
            }
        }

        .portlet {
            fieldset {
                margin-top: 10px;
                color: $text-color;
                font-size: 14px;
            }
        }

        .disabled {
            opacity: .3;
        }

        .pricing {
            &.has-success {
                &, & * {
                    color: #000 !important;
                }
            }

            .error-container {
                display: flex;
                flex-direction: column;

                .help-block {
                    text-align: right;
                }
            }

            &__item {
                display: flex;
                align-items: center;

                & > * {
                    flex: 1;
                }
            }
        }

        .natures-list {
            table {
                th:first-child, td:first-child {
                    width: 60%;
                }

                .form-group {
                    margin-bottom: 0;
                }

                label {
                    display: none;
                }
            }
        }

        .intervention-layouts-list {
            table {
                th:first-child, td:first-child {
                    width: 60%;
                }

                td.small .input-group {
                    width: 150px;
                }

                .pricing {
                    &__item {
                        & > .radio {
                            flex-grow: 0;
                            margin-right: 30px;
                        }
                    }
                }
            }
        }

        .fieldset-profiles {
            div.checkbox {
               display: block;

                &:first-child {
                    margin-top: 0;
                }
            }

            select option[value=""] {
                color: #BBB;
            }
        }
    }
}

#billing-synthesis-search-results {
    .table > tfoot > tr > th, .table > tfoot > tr > td {
        padding-right: 8px;
    }
}

.enhanced {
    color: red;
}

.invalid {
    color: red;
}
