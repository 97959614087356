#news {
    .news-item {
        padding: 30px;
        border: 1px solid #e8e8e8;
        h4 {
            margin: 0 0 13px;
            font-size: 30px;
            font-weight: 300;
            color: $colorGtf;
        }
        p {
            font-size: 14px;
            line-height: 1.714em;
            margin: 0 0 10px
        }
        .date {
            font-weight: bold;
            color: $color3;
            margin: 0 0 25px
        }
        .visu {
            img {
                width: 100%;
                height: 215px;
                float: left;
                vertical-align: top;
                object-fit: cover;
            }
        }
    }
    .load-more {
        text-align: center;
        i {
            font-size: 1.2em;
        }
    }
}

/* BO */
#editNewsForm {
    label {
        margin: 0 0 3px;
        padding: 0;
    }
    .checkbox {
        input[type=checkbox] {
            position: static;
            margin: 0;
        }
    }
    .preview {
        margin-top: 10px;
        img {
            width: 100%
        }
    }
    .date {
        .col-sm-10 {
            padding: 0;
            width: 100%;
        }
    }
    .actions {
        text-align: right;
        #news_pin {
            border-color: $color5;
            background-color: $color5;
            color: $color2;
        }
        #news_publish {
            border-color: #36ae43;
            background-color: #36ae43;
            &.unpublish {
                border-color: #e73d4a;
                background-color: #e73d4a;
            }
        }
    }

    .contract, .building {
        display: flex;
        align-items: center;

        .selector, .switchAll {
            flex-grow: 1;
        }
    }
}

.newsList {

    table.table {

        .publish, .pin {
            .fa-stack.reversed {
                color: darken($brand-main-color, 10%);
            }
        }
    }
}


@media (max-width: $screen-sm-max) {
    #editNewsForm {
        .vich-image {
            margin-bottom: 10px;

            > div {
                width: 100%;
            }

            .preview {
                width: 100%;
                max-width: 250px;
            }
        }

        .contract, .building {
            .switchAll {
                .form-group {
                    float: right;
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    #editNewsForm {
        .contract, .building {
            display: block;
            .switchAll {
                .form-group {
                    float: none;

                    .help {
                        display: inline;
                    }
                }
            }
        }
    }
}
