@mixin border-left($color: "") {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: $portlet-border-left-size;

        @if $color {
            background: $color;
        }
    }
}
