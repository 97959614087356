#emergency{
  background-color: white;
  border:1px solid $color2;
  padding:50px 50px 30px;
  h1{
    color : $colorGtf;
    font-size:26px;
    font-weight:300;
    margin:0 0 10px;
  }
  h2{
    color : black;
    font-size:18px;
    font-weight:300;
    margin:0 0 60px;
  }
  form{
    overflow: hidden;
  }
  @media (max-width: $screen-sm-max) {
    padding:25px 15px 15px;
  }
}