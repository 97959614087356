.credit-note-form-container {
    h1 {
        margin-bottom: 20px;
    }

    &__body {
        margin-bottom: 20px;

        ul {
            padding-left: 0;
            list-style-type: none;

            .item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;

                &--disabled > * {
                    opacity: .5;
                }

                &__title {
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                &__content {
                    &:not(:first-child) {
                        margin-left: 10px;
                    }

                    input {
                        font-size: 1.5rem;
                    }
                }

                .partially_consumed, .all_consumed {
                    font-size: 1.2rem;
                    font-style: italic;
                    color: $color3;

                    .amount {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        hr {
            border-color: $color2;
        }

        #credit_note_total {
            font-weight: 700;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;

        .btn {
            margin-left: 5px;
        }
    }
}
