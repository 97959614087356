/* Generated by Glyphter (http://www.glyphter.com) on  Tue Jan 03 2017*/
@font-face {
    font-family: 'gtf connecte';
    src: url('../fonts/gtf-connecte.eot');
    src: url('../fonts/gtf-connecte.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtf-connecte.woff') format('woff'),
         url('../fonts/gtf-connecte.ttf') format('truetype'),
         url('../fonts/gtf-connecte.svg#gtf-connecte') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'gtf connecte';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-check:before{content:'\0041';}
.icon-contact:before{content:'\0042';}
.icon-delete:before{content:'\0043';}
.icon-detail:before{content:'\0044';}
.icon-download:before{content:'\0045';}
.icon-edit:before{content:'\0046';}
.icon-export:before{content:'\0047';}
.icon-print:before{content:'\0048';}
.icon-save:before{content:'\0049';}
.icon-secure:before{content:'\004a';}
.icon-time:before{content:'\004b';}
.icon-avatar:before{content:'\004c';}
.icon-calendar:before{content:'\004d';}
.icon-warning:before{content:'\004e';}
.icon-contact2:before{content:'\004f';}
.icon-graph:before{content:'\0050';}
.icon-folder:before{content:'\0051';}
.icon-stats:before{content:'\0052';}
.icon-office:before{content:'\0053';}
.icon-user:before{content:'\0054';}
.icon-publish:before{content:'\0055';}
.icon-filetype-doc:before{content:'\0056';}
.icon-filetype-jpg:before{content:'\0057';}
.icon-filetype-pdf:before{content:'\0058';}
.icon-filetype-ppt:before{content:'\0059';}
.icon-filetype-svg:before{content:'\005a';}
.icon-filetype-txt:before{content:'\0061';}
.icon-filetype-xls:before{content:'\0062';}
.icon-filetype-zip:before{content:'\0063';}
.icon-pin:before{content:'\0064';}
.icon-unpin:before{content:'\0065';}
.icon-unpublish:before{content:'\0066';}
.icon-billing:before{content:'\0067';}
.icon-eye:before{content:'\0068';}
.icon-supervision:before{content:'\0069';}
.icon-settings:before{content:'\006a';}
