body[data-ctrl="GeneralMeeting"] {
  .ui-pnotify {
    background: white;
  }

  .general-meeting-form-container {
    form#general-meeting-form {
      > fieldset {
        width: 100%;
      }

      .vich-file {
        display: none;
      }

      @media (min-width: $screen-lg-min) {
        .comments-group {
          label {
            width: 100%;
            text-align: left;
          }

          > div {
            width: 100%;
          }
        }
      }

      label.control-label.required {
        padding-left: 15px;
      }

      i.disabled {
        opacity: 0.5;
      }

      .current-file {
        display: inline-block;

        .reset-file {
          cursor: pointer;
        }

        &:not(.file-selected) {
          .reset-file {
            display: none;
          }
        }
      }

      .file-input-group {
        margin-top: 8px;
      }

      .sending-state {
        .status-group {
          padding-top: 8px;
          display: flex;
          gap: 30px;

          .sent-date {
            font-weight: bold;
          }
        }
      }
    }
  }

  a.btn.disabled {
    pointer-events: all;

    &:active {
      pointer-events: none;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .contracts-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropbtn {
    text-transform: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    font-size: 12px;
    text-decoration: none;
    display: block;
    text-transform: none;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
