$portlet-border-left-size: 5px;

.portlet {
    &.bordered {
        position: relative;
        padding-left: ($default-portlet-margin + $portlet-border-left-size) !important;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: $portlet-border-left-size;
        }

        @import '../metronic/layout/colors_variables';
        @each $name, $colors in $component-colors {
            &.portlet-#{$name} {
                &:before {
                    background: map-get($colors, base) !important;
                }

                .portlet-title .caption-subject {
                    color: map-get($colors, base) !important;
                }

                &.portlet-title {
                    color: map-get($colors, base) !important;
                }
            }
        }

        .portlet-title {
            margin-bottom: 0;

            &.not-underlined {
                border: none !important;
                min-height: 0;
            }
        }
    }

    &.light {
        padding: 12px $default-portlet-margin $default-portlet-margin $default-portlet-margin;

        > .portlet-title {
            > .actions {
                padding: 10px 0 0 0;
            }
        }
    }

    &.portlet-title {
        padding: 12px 24px !important;
        font-size: 1.29em;
        margin-bottom: 15px;
    }

    &.solid {
        padding: 0 $default-margin 10px $default-margin;
    }
}
