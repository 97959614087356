body[data-ctrl="GeneralMeetingMonitoring"] {

  div#reminders {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    margin: 0 auto;

    table {
      th {
        text-align: center;
      }

      td {
        text-align: center;
      }

      margin: 0 30px;
    }
  }

  .building-list {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;

        a.building-list-building {
          background: $user-list-bg-color;
          position: relative;
          margin-bottom: 10px;
          @include border-left($brand-main-color);
          color: $brand-main-font-color;
          padding: 8px $default-margin;
          display: block;

          &:hover, &:active, &:focus {
            text-decoration: none;
          }

          span.building-name {
            color: $brand-main-color;
          }
        }

        .arrow {
          color: $brand-main-color;
          font-weight: bold;
          transition: .4s all;
          transform-origin: center center;
          position: absolute;
          right: 20px;
          top: 50%;
          font-size: 20px;
          height: 20px;
          line-height: 20px;
          margin-top: -10px;
        }

        .content {
          display: none;
        }

        &.active {
          .content {
            display: block;
          }

          .arrow {
            transform: rotate(180deg);
          }
        }

        .content {
          text-align: center;
        }
      }
    }
  }
}