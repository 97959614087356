$color-primary: #0383DE;

$default-margin: 30px;
$default-portlet-margin: 20px;

$table-cell-height: 50px;
$table-icon-height: 30px;

/**
* Users backend
*/
$user-list-bg-color: #cde1f5 !default;
$colorGtf : #014e9c !default;
$colorGtf2: #00aff0 !default;
$colorGtf3: #cde1f5 !default;
$colorGtfHover : #013e7d !default;
$color1: #e6e7e7 !default;
$color2: #dddee1 !default;
$color3: #777 !default;
$color4: #222 !default;
$color5: #333 !default;
$color6: #444 !default;
$color7: #bec1c4 !default;
$color8: #666 !default;
$color-black: #000 !default;
$color-white: #fff !default;
$color-success: #3cc051 !default;
$color-error: #ed4e2a !default;

$balance-credit-font-color: $color-success !default;
$balance-debit-font-color: $color-error !default;

$setting-ok-color: $color-success !default;
$setting-nok-color: $color-error !default;
