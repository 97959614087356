ul.contract-list {
  border: 1px solid $color2;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;

  li {
    padding: 10px 30px;
    line-height: 26px;

    &:not(:last-child) {
      border-bottom: 1px solid $color2;
    }

    &.selected {
      color: #fff;
      background: $brand-main-color !important;
      font-weight: bold;
    }
  }
}

.infosContrat {
  li {
    span {
      display: inline-block;
      padding-right: 5px;
      width: 19%;
      vertical-align: middle;

      @media (max-width: $screen-md-min) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

.add-contract-button {
  margin-bottom: 20px;
  text-align: center;
}
