.dataTables {
    &_info {
        display: none;
    }

    &_wrapper {
        position: static;
    }
}


table.dataTable {
    &.no-footer {
        border-bottom: 0;
    }

    tr.pinned{
        td {

        }
    }

    tr.rel {
        position: relative;

        td > span.centered {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
