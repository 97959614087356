#customer-list-profile-list {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;

            a.customer-list-customer {
                background: $user-list-bg-color;
                position: relative;
                margin-bottom: 10px;
                @include border-left($brand-main-color);

                color: $brand-main-font-color;
                padding: 8px $default-margin;
                display: block;

                &:hover, &:active, &:focus {
                    text-decoration: none;
                }
            }

            .fullname, .customername {
                text-transform: uppercase;
                color: $brand-main-color;
            }

            .registered {
                font-style: italic;
            }

            .content {
                display: none;
            }

            .arrow {
                color: $brand-main-color;
                font-weight: bold;
                transition: .4s all;
                transform-origin: center center;

                position: absolute;
                right: 20px;
                top: 50%;
                font-size: 20px;
                height: 20px;
                line-height:20px;
                margin-top: -10px;
            }

            &.active {
                .content {
                    display: block;
                }

                .arrow {
                    transform: rotate(180deg);
                }
            }

            &.more {
                text-align: center;
                padding-top: $default-margin;
            }
        }
    }
}

.searchengine-form-wrapper {
    .help {
        margin-bottom: 8px;
        display: block;
    }
}

.customer-list-profile-container {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .checkbox-switch {
        display: flex;
        align-items: center;
        margin: 0;

        .help {
            margin: 0;
            text-indent: 20px;
        }
    }

    .actions {
        border-bottom: 1px solid #bec1c4;
        padding: 10px $default-margin;

        ul {
            display: flex;
            justify-content: flex-end;

            li {
                display: flex;

                &:nth-child(2) {
                    margin: 0 auto 0 10px;
                }
            }
        }
    }

    .profile {
        padding: $default-margin;

        & +.profile {
            padding-top: 0;
        }

        table.customer-identity {
            line-height: 1.8;

            td:first-child {
                text-align: right;
                font-weight: lighter;
            }

            td:last-child {
                padding-left: 20px;
                color: #000;
            }
        }

        table.contracts {
            th {
                height: auto;
                vertical-align: top;
                padding-top: 0;
                color: #000;
                font-weight: bold;
                border-bottom: 1px solid #bec1c4;

                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }

            td {
                border-bottom: 1px solid #bec1c4;

                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}


@media (max-width: $screen-sm-max) {
    .customer-list-profile-container {

        .profile {
            table.customer-identity {
                width: 100%;

                td {
                    &:first-child {
                        width: 50%;
                        text-align: left;
                    }
                }
            }

            table.contracts {
                margin-top: 20px;
            }
        }
    }
}
