#login {
    margin: auto;
    width: 440px;
    max-width: 100%;

    background: rgba(255, 255, 255, 0.8);
    padding: $default-margin $default-margin * 2 $default-margin * 2;

    h2 {
        text-align: center;
        margin: 0 0 $default-margin 0;
    }

    .password-forgotten {
        text-align: right;
        font-size: 13px;
        margin-top: 4px;

        a {
            color: #222222;
        }
    }

    .input-group {
        width: 100%;
        position: relative;

        input {
            padding-left: $default-margin;
        }

        .input-addon {
            position: absolute;
            z-index: 10;
            top: 1px;
            bottom: 1px;
            left: 1px;
            width: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #cccccc;

            @include border-left($brand-main-color);
        }
    }

    button[type="submit"] {
        margin-top: $default-margin;
    }

    .success-block {
        text-align: center;
        padding: $default-margin * 2;

        .icon {
            color: $colorGtf;
            font-size: 80px;
        }
    }
}

@media (max-width: $screen-xs-max) { /* 767px */
    #login {
        padding: $default-margin;
    }
}
