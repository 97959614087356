header {
    display: none;
    z-index:999;
    .header-content{
        padding:0
    }
}
body{
    &.minimalist {
        header {
            padding:0;
            height:auto;
            .logo-link {
                img {
                    max-height: 140px;
                }
            }
        }
    }

    &.headerCenter{
        header {
            .logo{
                margin:0 auto;
                float: none;
            }

            @media (max-width: $screen-sm-max) {
                img {
                    height: 70px;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    header {
        position: fixed;
        top: 0;
        background: $bg-color;
        width: 100%;
        height: $header-height-xs;
        z-index: 999;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $default-margin/2 $default-margin;

        .page-logo {
            height: 100%;

            img {
                height: 100%;
            }
        }
        .logo {

            .page-logo {
                height: 140px;

                img {
                    height: 100%;
                }
            }
        }
    }

    .page-container {
        padding-top: $header-height-xs !important;
    }
}
