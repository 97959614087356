.searchengine-form-wrapper {
    overflow: hidden;
    margin-bottom: 60px;

    .input-group-addon + .form-control{
        border-left: 0;
    }

    .form-control {
        padding-left: 0;

        &:focus {
            border-color: $input-group-addon-border-color;
        }
    }
}

#building-list-search-results {
    th, td {
        &:nth-child(1n+2):not(:last-child) {
            text-align: center;
        }
    }

    div.more {
        text-align: center;
        padding-top: 30px;
        font-size: 1.6rem;
        font-weight: 700;
    }
}

#caretaker {

    .resetBtn {
        float: right;
        font-size: 0.8em;
        padding: 3px 5px;

        span {
            display: inline;
            margin-right: 5px;
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    .row + .row {
        margin-top: 15px;
    }

    .caretakerOriginalData {
        margin-top: 6px;
        font-size: 0.8em;
        color: #777;
    }
}


@media (max-width: $screen-sm-max) {
    #caretaker {

        .resetBtn {
            float: none;
            display: block;
            margin-bottom: 10px;
        }
    }
}
