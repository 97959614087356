table.sortable {
    thead {
        th.sortable-column {
            background: url(/images/sort_both.png) no-repeat right center;

            &.asc {
                background-image: url(/images/sort_asc.png);
            }

            &.desc {
                background-image: url(/images/sort_desc.png);
            }

            a {
                display: block;
            }
        }
    }
}
