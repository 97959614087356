#contact-add-document {
    #upload-zone {
        background: #fff;
        border: 2px dashed silver;
        border-radius: 4px !important;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 3em;
        line-height: 2em;
        position: relative;

        .text {
            text-align: center;
            margin: auto;
        }

        .preview-container {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .dz-preview {
                margin: 10px;
                position: relative;

                .dz-filename, .dz-size {
                    display: none;
                }

                .dz-success-mark, .dz-error-mark {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    left: 50%;
                    display: none;
                }

                &.dz-error {
                    .dz-error-mark {
                        display: block;
                    }
                }

                .dz-progress {
                    display: block;
                    width: 100%;
                    flex: 1 1 auto;

                    .dz-upload {
                        width: 0;
                        height: 100%;
                        display: block;
                        background: transparentize($brand-main-color, 0.9);
                        transition: .4s width;
                    }
                }
            }
        }
    }
}
