@charset "UTF-8";
/**
* Users backend
*/
/* Cubic Bezier Transition */
@media print {
  body {
    background-color: #fff !important;
  }
  .page-bar {
    display: none;
  }
  .page-sidebar-wrapper {
    display: none;
  }
  .page-quick-sidebar-wrapper {
    display: none;
  }
  .theme-panel {
    display: none;
  }
  .hidden-print {
    display: none;
  }
  .page-footer {
    display: none;
  }
  .no-page-break {
    page-break-after: avoid;
  }
  .page-container {
    margin: 0px !important;
    padding: 0px !important;
  }
  .page-content {
    padding: 0 !important;
    min-height: 300px !important;
    padding: 0px 20px 20px !important;
    margin: 0 !important;
  }
}

/***
Page Header
***/
.page-header.navbar {
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  height: 0px;
  min-height: 0px;
  filter: none;
  background-image: none;
  /* Fixed header */
  /* Header logo */
  /* Search box */
  /* Menu Toggler */
  /* Top menu */
}

.page-header.navbar.navbar-fixed-top {
  z-index: 9995;
}

.page-header.navbar.navbar-static-top {
  z-index: 9995;
}

.page-header.navbar .page-logo {
  float: left;
  display: block;
  width: 235px;
  height: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
  padding: 0;
}

.page-header.navbar .page-logo > .logo-image,
.page-header.navbar .page-logo > a {
  display: inline-block;
  float: left;
}

.page-header.navbar .page-logo .logo-default {
  margin: -7px 0 0 0;
}

.page-header.navbar .page-logo .logo-mini {
  display: none;
  margin-left: 5px;
}

.page-header.navbar .page-logo .text-logo {
  padding-left: 20px;
  padding-top: 12px;
}

.page-header.navbar .search-form {
  display: inline-block;
  width: 0px;
  position: relative;
  float: left;
  transition: all 0.6s;
}

.page-header.navbar .search-form .input-group .form-control {
  height: 0px;
  border: 0;
  background: transparent !important;
  font-size: 13px;
  padding-left: 0;
  margin-left: 12px;
  text-indent: -150000px;
}

.page-header.navbar .search-form .input-group .form-control:hover {
  cursor: pointer;
}

.page-header.navbar .search-form .input-group .input-group-btn {
  height: 0px;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit {
  margin-left: -24px;
  padding: 0;
  width: 0px;
  background: none;
  margin-top: 4px;
  display: block;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  font-size: 15px;
}

.page-header.navbar .search-form.open {
  transition: all 0.6s;
  width: 300px !important;
}

.page-header.navbar .search-form.open .input-group .form-control {
  text-indent: 0;
}

.page-header.navbar .search-form.open .input-group .form-control:hover {
  cursor: text;
}

.page-header.navbar .search-form.open .input-group .input-group-btn .btn.submit {
  margin-left: 0;
}

.page-header.navbar .menu-toggler {
  display: block;
  cursor: pointer;
  opacity: 0.7;
  filter: alpha(opacity=70);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
}

.page-header.navbar .menu-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}

.page-header.navbar .menu-toggler.sidebar-toggler {
  float: right;
  margin: -12px 0 0 0;
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .menu-toggler.sidebar-toggler {
  margin-right: 10.5px;
}

.page-header.navbar .menu-toggler.responsive-toggler {
  display: none;
  float: right;
  margin: -12px 6px 0 6px;
}

.page-header.navbar .top-menu {
  margin: 0;
  padding: 0;
  float: right;
}

.page-header.navbar .top-menu .navbar-nav {
  padding: 0;
  margin-right: 20px;
  display: block;
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Language */
  /* Dark version */
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown {
  margin: 0px;
  padding: 0px 4px;
  height: 0px;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown:last-child {
  padding-right: 0px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle {
  margin: 0px;
  padding: 17px 10px 9px 10px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:last-child {
  padding-right: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > i {
  font-size: 17px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > i.glyphicon {
  font-size: 16px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle > .badge {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 300;
  padding: 3px 6px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:focus {
  background: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu {
  margin-top: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #eee;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu > li > a {
  color: #555;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  min-width: 160px;
  max-width: 275px;
  width: 275px;
  z-index: 9995;
  /* header notifications dropdowns */
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
  display: block;
  overflow: hidden;
  padding: 15px 15px;
  letter-spacing: 0.5px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
  margin: 0;
  padding: 0;
  float: left;
  font-size: 13px;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
  display: inline-block;
  padding: 0;
  background: none;
  clear: inherit;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  right: 10px;
  border: 0;
  margin-top: -1px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
  text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list {
  padding-right: 0 !important;
  padding-left: 0;
  list-style: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 20px;
  white-space: normal;
  font-size: 13px;
  padding: 16px 15px 18px;
  text-shadow: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
  text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li:first-child a {
  border-top: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details {
  overflow: hidden;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon {
  margin-right: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon i {
  margin-right: 2px;
  margin-left: 1px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .details .label-icon .badge {
  right: 15px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li a .time {
  float: right;
  max-width: 75px;
  font-size: 11px;
  font-weight: 400;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
  text-align: right;
  padding: 1px 5px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .photo {
  float: left;
  margin: 0 6px 6px 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .photo img {
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject {
  display: block;
  margin-left: 46px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject .from {
  font-size: 13px;
  font-weight: 600;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .subject .time {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5 ;
  filter: alpha(opacity=50) ;
  float: right;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-menu .dropdown-menu-list > li .message {
  display: block !important;
  font-size: 12px;
  line-height: 1.3;
  margin-left: 46px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task {
  margin-bottom: 5px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task .desc {
  font-size: 13px;
  font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .task .percent {
  float: right;
  font-weight: 600;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .progress {
  display: block;
  height: 8px;
  margin: 8px 0 2px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list > li .progress .progress-bar {
  box-shadow: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
  padding: 14px 6px 12px 8px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > .username {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > img {
  float: left;
  margin-top: -5px;
  margin-right: 5px;
  height: 29px;
  display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle > i {
  display: inline-block;
  margin-top: 5px;
  margin: 0;
  font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu {
  width: 175px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a i {
  width: 15px;
  display: inline-block;
  margin-right: 9px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a .badge {
  margin-right: 10px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle {
  padding: 14px 3px 12px 7px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > img {
  margin-bottom: 2px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > i {
  font-size: 14px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-menu > li > a {
  font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-menu > li > a > img {
  margin-bottom: 2px;
  margin-right: 5px;
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu:before {
  border-left: none;
  border-right: none;
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu .dropdown-menu-list > li.external a {
  background: none !important;
  border: none !important;
}

/* Allow expanded search for above 768px */
@media (min-width: 768px) {
  /* 768px */
  .page-header.navbar {
    /* Search box */
  }
  .page-header.navbar .search-form.search-form-expanded {
    width: 200px;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .form-control {
    text-indent: 0;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .form-control:hover {
    cursor: text;
  }
  .page-header.navbar .search-form.search-form-expanded .input-group .input-group-btn .btn.submit {
    margin-left: 0;
  }
}

/*** 
Horizontal Menu 
***/
.page-header.navbar {
  /* Header container */
  /* Mega menu */
}

.page-header.navbar .container {
  position: relative;
}

.page-header.navbar .hor-menu {
  margin: 0 0 0 -17px;
  margin: 0;
  float: left;
}

.page-header.navbar .hor-menu .navbar-nav {
  position: static;
  /* Mega menu */
  /* Mega Menu Dropdown */
  /* Classic menu */
}

.page-header.navbar .hor-menu .navbar-nav.navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown {
  position: static;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
  left: auto;
  width: auto;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content {
  font-family: "Open Sans", sans-serif;
  padding: 15px;
  margin: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content.mega-menu-responsive-content {
  padding: 10px 18px 10px 45px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu {
  padding: 0;
  margin: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu:last-child {
  border-right: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li {
  margin: 0 !important;
  list-style: none;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
  margin-top: 5px;
  padding-left: 6px;
  font-size: 15px;
  font-weight: 400;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a {
  display: block;
  white-space: normal;
  font-family: "Open Sans", sans-serif;
  padding: 7px;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a:hover {
  text-decoration: none;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a.iconify {
  padding: 7px 7px 7px 30px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a.iconify > i {
  position: absolute;
  top: auto !important;
  margin-left: -24px;
  font-size: 15px;
  margin-top: 3px !important;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a .badge,
.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > a .label {
  margin-left: 5px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown.mega-menu-full .dropdown-menu {
  left: 20px;
  right: 20px;
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown:hover > .dropdown-menu {
  display: block;
}

.page-header.navbar .hor-menu .navbar-nav > li.menu-dropdown .dropdown-menu:after, .page-header.navbar .hor-menu .navbar-nav > li.menu-dropdown .dropdown-menu:before {
  display: none !important;
}

.page-header.navbar .hor-menu .navbar-nav > li > a {
  font-size: 14px;
  font-weight: 400;
  padding: 13px 13px;
}

.page-header.navbar .hor-menu .navbar-nav > li > a:focus {
  background: none !important;
}

.page-header.navbar .hor-menu .navbar-nav > li.current .selected, .page-header.navbar .hor-menu .navbar-nav > li.active .selected {
  left: 50%;
  bottom: 0;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  display: inline-block;
  margin: 0;
  width: 0;
  height: 0px;
  margin-left: -7px;
  margin-bottom: -6px;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu {
  margin-top: 0;
  border: none;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 9px 10px;
  white-space: normal;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a .label,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a .badge {
  font-weight: 300;
}

.page-header.navbar .hor-menu .navbar-nav > li.classic-menu-dropdown .dropdown-menu {
  min-width: 195px;
  max-width: 235px;
}

.page-header.navbar .hor-menu .navbar-nav > li.classic-menu-dropdown:hover > .dropdown-menu {
  display: block;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > .dropdown-menu {
  top: 0;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > a:after {
  top: 9px;
  right: 10px;
}

/* Form medium devices upto large devices */
@media (min-width: 992px) and (max-width: 1200px) {
  /* 992px 1200px */
  /* Boxed layout */
  .page-boxed .page-header.navbar {
    /* Top menu */
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
    display: none;
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle .langname {
    display: none;
  }
}

@media (min-width: 992px) {
  /* 992px */
  /* Page header */
  .page-header.navbar {
    /* Header logo */
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    padding: 0;
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    width: 45px;
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo .logo-default {
    display: none;
  }
  /* Boxed Layout */
  .page-boxed .page-header.navbar {
    /* Page logo */
    /* Top menu */
  }
  .page-boxed .page-header.navbar .page-logo {
    width: 236px;
  }
  .page-boxed .page-header.navbar .top-menu .navbar-nav {
    margin-right: 0px;
  }
  /* Sidebar closed & logo hidden */
  .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar {
    /* Page logo */
  }
  .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar .page-logo {
    width: 46px;
  }
  /* Boxed layout & page sidebar fixed layout */
  .page-boxed.page-sidebar-fixed .page-header.navbar {
    /* Page logo */
  }
  .page-boxed.page-sidebar-fixed .page-header.navbar .page-logo {
    width: 235px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Page header */
  .page-header.navbar {
    padding: 0 20px 0 20px;
    position: relative;
    clear: both;
    /* Page logo */
    /* Menu Toggler */
    /* Top Menu */
  }
  .page-header.navbar .page-logo {
    width: auto;
    padding: 0;
    margin-right: 10px;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .page-header.navbar .page-logo img {
    margin-left: 4px !important;
  }
  .page-header.navbar .menu-toggler.sidebar-toggler {
    display: none !important;
  }
  .page-header.navbar .menu-toggler.responsive-toggler {
    display: inline-block;
  }
  .page-header.navbar .top-menu .navbar-nav {
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .page-header.navbar .top-menu .navbar-nav > li {
    float: left;
  }
  .page-header.navbar .top-menu .navbar-nav .nav li.dropdown i {
    display: inline-block;
    position: relative;
    top: 1px;
    right: 0px;
  }
  .page-header.navbar .top-menu .navbar-nav .open .dropdown-menu {
    position: absolute;
  }
  /* Fixed header for mobile */
  .page-header-fixed.page-header-fixed-mobile .navbar-fixed-top {
    position: fixed;
  }
  /* Boxed Layout */
  .page-boxed .page-header.navbar > .container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  /* Boxed Layout */
  .page-boxed .page-header.navbar {
    margin: auto !important;
    padding: 0;
  }
  .page-boxed .page-header.navbar > .container {
    margin: auto !important;
  }
}

@media (max-width: 767px) {
  /* 767px */
  /* Page header */
  .page-header.navbar {
    padding: 0 10px 0 10px;
    /* Header logo */
    /* Search box */
    /* Top navigation menu*/
  }
  .page-header.navbar .page-logo {
    width: auto;
  }
  .page-header.navbar .search-form.open {
    z-index: 3;
    left: 10px;
    right: 10px;
    position: absolute;
    width: auto !important;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended > .dropdown-menu {
    max-width: 255px;
    width: 255px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu {
    margin-right: -190px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu:before {
    margin-right: 190px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu {
    margin-right: -150px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu:before {
    margin-right: 150px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu {
    margin-right: -110px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu:after, .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu:before {
    margin-right: 110px;
  }
}

@media (max-width: 580px) {
  /* Page header */
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
    display: none;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle .langname {
    display: none;
  }
}

@media (max-width: 480px) {
  /* 480px */
  /* Fixed header for mobile */
  .page-header-fixed.page-header-fixed-mobile .page-header.navbar {
    height: 80px;
  }
  .page-header.navbar {
    /* Top menu */
  }
  .page-header.navbar .top-menu {
    display: block;
    clear: both;
    float: none;
  }
  .page-header.navbar .top-menu .navbar-nav {
    margin-right: 0;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle {
    padding: 17px 6px 9px 6px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-language .dropdown-toggle {
    padding: 14px 4px 12px 2px;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    padding: 14px 0px 12px 2px;
  }
}

/***
Pace - Page Progress
***/
.pace .pace-progress {
  z-index: 10005;
  top: 0px;
  height: 2px;
  box-shadow: none;
}

.pace .pace-progress-inner {
  box-shadow: none;
}

.pace .pace-inactive {
  display: none;
}

.pace .pace-activity {
  top: 4px;
  z-index: 10005;
  right: 20px;
  border-radius: 10px !important;
}

@media (max-width: 480px) {
  /* 480px */
  .page-header-fixed .pace .pace-progress {
    top: 0px;
  }
  .page-header-fixed .pace .pace-activity {
    top: 4px;
  }
}

/***
Page container
***/
.page-container {
  margin: 0px;
  padding: 0px;
  position: relative;
  /* Fixed header */
  /* Fixed footer for mobile */
}

.page-container:before, .page-container:after {
  content: " ";
  display: table;
}

.page-container:after {
  clear: both;
}

.page-header-fixed .page-container {
  margin-top: 0px;
}

.page-footer-fixed.page-footer-fixed-mobile .page-container {
  margin-bottom: 20px !important;
}

@media (min-width: 992px) {
  /* Page container in fixed footer */
  .page-footer-fixed .page-container {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 991px) {
  /* Page container */
  .page-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 0px !important;
  }
}

@media (max-width: 480px) {
  /* Page container */
  .page-header-fixed.page-header-fixed-mobile .page-container {
    margin-top: 80px !important;
  }
}

/***
Page sidebar
***/
/* Page Sidebar */
.page-sidebar,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  /* Default sidebar menu */
  /* light sidebar menu */
}

.page-sidebar.navbar-collapse,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
  padding: 0;
  box-shadow: none;
}

.page-sidebar .page-sidebar-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  /* 1st level links */
  /* all links */
}

.page-sidebar .page-sidebar-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper {
  border: 0 !important;
}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:before, .page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:after, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:before, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper:after {
  content: " ";
  display: table;
}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:after, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-toggler-wrapper:after,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.sidebar-search-wrapper:after {
  clear: both;
}

.page-sidebar .page-sidebar-menu > li.start > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.start > a {
  border-top-color: transparent !important;
}

.page-sidebar .page-sidebar-menu > li.last > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.last > a {
  border-bottom-color: transparent !important;
}

.page-sidebar .page-sidebar-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar .page-sidebar-menu > li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i {
  font-size: 16px;
  margin-right: 5px;
  text-shadow: none;
}

.page-sidebar .page-sidebar-menu > li > a > i.glyphicon,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i.glyphicon {
  margin-left: 1px;
  margin-right: 4px;
}

.page-sidebar .page-sidebar-menu > li > a > [class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > [class^="icon-"] {
  margin-left: 1px;
  margin-right: 4px;
}

.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-fixed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  transition: all 0.2s ease;
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-reversed.page-sidebar-fixed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
  transition: none;
}

.page-sidebar .page-sidebar-menu > li.heading,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading {
  padding: 15px 15px 15px 15px;
}

.page-sidebar .page-sidebar-menu > li.heading > h3,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar .page-sidebar-menu > li.heading + li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading + li > a {
  border-top: 0;
}

.page-sidebar .page-sidebar-menu > li.open > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a {
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
  border: none;
  text-shadow: none;
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu > li.active > a > .selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  display: block;
  background-image: none;
  /* will be set in a theme css file*/
  float: right;
  position: absolute;
  right: 0px;
  top: 8px;
  background: none;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ffffff;
}

.page-sidebar-reversed .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  right: auto;
  left: 0;
  border-right: 0;
  border-left: 8px solid #ffffff;
}

.page-container-bg-solid .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-container-bg-solid
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  border-color: transparent #eef1f5 transparent transparent;
}

.page-container-bg-solid.page-sidebar-reversed .page-sidebar .page-sidebar-menu > li.active > a > .selected, .page-container-bg-solid.page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected {
  border-color: transparent transparent transparent #eef1f5;
}

.page-sidebar .page-sidebar-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a {
  position: relative;
}

.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before {
  float: right;
  width: 20px;
  text-align: center;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f105";
  font-weight: 300;
  text-shadow: none;
  position: absolute;
  top: 4px;
  right: 14px;
}

.page-sidebar .page-sidebar-menu li > a > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before {
  content: "\f107";
}

.page-sidebar .page-sidebar-menu li > a > .badge,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .badge {
  float: right;
  margin-top: 1px;
  margin-right: 0px;
  position: absolute;
  right: 14px;
  top: 6px;
}

.page-sidebar .page-sidebar-menu > li > a > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before {
  top: 8px;
}

.page-sidebar .page-sidebar-menu .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
  list-style: none;
  display: none;
  padding: 0;
  margin: 8px 0px 8px 0px;
}

.page-sidebar .page-sidebar-menu .sub-menu li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
  /* 2nd level sub menu */
}

.page-sidebar .page-sidebar-menu .sub-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a {
  display: block;
  margin: 0;
  padding: 6px 15px 6px 43px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  background: none;
}

.page-sidebar .page-sidebar-menu .sub-menu li > a > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a > i {
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu {
  margin: 0;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li {
  /* 3rd level sub menu */
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > a {
  padding-left: 60px;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu {
  margin: 0;
}

.page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a {
  padding-left: 80px;
}

.page-sidebar .page-sidebar-menu .sub-menu.always-open,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu.always-open {
  display: block;
}

.page-sidebar .page-sidebar-menu li.active > .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > .sub-menu {
  display: block;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li > a {
  border: 0;
  margin: 0;
  padding-left: 11px;
  border-left: 4px solid transparent;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
  margin: 0;
  padding: 1px 0;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li > a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li > a {
  padding-top: 8px;
  padding-bottom: 8px;
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li:first-child,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu li:first-child {
  margin-top: 0 !important;
}

.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li > a, .page-sidebar-reversed
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li > a {
  padding-left: 15px;
  padding-right: 11px;
  border-left: 0;
  border-right: 4px solid transparent;
}

.page-sidebar .sidebar-toggler,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler {
  display: block;
  cursor: pointer;
  opacity: 0.7;
  filter: alpha(opacity=70);
  width: 30px;
  height: 27px;
  margin-top: 15px;
  margin-right: 19px;
  float: right;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .sidebar-toggler:hover,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}

.page-sidebar .sidebar-search,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search {
  padding: 0;
  margin: 22px 18px 22px 18px;
}

.page-sidebar .sidebar-search .remove,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .remove {
  display: none;
}

.page-sidebar .sidebar-search .remove > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .remove > i {
  font-size: 16px;
}

.page-sidebar .sidebar-search .input-group,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .sidebar-search .input-group .form-control,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control {
  border: 0;
  font-size: 14px;
  padding: 0;
  height: auto;
  line-height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .sidebar-search .input-group .input-group-btn .btn,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn {
  padding: 2px 0 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 100% 3px;
}

.page-sidebar .sidebar-search .input-group .input-group-btn .btn > i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn > i {
  font-size: 15px;
}

.page-sidebar .sidebar-search.sidebar-search-bordered,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered {
  margin: 25px 18px 25px 18px;
}

.page-sidebar .sidebar-search.sidebar-search-bordered .input-group .form-control,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group .form-control {
  font-size: 13px;
  padding: 6px 8px;
}

.page-sidebar .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn {
  margin-right: 6px;
}

@media (min-width: 992px) {
  /* 992px */
  .page-sidebar {
    width: 235px;
    float: left;
    position: relative;
    margin-right: -100%;
  }
  .page-full-width .page-sidebar {
    display: none !important;
  }
  .page-sidebar.collapse {
    display: block;
    max-height: none !important;
  }
  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%;
  }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -235px;
  }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right;
  }
  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    margin-left: 0;
    top: 0px;
  }
  .page-sidebar-fixed .page-sidebar-menu > li.last {
    margin-bottom: 15px !important;
  }
  .page-sidebar-fixed .page-sidebar-menu .sub-menu {
    height: auto !important;
  }
  /* Sidebar Closed */
  .page-sidebar-closed .page-sidebar {
    width: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
    width: 45px !important;
    /* sidebar toggler */
    /* sidebar search */
    /* sidebar bordered search */
    /* sidebar search expanded */
    /* sidebar bordered search expanded */
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li {
    /* hide opened sub menu */
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.open > .sub-menu,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
    display: none !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    width: 256px !important;
    position: relative !important;
    z-index: 10000;
    display: block !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    -ms-border-radius: 0 4px 0 0;
    -o-border-radius: 0 4px 0 0;
    border-radius: 0 4px 0 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
    margin-right: 10px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
    display: inline !important;
    padding-left: 15px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .badge {
    display: block !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .selected {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover.heading {
    width: 45px !important;
    box-shadow: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    width: 210px;
    position: absolute;
    z-index: 2000;
    left: 46px;
    margin-top: 0;
    top: 100%;
    display: block !important;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > a {
    padding-left: 15px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > .sub-menu > li > a {
    padding-left: 30px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu > li > .sub-menu > li > .sub-menu > li > a {
    padding-left: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.heading > h3 {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-toggler-wrapper .sidebar-toggler {
    margin-right: 8px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-toggler-wrapper:hover {
    width: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-search-wrapper:hover {
    width: 45px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a {
    padding-left: 11px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a .selected {
    right: -3px !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .badge,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .title,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .arrow {
    display: none !important;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-toggler {
    margin-left: 3px;
    margin-right: 3px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group {
    border-color: transparent;
    margin-left: -4px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group .form-control {
    display: none;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group .input-group-btn .btn {
    display: block;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.sidebar-search-bordered .input-group {
    padding: 5px 0 3px 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open {
    height: 40px;
    margin-top: 15px;
    margin-bottom: 14px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group {
    width: 210px;
    position: relative;
    z-index: 1;
    margin-left: 24px;
    padding: 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .form-control {
    background: none;
    border: 0;
    display: block;
    padding: 8px 8px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .input-group-btn .btn {
    display: block;
    margin-right: 8px;
    margin-top: 1px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .remove {
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin: 10px -5px 8px -7px;
    display: block;
    float: left;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open.sidebar-search-bordered {
    height: 38px;
    margin-top: 23px;
    margin-bottom: 23px;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open.sidebar-search-bordered .input-group {
    padding: 0;
  }
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li > a {
    padding-right: 11px;
    padding-left: 7px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar {
    margin-left: -45px;
    width: 45px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
    /* sidebar search */
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
    left: auto;
    right: 46px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    margin-left: -211px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
    -webkit-border-radius: 4px 0 0 0;
    -moz-border-radius: 4px 0 0 0;
    -ms-border-radius: 4px 0 0 0;
    -o-border-radius: 4px 0 0 0;
    border-radius: 4px 0 0 0;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
    padding-left: 0;
    padding-right: 15px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
    margin-right: 0;
    margin-left: 2px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-search-wrapper:hover, .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.sidebar-toggler-wrapper:hover {
    margin-left: 0;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group {
    margin-left: -227px;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .input-group-btn .btn {
    margin-right: 10px !important;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .remove {
    margin: 9px 4px 12px -16px  !important;
    float: right !important;
  }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li > a {
    padding-right: 7px;
    padding-left: 11px;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
    width: 235px !important;
    display: block;
    z-index: 10000;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
    width: 235px !important;
  }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .selected {
    display: none !important;
  }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover {
    width: 235px !important;
    z-index: 10000;
    margin-left: -235px !important;
  }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover .page-sidebar-menu {
    width: 235px !important;
  }
  .page-sidebar-closed.page-sidebar-hide .page-sidebar {
    display: none !important;
  }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu {
    display: none;
    width: 210px;
    z-index: 2000;
    position: absolute;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu > li > a {
    margin: 3px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li.active .sub-menu, .page-sidebar-menu.page-sidebar-menu-hover-submenu li.open .sub-menu {
    display: none !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li a > .arrow {
    display: none;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    margin-top: -20px;
    background: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 12px double transparent;
    border-bottom: 12px double transparent;
    border-left: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:after, .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:before {
    display: none;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    right: auto;
    left: 0;
    border-right: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    display: inline-block !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    z-index: 1;
    right: 0px;
    margin-top: -23px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .selected {
    display: none;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 235px;
    margin-top: -40px;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: -210px !important;
  }
  .page-sidebar-closed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 0;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li > a {
    padding-left: 15px;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
    margin-left: 210px;
    margin-top: -38px !important;
  }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
    margin-left: -210px !important;
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    margin-top: -41px;
  }
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-hover-submenu li:hover > .sub-menu > li > .sub-menu {
    margin-top: -41px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  .page-sidebar {
    border-top: 0 !important;
    margin: 20px;
  }
  .page-sidebar .sidebar-toggler {
    display: none;
  }
  .page-sidebar .selected {
    display: none !important;
  }
  .page-sidebar.navbar-collapse {
    max-height: none;
    /* set some max height to have a scrollable menu on mobile devices */
  }
  .page-sidebar.navbar-collapse.collapse {
    display: none !important;
  }
  .page-sidebar.navbar-collapse.in {
    border-top: 0 !important;
    margin: 20px;
    position: relative;
    overflow: hidden !important;
    overflow-y: auto !important;
    display: block !important;
  }
  .page-sidebar.navbar-collapse.navbar-no-scroll {
    max-height: none !important;
  }
  .page-sidebar .mega-menu-responsive-content {
    padding: 10px 18px 10px 45px;
  }
  .page-full-width .page-sidebar-menu {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none;
  }
  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}

@media (max-width: 480px) {
  /* 480px */
  /* Page sidebar */
  .page-sidebar,
  .page-sidebar.in {
    margin: 0 10px 10px 10px !important;
  }
  .page-header-fixed.page-header-fixed-mobile .page-sidebar, .page-header-fixed.page-header-fixed-mobile
  .page-sidebar.in {
    margin-top: 10px !important;
  }
}

/***
Page content
***/
/* Page title */
.page-title {
  padding: 12px 0;
  font-size: 2.14em;
  letter-spacing: -1px;
  display: block;
  color: #666;
  margin: 0px 0px 15px 0px;
  font-weight: 300;
  /* subtitle */
}

.page-title small {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 300;
  color: #888;
}

.page-content-white .page-title,
.page-container-bg-solid .page-title {
  color: #666;
  margin-bottom: 20px;
  margin-top: 20px;
}

.page-content-white .page-title small,
.page-container-bg-solid .page-title small {
  color: #666;
}

.page-content-white .page-title {
  margin: 25px 0;
  font-size: 24px;
}

/* Page breadcrumb */
.page-bar {
  padding: 0px;
  background-color: #f1f4f7;
  margin-bottom: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-bar:before, .page-bar:after {
  content: " ";
  display: table;
}

.page-bar:after {
  clear: both;
}

.page-bar .page-breadcrumb {
  display: inline-block;
  float: left;
  padding: 8px;
  margin: 0;
  list-style: none;
}

.page-bar .page-breadcrumb > li {
  display: inline-block;
}

.ie8 .page-bar .page-breadcrumb > li {
  margin-right: 1px;
}

.page-bar .page-breadcrumb > li > a,
.page-bar .page-breadcrumb > li > span {
  color: #888;
  font-size: 14px;
  text-shadow: none;
}

.page-bar .page-breadcrumb > li > i {
  color: #aaa;
  font-size: 14px;
  text-shadow: none;
}

.page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-bar .page-breadcrumb > li > i[class*="icon-"] {
  color: gray;
}

.page-bar .page-toolbar {
  display: inline-block;
  float: right;
  padding: 0;
}

.page-bar .page-toolbar .btn-fit-height {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.page-md .page-bar .page-toolbar .btn-fit-height {
  padding-top: 9px;
  padding-bottom: 9px;
  box-shadow: none !important;
}

.page-content-white .page-bar,
.page-container-bg-solid .page-bar {
  background-color: #ffffff;
  position: relative;
  padding: 0px 20px;
  margin: -25px -20px 0 -20px;
}

.page-content-white .page-bar .page-breadcrumb,
.page-container-bg-solid .page-bar .page-breadcrumb {
  padding: 11px 0;
}

.page-content-white .page-bar .page-breadcrumb > li > a,
.page-content-white .page-bar .page-breadcrumb > li > span,
.page-container-bg-solid .page-bar .page-breadcrumb > li > a,
.page-container-bg-solid .page-bar .page-breadcrumb > li > span {
  color: #888;
}

.page-content-white .page-bar .page-breadcrumb > li > i,
.page-container-bg-solid .page-bar .page-breadcrumb > li > i {
  color: #aaa;
}

.page-content-white .page-bar .page-breadcrumb > li > i.fa-circle,
.page-container-bg-solid .page-bar .page-breadcrumb > li > i.fa-circle {
  font-size: 5px;
  margin: 0 5px;
  position: relative;
  top: -3px;
  opacity: 0.4 ;
  filter: alpha(opacity=40) ;
}

.page-content-white .page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-content-white .page-bar .page-breadcrumb > li > i[class*="icon-"],
.page-container-bg-solid .page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-container-bg-solid .page-bar .page-breadcrumb > li > i[class*="icon-"] {
  color: #8c8c8c;
}

.page-content-white .page-bar .page-toolbar,
.page-container-bg-solid .page-bar .page-toolbar {
  padding: 6px 0;
}

.page-content-white .page-bar .page-toolbar .btn,
.page-container-bg-solid .page-bar .page-toolbar .btn {
  margin-top: -2px;
}

.page-content-white .page-bar .page-toolbar .btn.btn-sm,
.page-container-bg-solid .page-bar .page-toolbar .btn.btn-sm {
  margin-top: 0px;
}

/* Page content */
.page-content {
  margin-top: 0px;
  padding: 0px;
  background-color: #fff;
}

.page-container-bg-solid .page-content {
  background: #eef1f5;
}

.page-content-white .page-content .page-bar {
  border-bottom: 1px solid #e7ecf1;
}

.page-content-white.page-md .page-content .page-bar,
.page-container-bg-solid.page-md .page-content .page-bar {
  border-radius: 0 !important;
}

.page-full-width .page-content {
  margin-left: 0px !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Page content */
  .page-content-wrapper {
    float: left;
    width: 100%;
  }
  .page-content-wrapper .page-content-before {
    margin-left: 235px;
  }
  .page-content-wrapper .page-content {
    margin-left: 235px;
    margin-top: 0px;
    min-height: 600px;
    padding: 15px 20px 10px 20px;
  }
  .page-content-wrapper .page-content.no-min-height {
    min-height: auto;
  }
  .page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
    margin-left: 45px;
  }
  .page-sidebar-reversed .page-content-wrapper .page-content {
    margin-left: 0 !important;
    margin-right: 235px !important;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
    margin-left: 0;
    margin-right: 45px;
  }
  .page-sidebar-reversed.page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 0 !important;
    margin-right: 45px !important;
  }
  .page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 45px !important;
  }
  .page-sidebar-closed.page-sidebar-hide .page-content-wrapper .page-content {
    margin-left: 0 !important;
  }
  .page-sidebar-closed.page-sidebar-reversed.page-sidebar-hide .page-content-wrapper .page-content {
    margin-right: 0 !important;
  }
  .page-full-width .page-content-wrapper .page-content {
    margin-left: 0px !important;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Bg solid content's breadcrumb */
  .page-content-white .page-bar,
  .page-container-bg-solid .page-bar {
    margin-top: -20px;
  }
  /* Boxed page container  */
  .page-boxed > .container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  /* Page content */
  .page-content-wrapper .page-content {
    margin: 0px !important;
    padding: 20px 20px 20px 20px !important;
    min-height: 280px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /*  768px & 991px */
  /* Boxed page container */
  .page-boxed > .container {
    margin: auto !important;
  }
}

@media (max-width: 767px) {
  /* 767px */
  /* Page content */
  .page-content-wrapper .page-content {
    padding: 20px 10px 10px 10px !important;
    overflow: hidden;
    /* Page title */
  }
  .page-content-wrapper .page-content .page-title {
    margin-bottom: 20px;
    font-size: 2.14em;
  }
  .page-content-wrapper .page-content .page-title small {
    font-size: 13px;
    padding-top: 3px;
  }
}

@media (max-width: 480px) {
  /* 480px */
  /* Dashboard date range panel */
  .page-content-wrapper .page-content .page-title small {
    display: block;
    clear: both;
  }
}

/***
Page footer
***/
.page-footer {
  padding: 8px 20px 5px 20px;
  font-size: 13px;
  height: 33px;
}

.page-footer:before, .page-footer:after {
  content: " ";
  display: table;
}

.page-footer:after {
  clear: both;
}

.page-footer .page-footer-inner {
  float: left;
  display: inline-block;
}

.page-footer-fixed.page-footer-fixed-mobile .page-footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}

.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .page-footer {
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Default footer */
  .page-footer {
    clear: left;
  }
  /* Fixed footer */
  .page-footer-fixed .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
  }
  /* Footer with footer sidebar */
  .page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-left: 45px;
  }
  .page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0 !important;
  }
  /* Fixed Sidebar */
  .page-sidebar-fixed .page-footer {
    margin-left: 235px;
    padding: 8px 20px 5px 20px;
  }
  /* Boxed page */
  .page-boxed .page-footer {
    padding: 8px 0 5px 0;
  }
  .page-boxed.page-sidebar-fixed .page-footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  /* Page sidebar reversed */
  .page-sidebar-reversed.page-sidebar-fixed .page-footer {
    margin-left: 0;
    margin-right: 235px;
    padding: 8px 20px 5px 20px;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0;
    margin-right: 0;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-right: 45px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Boxed Layout */
  .page-boxed .page-footer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  /* 767px */
  /* Default footer & boxed footer */
  .page-footer,
  .page-boxed .page-footer {
    padding-left: 10px;
    padding-right: 10px;
  }
  /* Fixed footer */
  .page-footer-fixed .page-footer .container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Scroll Top Top */
.scroll-to-top {
  display: inline-block;
  padding: 1px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  z-index: 10001;
  display: none;
  right: 10px;
}

.scroll-to-top > i {
  display: inline-block;
  color: #687991;
  font-size: 30px;
  opacity: 0.6 ;
  filter: alpha(opacity=60) ;
}

.scroll-to-top:hover {
  cursor: pointer;
}

.scroll-to-top:hover > i {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}

@media (min-width: 992px) {
  /* 992px */
  .scroll-to-top {
    right: 20px;
  }
}

@media (max-width: 991px) {
  /* 991px */
  .scroll-to-top {
    bottom: 10px;
    right: 10px;
  }
  .scroll-to-top > i {
    font-size: 28px;
  }
}

/***
Theme Panel
***/
.theme-panel {
  width: 420px;
  margin-top: -13px;
  margin-right: 0px;
  z-index: 100;
  float: right;
  position: relative;
  /* content solid bg color */
}

.theme-panel > .toggler {
  top: 4px;
  right: 0;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  background: #536881 url(../images/icon-color.png) center no-repeat;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.theme-panel > .toggler:hover {
  background-color: #3f4f62 !important;
}

.theme-panel > .toggler-close {
  display: none;
  top: 4px;
  right: 0;
  padding: 20px;
  z-index: 101;
  cursor: pointer;
  position: absolute;
  background: #2b3643 url(../images/icon-color-close.png) center no-repeat !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.theme-panel > .toggler-close:hover {
  background-color: #212933 !important;
}

.theme-panel > .theme-options {
  top: 4px;
  right: 0;
  display: none;
  position: absolute;
  z-index: 100;
  background: #2b3643;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.theme-panel > .theme-options > .theme-option {
  color: #c6cfda;
  padding: 15px;
  border-top: 1px solid #354353;
  margin-top: 0px;
  margin-bottom: 0px;
}

.theme-panel > .theme-options > .theme-option > span {
  text-transform: uppercase;
  display: inline-block;
  width: 145px;
  font-size: 13px;
  font-weight: 300;
}

.theme-panel > .theme-options > .theme-option > select.form-control {
  display: inline;
  width: 135px;
  padding: 2px;
  text-transform: lowercase;
}

.theme-panel > .theme-options > .theme-option.theme-colors {
  border-top: 0;
}

.theme-panel > .theme-options > .theme-option.theme-colors > span {
  display: block;
  width: auto;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul {
  list-style: none;
  padding: 0;
  display: block;
  margin-bottom: 10px !important;
  margin-top: 15px;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li {
  width: 40px;
  height: 40px;
  margin: 0 4px;
  cursor: pointer;
  list-style: none;
  float: left;
  border: solid 1px #707070;
  /* theme colors */
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:first-child {
  margin-left: 0;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li:hover, .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.current {
  border: solid 2px #d64635;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-default {
  background: #333438;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-darkblue {
  background: #2b3643;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-blue {
  background: #2D5F8B;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-grey {
  background: #697380;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light {
  background: #F9FAFD;
}

.theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light2 {
  background: #F1F1F1;
}

.page-content-white .theme-panel,
.page-container-bg-solid .theme-panel {
  position: absolute;
  margin-top: 30px;
  margin-right: 20px;
  right: 0;
}

.page-content-white .theme-panel > .toggler1,
.page-container-bg-solid .theme-panel > .toggler1 {
  background: #BFCAD1 url(../images/icon-color.png) center no-repeat;
}

.page-content-white.page-sidebar-reversed .theme-panel,
.page-container-bg-solid.page-sidebar-reversed .theme-panel {
  margin-right: 255px;
}

.page-content-white.page-sidebar-reversed.page-sidebar-closed .theme-panel,
.page-container-bg-solid.page-sidebar-reversed.page-sidebar-closed .theme-panel {
  margin-right: 65px;
}

/******************
Page Quick Sidebar
******************/
/* Quick sidebar toggler */
.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle {
  padding: 17px 10px 9px 10px !important;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i {
  top: 0px;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i:before {
  content: ""/*rtl:""*/;
}

.page-quick-sidebar-open .page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i:before {
  content: ""/*rtl:""*/;
}

/***
Page Loading
***/
.page-on-load {
  background: #fefefe;
}

.page-on-load .page-header,
.page-on-load .page-container,
.page-on-load .page-footer,
.page-on-load > .clearfix {
  display: none;
  transition: all 2s;
}

/***********
    Page Header
    ***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  background-color: #003254;
  /* Top notification menu/bar */
  /* Header seaech box */
  /* Toggler button for sidebar expand/collapse and responsive sidebar menu */
}

.page-header.navbar .top-menu .navbar-nav {
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Language */
  /* Dark version */
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
  color: #999999;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle .badge.badge-default {
  background-color: #014e9c;
  color: #ffffff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
  background-color: #005087;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover > i {
  color: #bfbfbf;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
  background-color: #005087;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle > i {
  color: #bfbfbf;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  border-color: #e7eaf0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
  border-bottom-color: #eaedf2;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
  background: #eaedf2;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
  color: #62878f;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
  color: #337ab7;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
  color: #23527c;
  text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
  border-bottom: 1px solid #EFF2F6 !important;
  color: #888888;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
  background: #f8f9fa;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a .time {
  background: #f1f1f1;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a:hover .time {
  background: #e4e4e4;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .circle {
  background-color: #014e9c;
  color: #666666;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .corner {
  border-color: transparent transparent transparent #014e9c;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
  color: #5b9bd1;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
  background-color: #dfe2e9;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
  color: #a1d9ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > i {
  color: #a1d9ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
  width: 195px;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > .langname {
  color: #a1d9ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
  background: #005087;
  border: 0;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu:after {
  border-bottom-color: #005087;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external {
  background: #00385e;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > h3 {
  color: #5ebeff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > a:hover {
  color: #5496cf;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
  color: #78c8ff;
  border-bottom: 1px solid #0063a6 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i {
  color: #45b4ff;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
  background: #005d9b;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a {
  border-bottom: 0 !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
  background: #0063a6;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a .time {
  background: #00416e;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a:hover .time {
  background: #003254;
}

.page-header.navbar .search-form {
  background: #002640;
}

.page-header.navbar .search-form:hover {
  background: #005087;
}

.page-header.navbar .search-form .input-group .form-control {
  color: #999999;
}

.page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
  color: #969696;
  opacity: 1;
}

.page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
  color: #969696;
}

.page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
  color: #969696;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  color: #999999;
}

.page-header.navbar .search-form.open {
  background: #005087;
}

.page-header.navbar .menu-toggler {
  background-image: url(../images/images/sidebar_toggler_icon_default.png);
}

/* Default Horizontal Menu */
.page-header.navbar {
  /* Default Mega Menu */
  /* Light Mega Menu */
}

.page-header.navbar .hor-menu .navbar-nav {
  /* Mega menu content */
  /* Classic menu */
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
  box-shadow: 5px 5px rgba(0, 80, 135, 0.2);
}

.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
  color: #a1d9ff;
}

.page-header.navbar .hor-menu .navbar-nav > li > a {
  color: #a1d9ff;
}

.page-header.navbar .hor-menu .navbar-nav > li > a > i {
  color: #089bff;
}

.page-header.navbar .hor-menu .navbar-nav > li:hover > a,
.page-header.navbar .hor-menu .navbar-nav > li.open > a,
.page-header.navbar .hor-menu .navbar-nav > li > a:hover {
  color: #bae3ff;
  background: #005087 !important;
}

.page-header.navbar .hor-menu .navbar-nav > li:hover > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.open > a > i,
.page-header.navbar .hor-menu .navbar-nav > li > a:hover > i {
  color: #21a5ff;
}

.page-header.navbar .hor-menu .navbar-nav > li.active > a,
.page-header.navbar .hor-menu .navbar-nav > li.active > a, .page-header.navbar .hor-menu .navbar-nav > li.current > a,
.page-header.navbar .hor-menu .navbar-nav > li.current > a {
  color: white;
  background: #014e9c !important;
}

.page-header.navbar .hor-menu .navbar-nav > li.active > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.active > a > i, .page-header.navbar .hor-menu .navbar-nav > li.current > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.current > a > i {
  color: #089bff;
}

.page-header.navbar .hor-menu .navbar-nav > li.active .selected, .page-header.navbar .hor-menu .navbar-nav > li.current .selected {
  border-top: 6px solid #014e9c;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu {
  box-shadow: 5px 5px rgba(0, 80, 135, 0.2);
  background: #005087;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a {
  color: #87ceff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a > i {
  color: #87ceff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a {
  color: #ceebff;
  background: #005d9b;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a > i {
  color: #ceebff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover, .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover {
  color: #ceebff;
  background: #005d9b;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a > i,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover > i, .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a > i,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover > i {
  color: #ceebff;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.divider {
  background-color: #0060a1;
}

.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > a:after {
  color: #87ceff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav {
  /* Mega menu content */
  /* Classic menu */
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
  color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a {
  color: #a1d9ff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a > i {
  color: #089bff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover {
  color: #bae3ff;
  background: #005087;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover > i {
  color: #21a5ff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a {
  color: #333 !important;
  background: white !important;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a > i {
  color: #333 !important;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover {
  color: white;
  background: #014e9c;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover > i, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover > i {
  color: #089bff;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  background: white;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li > a {
  color: #000;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li > a > i {
  color: #888;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li:hover > a {
  color: #000;
  background: whitesmoke;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li:hover > a > i {
  color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a:hover, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a:hover {
  color: #000;
  background: whitesmoke;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a:hover > i, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a:hover > i {
  color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.divider {
  background-color: whitesmoke;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
  border: 1px solid #f2f2f2;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > .dropdown-menu {
  border-top: 0;
}

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #000000;
  /* Default sidebar */
  /* light sidebar */
  /* Sidebar search */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* 1st level links */
  /* All links */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
.page-sidebar .page-sidebar-menu > li > a {
  border-top: 1px solid #000000;
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i,
.page-sidebar .page-sidebar-menu > li > a > i {
  color: #014e9c;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
  color: #015bb5;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3,
.page-sidebar .page-sidebar-menu > li.heading > h3 {
  color: #c4c4c4;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
.page-sidebar .page-sidebar-menu > li:hover > a,
.page-sidebar .page-sidebar-menu > li.open > a {
  background: #222222;
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu > li.open > a > i {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar .page-sidebar-menu > li.active.open > a {
  background: #014e9c;
  border-top-color: transparent;
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
.page-sidebar .page-sidebar-menu > li.active > a:hover,
.page-sidebar .page-sidebar-menu > li.active.open > a:hover {
  background: #014e9c;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
.page-sidebar .page-sidebar-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu > li.active.open > a > i {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .selected, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .selected,
.page-sidebar .page-sidebar-menu > li.active > a > .selected,
.page-sidebar .page-sidebar-menu > li.active.open > a > .selected {
  border-right-color: #eef1f5;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active + li > a,
.page-sidebar .page-sidebar-menu > li.active + li > a {
  border-top-color: transparent;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open + li > a,
.page-sidebar .page-sidebar-menu > li.active.open + li > a {
  border-top-color: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:last-child > a,
.page-sidebar .page-sidebar-menu > li:last-child > a {
  border-bottom: 1px solid transparent !important;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:hover .sub-menu, .page-sidebar-closed
.page-sidebar .page-sidebar-menu:hover .sub-menu {
  background-color: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a,
.page-sidebar .page-sidebar-menu .sub-menu > li > a {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
  color: #015bb5;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
  color: #ffffff;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
  background: #222222 !important;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
  color: #314465;
  color: #0681fd;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before {
  color: #314465;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.open > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
  background: #050505;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
  background: #0a0a0a;
  border-left: 4px solid #014e9c;
  color: #f1f1f1;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
  border-left: 4px solid #014e9c;
  background: #050505;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > i,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > i {
  color: #eeeeee;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow.open:before {
  color: #eeeeee;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
  background: #050505;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a {
  background: #0a0a0a !important;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler,
.page-sidebar .sidebar-toggler {
  background: url(../images/sidebar_inline_toggler_icon_default.jpg);
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group,
.page-sidebar .sidebar-search .input-group {
  border-bottom: 1px solid black;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control,
.page-sidebar .sidebar-search .input-group .form-control {
  background-color: #000000;
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control::-moz-placeholder,
.page-sidebar .sidebar-search .input-group .form-control::-moz-placeholder {
  color: #141414;
  opacity: 1;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control:-ms-input-placeholder,
.page-sidebar .sidebar-search .input-group .form-control:-ms-input-placeholder {
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control::-webkit-input-placeholder,
.page-sidebar .sidebar-search .input-group .form-control::-webkit-input-placeholder {
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn > i,
.page-sidebar .sidebar-search .input-group .input-group-btn .btn > i {
  color: #141414;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group,
.page-sidebar .sidebar-search.sidebar-search-bordered .input-group {
  border: 1px solid black;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.open .input-group, .page-sidebar-closed
.page-sidebar .sidebar-search.open .input-group {
  background-color: #000000;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.open .remove > i, .page-sidebar-closed
.page-sidebar .sidebar-search.open .remove > i {
  color: #141414;
}

.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group, .page-sidebar-closed
.page-sidebar .sidebar-search.sidebar-search-solid .input-group {
  background: none;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group,
.page-sidebar .sidebar-search.sidebar-search-solid .input-group {
  border: 1px solid black;
  background: black;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group .form-control,
.page-sidebar .sidebar-search.sidebar-search-solid .input-group .form-control {
  background: black;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid.open .input-group,
.page-sidebar .sidebar-search.sidebar-search-solid.open .input-group {
  border: 1px solid #000000;
  background: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid.open .input-group .form-control,
.page-sidebar .sidebar-search.sidebar-search-solid.open .input-group .form-control {
  background: #000000;
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
  border-left: 0;
  border-right: 4px solid #014e9c;
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
  border-left: 0;
  border-right: 4px solid #014e9c;
}

.page-content {
  background-color: #eef1f5;
}

/******
    Page Footer
    ******/
.page-footer .page-footer-inner {
  color: #666666;
}

.page-footer-fixed .page-footer {
  background-color: black;
}

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(34, 34, 34, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu.sidebar-search-wrapper {
    box-shadow: none;
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(34, 34, 34, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-closed > li:hover.sidebar-search-wrapper {
    box-shadow: none;
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(34, 34, 34, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu.sidebar-search-wrapper {
    box-shadow: none;
  }
  /* Light sidebar menu */
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li.heading {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #000000 !important;
  }
  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer {
    background-color: #fff;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
    color: #333;
  }
  /* Boxed Layout */
  .page-boxed {
    background-color: black !important;
    /* Page container */
    /* Page sidebar */
    /* Page footer */
  }
  .page-boxed .page-container {
    background-color: #000000;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  .page-boxed.page-sidebar-reversed .page-container {
    border-left: 0;
    border-right: 1px solid #000000;
  }
  .page-boxed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-fixed .page-sidebar {
    border-left: 1px solid #000000;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    border-right: 1px solid #000000;
    border-left: 0;
  }
  .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer {
    background-color: black !important;
  }
  .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer .page-footer-inner {
    color: #666666;
  }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #2c2c2c;
  }
  .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-left: 8px solid #2c2c2c;
  }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #2c2c2c !important;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Page sidebar */
  .page-sidebar {
    background-color: black;
    /* light sidebar */
  }
  .page-sidebar .page-sidebar-menu > li > a {
    border-top: 1px solid #000000;
  }
  .page-sidebar .page-sidebar-menu > li:hover > a, .page-sidebar .page-sidebar-menu > li.open > a {
    background: black;
  }
  .page-sidebar .page-sidebar-menu > li:last-child > a {
    border-bottom: 0 !important;
  }
  .page-sidebar .page-sidebar-menu > li .sub-menu {
    background-color: black !important;
  }
  .page-sidebar .page-sidebar-menu .sidebar-search input {
    background-color: black !important;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
    /* 1st level links */
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
    background: #080808;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
    background: #080808;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
    background: #080808;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
    background: black !important;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a {
    background: #080808 !important;
  }
}

@media (max-width: 480px) {
  /* 480px */
  .page-header.navbar {
    /* Top menu */
  }
  .page-header.navbar .top-menu {
    background-color: #000000;
  }
  .page-header-fixed-mobile .page-header.navbar .top-menu {
    background-color: #003254;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    background-color: #00538c;
  }
  .page-header-fixed-mobile .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
    background: none;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle:hover {
    background-color: #005087;
  }
}

/****
    Boby
    ****/
body {
  background-color: #000000;
}

/****
 CSS3 Spinner Bar
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #015bb5;
}

.page-sidebar .page-sidebar-menu > li > a {
  background: #000000;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* Change sidebar link font weight */
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a,
.page-sidebar .page-sidebar-menu li > a {
  font-weight: normal;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > i,
.page-sidebar .page-sidebar-menu li > a > i {
  font-weight: normal;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li .sub-menu li > a,
.page-sidebar .page-sidebar-menu li .sub-menu li > a {
  font-weight: normal;
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li .sub-menu li > a > i,
.page-sidebar .page-sidebar-menu li .sub-menu li > a > i {
  font-weight: normal;
}

/***
Custom color buttons
***/
.bg-white {
  background: #ffffff !important;
}

.bg-hover-white:hover {
  background: #ffffff !important;
}

.font-white {
  color: #ffffff !important;
}

.bg-font-white {
  color: #666 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-top-white {
  border-top-color: #ffffff !important;
}

.border-bottom-white {
  border-bottom-color: #ffffff !important;
}

.border-left-white {
  border-left-color: #ffffff !important;
}

.border-right-white {
  border-right-color: #ffffff !important;
}

.bg-default {
  background: #014e9c !important;
}

.bg-hover-default:hover {
  background: #014e9c !important;
}

.font-default {
  color: #014e9c !important;
}

.bg-font-default {
  color: #ffffff !important;
}

.border-default {
  border-color: #014e9c !important;
}

.border-top-default {
  border-top-color: #014e9c !important;
}

.border-bottom-default {
  border-bottom-color: #014e9c !important;
}

.border-left-default {
  border-left-color: #014e9c !important;
}

.border-right-default {
  border-right-color: #014e9c !important;
}

.bg-dark {
  background: #666666 !important;
}

.bg-hover-dark:hover {
  background: #666666 !important;
}

.font-dark {
  color: #666666 !important;
}

.bg-font-dark {
  color: #FFFFFF !important;
}

.border-dark {
  border-color: #666666 !important;
}

.border-top-dark {
  border-top-color: #666666 !important;
}

.border-bottom-dark {
  border-bottom-color: #666666 !important;
}

.border-left-dark {
  border-left-color: #666666 !important;
}

.border-right-dark {
  border-right-color: #666666 !important;
}

.bg-blue-1 {
  background: #013e7d !important;
}

.bg-hover-blue-1:hover {
  background: #013e7d !important;
}

.font-blue-1 {
  color: #013e7d !important;
}

.bg-font-blue-1 {
  color: #FFFFFF !important;
}

.border-blue-1 {
  border-color: #013e7d !important;
}

.border-top-blue-1 {
  border-top-color: #013e7d !important;
}

.border-bottom-blue-1 {
  border-bottom-color: #013e7d !important;
}

.border-left-blue-1 {
  border-left-color: #013e7d !important;
}

.border-right-blue-1 {
  border-right-color: #013e7d !important;
}

.bg-blue-2 {
  background: #0554a3 !important;
}

.bg-hover-blue-2:hover {
  background: #0554a3 !important;
}

.font-blue-2 {
  color: #0554a3 !important;
}

.bg-font-blue-2 {
  color: #FFFFFF !important;
}

.border-blue-2 {
  border-color: #0554a3 !important;
}

.border-top-blue-2 {
  border-top-color: #0554a3 !important;
}

.border-bottom-blue-2 {
  border-bottom-color: #0554a3 !important;
}

.border-left-blue-2 {
  border-left-color: #0554a3 !important;
}

.border-right-blue-2 {
  border-right-color: #0554a3 !important;
}

.bg-blue {
  background: #3598dc !important;
}

.bg-hover-blue:hover {
  background: #3598dc !important;
}

.font-blue {
  color: #3598dc !important;
}

.bg-font-blue {
  color: #FFFFFF !important;
}

.border-blue {
  border-color: #3598dc !important;
}

.border-top-blue {
  border-top-color: #3598dc !important;
}

.border-bottom-blue {
  border-bottom-color: #3598dc !important;
}

.border-left-blue {
  border-left-color: #3598dc !important;
}

.border-right-blue {
  border-right-color: #3598dc !important;
}

.bg-blue-madison {
  background: #578ebe !important;
}

.bg-hover-blue-madison:hover {
  background: #578ebe !important;
}

.font-blue-madison {
  color: #578ebe !important;
}

.bg-font-blue-madison {
  color: #FFFFFF !important;
}

.border-blue-madison {
  border-color: #578ebe !important;
}

.border-top-blue-madison {
  border-top-color: #578ebe !important;
}

.border-bottom-blue-madison {
  border-bottom-color: #578ebe !important;
}

.border-left-blue-madison {
  border-left-color: #578ebe !important;
}

.border-right-blue-madison {
  border-right-color: #578ebe !important;
}

.bg-blue-chambray {
  background: #2C3E50 !important;
}

.bg-hover-blue-chambray:hover {
  background: #2C3E50 !important;
}

.font-blue-chambray {
  color: #2C3E50 !important;
}

.bg-font-blue-chambray {
  color: #FFFFFF !important;
}

.border-blue-chambray {
  border-color: #2C3E50 !important;
}

.border-top-blue-chambray {
  border-top-color: #2C3E50 !important;
}

.border-bottom-blue-chambray {
  border-bottom-color: #2C3E50 !important;
}

.border-left-blue-chambray {
  border-left-color: #2C3E50 !important;
}

.border-right-blue-chambray {
  border-right-color: #2C3E50 !important;
}

.bg-blue-ebonyclay {
  background: #22313F !important;
}

.bg-hover-blue-ebonyclay:hover {
  background: #22313F !important;
}

.font-blue-ebonyclay {
  color: #22313F !important;
}

.bg-font-blue-ebonyclay {
  color: #FFFFFF !important;
}

.border-blue-ebonyclay {
  border-color: #22313F !important;
}

.border-top-blue-ebonyclay {
  border-top-color: #22313F !important;
}

.border-bottom-blue-ebonyclay {
  border-bottom-color: #22313F !important;
}

.border-left-blue-ebonyclay {
  border-left-color: #22313F !important;
}

.border-right-blue-ebonyclay {
  border-right-color: #22313F !important;
}

.bg-blue-hoki {
  background: #67809F !important;
}

.bg-hover-blue-hoki:hover {
  background: #67809F !important;
}

.font-blue-hoki {
  color: #67809F !important;
}

.bg-font-blue-hoki {
  color: #FFFFFF !important;
}

.border-blue-hoki {
  border-color: #67809F !important;
}

.border-top-blue-hoki {
  border-top-color: #67809F !important;
}

.border-bottom-blue-hoki {
  border-bottom-color: #67809F !important;
}

.border-left-blue-hoki {
  border-left-color: #67809F !important;
}

.border-right-blue-hoki {
  border-right-color: #67809F !important;
}

.bg-blue-steel {
  background: #4B77BE !important;
}

.bg-hover-blue-steel:hover {
  background: #4B77BE !important;
}

.font-blue-steel {
  color: #4B77BE !important;
}

.bg-font-blue-steel {
  color: #FFFFFF !important;
}

.border-blue-steel {
  border-color: #4B77BE !important;
}

.border-top-blue-steel {
  border-top-color: #4B77BE !important;
}

.border-bottom-blue-steel {
  border-bottom-color: #4B77BE !important;
}

.border-left-blue-steel {
  border-left-color: #4B77BE !important;
}

.border-right-blue-steel {
  border-right-color: #4B77BE !important;
}

.bg-blue-soft {
  background: #4c87b9 !important;
}

.bg-hover-blue-soft:hover {
  background: #4c87b9 !important;
}

.font-blue-soft {
  color: #4c87b9 !important;
}

.bg-font-blue-soft {
  color: #FFFFFF !important;
}

.border-blue-soft {
  border-color: #4c87b9 !important;
}

.border-top-blue-soft {
  border-top-color: #4c87b9 !important;
}

.border-bottom-blue-soft {
  border-bottom-color: #4c87b9 !important;
}

.border-left-blue-soft {
  border-left-color: #4c87b9 !important;
}

.border-right-blue-soft {
  border-right-color: #4c87b9 !important;
}

.bg-blue-dark {
  background: #5e738b !important;
}

.bg-hover-blue-dark:hover {
  background: #5e738b !important;
}

.font-blue-dark {
  color: #5e738b !important;
}

.bg-font-blue-dark {
  color: #FFFFFF !important;
}

.border-blue-dark {
  border-color: #5e738b !important;
}

.border-top-blue-dark {
  border-top-color: #5e738b !important;
}

.border-bottom-blue-dark {
  border-bottom-color: #5e738b !important;
}

.border-left-blue-dark {
  border-left-color: #5e738b !important;
}

.border-right-blue-dark {
  border-right-color: #5e738b !important;
}

.bg-blue-sharp {
  background: #5C9BD1 !important;
}

.bg-hover-blue-sharp:hover {
  background: #5C9BD1 !important;
}

.font-blue-sharp {
  color: #5C9BD1 !important;
}

.bg-font-blue-sharp {
  color: #FFFFFF !important;
}

.border-blue-sharp {
  border-color: #5C9BD1 !important;
}

.border-top-blue-sharp {
  border-top-color: #5C9BD1 !important;
}

.border-bottom-blue-sharp {
  border-bottom-color: #5C9BD1 !important;
}

.border-left-blue-sharp {
  border-left-color: #5C9BD1 !important;
}

.border-right-blue-sharp {
  border-right-color: #5C9BD1 !important;
}

.bg-green {
  background: #32c5d2 !important;
}

.bg-hover-green:hover {
  background: #32c5d2 !important;
}

.font-green {
  color: #32c5d2 !important;
}

.bg-font-green {
  color: #FFFFFF !important;
}

.border-green {
  border-color: #32c5d2 !important;
}

.border-top-green {
  border-top-color: #32c5d2 !important;
}

.border-bottom-green {
  border-bottom-color: #32c5d2 !important;
}

.border-left-green {
  border-left-color: #32c5d2 !important;
}

.border-right-green {
  border-right-color: #32c5d2 !important;
}

.bg-green-meadow {
  background: #1BBC9B !important;
}

.bg-hover-green-meadow:hover {
  background: #1BBC9B !important;
}

.font-green-meadow {
  color: #1BBC9B !important;
}

.bg-font-green-meadow {
  color: #FFFFFF !important;
}

.border-green-meadow {
  border-color: #1BBC9B !important;
}

.border-top-green-meadow {
  border-top-color: #1BBC9B !important;
}

.border-bottom-green-meadow {
  border-bottom-color: #1BBC9B !important;
}

.border-left-green-meadow {
  border-left-color: #1BBC9B !important;
}

.border-right-green-meadow {
  border-right-color: #1BBC9B !important;
}

.bg-green-seagreen {
  background: #1BA39C !important;
}

.bg-hover-green-seagreen:hover {
  background: #1BA39C !important;
}

.font-green-seagreen {
  color: #1BA39C !important;
}

.bg-font-green-seagreen {
  color: #FFFFFF !important;
}

.border-green-seagreen {
  border-color: #1BA39C !important;
}

.border-top-green-seagreen {
  border-top-color: #1BA39C !important;
}

.border-bottom-green-seagreen {
  border-bottom-color: #1BA39C !important;
}

.border-left-green-seagreen {
  border-left-color: #1BA39C !important;
}

.border-right-green-seagreen {
  border-right-color: #1BA39C !important;
}

.bg-green-turquoise {
  background: #36D7B7 !important;
}

.bg-hover-green-turquoise:hover {
  background: #36D7B7 !important;
}

.font-green-turquoise {
  color: #36D7B7 !important;
}

.bg-font-green-turquoise {
  color: #FFFFFF !important;
}

.border-green-turquoise {
  border-color: #36D7B7 !important;
}

.border-top-green-turquoise {
  border-top-color: #36D7B7 !important;
}

.border-bottom-green-turquoise {
  border-bottom-color: #36D7B7 !important;
}

.border-left-green-turquoise {
  border-left-color: #36D7B7 !important;
}

.border-right-green-turquoise {
  border-right-color: #36D7B7 !important;
}

.bg-green-haze {
  background: #44b6ae !important;
}

.bg-hover-green-haze:hover {
  background: #44b6ae !important;
}

.font-green-haze {
  color: #44b6ae !important;
}

.bg-font-green-haze {
  color: #FFFFFF !important;
}

.border-green-haze {
  border-color: #44b6ae !important;
}

.border-top-green-haze {
  border-top-color: #44b6ae !important;
}

.border-bottom-green-haze {
  border-bottom-color: #44b6ae !important;
}

.border-left-green-haze {
  border-left-color: #44b6ae !important;
}

.border-right-green-haze {
  border-right-color: #44b6ae !important;
}

.bg-green-jungle {
  background: #26C281 !important;
}

.bg-hover-green-jungle:hover {
  background: #26C281 !important;
}

.font-green-jungle {
  color: #26C281 !important;
}

.bg-font-green-jungle {
  color: #FFFFFF !important;
}

.border-green-jungle {
  border-color: #26C281 !important;
}

.border-top-green-jungle {
  border-top-color: #26C281 !important;
}

.border-bottom-green-jungle {
  border-bottom-color: #26C281 !important;
}

.border-left-green-jungle {
  border-left-color: #26C281 !important;
}

.border-right-green-jungle {
  border-right-color: #26C281 !important;
}

.bg-green-soft {
  background: #3faba4 !important;
}

.bg-hover-green-soft:hover {
  background: #3faba4 !important;
}

.font-green-soft {
  color: #3faba4 !important;
}

.bg-font-green-soft {
  color: #FFFFFF !important;
}

.border-green-soft {
  border-color: #3faba4 !important;
}

.border-top-green-soft {
  border-top-color: #3faba4 !important;
}

.border-bottom-green-soft {
  border-bottom-color: #3faba4 !important;
}

.border-left-green-soft {
  border-left-color: #3faba4 !important;
}

.border-right-green-soft {
  border-right-color: #3faba4 !important;
}

.bg-green-dark {
  background: #4DB3A2 !important;
}

.bg-hover-green-dark:hover {
  background: #4DB3A2 !important;
}

.font-green-dark {
  color: #4DB3A2 !important;
}

.bg-font-green-dark {
  color: #FFFFFF !important;
}

.border-green-dark {
  border-color: #4DB3A2 !important;
}

.border-top-green-dark {
  border-top-color: #4DB3A2 !important;
}

.border-bottom-green-dark {
  border-bottom-color: #4DB3A2 !important;
}

.border-left-green-dark {
  border-left-color: #4DB3A2 !important;
}

.border-right-green-dark {
  border-right-color: #4DB3A2 !important;
}

.bg-green-sharp {
  background: #2ab4c0 !important;
}

.bg-hover-green-sharp:hover {
  background: #2ab4c0 !important;
}

.font-green-sharp {
  color: #2ab4c0 !important;
}

.bg-font-green-sharp {
  color: #FFFFFF !important;
}

.border-green-sharp {
  border-color: #2ab4c0 !important;
}

.border-top-green-sharp {
  border-top-color: #2ab4c0 !important;
}

.border-bottom-green-sharp {
  border-bottom-color: #2ab4c0 !important;
}

.border-left-green-sharp {
  border-left-color: #2ab4c0 !important;
}

.border-right-green-sharp {
  border-right-color: #2ab4c0 !important;
}

.bg-grey {
  background: #E5E5E5 !important;
}

.bg-hover-grey:hover {
  background: #E5E5E5 !important;
}

.font-grey {
  color: #E5E5E5 !important;
}

.bg-font-grey {
  color: #333333 !important;
}

.border-grey {
  border-color: #E5E5E5 !important;
}

.border-top-grey {
  border-top-color: #E5E5E5 !important;
}

.border-bottom-grey {
  border-bottom-color: #E5E5E5 !important;
}

.border-left-grey {
  border-left-color: #E5E5E5 !important;
}

.border-right-grey {
  border-right-color: #E5E5E5 !important;
}

.bg-grey-steel {
  background: #e9edef !important;
}

.bg-hover-grey-steel:hover {
  background: #e9edef !important;
}

.font-grey-steel {
  color: #e9edef !important;
}

.bg-font-grey-steel {
  color: #80898e !important;
}

.border-grey-steel {
  border-color: #e9edef !important;
}

.border-top-grey-steel {
  border-top-color: #e9edef !important;
}

.border-bottom-grey-steel {
  border-bottom-color: #e9edef !important;
}

.border-left-grey-steel {
  border-left-color: #e9edef !important;
}

.border-right-grey-steel {
  border-right-color: #e9edef !important;
}

.bg-grey-cararra {
  background: #fafafa !important;
}

.bg-hover-grey-cararra:hover {
  background: #fafafa !important;
}

.font-grey-cararra {
  color: #fafafa !important;
}

.bg-font-grey-cararra {
  color: #333333 !important;
}

.border-grey-cararra {
  border-color: #fafafa !important;
}

.border-top-grey-cararra {
  border-top-color: #fafafa !important;
}

.border-bottom-grey-cararra {
  border-bottom-color: #fafafa !important;
}

.border-left-grey-cararra {
  border-left-color: #fafafa !important;
}

.border-right-grey-cararra {
  border-right-color: #fafafa !important;
}

.bg-grey-gallery {
  background: #555555 !important;
}

.bg-hover-grey-gallery:hover {
  background: #555555 !important;
}

.font-grey-gallery {
  color: #555555 !important;
}

.bg-font-grey-gallery {
  color: #ffffff !important;
}

.border-grey-gallery {
  border-color: #555555 !important;
}

.border-top-grey-gallery {
  border-top-color: #555555 !important;
}

.border-bottom-grey-gallery {
  border-bottom-color: #555555 !important;
}

.border-left-grey-gallery {
  border-left-color: #555555 !important;
}

.border-right-grey-gallery {
  border-right-color: #555555 !important;
}

.bg-grey-cascade {
  background: #95A5A6 !important;
}

.bg-hover-grey-cascade:hover {
  background: #95A5A6 !important;
}

.font-grey-cascade {
  color: #95A5A6 !important;
}

.bg-font-grey-cascade {
  color: #FFFFFF !important;
}

.border-grey-cascade {
  border-color: #95A5A6 !important;
}

.border-top-grey-cascade {
  border-top-color: #95A5A6 !important;
}

.border-bottom-grey-cascade {
  border-bottom-color: #95A5A6 !important;
}

.border-left-grey-cascade {
  border-left-color: #95A5A6 !important;
}

.border-right-grey-cascade {
  border-right-color: #95A5A6 !important;
}

.bg-grey-silver {
  background: #BFBFBF !important;
}

.bg-hover-grey-silver:hover {
  background: #BFBFBF !important;
}

.font-grey-silver {
  color: #BFBFBF !important;
}

.bg-font-grey-silver {
  color: #FAFCFB !important;
}

.border-grey-silver {
  border-color: #BFBFBF !important;
}

.border-top-grey-silver {
  border-top-color: #BFBFBF !important;
}

.border-bottom-grey-silver {
  border-bottom-color: #BFBFBF !important;
}

.border-left-grey-silver {
  border-left-color: #BFBFBF !important;
}

.border-right-grey-silver {
  border-right-color: #BFBFBF !important;
}

.bg-grey-salsa {
  background: #ACB5C3 !important;
}

.bg-hover-grey-salsa:hover {
  background: #ACB5C3 !important;
}

.font-grey-salsa {
  color: #ACB5C3 !important;
}

.bg-font-grey-salsa {
  color: #FAFCFB !important;
}

.border-grey-salsa {
  border-color: #ACB5C3 !important;
}

.border-top-grey-salsa {
  border-top-color: #ACB5C3 !important;
}

.border-bottom-grey-salsa {
  border-bottom-color: #ACB5C3 !important;
}

.border-left-grey-salsa {
  border-left-color: #ACB5C3 !important;
}

.border-right-grey-salsa {
  border-right-color: #ACB5C3 !important;
}

.bg-grey-salt {
  background: #bfcad1 !important;
}

.bg-hover-grey-salt:hover {
  background: #bfcad1 !important;
}

.font-grey-salt {
  color: #bfcad1 !important;
}

.bg-font-grey-salt {
  color: #FAFCFB !important;
}

.border-grey-salt {
  border-color: #bfcad1 !important;
}

.border-top-grey-salt {
  border-top-color: #bfcad1 !important;
}

.border-bottom-grey-salt {
  border-bottom-color: #bfcad1 !important;
}

.border-left-grey-salt {
  border-left-color: #bfcad1 !important;
}

.border-right-grey-salt {
  border-right-color: #bfcad1 !important;
}

.bg-grey-mint {
  background: #525e64 !important;
}

.bg-hover-grey-mint:hover {
  background: #525e64 !important;
}

.font-grey-mint {
  color: #525e64 !important;
}

.bg-font-grey-mint {
  color: #FFFFFF !important;
}

.border-grey-mint {
  border-color: #525e64 !important;
}

.border-top-grey-mint {
  border-top-color: #525e64 !important;
}

.border-bottom-grey-mint {
  border-bottom-color: #525e64 !important;
}

.border-left-grey-mint {
  border-left-color: #525e64 !important;
}

.border-right-grey-mint {
  border-right-color: #525e64 !important;
}

.bg-red {
  background: #e7505a !important;
}

.bg-hover-red:hover {
  background: #e7505a !important;
}

.font-red {
  color: #e7505a !important;
}

.bg-font-red {
  color: #ffffff !important;
}

.border-red {
  border-color: #e7505a !important;
}

.border-top-red {
  border-top-color: #e7505a !important;
}

.border-bottom-red {
  border-bottom-color: #e7505a !important;
}

.border-left-red {
  border-left-color: #e7505a !important;
}

.border-right-red {
  border-right-color: #e7505a !important;
}

.bg-red-pink {
  background: #E08283 !important;
}

.bg-hover-red-pink:hover {
  background: #E08283 !important;
}

.font-red-pink {
  color: #E08283 !important;
}

.bg-font-red-pink {
  color: #ffffff !important;
}

.border-red-pink {
  border-color: #E08283 !important;
}

.border-top-red-pink {
  border-top-color: #E08283 !important;
}

.border-bottom-red-pink {
  border-bottom-color: #E08283 !important;
}

.border-left-red-pink {
  border-left-color: #E08283 !important;
}

.border-right-red-pink {
  border-right-color: #E08283 !important;
}

.bg-red-sunglo {
  background: #E26A6A !important;
}

.bg-hover-red-sunglo:hover {
  background: #E26A6A !important;
}

.font-red-sunglo {
  color: #E26A6A !important;
}

.bg-font-red-sunglo {
  color: #ffffff !important;
}

.border-red-sunglo {
  border-color: #E26A6A !important;
}

.border-top-red-sunglo {
  border-top-color: #E26A6A !important;
}

.border-bottom-red-sunglo {
  border-bottom-color: #E26A6A !important;
}

.border-left-red-sunglo {
  border-left-color: #E26A6A !important;
}

.border-right-red-sunglo {
  border-right-color: #E26A6A !important;
}

.bg-red-intense {
  background: #e35b5a !important;
}

.bg-hover-red-intense:hover {
  background: #e35b5a !important;
}

.font-red-intense {
  color: #e35b5a !important;
}

.bg-font-red-intense {
  color: #ffffff !important;
}

.border-red-intense {
  border-color: #e35b5a !important;
}

.border-top-red-intense {
  border-top-color: #e35b5a !important;
}

.border-bottom-red-intense {
  border-bottom-color: #e35b5a !important;
}

.border-left-red-intense {
  border-left-color: #e35b5a !important;
}

.border-right-red-intense {
  border-right-color: #e35b5a !important;
}

.bg-red-thunderbird {
  background: #D91E18 !important;
}

.bg-hover-red-thunderbird:hover {
  background: #D91E18 !important;
}

.font-red-thunderbird {
  color: #D91E18 !important;
}

.bg-font-red-thunderbird {
  color: #ffffff !important;
}

.border-red-thunderbird {
  border-color: #D91E18 !important;
}

.border-top-red-thunderbird {
  border-top-color: #D91E18 !important;
}

.border-bottom-red-thunderbird {
  border-bottom-color: #D91E18 !important;
}

.border-left-red-thunderbird {
  border-left-color: #D91E18 !important;
}

.border-right-red-thunderbird {
  border-right-color: #D91E18 !important;
}

.bg-red-flamingo {
  background: #EF4836 !important;
}

.bg-hover-red-flamingo:hover {
  background: #EF4836 !important;
}

.font-red-flamingo {
  color: #EF4836 !important;
}

.bg-font-red-flamingo {
  color: #ffffff !important;
}

.border-red-flamingo {
  border-color: #EF4836 !important;
}

.border-top-red-flamingo {
  border-top-color: #EF4836 !important;
}

.border-bottom-red-flamingo {
  border-bottom-color: #EF4836 !important;
}

.border-left-red-flamingo {
  border-left-color: #EF4836 !important;
}

.border-right-red-flamingo {
  border-right-color: #EF4836 !important;
}

.bg-red-soft {
  background: #d05454 !important;
}

.bg-hover-red-soft:hover {
  background: #d05454 !important;
}

.font-red-soft {
  color: #d05454 !important;
}

.bg-font-red-soft {
  color: #ffffff !important;
}

.border-red-soft {
  border-color: #d05454 !important;
}

.border-top-red-soft {
  border-top-color: #d05454 !important;
}

.border-bottom-red-soft {
  border-bottom-color: #d05454 !important;
}

.border-left-red-soft {
  border-left-color: #d05454 !important;
}

.border-right-red-soft {
  border-right-color: #d05454 !important;
}

.bg-red-haze {
  background: #f36a5a !important;
}

.bg-hover-red-haze:hover {
  background: #f36a5a !important;
}

.font-red-haze {
  color: #f36a5a !important;
}

.bg-font-red-haze {
  color: #ffffff !important;
}

.border-red-haze {
  border-color: #f36a5a !important;
}

.border-top-red-haze {
  border-top-color: #f36a5a !important;
}

.border-bottom-red-haze {
  border-bottom-color: #f36a5a !important;
}

.border-left-red-haze {
  border-left-color: #f36a5a !important;
}

.border-right-red-haze {
  border-right-color: #f36a5a !important;
}

.bg-red-mint {
  background: #e43a45 !important;
}

.bg-hover-red-mint:hover {
  background: #e43a45 !important;
}

.font-red-mint {
  color: #e43a45 !important;
}

.bg-font-red-mint {
  color: #ffffff !important;
}

.border-red-mint {
  border-color: #e43a45 !important;
}

.border-top-red-mint {
  border-top-color: #e43a45 !important;
}

.border-bottom-red-mint {
  border-bottom-color: #e43a45 !important;
}

.border-left-red-mint {
  border-left-color: #e43a45 !important;
}

.border-right-red-mint {
  border-right-color: #e43a45 !important;
}

.bg-yellow {
  background: #c49f47 !important;
}

.bg-hover-yellow:hover {
  background: #c49f47 !important;
}

.font-yellow {
  color: #c49f47 !important;
}

.bg-font-yellow {
  color: #ffffff !important;
}

.border-yellow {
  border-color: #c49f47 !important;
}

.border-top-yellow {
  border-top-color: #c49f47 !important;
}

.border-bottom-yellow {
  border-bottom-color: #c49f47 !important;
}

.border-left-yellow {
  border-left-color: #c49f47 !important;
}

.border-right-yellow {
  border-right-color: #c49f47 !important;
}

.bg-yellow-gold {
  background: #E87E04 !important;
}

.bg-hover-yellow-gold:hover {
  background: #E87E04 !important;
}

.font-yellow-gold {
  color: #E87E04 !important;
}

.bg-font-yellow-gold {
  color: #ffffff !important;
}

.border-yellow-gold {
  border-color: #E87E04 !important;
}

.border-top-yellow-gold {
  border-top-color: #E87E04 !important;
}

.border-bottom-yellow-gold {
  border-bottom-color: #E87E04 !important;
}

.border-left-yellow-gold {
  border-left-color: #E87E04 !important;
}

.border-right-yellow-gold {
  border-right-color: #E87E04 !important;
}

.bg-yellow-casablanca {
  background: #f2784b !important;
}

.bg-hover-yellow-casablanca:hover {
  background: #f2784b !important;
}

.font-yellow-casablanca {
  color: #f2784b !important;
}

.bg-font-yellow-casablanca {
  color: #ffffff !important;
}

.border-yellow-casablanca {
  border-color: #f2784b !important;
}

.border-top-yellow-casablanca {
  border-top-color: #f2784b !important;
}

.border-bottom-yellow-casablanca {
  border-bottom-color: #f2784b !important;
}

.border-left-yellow-casablanca {
  border-left-color: #f2784b !important;
}

.border-right-yellow-casablanca {
  border-right-color: #f2784b !important;
}

.bg-yellow-crusta {
  background: #f3c200 !important;
}

.bg-hover-yellow-crusta:hover {
  background: #f3c200 !important;
}

.font-yellow-crusta {
  color: #f3c200 !important;
}

.bg-font-yellow-crusta {
  color: #ffffff !important;
}

.border-yellow-crusta {
  border-color: #f3c200 !important;
}

.border-top-yellow-crusta {
  border-top-color: #f3c200 !important;
}

.border-bottom-yellow-crusta {
  border-bottom-color: #f3c200 !important;
}

.border-left-yellow-crusta {
  border-left-color: #f3c200 !important;
}

.border-right-yellow-crusta {
  border-right-color: #f3c200 !important;
}

.bg-yellow-lemon {
  background: #F7CA18 !important;
}

.bg-hover-yellow-lemon:hover {
  background: #F7CA18 !important;
}

.font-yellow-lemon {
  color: #F7CA18 !important;
}

.bg-font-yellow-lemon {
  color: #ffffff !important;
}

.border-yellow-lemon {
  border-color: #F7CA18 !important;
}

.border-top-yellow-lemon {
  border-top-color: #F7CA18 !important;
}

.border-bottom-yellow-lemon {
  border-bottom-color: #F7CA18 !important;
}

.border-left-yellow-lemon {
  border-left-color: #F7CA18 !important;
}

.border-right-yellow-lemon {
  border-right-color: #F7CA18 !important;
}

.bg-yellow-saffron {
  background: #F4D03F !important;
}

.bg-hover-yellow-saffron:hover {
  background: #F4D03F !important;
}

.font-yellow-saffron {
  color: #F4D03F !important;
}

.bg-font-yellow-saffron {
  color: #ffffff !important;
}

.border-yellow-saffron {
  border-color: #F4D03F !important;
}

.border-top-yellow-saffron {
  border-top-color: #F4D03F !important;
}

.border-bottom-yellow-saffron {
  border-bottom-color: #F4D03F !important;
}

.border-left-yellow-saffron {
  border-left-color: #F4D03F !important;
}

.border-right-yellow-saffron {
  border-right-color: #F4D03F !important;
}

.bg-yellow-soft {
  background: #c8d046 !important;
}

.bg-hover-yellow-soft:hover {
  background: #c8d046 !important;
}

.font-yellow-soft {
  color: #c8d046 !important;
}

.bg-font-yellow-soft {
  color: #ffffff !important;
}

.border-yellow-soft {
  border-color: #c8d046 !important;
}

.border-top-yellow-soft {
  border-top-color: #c8d046 !important;
}

.border-bottom-yellow-soft {
  border-bottom-color: #c8d046 !important;
}

.border-left-yellow-soft {
  border-left-color: #c8d046 !important;
}

.border-right-yellow-soft {
  border-right-color: #c8d046 !important;
}

.bg-yellow-haze {
  background: #c5bf66 !important;
}

.bg-hover-yellow-haze:hover {
  background: #c5bf66 !important;
}

.font-yellow-haze {
  color: #c5bf66 !important;
}

.bg-font-yellow-haze {
  color: #ffffff !important;
}

.border-yellow-haze {
  border-color: #c5bf66 !important;
}

.border-top-yellow-haze {
  border-top-color: #c5bf66 !important;
}

.border-bottom-yellow-haze {
  border-bottom-color: #c5bf66 !important;
}

.border-left-yellow-haze {
  border-left-color: #c5bf66 !important;
}

.border-right-yellow-haze {
  border-right-color: #c5bf66 !important;
}

.bg-yellow-mint {
  background: #c5b96b !important;
}

.bg-hover-yellow-mint:hover {
  background: #c5b96b !important;
}

.font-yellow-mint {
  color: #c5b96b !important;
}

.bg-font-yellow-mint {
  color: #ffffff !important;
}

.border-yellow-mint {
  border-color: #c5b96b !important;
}

.border-top-yellow-mint {
  border-top-color: #c5b96b !important;
}

.border-bottom-yellow-mint {
  border-bottom-color: #c5b96b !important;
}

.border-left-yellow-mint {
  border-left-color: #c5b96b !important;
}

.border-right-yellow-mint {
  border-right-color: #c5b96b !important;
}

.bg-purple {
  background: #8E44AD !important;
}

.bg-hover-purple:hover {
  background: #8E44AD !important;
}

.font-purple {
  color: #8E44AD !important;
}

.bg-font-purple {
  color: #ffffff !important;
}

.border-purple {
  border-color: #8E44AD !important;
}

.border-top-purple {
  border-top-color: #8E44AD !important;
}

.border-bottom-purple {
  border-bottom-color: #8E44AD !important;
}

.border-left-purple {
  border-left-color: #8E44AD !important;
}

.border-right-purple {
  border-right-color: #8E44AD !important;
}

.bg-purple-plum {
  background: #8775a7 !important;
}

.bg-hover-purple-plum:hover {
  background: #8775a7 !important;
}

.font-purple-plum {
  color: #8775a7 !important;
}

.bg-font-purple-plum {
  color: #ffffff !important;
}

.border-purple-plum {
  border-color: #8775a7 !important;
}

.border-top-purple-plum {
  border-top-color: #8775a7 !important;
}

.border-bottom-purple-plum {
  border-bottom-color: #8775a7 !important;
}

.border-left-purple-plum {
  border-left-color: #8775a7 !important;
}

.border-right-purple-plum {
  border-right-color: #8775a7 !important;
}

.bg-purple-medium {
  background: #BF55EC !important;
}

.bg-hover-purple-medium:hover {
  background: #BF55EC !important;
}

.font-purple-medium {
  color: #BF55EC !important;
}

.bg-font-purple-medium {
  color: #ffffff !important;
}

.border-purple-medium {
  border-color: #BF55EC !important;
}

.border-top-purple-medium {
  border-top-color: #BF55EC !important;
}

.border-bottom-purple-medium {
  border-bottom-color: #BF55EC !important;
}

.border-left-purple-medium {
  border-left-color: #BF55EC !important;
}

.border-right-purple-medium {
  border-right-color: #BF55EC !important;
}

.bg-purple-studio {
  background: #8E44AD !important;
}

.bg-hover-purple-studio:hover {
  background: #8E44AD !important;
}

.font-purple-studio {
  color: #8E44AD !important;
}

.bg-font-purple-studio {
  color: #ffffff !important;
}

.border-purple-studio {
  border-color: #8E44AD !important;
}

.border-top-purple-studio {
  border-top-color: #8E44AD !important;
}

.border-bottom-purple-studio {
  border-bottom-color: #8E44AD !important;
}

.border-left-purple-studio {
  border-left-color: #8E44AD !important;
}

.border-right-purple-studio {
  border-right-color: #8E44AD !important;
}

.bg-purple-wisteria {
  background: #9B59B6 !important;
}

.bg-hover-purple-wisteria:hover {
  background: #9B59B6 !important;
}

.font-purple-wisteria {
  color: #9B59B6 !important;
}

.bg-font-purple-wisteria {
  color: #ffffff !important;
}

.border-purple-wisteria {
  border-color: #9B59B6 !important;
}

.border-top-purple-wisteria {
  border-top-color: #9B59B6 !important;
}

.border-bottom-purple-wisteria {
  border-bottom-color: #9B59B6 !important;
}

.border-left-purple-wisteria {
  border-left-color: #9B59B6 !important;
}

.border-right-purple-wisteria {
  border-right-color: #9B59B6 !important;
}

.bg-purple-seance {
  background: #9A12B3 !important;
}

.bg-hover-purple-seance:hover {
  background: #9A12B3 !important;
}

.font-purple-seance {
  color: #9A12B3 !important;
}

.bg-font-purple-seance {
  color: #ffffff !important;
}

.border-purple-seance {
  border-color: #9A12B3 !important;
}

.border-top-purple-seance {
  border-top-color: #9A12B3 !important;
}

.border-bottom-purple-seance {
  border-bottom-color: #9A12B3 !important;
}

.border-left-purple-seance {
  border-left-color: #9A12B3 !important;
}

.border-right-purple-seance {
  border-right-color: #9A12B3 !important;
}

.bg-purple-intense {
  background: #8775a7 !important;
}

.bg-hover-purple-intense:hover {
  background: #8775a7 !important;
}

.font-purple-intense {
  color: #8775a7 !important;
}

.bg-font-purple-intense {
  color: #ffffff !important;
}

.border-purple-intense {
  border-color: #8775a7 !important;
}

.border-top-purple-intense {
  border-top-color: #8775a7 !important;
}

.border-bottom-purple-intense {
  border-bottom-color: #8775a7 !important;
}

.border-left-purple-intense {
  border-left-color: #8775a7 !important;
}

.border-right-purple-intense {
  border-right-color: #8775a7 !important;
}

.bg-purple-sharp {
  background: #796799 !important;
}

.bg-hover-purple-sharp:hover {
  background: #796799 !important;
}

.font-purple-sharp {
  color: #796799 !important;
}

.bg-font-purple-sharp {
  color: #ffffff !important;
}

.border-purple-sharp {
  border-color: #796799 !important;
}

.border-top-purple-sharp {
  border-top-color: #796799 !important;
}

.border-bottom-purple-sharp {
  border-bottom-color: #796799 !important;
}

.border-left-purple-sharp {
  border-left-color: #796799 !important;
}

.border-right-purple-sharp {
  border-right-color: #796799 !important;
}

.bg-purple-soft {
  background: #8877a9 !important;
}

.bg-hover-purple-soft:hover {
  background: #8877a9 !important;
}

.font-purple-soft {
  color: #8877a9 !important;
}

.bg-font-purple-soft {
  color: #ffffff !important;
}

.border-purple-soft {
  border-color: #8877a9 !important;
}

.border-top-purple-soft {
  border-top-color: #8877a9 !important;
}

.border-bottom-purple-soft {
  border-bottom-color: #8877a9 !important;
}

.border-left-purple-soft {
  border-left-color: #8877a9 !important;
}

.border-right-purple-soft {
  border-right-color: #8877a9 !important;
}

/* Mixins */
/* Layout */
@media (max-width: 767px) {
  body {
    font-size: 12px;
  }
  .container {
    margin: 0;
  }
}

h1, h1.page-title {
  font-weight: 300;
  font-size: 30px;
  color: #444;
  padding: 0;
  margin: 0 0 35px;
}

h1 small, h1.page-title small {
  font-size: 24px;
  font-weight: bold;
  display: block;
  padding-top: 25px;
  color: #014e9c;
}

hr.strong {
  border-color: #333;
}

.header-link {
  float: right;
  margin: 20px;
}

.header-link a {
  font-size: 14px;
}

.icon {
  font-size: 36px;
  vertical-align: middle;
  color: #666666;
}

.icon:hover {
  color: #333333;
}

.icon.icon-flip-horizontal {
  transform: rotate(180deg);
}

a:hover {
  text-decoration: none;
}

.page-actions {
  padding: 12px 0;
}

.alert .help-block {
  margin: 0;
}

.alert .help-block .list-unstyled {
  margin: 0;
}

.btn {
  padding: 6px 30px;
  transition: all 0.3s ease-in-out;
}

.btn:not(.btn-danger):not(.btn-default):not(.btn-success) {
  background-color: #014e9c;
  color: white;
}

.btn:not(.btn-danger):not(.btn-default):not(.btn-success):hover, .btn:not(.btn-danger):not(.btn-default):not(.btn-success):focus {
  background-color: #013e7d;
  color: white;
}

.center {
  text-align: center;
}

.col-center {
  float: none;
  margin: 0 auto;
  text-align: center;
}

.fa-stack, li .fa-stack {
  width: 2em;
  height: 2em;
  color: #999999;
}

.fa-stack .fa-circle-thin, li .fa-stack .fa-circle-thin {
  display: none !important;
}

.fa-stack .fa, li .fa-stack .fa {
  margin: auto;
  width: 100%;
  line-height: 37px;
}

footer {
  background-color: #000000;
  color: gray;
  font-size: 11px;
  padding: 10px 0;
  text-align: center;
}

footer ul {
  display: inline-block;
  padding: 0;
}

footer ul li {
  display: inline-block;
}

footer ul li:not(:last-child):after {
  content: ' - ';
}

footer ul li a {
  color: gray;
}

.tooltip[role="tooltip"] {
  z-index: 9000;
}

.spaced-items {
  color: red;
}

.spaced-items > *:not(:first-child) {
  margin-top: 30px;
}

.nowrap {
  white-space: nowrap;
}

.not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
}

.flex {
  display: flex;
}

.flex--end {
  align-self: flex-end;
}

.flex--start {
  align-self: flex-start;
}

.flex--center {
  align-self: center;
}

.flex--wrap {
  flex-wrap: wrap;
}

.flex--column {
  flex-direction: column;
}

.flex--row {
  flex-direction: row;
}

.flex--content-end {
  justify-content: flex-end;
}

.flex--space-between {
  justify-content: space-between;
}

.flex--space-around {
  justify-content: space-around;
}

.flex--baseline {
  align-items: baseline;
}

.flex--vertical-align {
  flex-direction: row;
  align-items: center;
}

.flex--grow {
  flex: 1;
}

.flex--small-gap {
  gap: 20px;
}

form fieldset {
  margin-bottom: 20px;
}

form fieldset.fieldset {
  background-color: white;
  padding: 35px 30px 20px;
  border: 1px solid #dddee1;
}

form fieldset.fieldset h2 {
  font-size: 18px;
  font-weight: 400;
  color: #014e9c;
  margin: 0 0 25px;
}

form fieldset.files {
  padding: 0 30px 20px;
}

form fieldset.files .drop_uploader.drop_zone {
  padding: 40px 10px;
  background-color: white;
}

form fieldset.files .drop_uploader.drop_zone .text_wrapper {
  width: 90%;
  max-width: 320px;
  margin: 0 auto;
}

form fieldset.files .drop_uploader.drop_zone .text_wrapper .fa-cloud-upload {
  display: none;
}

form fieldset.files .drop_uploader.drop_zone .text_wrapper .text {
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  color: #444;
  line-height: 1.8em;
}

form fieldset.files .drop_uploader.drop_zone .text_wrapper .text .file_browse {
  margin-top: 20px;
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 0;
  border: 0;
}

form fieldset.files .drop_uploader.drop_zone .text_wrapper .text .file_browse i {
  margin: 0;
  transform: rotate(180deg);
}

form fieldset.files .drop_uploader.drop_zone .text_wrapper .text .file_browse:hover {
  color: #014e9c;
  background-color: transparent;
}

form fieldset.files .drop_uploader.drop_zone ul.files.thumb {
  margin: 0 30px;
}

form #contract_mapping_submit {
  float: right;
}

form #contact_submit_submit {
  display: inline-block;
  font-size: 16px;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.form-group {
  text-align: left;
}

.form-group .control-label {
  color: #333 !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.form-group .control-label.required:after {
  content: '*';
  display: inline;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
  margin: 0 0.5em;
}

.form-group .input-group-addon.tooltips {
  background-color: white;
  border-left-color: white;
}

.form-group .input-group-addon.tooltips i {
  color: #014e9c;
}

.g-recaptcha {
  margin-bottom: 20px;
}

.g-recaptcha > div {
  width: 100% !important;
}

div.radio, div.checkbox {
  display: inline-block;
  margin-right: 15px;
}

div.radio label, div.checkbox label {
  padding-left: 30px;
  cursor: pointer;
  position: relative;
}

div.radio label:before, div.radio label:after, div.checkbox label:before, div.checkbox label:after {
  content: '';
  position: absolute;
  top: 50%;
  z-index: 10;
}

div.radio label:before, div.checkbox label:before {
  left: 0;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border: 1px solid #777;
  background: transparent;
}

div.radio label:after, div.checkbox label:after {
  left: 0;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  background-image: radial-gradient(white 30%, #014e9c 50%, #013e7d 100%);
  opacity: 0;
  transform: scale(0);
  transition: all .3s ease-in-out;
}

div.radio input, div.checkbox input {
  position: absolute;
  left: -9999px;
}

div.radio input:checked + label:after, div.checkbox input:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

div.checkbox label:after {
  background: #013e7d;
  box-shadow: inset 0 0 1px 2px #fff;
  border: 1px solid #777;
}

div.checkbox input:disabled + label:after {
  background: #c4c4c4;
}

div.radio label:before, div.radio label:after {
  border-radius: 50%;
}

.input-group.form_date .input-group-btn .btn.default:not(.btn-outline), .input-group.form_datetime .input-group-btn .btn.default:not(.btn-outline) {
  border-left: 0;
  border-color: #c2cad8;
  background-color: white;
  padding: 0 10px;
  line-height: 32px;
}

.input-group.form_date .input-group-btn .btn.default:not(.btn-outline).date-set, .input-group.form_datetime .input-group-btn .btn.default:not(.btn-outline).date-set {
  background-color: #014e9c;
}

.input-group.form_date .input-group-btn .btn.default:not(.btn-outline).date-set .icon-calendar, .input-group.form_datetime .input-group-btn .btn.default:not(.btn-outline).date-set .icon-calendar {
  margin-right: 0;
}

.input-group .has-success.form_date .input-group-btn .btn.default:not(.btn-outline), .input-group .has-success.form_datetime .input-group-btn .btn.default:not(.btn-outline) {
  border-color: #27a4b0;
}

.input-group .has-error.form_date .input-group-btn .btn.default:not(.btn-outline), .input-group .has-error.form_datetime .input-group-btn .btn.default:not(.btn-outline) {
  border-color: #e73d4a;
}

.input-group-addon + .help-block {
  position: absolute;
  left: 0;
  transform: translateY(100%);
}

label.button-like {
  padding: 0.2em 0.5em;
  background: #eee;
  border: 1px solid #222;
  border-radius: 2px !important;
  cursor: pointer;
}

label.button-like:hover {
  background: #ddd;
}

input[type="radio"][disabled="disabled"] + label {
  opacity: 0.7;
}

header {
  display: none;
  z-index: 999;
}

header .header-content {
  padding: 0;
}

body.minimalist header {
  padding: 0;
  height: auto;
}

body.minimalist header .logo-link img {
  max-height: 140px;
}

body.headerCenter header .logo {
  margin: 0 auto;
  float: none;
}

@media (max-width: 991px) {
  body.headerCenter header img {
    height: 70px;
  }
}

@media (max-width: 991px) {
  header {
    position: fixed;
    top: 0;
    background: #000000;
    width: 100%;
    height: 80px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
  }
  header .page-logo {
    height: 100%;
  }
  header .page-logo img {
    height: 100%;
  }
  header .logo .page-logo {
    height: 140px;
  }
  header .logo .page-logo img {
    height: 100%;
  }
  .page-container {
    padding-top: 80px !important;
  }
}

.page-sidebar-wrapper {
  position: fixed;
  top: 0px;
  bottom: 0;
  overflow: auto;
}

.page-sidebar {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  z-index: 2;
}

@media all and (-ms-high-contrast: none) {
  .page-sidebar {
    height: 100%;
  }
}

.page-sidebar .page-logo {
  padding: 2em;
  text-align: center;
}

.page-sidebar .page-logo img {
  width: 100%;
}

.page-sidebar .user-identity-box {
  padding: 10px 15px;
}

.page-sidebar .user-identity-box .user-identity-content {
  font-weight: bold;
}

.page-sidebar .user-identity-box .user-identity-content a {
  color: white;
}

.page-sidebar .user-identity-box .user-identity-content a:hover {
  color: #014e9c;
}

.page-sidebar .user-identity-box span {
  color: white;
}

.page-sidebar .sidebar-footer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}

.page-sidebar .sidebar-footer .page-sidebar-menu {
  flex: 1;
}

@media (max-width: 991px) {
  /* 991px */
  .page-sidebar-wrapper {
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 80px;
    overflow: auto;
    bottom: 100%;
  }
  .page-sidebar-wrapper.open, .page-sidebar-wrapper.closing {
    transition: 0.4s bottom;
    bottom: 0;
  }
  .page-sidebar-wrapper.closing {
    bottom: 100%;
  }
  .page-sidebar {
    margin: 0 !important;
  }
  .page-sidebar .page-logo {
    display: none;
  }
}

/* Plugins */
.full .pace .pace-progress {
  z-index: 10005;
  top: 0px;
  height: 4px;
}

html body .edit-cookies-preferences {
  cursor: pointer;
}

html body .edit-cookies-preferences:hover {
  text-decoration: underline;
}

html body .tac_activate {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

html body .tac_activate .tac_float .tarteaucitronAllow {
  margin: 5px;
}

html body .g-recaptcha .tac_activate {
  width: auto;
  margin-top: 10px;
}

html body #tarteaucitronRoot #tarteaucitronPercentage {
  display: none !important;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig {
  width: 300px;
  padding: 25px;
  right: 2vw;
  bottom: 20vh;
  left: auto;
  border: 1px solid #014e9c;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5) !important;
  transform: none;
  top: auto;
  max-height: calc(85vh - (25px * 2));
  overflow: auto;
}

@media (max-width: 768px) {
  html body #tarteaucitronRoot div#tarteaucitronAlertBig {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig:before {
  font-size: 2.7rem;
  font-weight: bold;
  text-transform: uppercase;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig, html body #tarteaucitronRoot div#tarteaucitronAlertBig #tarteaucitronPrivacyUrl, html body #tarteaucitronRoot div#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog, html body #tarteaucitronRoot div#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert, html body #tarteaucitronRoot div#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  color: #000;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
  font-size: 1.7rem;
  line-height: 1.5;
  font-weight: 300;
  padding: 0;
  margin: 0 0 25px;
  white-space: pre-line;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert a {
  color: #000;
  text-decoration: underline;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig button + button, html body #tarteaucitronRoot div#tarteaucitronAlertBig br + button {
  margin-top: 10px;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig button {
  text-transform: uppercase;
  font-size: 1.3rem !important;
  padding: 10px;
  display: block;
  width: calc(100% - 25px);
  margin-left: 0;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 600;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig button span:before {
  color: #000;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig button:hover span:before {
  color: #fff;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig button.tarteaucitronAllow:hover {
  color: #fff;
  background-color: #3cc051;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig button.tarteaucitronDeny:hover {
  background-color: #ed4e2a;
  color: #fff;
  font-weight: 700;
}

html body #tarteaucitronRoot div#tarteaucitronAlertBig br {
  display: none;
}

html body #tarteaucitronRoot .tarteaucitronH1, html body #tarteaucitronRoot .tarteaucitronH2 {
  color: #000;
  font-size: 1.6rem !important;
  font-weight: bold;
  margin: 0;
}

html body #tarteaucitronRoot .tarteaucitronH1 {
  text-transform: uppercase;
  margin-right: auto;
}

html body #tarteaucitronRoot .tarteaucitronH3 {
  font-size: 1.3rem !important;
  font-weight: 500;
}

html body #tarteaucitronRoot #tarteaucitron {
  max-width: 680px;
}

@media (max-width: 992px) {
  html body #tarteaucitronRoot #tarteaucitron {
    margin: auto !important;
  }
}

@media (max-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron {
    width: 90vw !important;
    background: none;
  }
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServicesTitle_mandatory .tarteaucitronName {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServicesTitle_mandatory .tarteaucitronName .tarteaucitronH3 {
  font-size: 1.4rem !important;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServicesTitle_mandatory .tarteaucitronName .tarteaucitronH3 a {
  text-decoration: underline;
  font-size: 1.4rem;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServicesTitle_mandatory button {
  display: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel {
  background: transparent;
  color: #000;
  top: 34px;
  right: 22px;
  width: auto;
  padding-right: 30px;
  font-weight: 300;
  font-size: 1.4rem;
  height: 20px;
  outline: none;
  z-index: 2;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:before, html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:after {
  content: "";
  width: 18px;
  height: 20px;
  border-left: 1px solid #000;
  display: block;
  margin-left: 5px;
  position: absolute;
  right: 0;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:before {
  transform: rotate(-45deg);
  top: -1px;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:after {
  transform: rotate(45deg);
  top: 12px;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:hover {
  color: #014e9c;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:hover:before, html body #tarteaucitronRoot #tarteaucitron #tarteaucitronClosePanel:hover:after {
  border-color: #014e9c;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 14px 0 #bec1c4;
  padding: 40px 25px;
  height: auto !important;
  max-height: calc(85vh - (25px * 2));
  overflow: auto;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine, html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronMainLine {
  background: none;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronMainLine .tarteaucitronName {
    flex-basis: 100%;
    text-align: center;
  }
  html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronMainLine .tarteaucitronAsk {
    margin-left: 0 !important;
  }
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border: none;
  background: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: flex;
  margin-left: 16px;
  width: auto;
  max-width: 280px;
  min-width: 280px;
  text-align: left;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  display: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName > * {
  line-height: 1.4 !important;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  margin: 0 0 auto auto !important;
  float: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
  width: 125px;
  border: 1px solid #bec1c4;
  font-size: 1.3rem !important;
  padding: 10px;
  border-radius: 0;
  opacity: 1 !important;
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 600;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button span:before {
  color: #000;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button:hover {
  background-color: #777;
  color: #fff;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button:hover span:before {
  color: #fff;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button.tarteaucitronAllow:hover {
  background-color: #3cc051;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button.tarteaucitronDeny:hover {
  background-color: #ed4e2a;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk p {
  margin-left: 17px;
  font-size: 1.7rem;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk a {
  font-size: 1.6rem;
  text-decoration: underline;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed button.tarteaucitronAllow {
  background-color: #3cc051;
  color: #fff;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed button.tarteaucitronAllow span:before {
  color: #fff;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied button.tarteaucitronDeny {
  background-color: #ed4e2a;
  color: #fff;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied button.tarteaucitronDeny span:before {
  color: #fff;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder {
  border: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder, html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronSelfLink, html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronHidden {
  background: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronName {
  margin-right: 10px;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronTitle {
  margin-bottom: 10px;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronTitle button {
  background: none;
  color: #000;
  padding: 0;
  margin: 0;
  outline: 0;
  cursor: pointer;
  display: inline-block;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronTitle button .tarteaucitronPlus {
  display: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronSelfLink {
  text-transform: none;
  color: #000;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: left !important;
  font-weight: 600;
  padding: 0 !important;
  text-indent: -18px;
  overflow: hidden;
  margin-top: 10px;
  pointer-events: none;
  position: inherit;
  display: none;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder > ul > li {
  margin-top: 21px;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronDetails {
  background: none;
  color: #000;
  line-height: 1.4;
  padding: 0 0 25px;
  height: 100%;
  font-size: 1.4rem;
  position: static;
  max-width: none;
  display: block !important;
}

html body #tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronBorder .tarteaucitronDetails a {
  font-size: 1.4rem;
  text-decoration: underline;
}

html body #tarteaucitronRoot button#tarteaucitronBack {
  opacity: .2;
}

html body #tarteaucitronRoot #tarteaucitronInfo {
  margin: 25px 0 !important;
  padding: 0 !important;
  max-width: none !important;
  background: none;
  border: 0;
  color: #000;
  font-size: 1.6rem !important;
  font-weight: 300;
  line-height: 1.4;
  text-align: left !important;
}

html body #tarteaucitronRoot #tarteaucitronInfo a {
  color: #000;
  text-decoration: underline;
  font-size: 1.6rem;
}

html body:not(.modal-open) #tarteaucitronRoot.tarteaucitronBeforeVisible:before {
  display: none;
}

html body.modal-open #tarteaucitronRoot div#tarteaucitronAlertBig {
  display: none !important;
}

.grid-search .filter-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.grid-search .filter-inputs .filter-input {
  flex-basis: 50%;
  flex-grow: 1;
  padding: 4px 15px;
}

.grid-search .filter-inputs .filter-input .grid-filter-input {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.grid-search .filter-inputs .filter-input .grid-filter-input > * {
  flex: 1 1 auto;
  padding: 0 15px;
}

.grid-search .filter-inputs .filter-input .grid-filter-input > *.grid-filter-operator {
  flex-basis: 30%;
}

.grid-search .grid-search-action {
  text-align: right;
}

.grid-search .form_datetime {
  width: 100%;
}

.grid-search .form_datetime .input-group-btn {
  width: auto;
}

.grid-search .form_datetime .input-group-btn button {
  width: 100%;
  padding: 6px;
}

.grid-search .form_datetime .input-icon.right > .form-control {
  padding-right: 0;
}

.grid .grid_header {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid .grid_header .mass-actions {
  flex-grow: 1;
}

.grid .grid_header .exports {
  margin-left: 10px;
}

.grid .mass-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -8px;
}

.grid .mass-actions > * {
  flex: 1 1 auto;
  padding-bottom: 8px;
}

.grid .mass-actions .total {
  color: #014e9c;
  display: inline-block;
  margin-right: 6px;
}

.grid .mass-actions .grid_massactions {
  display: flex;
  justify-content: flex-end;
}

.grid .mass-actions .grid_massactions select {
  width: auto;
}

.grid .mass-actions .grid_massactions .btn {
  margin-left: 10px;
  flex-shrink: 0;
}

.grid .align-center {
  text-align: center;
}

.grid .grid_body tr.grid-row-filters {
  display: none;
}

.grid .grid_body th.sortable {
  background-image: url(../images/sort_both.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 30px;
}

.grid .grid_body th.sortable.asc {
  background-image: url(../images/sort_asc.png);
}

.grid .grid_body th.sortable.desc {
  background-image: url(../images/sort_desc.png);
}

.grid .grid_body .grid-row-actions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.grid .grid_body [class^=grid_boolean] {
  display: inline-block;
  min-width: 40px;
}

.grid .grid_boolean_Oui {
  background-color: #35AA47;
}

.grid .grid_boolean_Non {
  background-color: #014e9c;
}

.grid_footer {
  margin-top: 20px;
}

.grid_footer .dataTables_paginate {
  text-align: right;
}

.grid_footer .dataTables_paginate .pagination {
  margin: 0;
}

@media (max-width: 480px) {
  .grid-search .filter-inputs {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .grid-search .grid-search-action input {
    display: block;
    width: 100%;
    margin: 8px 0;
  }
  .grid .grid_header .mass-actions .grid_massactions_helper {
    width: 100%;
  }
  .grid .grid_header .mass-actions .grid_massactions {
    width: 100%;
    flex-direction: column;
  }
  .grid .grid_header .mass-actions .grid_massactions .btn {
    margin-left: 0;
    margin-top: 8px;
  }
}

.progress {
  height: 4px;
}

.dataTables_info {
  display: none;
}

.dataTables_wrapper {
  position: static;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

table.dataTable tr.rel {
  position: relative;
}

table.dataTable tr.rel td > span.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.portlet.bordered {
  position: relative;
  padding-left: 25px !important;
}

.portlet.bordered:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.portlet.bordered.portlet-white:before {
  background: #ffffff !important;
}

.portlet.bordered.portlet-white .portlet-title .caption-subject {
  color: #ffffff !important;
}

.portlet.bordered.portlet-white.portlet-title {
  color: #ffffff !important;
}

.portlet.bordered.portlet-default:before {
  background: #014e9c !important;
}

.portlet.bordered.portlet-default .portlet-title .caption-subject {
  color: #014e9c !important;
}

.portlet.bordered.portlet-default.portlet-title {
  color: #014e9c !important;
}

.portlet.bordered.portlet-dark:before {
  background: #666666 !important;
}

.portlet.bordered.portlet-dark .portlet-title .caption-subject {
  color: #666666 !important;
}

.portlet.bordered.portlet-dark.portlet-title {
  color: #666666 !important;
}

.portlet.bordered.portlet-blue-1:before {
  background: #013e7d !important;
}

.portlet.bordered.portlet-blue-1 .portlet-title .caption-subject {
  color: #013e7d !important;
}

.portlet.bordered.portlet-blue-1.portlet-title {
  color: #013e7d !important;
}

.portlet.bordered.portlet-blue-2:before {
  background: #0554a3 !important;
}

.portlet.bordered.portlet-blue-2 .portlet-title .caption-subject {
  color: #0554a3 !important;
}

.portlet.bordered.portlet-blue-2.portlet-title {
  color: #0554a3 !important;
}

.portlet.bordered.portlet-blue:before {
  background: #3598dc !important;
}

.portlet.bordered.portlet-blue .portlet-title .caption-subject {
  color: #3598dc !important;
}

.portlet.bordered.portlet-blue.portlet-title {
  color: #3598dc !important;
}

.portlet.bordered.portlet-blue-madison:before {
  background: #578ebe !important;
}

.portlet.bordered.portlet-blue-madison .portlet-title .caption-subject {
  color: #578ebe !important;
}

.portlet.bordered.portlet-blue-madison.portlet-title {
  color: #578ebe !important;
}

.portlet.bordered.portlet-blue-chambray:before {
  background: #2C3E50 !important;
}

.portlet.bordered.portlet-blue-chambray .portlet-title .caption-subject {
  color: #2C3E50 !important;
}

.portlet.bordered.portlet-blue-chambray.portlet-title {
  color: #2C3E50 !important;
}

.portlet.bordered.portlet-blue-ebonyclay:before {
  background: #22313F !important;
}

.portlet.bordered.portlet-blue-ebonyclay .portlet-title .caption-subject {
  color: #22313F !important;
}

.portlet.bordered.portlet-blue-ebonyclay.portlet-title {
  color: #22313F !important;
}

.portlet.bordered.portlet-blue-hoki:before {
  background: #67809F !important;
}

.portlet.bordered.portlet-blue-hoki .portlet-title .caption-subject {
  color: #67809F !important;
}

.portlet.bordered.portlet-blue-hoki.portlet-title {
  color: #67809F !important;
}

.portlet.bordered.portlet-blue-steel:before {
  background: #4B77BE !important;
}

.portlet.bordered.portlet-blue-steel .portlet-title .caption-subject {
  color: #4B77BE !important;
}

.portlet.bordered.portlet-blue-steel.portlet-title {
  color: #4B77BE !important;
}

.portlet.bordered.portlet-blue-soft:before {
  background: #4c87b9 !important;
}

.portlet.bordered.portlet-blue-soft .portlet-title .caption-subject {
  color: #4c87b9 !important;
}

.portlet.bordered.portlet-blue-soft.portlet-title {
  color: #4c87b9 !important;
}

.portlet.bordered.portlet-blue-dark:before {
  background: #5e738b !important;
}

.portlet.bordered.portlet-blue-dark .portlet-title .caption-subject {
  color: #5e738b !important;
}

.portlet.bordered.portlet-blue-dark.portlet-title {
  color: #5e738b !important;
}

.portlet.bordered.portlet-blue-sharp:before {
  background: #5C9BD1 !important;
}

.portlet.bordered.portlet-blue-sharp .portlet-title .caption-subject {
  color: #5C9BD1 !important;
}

.portlet.bordered.portlet-blue-sharp.portlet-title {
  color: #5C9BD1 !important;
}

.portlet.bordered.portlet-green:before {
  background: #32c5d2 !important;
}

.portlet.bordered.portlet-green .portlet-title .caption-subject {
  color: #32c5d2 !important;
}

.portlet.bordered.portlet-green.portlet-title {
  color: #32c5d2 !important;
}

.portlet.bordered.portlet-green-meadow:before {
  background: #1BBC9B !important;
}

.portlet.bordered.portlet-green-meadow .portlet-title .caption-subject {
  color: #1BBC9B !important;
}

.portlet.bordered.portlet-green-meadow.portlet-title {
  color: #1BBC9B !important;
}

.portlet.bordered.portlet-green-seagreen:before {
  background: #1BA39C !important;
}

.portlet.bordered.portlet-green-seagreen .portlet-title .caption-subject {
  color: #1BA39C !important;
}

.portlet.bordered.portlet-green-seagreen.portlet-title {
  color: #1BA39C !important;
}

.portlet.bordered.portlet-green-turquoise:before {
  background: #36D7B7 !important;
}

.portlet.bordered.portlet-green-turquoise .portlet-title .caption-subject {
  color: #36D7B7 !important;
}

.portlet.bordered.portlet-green-turquoise.portlet-title {
  color: #36D7B7 !important;
}

.portlet.bordered.portlet-green-haze:before {
  background: #44b6ae !important;
}

.portlet.bordered.portlet-green-haze .portlet-title .caption-subject {
  color: #44b6ae !important;
}

.portlet.bordered.portlet-green-haze.portlet-title {
  color: #44b6ae !important;
}

.portlet.bordered.portlet-green-jungle:before {
  background: #26C281 !important;
}

.portlet.bordered.portlet-green-jungle .portlet-title .caption-subject {
  color: #26C281 !important;
}

.portlet.bordered.portlet-green-jungle.portlet-title {
  color: #26C281 !important;
}

.portlet.bordered.portlet-green-soft:before {
  background: #3faba4 !important;
}

.portlet.bordered.portlet-green-soft .portlet-title .caption-subject {
  color: #3faba4 !important;
}

.portlet.bordered.portlet-green-soft.portlet-title {
  color: #3faba4 !important;
}

.portlet.bordered.portlet-green-dark:before {
  background: #4DB3A2 !important;
}

.portlet.bordered.portlet-green-dark .portlet-title .caption-subject {
  color: #4DB3A2 !important;
}

.portlet.bordered.portlet-green-dark.portlet-title {
  color: #4DB3A2 !important;
}

.portlet.bordered.portlet-green-sharp:before {
  background: #2ab4c0 !important;
}

.portlet.bordered.portlet-green-sharp .portlet-title .caption-subject {
  color: #2ab4c0 !important;
}

.portlet.bordered.portlet-green-sharp.portlet-title {
  color: #2ab4c0 !important;
}

.portlet.bordered.portlet-grey:before {
  background: #E5E5E5 !important;
}

.portlet.bordered.portlet-grey .portlet-title .caption-subject {
  color: #E5E5E5 !important;
}

.portlet.bordered.portlet-grey.portlet-title {
  color: #E5E5E5 !important;
}

.portlet.bordered.portlet-grey-steel:before {
  background: #e9edef !important;
}

.portlet.bordered.portlet-grey-steel .portlet-title .caption-subject {
  color: #e9edef !important;
}

.portlet.bordered.portlet-grey-steel.portlet-title {
  color: #e9edef !important;
}

.portlet.bordered.portlet-grey-cararra:before {
  background: #fafafa !important;
}

.portlet.bordered.portlet-grey-cararra .portlet-title .caption-subject {
  color: #fafafa !important;
}

.portlet.bordered.portlet-grey-cararra.portlet-title {
  color: #fafafa !important;
}

.portlet.bordered.portlet-grey-gallery:before {
  background: #555555 !important;
}

.portlet.bordered.portlet-grey-gallery .portlet-title .caption-subject {
  color: #555555 !important;
}

.portlet.bordered.portlet-grey-gallery.portlet-title {
  color: #555555 !important;
}

.portlet.bordered.portlet-grey-cascade:before {
  background: #95A5A6 !important;
}

.portlet.bordered.portlet-grey-cascade .portlet-title .caption-subject {
  color: #95A5A6 !important;
}

.portlet.bordered.portlet-grey-cascade.portlet-title {
  color: #95A5A6 !important;
}

.portlet.bordered.portlet-grey-silver:before {
  background: #BFBFBF !important;
}

.portlet.bordered.portlet-grey-silver .portlet-title .caption-subject {
  color: #BFBFBF !important;
}

.portlet.bordered.portlet-grey-silver.portlet-title {
  color: #BFBFBF !important;
}

.portlet.bordered.portlet-grey-salsa:before {
  background: #ACB5C3 !important;
}

.portlet.bordered.portlet-grey-salsa .portlet-title .caption-subject {
  color: #ACB5C3 !important;
}

.portlet.bordered.portlet-grey-salsa.portlet-title {
  color: #ACB5C3 !important;
}

.portlet.bordered.portlet-grey-salt:before {
  background: #bfcad1 !important;
}

.portlet.bordered.portlet-grey-salt .portlet-title .caption-subject {
  color: #bfcad1 !important;
}

.portlet.bordered.portlet-grey-salt.portlet-title {
  color: #bfcad1 !important;
}

.portlet.bordered.portlet-grey-mint:before {
  background: #525e64 !important;
}

.portlet.bordered.portlet-grey-mint .portlet-title .caption-subject {
  color: #525e64 !important;
}

.portlet.bordered.portlet-grey-mint.portlet-title {
  color: #525e64 !important;
}

.portlet.bordered.portlet-red:before {
  background: #e7505a !important;
}

.portlet.bordered.portlet-red .portlet-title .caption-subject {
  color: #e7505a !important;
}

.portlet.bordered.portlet-red.portlet-title {
  color: #e7505a !important;
}

.portlet.bordered.portlet-red-pink:before {
  background: #E08283 !important;
}

.portlet.bordered.portlet-red-pink .portlet-title .caption-subject {
  color: #E08283 !important;
}

.portlet.bordered.portlet-red-pink.portlet-title {
  color: #E08283 !important;
}

.portlet.bordered.portlet-red-sunglo:before {
  background: #E26A6A !important;
}

.portlet.bordered.portlet-red-sunglo .portlet-title .caption-subject {
  color: #E26A6A !important;
}

.portlet.bordered.portlet-red-sunglo.portlet-title {
  color: #E26A6A !important;
}

.portlet.bordered.portlet-red-intense:before {
  background: #e35b5a !important;
}

.portlet.bordered.portlet-red-intense .portlet-title .caption-subject {
  color: #e35b5a !important;
}

.portlet.bordered.portlet-red-intense.portlet-title {
  color: #e35b5a !important;
}

.portlet.bordered.portlet-red-thunderbird:before {
  background: #D91E18 !important;
}

.portlet.bordered.portlet-red-thunderbird .portlet-title .caption-subject {
  color: #D91E18 !important;
}

.portlet.bordered.portlet-red-thunderbird.portlet-title {
  color: #D91E18 !important;
}

.portlet.bordered.portlet-red-flamingo:before {
  background: #EF4836 !important;
}

.portlet.bordered.portlet-red-flamingo .portlet-title .caption-subject {
  color: #EF4836 !important;
}

.portlet.bordered.portlet-red-flamingo.portlet-title {
  color: #EF4836 !important;
}

.portlet.bordered.portlet-red-soft:before {
  background: #d05454 !important;
}

.portlet.bordered.portlet-red-soft .portlet-title .caption-subject {
  color: #d05454 !important;
}

.portlet.bordered.portlet-red-soft.portlet-title {
  color: #d05454 !important;
}

.portlet.bordered.portlet-red-haze:before {
  background: #f36a5a !important;
}

.portlet.bordered.portlet-red-haze .portlet-title .caption-subject {
  color: #f36a5a !important;
}

.portlet.bordered.portlet-red-haze.portlet-title {
  color: #f36a5a !important;
}

.portlet.bordered.portlet-red-mint:before {
  background: #e43a45 !important;
}

.portlet.bordered.portlet-red-mint .portlet-title .caption-subject {
  color: #e43a45 !important;
}

.portlet.bordered.portlet-red-mint.portlet-title {
  color: #e43a45 !important;
}

.portlet.bordered.portlet-yellow:before {
  background: #c49f47 !important;
}

.portlet.bordered.portlet-yellow .portlet-title .caption-subject {
  color: #c49f47 !important;
}

.portlet.bordered.portlet-yellow.portlet-title {
  color: #c49f47 !important;
}

.portlet.bordered.portlet-yellow-gold:before {
  background: #E87E04 !important;
}

.portlet.bordered.portlet-yellow-gold .portlet-title .caption-subject {
  color: #E87E04 !important;
}

.portlet.bordered.portlet-yellow-gold.portlet-title {
  color: #E87E04 !important;
}

.portlet.bordered.portlet-yellow-casablanca:before {
  background: #f2784b !important;
}

.portlet.bordered.portlet-yellow-casablanca .portlet-title .caption-subject {
  color: #f2784b !important;
}

.portlet.bordered.portlet-yellow-casablanca.portlet-title {
  color: #f2784b !important;
}

.portlet.bordered.portlet-yellow-crusta:before {
  background: #f3c200 !important;
}

.portlet.bordered.portlet-yellow-crusta .portlet-title .caption-subject {
  color: #f3c200 !important;
}

.portlet.bordered.portlet-yellow-crusta.portlet-title {
  color: #f3c200 !important;
}

.portlet.bordered.portlet-yellow-lemon:before {
  background: #F7CA18 !important;
}

.portlet.bordered.portlet-yellow-lemon .portlet-title .caption-subject {
  color: #F7CA18 !important;
}

.portlet.bordered.portlet-yellow-lemon.portlet-title {
  color: #F7CA18 !important;
}

.portlet.bordered.portlet-yellow-saffron:before {
  background: #F4D03F !important;
}

.portlet.bordered.portlet-yellow-saffron .portlet-title .caption-subject {
  color: #F4D03F !important;
}

.portlet.bordered.portlet-yellow-saffron.portlet-title {
  color: #F4D03F !important;
}

.portlet.bordered.portlet-yellow-soft:before {
  background: #c8d046 !important;
}

.portlet.bordered.portlet-yellow-soft .portlet-title .caption-subject {
  color: #c8d046 !important;
}

.portlet.bordered.portlet-yellow-soft.portlet-title {
  color: #c8d046 !important;
}

.portlet.bordered.portlet-yellow-haze:before {
  background: #c5bf66 !important;
}

.portlet.bordered.portlet-yellow-haze .portlet-title .caption-subject {
  color: #c5bf66 !important;
}

.portlet.bordered.portlet-yellow-haze.portlet-title {
  color: #c5bf66 !important;
}

.portlet.bordered.portlet-yellow-mint:before {
  background: #c5b96b !important;
}

.portlet.bordered.portlet-yellow-mint .portlet-title .caption-subject {
  color: #c5b96b !important;
}

.portlet.bordered.portlet-yellow-mint.portlet-title {
  color: #c5b96b !important;
}

.portlet.bordered.portlet-purple:before {
  background: #8E44AD !important;
}

.portlet.bordered.portlet-purple .portlet-title .caption-subject {
  color: #8E44AD !important;
}

.portlet.bordered.portlet-purple.portlet-title {
  color: #8E44AD !important;
}

.portlet.bordered.portlet-purple-plum:before {
  background: #8775a7 !important;
}

.portlet.bordered.portlet-purple-plum .portlet-title .caption-subject {
  color: #8775a7 !important;
}

.portlet.bordered.portlet-purple-plum.portlet-title {
  color: #8775a7 !important;
}

.portlet.bordered.portlet-purple-medium:before {
  background: #BF55EC !important;
}

.portlet.bordered.portlet-purple-medium .portlet-title .caption-subject {
  color: #BF55EC !important;
}

.portlet.bordered.portlet-purple-medium.portlet-title {
  color: #BF55EC !important;
}

.portlet.bordered.portlet-purple-studio:before {
  background: #8E44AD !important;
}

.portlet.bordered.portlet-purple-studio .portlet-title .caption-subject {
  color: #8E44AD !important;
}

.portlet.bordered.portlet-purple-studio.portlet-title {
  color: #8E44AD !important;
}

.portlet.bordered.portlet-purple-wisteria:before {
  background: #9B59B6 !important;
}

.portlet.bordered.portlet-purple-wisteria .portlet-title .caption-subject {
  color: #9B59B6 !important;
}

.portlet.bordered.portlet-purple-wisteria.portlet-title {
  color: #9B59B6 !important;
}

.portlet.bordered.portlet-purple-seance:before {
  background: #9A12B3 !important;
}

.portlet.bordered.portlet-purple-seance .portlet-title .caption-subject {
  color: #9A12B3 !important;
}

.portlet.bordered.portlet-purple-seance.portlet-title {
  color: #9A12B3 !important;
}

.portlet.bordered.portlet-purple-intense:before {
  background: #8775a7 !important;
}

.portlet.bordered.portlet-purple-intense .portlet-title .caption-subject {
  color: #8775a7 !important;
}

.portlet.bordered.portlet-purple-intense.portlet-title {
  color: #8775a7 !important;
}

.portlet.bordered.portlet-purple-sharp:before {
  background: #796799 !important;
}

.portlet.bordered.portlet-purple-sharp .portlet-title .caption-subject {
  color: #796799 !important;
}

.portlet.bordered.portlet-purple-sharp.portlet-title {
  color: #796799 !important;
}

.portlet.bordered.portlet-purple-soft:before {
  background: #8877a9 !important;
}

.portlet.bordered.portlet-purple-soft .portlet-title .caption-subject {
  color: #8877a9 !important;
}

.portlet.bordered.portlet-purple-soft.portlet-title {
  color: #8877a9 !important;
}

.portlet.bordered .portlet-title {
  margin-bottom: 0;
}

.portlet.bordered .portlet-title.not-underlined {
  border: none !important;
  min-height: 0;
}

.portlet.light {
  padding: 12px 20px 20px 20px;
}

.portlet.light > .portlet-title > .actions {
  padding: 10px 0 0 0;
}

.portlet.portlet-title {
  padding: 12px 24px !important;
  font-size: 1.29em;
  margin-bottom: 15px;
}

.portlet.solid {
  padding: 0 30px 10px 30px;
}

/*
 * Row with equal height columns
 * --------------------------------------------------
 */
[class*="row-eq-height"] {
  flex-wrap: wrap;
}

[class*="row-eq-height"].height-center {
  align-items: center;
}

[class*="row-eq-height"] [class*="col-"] {
  display: flex;
  flex-direction: column;
  flex: 1;
}

[class*="row-eq-height"] [class*="col-"] > div {
  flex-grow: 1;
}

.row-eq-height {
  display: flex;
}

@media (min-width: 480px) {
  /* 991px */
  .row-eq-height-xs {
    display: flex;
  }
}

@media (min-width: 768px) {
  /* 991px */
  .row-eq-height-sm {
    display: flex;
  }
}

@media (min-width: 992px) {
  /* 991px */
  .row-eq-height-md {
    display: flex;
  }
}

.stat {
  font-size: 1em;
  margin-bottom: 30px;
}

.stat.stat-white {
  background-color: #ffffff !important;
  color: #666;
}

.stat.stat-white .more {
  background-color: #e6e6e6 !important;
}

.stat.stat-default {
  background-color: #014e9c !important;
  color: #ffffff;
}

.stat.stat-default .more {
  background-color: #013569 !important;
}

.stat.stat-dark {
  background-color: #666666 !important;
  color: #FFFFFF;
}

.stat.stat-dark .more {
  background-color: #4d4d4d !important;
}

.stat.stat-blue-1 {
  background-color: #013e7d !important;
  color: #FFFFFF;
}

.stat.stat-blue-1 .more {
  background-color: #01254a !important;
}

.stat.stat-blue-2 {
  background-color: #0554a3 !important;
  color: #FFFFFF;
}

.stat.stat-blue-2 .more {
  background-color: #033b72 !important;
}

.stat.stat-blue {
  background-color: #3598dc !important;
  color: #FFFFFF;
}

.stat.stat-blue .more {
  background-color: #217ebd !important;
}

.stat.stat-blue-madison {
  background-color: #578ebe !important;
  color: #FFFFFF;
}

.stat.stat-blue-madison .more {
  background-color: #3f74a3 !important;
}

.stat.stat-blue-chambray {
  background-color: #2C3E50 !important;
  color: #FFFFFF;
}

.stat.stat-blue-chambray .more {
  background-color: #1a252f !important;
}

.stat.stat-blue-ebonyclay {
  background-color: #22313F !important;
  color: #FFFFFF;
}

.stat.stat-blue-ebonyclay .more {
  background-color: #10171e !important;
}

.stat.stat-blue-hoki {
  background-color: #67809F !important;
  color: #FFFFFF;
}

.stat.stat-blue-hoki .more {
  background-color: #526781 !important;
}

.stat.stat-blue-steel {
  background-color: #4B77BE !important;
  color: #FFFFFF;
}

.stat.stat-blue-steel .more {
  background-color: #395f9d !important;
}

.stat.stat-blue-soft {
  background-color: #4c87b9 !important;
  color: #FFFFFF;
}

.stat.stat-blue-soft .more {
  background-color: #3b6d97 !important;
}

.stat.stat-blue-dark {
  background-color: #5e738b !important;
  color: #FFFFFF;
}

.stat.stat-blue-dark .more {
  background-color: #495a6d !important;
}

.stat.stat-blue-sharp {
  background-color: #5C9BD1 !important;
  color: #FFFFFF;
}

.stat.stat-blue-sharp .more {
  background-color: #3782c3 !important;
}

.stat.stat-green {
  background-color: #32c5d2 !important;
  color: #FFFFFF;
}

.stat.stat-green .more {
  background-color: #26a1ab !important;
}

.stat.stat-green-meadow {
  background-color: #1BBC9B !important;
  color: #FFFFFF;
}

.stat.stat-green-meadow .more {
  background-color: #158f76 !important;
}

.stat.stat-green-seagreen {
  background-color: #1BA39C !important;
  color: #FFFFFF;
}

.stat.stat-green-seagreen .more {
  background-color: #147772 !important;
}

.stat.stat-green-turquoise {
  background-color: #36D7B7 !important;
  color: #FFFFFF;
}

.stat.stat-green-turquoise .more {
  background-color: #24b699 !important;
}

.stat.stat-green-haze {
  background-color: #44b6ae !important;
  color: #FFFFFF;
}

.stat.stat-green-haze .more {
  background-color: #36918b !important;
}

.stat.stat-green-jungle {
  background-color: #26C281 !important;
  color: #FFFFFF;
}

.stat.stat-green-jungle .more {
  background-color: #1e9765 !important;
}

.stat.stat-green-soft {
  background-color: #3faba4 !important;
  color: #FFFFFF;
}

.stat.stat-green-soft .more {
  background-color: #318680 !important;
}

.stat.stat-green-dark {
  background-color: #4DB3A2 !important;
  color: #FFFFFF;
}

.stat.stat-green-dark .more {
  background-color: #3d9082 !important;
}

.stat.stat-green-sharp {
  background-color: #2ab4c0 !important;
  color: #FFFFFF;
}

.stat.stat-green-sharp .more {
  background-color: #218d96 !important;
}

.stat.stat-grey {
  background-color: #E5E5E5 !important;
  color: #333333;
}

.stat.stat-grey .more {
  background-color: #cccccc !important;
}

.stat.stat-grey-steel {
  background-color: #e9edef !important;
  color: #80898e;
}

.stat.stat-grey-steel .more {
  background-color: #cbd5da !important;
}

.stat.stat-grey-cararra {
  background-color: #fafafa !important;
  color: #333333;
}

.stat.stat-grey-cararra .more {
  background-color: #e1e1e1 !important;
}

.stat.stat-grey-gallery {
  background-color: #555555 !important;
  color: #ffffff;
}

.stat.stat-grey-gallery .more {
  background-color: #3c3c3c !important;
}

.stat.stat-grey-cascade {
  background-color: #95A5A6 !important;
  color: #FFFFFF;
}

.stat.stat-grey-cascade .more {
  background-color: #798d8f !important;
}

.stat.stat-grey-silver {
  background-color: #BFBFBF !important;
  color: #FAFCFB;
}

.stat.stat-grey-silver .more {
  background-color: #a6a6a6 !important;
}

.stat.stat-grey-salsa {
  background-color: #ACB5C3 !important;
  color: #FAFCFB;
}

.stat.stat-grey-salsa .more {
  background-color: #8e9bae !important;
}

.stat.stat-grey-salt {
  background-color: #bfcad1 !important;
  color: #FAFCFB;
}

.stat.stat-grey-salt .more {
  background-color: #a1b1bc !important;
}

.stat.stat-grey-mint {
  background-color: #525e64 !important;
  color: #FFFFFF;
}

.stat.stat-grey-mint .more {
  background-color: #3b4448 !important;
}

.stat.stat-red {
  background-color: #e7505a !important;
  color: #ffffff;
}

.stat.stat-red .more {
  background-color: #e12330 !important;
}

.stat.stat-red-pink {
  background-color: #E08283 !important;
  color: #ffffff;
}

.stat.stat-red-pink .more {
  background-color: #d6595a !important;
}

.stat.stat-red-sunglo {
  background-color: #E26A6A !important;
  color: #ffffff;
}

.stat.stat-red-sunglo .more {
  background-color: #da3f3f !important;
}

.stat.stat-red-intense {
  background-color: #e35b5a !important;
  color: #ffffff;
}

.stat.stat-red-intense .more {
  background-color: #dc302e !important;
}

.stat.stat-red-thunderbird {
  background-color: #D91E18 !important;
  color: #ffffff;
}

.stat.stat-red-thunderbird .more {
  background-color: #ab1813 !important;
}

.stat.stat-red-flamingo {
  background-color: #EF4836 !important;
  color: #ffffff;
}

.stat.stat-red-flamingo .more {
  background-color: #e02612 !important;
}

.stat.stat-red-soft {
  background-color: #d05454 !important;
  color: #ffffff;
}

.stat.stat-red-soft .more {
  background-color: #bd3434 !important;
}

.stat.stat-red-haze {
  background-color: #f36a5a !important;
  color: #ffffff;
}

.stat.stat-red-haze .more {
  background-color: #f03f2a !important;
}

.stat.stat-red-mint {
  background-color: #e43a45 !important;
  color: #ffffff;
}

.stat.stat-red-mint .more {
  background-color: #cf1c28 !important;
}

.stat.stat-yellow {
  background-color: #c49f47 !important;
  color: #ffffff;
}

.stat.stat-yellow .more {
  background-color: #a48334 !important;
}

.stat.stat-yellow-gold {
  background-color: #E87E04 !important;
  color: #ffffff;
}

.stat.stat-yellow-gold .more {
  background-color: #b66303 !important;
}

.stat.stat-yellow-casablanca {
  background-color: #f2784b !important;
  color: #ffffff;
}

.stat.stat-yellow-casablanca .more {
  background-color: #ef541b !important;
}

.stat.stat-yellow-crusta {
  background-color: #f3c200 !important;
  color: #ffffff;
}

.stat.stat-yellow-crusta .more {
  background-color: #c09900 !important;
}

.stat.stat-yellow-lemon {
  background-color: #F7CA18 !important;
  color: #ffffff;
}

.stat.stat-yellow-lemon .more {
  background-color: #d5ab07 !important;
}

.stat.stat-yellow-saffron {
  background-color: #F4D03F !important;
  color: #ffffff;
}

.stat.stat-yellow-saffron .more {
  background-color: #f1c40f !important;
}

.stat.stat-yellow-soft {
  background-color: #c8d046 !important;
  color: #ffffff;
}

.stat.stat-yellow-soft .more {
  background-color: #adb52e !important;
}

.stat.stat-yellow-haze {
  background-color: #c5bf66 !important;
  color: #ffffff;
}

.stat.stat-yellow-haze .more {
  background-color: #b4ad44 !important;
}

.stat.stat-yellow-mint {
  background-color: #c5b96b !important;
  color: #ffffff;
}

.stat.stat-yellow-mint .more {
  background-color: #b6a747 !important;
}

.stat.stat-purple {
  background-color: #8E44AD !important;
  color: #ffffff;
}

.stat.stat-purple .more {
  background-color: #703688 !important;
}

.stat.stat-purple-plum {
  background-color: #8775a7 !important;
  color: #ffffff;
}

.stat.stat-purple-plum .more {
  background-color: #6d5b8e !important;
}

.stat.stat-purple-medium {
  background-color: #BF55EC !important;
  color: #ffffff;
}

.stat.stat-purple-medium .more {
  background-color: #ae27e7 !important;
}

.stat.stat-purple-studio {
  background-color: #8E44AD !important;
  color: #ffffff;
}

.stat.stat-purple-studio .more {
  background-color: #703688 !important;
}

.stat.stat-purple-wisteria {
  background-color: #9B59B6 !important;
  color: #ffffff;
}

.stat.stat-purple-wisteria .more {
  background-color: #804399 !important;
}

.stat.stat-purple-seance {
  background-color: #9A12B3 !important;
  color: #ffffff;
}

.stat.stat-purple-seance .more {
  background-color: #720d85 !important;
}

.stat.stat-purple-intense {
  background-color: #8775a7 !important;
  color: #ffffff;
}

.stat.stat-purple-intense .more {
  background-color: #6d5b8e !important;
}

.stat.stat-purple-sharp {
  background-color: #796799 !important;
  color: #ffffff;
}

.stat.stat-purple-sharp .more {
  background-color: #61527b !important;
}

.stat.stat-purple-soft {
  background-color: #8877a9 !important;
  color: #ffffff;
}

.stat.stat-purple-soft .more {
  background-color: #6e5c91 !important;
}

.stat .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.stat .content .visual {
  width: 120px;
  text-align: center;
  font-size: 4em;
}

.stat .content .title {
  font-size: 1.5em;
  flex: 2;
  flex-shrink: 0;
}

.stat .content .details {
  padding: 0 30px;
}

.stat .content .details .number {
  font-size: 2em;
  font-weight: bold;
}

.stat .more {
  text-transform: uppercase;
  font-weight: 300;
  padding: 6px 10px;
  font-size: 0.8em;
  text-align: right;
}

@media (max-width: 767px) {
  /* 991px */
  .stat .content {
    flex-direction: column;
    padding: 0 30px;
  }
  .stat .content .title {
    text-align: center;
  }
}

#page-bar .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 30px;
}

#page-bar .others {
  display: none;
}

#page-bar .others ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#page-bar .others ul button {
  white-space: normal;
}

#page-bar .actions .action {
  cursor: pointer;
  padding: 0 5px;
}

#page-bar .actions .action .fa-stack {
  width: 0.75em;
  height: 0.75em;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.5s ease-in-out;
}

#page-bar .actions .action .fa-stack:hover {
  color: white;
}

#page-bar .actions .action .fa-stack .fa {
  line-height: 10px;
}

#page-bar .actions .action .icon-delete {
  transform: rotate(45deg);
}

.burger {
  width: 40px;
  height: 30px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.burger span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #014e9c;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-child(1) {
  top: 0;
}

.burger span:nth-child(2), .burger span:nth-child(3) {
  top: 12.5px;
}

.burger span:nth-child(4) {
  top: 25px;
}

.burger.open span:nth-child(1) {
  top: 12.5px;
  width: 0;
  left: 50%;
}

.burger.open span:nth-child(2) {
  transform: rotate(45deg);
}

.burger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.burger.open span:nth-child(4) {
  top: 10px;
  width: 0;
  left: 50%;
}

.mce-i-iban:before {
  font-family: FontAwesome;
  content: "\f19c";
}

.mce-i-accountant:before {
  font-family: FontAwesome;
  content: "\f098";
}

.mce-i-payment:before {
  font-family: FontAwesome;
  content: "\f09d";
}

.portlet-table.light {
  padding: 0 20px;
  margin: 0;
}

.portlet-table.light .portlet-body {
  padding: 0;
  overflow: auto;
}

.portlet-table.table-extended {
  padding: 0 !important;
}

.portlet-table.table-extended table td:first-child, .portlet-table.table-extended table th:first-child {
  padding-left: 20px;
}

.portlet-table.table-extended table td:last-child, .portlet-table.table-extended table th:last-child {
  padding-right: 20px;
}

table.table {
  margin: 0;
}

table.table caption {
  color: #014e9c;
  padding: 10px 25px;
  position: relative;
  background-color: #cde1f5;
  text-transform: uppercase;
}

table.table caption:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #014e9c;
}

table.table tr td, table.table tr th {
  height: 50px;
  vertical-align: middle;
  padding-left: 25px;
}

table.table tr td.sortable-column, table.table tr th.sortable-column {
  padding-right: 16px;
}

table.table tr th {
  font-weight: bold;
  color: black;
  border-bottom: 1px solid #bec1c4;
}

table.table tr th a {
  color: black;
}

table.table tr td {
  border-top: 1px solid #bec1c4;
}

table.table tr.pinned {
  color: #fff;
  background: #014e9c !important;
  text-transform: uppercase;
}

table.table .icon {
  font-size: 30px;
  margin: 0 5px;
}

table.table-hover tbody tr:hover {
  background: #e6e7e8;
}

td.right, th.right {
  text-align: right;
}

table.dataTable tr.pinned td {
  background: #014e9c !important;
}

.balance.prefixed {
  font-weight: bold;
  white-space: nowrap;
  position: relative;
  text-indent: 26px;
}

.balance.prefixed:before {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
  text-indent: 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 20px;
  height: 20px;
  font-size: 14px;
}

.balance.prefixed.debit:before {
  content: "-";
  background: #ed4e2a;
}

.balance.prefixed.credit:before {
  content: "+";
  background: #3cc051;
}

/* iOS 8 Pure CSS Switch -- */
.checkbox-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-switch .help {
  margin: 0 20px 0 0;
}

.checkbox-switch .help--right {
  margin: 0 0 0 20px;
}

.checkbox-switch label {
  display: block;
  cursor: pointer;
  width: 40.456px;
  height: 26px;
  border-radius: 50px !important;
  margin-bottom: 0;
  box-shadow: inset 0 0 0 2px #E4E4E4;
  transition: all 0.4s;
}

.checkbox-switch label:before {
  content: '';
  width: 21.84px;
  height: 21.84px;
  background: #FFF;
  display: inline-block;
  border-radius: 60px;
  margin-top: 2px;
  margin-left: 2px;
  transition: all 0.3s;
  box-shadow: 0 0 0 1px #E4E4E4, 0 3px 2px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.checkbox-switch input {
  display: none;
}

.checkbox-switch input:checked + label:before {
  margin-left: 15.69693px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
}

.checkbox-switch label:active {
  box-shadow: inset 0 0 0 20px #E4E4E4;
}

.checkbox-switch label:active:before {
  width: 24.7px;
}

.checkbox-switch input:checked + label:active:before {
  margin-left: 13px;
}

.checkbox-switch input:checked + label {
  box-shadow: inset 0 0 0 20px #53D769;
  border: none;
}

.alert {
  margin: 0 auto 20px;
}

.alert.alert-success {
  background-color: rgba(83, 215, 105, 0.36);
  border: none;
  color: #4da251;
}

.alert.alert-notice {
  border: 1px solid #1b809e;
  background: rgba(0, 0, 0, 0.05);
}

.alert.alert-notice > i {
  color: #1b809e;
}

.alert.alert-inline {
  display: inline-block;
}

table.sortable thead th.sortable-column {
  background: url(/images/sort_both.png) no-repeat right center;
}

table.sortable thead th.sortable-column.asc {
  background-image: url(/images/sort_asc.png);
}

table.sortable thead th.sortable-column.desc {
  background-image: url(/images/sort_desc.png);
}

table.sortable thead th.sortable-column a {
  display: block;
}

.payment-button {
  margin-bottom: 20px;
  text-align: center;
}

/* Specific Pages */
.authentication-authenticationForm-title {
  color: #fff;
  font-size: 16px;
  padding: 0 0 10px;
  text-align: left;
}

@media (max-width: 991px) {
  .authentication-authenticationForm-title {
    text-align: center;
  }
}

.authentication-authenticationForm form .form-group {
  vertical-align: top;
  margin-right: 8px;
  width: 36%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .authentication-authenticationForm form .form-group {
    width: 33%;
  }
}

@media (max-width: 991px) {
  .authentication-authenticationForm form .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
}

.authentication-authenticationForm form .form-group .input-group {
  width: 100%;
}

.authentication-authenticationForm form .form-group .input-group-addon {
  background: #fff;
  padding-right: 0;
}

.authentication-authenticationForm form .form-group .input-icon.right > .form-control {
  padding-right: 20px;
  padding-left: 5px;
}

.authentication-authenticationForm form .form-group .input-icon.right i {
  right: 5px;
}

.authentication-authenticationForm form .form-group input {
  display: block;
  width: 100% !important;
  border-left: 0;
  padding-left: 5px;
}

.authentication-authenticationForm form .form-group .authentication-authenticationForm-passwordLost {
  display: block;
  font-size: 12px;
  margin-top: 8px;
  white-space: nowrap;
  font-style: italic;
  color: white;
}

@media (max-width: 991px) {
  .authentication-authenticationForm form .form-group .authentication-authenticationForm-passwordLost {
    text-align: right;
  }
}

.authentication-authenticationForm form button {
  float: right;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 991px) {
  .authentication-authenticationForm form button {
    width: 100%;
    margin: 30px 0;
  }
}

.authentication-authenticationForm form button [class^='icon-'] {
  margin-left: 8px;
  font-size: 14px;
  vertical-align: middle;
}

.presentation {
  padding: 20px 40px;
}

.presentation h1 {
  font-size: 30px;
  font-weight: 300;
  color: #014e9c;
  margin: 0 0 25px;
}

.presentation p {
  margin: 0 0 20px;
}

.presentation p strong {
  color: #014e9c;
}

.presentation p em {
  color: #777;
  font-size: 0.857em;
}

.presentation ul {
  background-color: #dddee1;
  list-style: none;
  padding: 20px 20px 40px;
  margin: 20px 0 40px;
  width: 100%;
  float: left;
}

.presentation ul li {
  float: left;
  text-align: center;
  width: 50%;
  text-transform: uppercase;
  font-size: 11px;
  padding: 20px;
}

.presentation ul li i {
  color: #014e9c;
  font-size: 50px;
  display: inline-block;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .presentation {
    padding: 10px 20px;
  }
  .presentation ul li {
    width: 100%;
  }
}

.well {
  background-color: white;
}

.inscription {
  padding: 0;
}

.inscription .well {
  padding: 20px 70px 40px;
  text-align: center;
}

.inscription .well h2 {
  font-size: 30px;
  font-weight: 300;
  color: #222;
  margin: 0 0 10px;
}

.inscription .well h3 {
  font-size: 24px;
  font-weight: 300;
  color: #014e9c;
  margin: 0 0 45px;
}

.inscription .well .visuAccount {
  margin-top: 60px;
  width: 100%;
  max-width: 431px;
}

.inscription .well .input-icon.right {
  border-right: none;
}

@media (max-width: 991px) {
  .inscription .well {
    padding: 10px 20px 10px;
    margin-bottom: 80px;
  }
}

.inscription .well #registrationForm-profileSpecific .col-md-4 {
  padding: 0;
  width: 100%;
}

.emergency {
  background-color: black;
  display: block;
  text-align: center;
  padding: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  font-size: 18px;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  z-index: 100;
}

.emergency:hover, .emergency:focus {
  color: white;
  background-color: #222;
}

#registrationForm.editing h2 {
  display: none;
}

#registrationForm.editing h3 {
  font-size: 30px;
  margin: 0 0 30px;
}

#building-detail #map {
  display: none;
  height: 300px;
}

#building-detail .building-chooser {
  padding: 12px;
  background: #014e9c;
  color: #fff;
  font-weight: bold;
}

#building-detail .building-chooser:before {
  background: #00aff0;
}

#building-detail .building-chooser i.fa {
  color: #fff !important;
}

#building-detail .building-chooser .dropdown > .dropdown-menu {
  margin-top: 0;
}

#building-detail .building-identity .identity {
  margin-top: 15px;
}

#building-detail .building-identity .identity .name {
  font-size: 1.4em;
  margin-bottom: 5px;
}

.person {
  display: flex;
  align-items: center;
}

.person.bordered {
  padding: 30px 0;
}

.person.bordered:not(:first-child) {
  border-top: 1px solid #eef1f5;
}

.person .person-avatar {
  width: 50px;
  flex-shrink: 0;
  margin-right: 15px;
}

.person .person-avatar i {
  border-radius: 50%;
  background: #014e9c;
  padding: 12px;
  color: #fff;
  font-size: 22px;
}

.person .person-identity {
  flex-grow: 1;
  margin-right: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.person .person-identity .person-name {
  flex-grow: 1;
  margin-right: 15px;
}

.person .person-identity .council-link {
  margin-top: 5px;
}

.person .person-contact-information {
  flex-grow: 1;
  margin-right: 15px;
}

.person .person-contact {
  width: 50px;
  flex-shrink: 0;
  text-align: right;
}

.well.dashboard {
  position: relative;
  padding: 20px 10px 20px 25px;
  line-height: 32px;
  text-align: left;
}

.well.dashboard:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #014e9c;
}

.well.dashboard .btn {
  display: inline-block;
}

.well.dashboard.doc:before {
  background-color: #00aff0;
}

.well.dashboard.doc .btn {
  border-color: #00aff0;
  background-color: #00aff0;
}

#dashboard-push {
  display: flex;
  margin: 0 -15px;
}

#dashboard-push > div {
  flex: 1 1 100%;
  margin: 0 15px 30px;
}

#add-document #upload-zone {
  background: #fff;
  border: 2px dashed silver;
  border-radius: 4px !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 3em;
  line-height: 2em;
  position: relative;
}

#add-document #upload-zone .text {
  text-align: center;
  margin: auto;
}

#add-document #upload-zone .preview-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

#add-document #upload-zone .preview-container .dz-preview {
  display: none;
}

#add-document #upload-zone .preview-container .dz-preview.dz-processing {
  flex: 1 1 auto;
  display: flex;
}

#add-document #upload-zone .preview-container .dz-preview.dz-complete {
  display: none;
}

#add-document #upload-zone .preview-container .dz-preview > * {
  display: none;
}

#add-document #upload-zone .preview-container .dz-preview .dz-progress {
  display: block;
  width: 100%;
  flex: 1 1 auto;
}

#add-document #upload-zone .preview-container .dz-preview .dz-progress .dz-upload {
  width: 0;
  height: 100%;
  display: block;
  background: rgba(1, 78, 156, 0.1);
  transition: .4s width;
}

#add-document #step3 .portlet.solid {
  margin-bottom: 8px;
}

#add-document #step4 ul.files-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

#add-document #step4 ul.files-list li {
  padding: 8px;
}

#add-document #step4 ul.files-list li:not(:first-child) {
  border-top: 1px solid #818189;
}

#add-document #step4 ul.files-list li .files-list-item-description {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#add-document .help {
  display: block;
  margin-bottom: 8px;
}

#add-document .profiles .profile-title {
  background: #cde1f5;
  position: relative;
  margin-bottom: 10px;
  color: #014e9c;
  padding: 8px 30px;
  display: block;
}

#add-document .profiles .profile-title:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #014e9c;
}

#add-document .form-submit {
  margin: auto;
}

#add-document .checkbox label {
  position: relative;
  padding-right: 10px;
  margin-right: 40.456px;
  display: flex;
  align-items: center;
}

#add-document .checkbox label input {
  position: absolute;
  left: 100%;
  margin: 0;
}

.block .block-expand-btn .block-expand-btn-icon {
  transition-duration: 0.5s;
}

.block .block-content {
  height: 0;
  overflow: hidden;
}

.block.active .block-content {
  height: auto;
  margin-bottom: 30px;
}

.block.active .block-expand-btn .block-expand-btn-icon {
  transform: rotate(180deg);
}

.document-type {
  cursor: pointer;
}

.document-type .document-type-expand-btn {
  pointer-events: none;
}

.document-type .document-type-expand-btn .document-type-expand-btn-icon {
  transition-duration: 0.5s;
}

.document-type.active .document-type-expand-btn .document-type-expand-btn-icon {
  transform: rotate(180deg);
}

.document-type .document-type-content table.table {
  margin-bottom: 0;
}

.grid-search .preselected-contract {
  margin-right: 15px;
  display: inline-block;
}

.grid-search .preselected-contract-name, .grid-search .preselected-contract-contract-number {
  color: #014e9c;
  font-weight: bold;
  background: #e6e7e7;
  border-radius: 10px !important;
  padding: 0 8px;
}

.grid .document-action-columns {
  min-width: 100px;
}

#documents .portlet {
  margin-bottom: 30px;
}

#documents .portlet .portlet-title {
  padding: 7.5px 0;
}

#documents .portlet .portlet-title > .caption {
  pointer-events: none;
}

#documents .portlet .portlet-title .actions {
  padding-right: 7.5px;
}

#documents .block .block-title {
  color: #666666;
  cursor: pointer;
  margin-bottom: calc(30px / 1.2 - 10px);
  padding: 10px;
  transition: background 0.3s;
}

#documents .block .block-title:hover {
  background: #f4f6f9;
}

#documents .block.active .block {
  color: #014e9c;
}

#documents table a {
  color: #666666;
  margin-left: 8px;
}

#documents table a:hover {
  text-decoration: none;
  color: #333333;
}

#documents table td.date {
  width: 20%;
}

#documents table td.actions {
  text-align: right;
  padding-right: 0;
}

#documents .filetype.icon {
  font-size: 24px;
}

.accounting-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  color: #014e9c;
}

.accounting-title .title {
  font-weight: bold;
  font-size: 1.571em;
}

.accounting-title .balance {
  font-size: 1.3em;
}

.accounting-title .balance.debit {
  color: #ed4e2a;
}

.accounting-title .balance.credit {
  color: #3cc051;
}

.accounting-filters {
  display: flex;
  align-items: center;
  justify-content: right;
}

.accounting-filters > .form-group {
  flex: 0 1 700px;
}

.building-outlay .total-row .total {
  font-style: italic;
  text-align: right;
}

.building-outlay .total-row .debit {
  color: #ed4e2a;
}

.building-outlay .total-row .credit {
  color: #3cc051;
}

.building-outlay .debit, .building-outlay .credit {
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

#building-list .portlet ~ .accounting-building-title {
  margin-top: 30px;
}

.accounting-building-title {
  color: #014e9c;
  margin: 0 0 25px 6px;
}

#building-accounting .portlet {
  margin-bottom: 30px;
}

.export-table {
  white-space: nowrap;
}

.export-table .help {
  color: #666666;
  margin-right: 1vw;
  font-size: 12px;
}

@media (max-width: 480px) {
  .help {
    display: none;
  }
}

#accounting-detail-table {
  border-collapse: collapse;
  overflow: hidden;
}

#accounting-detail-table .icon-delete {
  font-size: 3rem;
  position: absolute;
  transform: translate(-100%, 10%);
}

#accounting-detail-table tr.document[data-expandable] {
  cursor: pointer;
}

#accounting-detail-table td.expandable > i {
  font-size: 2rem;
  transition: transform 0.3s;
}

#accounting-detail-table tr.expanded td.expandable > i {
  transform: rotate(180deg);
}

#accounting-detail-table tr.pinned.thin > td {
  height: auto;
  padding: 2px 20px;
}

#accounting-detail-table tr.child .balance.prefixed {
  font-weight: normal;
}

#accounting-detail-table tr.child, #accounting-detail-table tr.child > td {
  height: auto;
  padding: 0;
}

#accounting-detail-table tr.child > td {
  border-top-style: none;
}

#accounting-detail-table tr.child > td > div {
  opacity: 1;
  max-height: 50px;
  margin: 8px 10px 8px 25px;
  transition: opacity 0.6s, max-height 0.3s, margin 0.3s;
}

#accounting-detail-table tr.child:not(.expanded) > td > div {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  margin: 0 10px 0 25px;
  transition: opacity 0.3s, max-height 0.3s, margin 0.3s;
}

#news .news-item {
  padding: 30px;
  border: 1px solid #e8e8e8;
}

#news .news-item h4 {
  margin: 0 0 13px;
  font-size: 30px;
  font-weight: 300;
  color: #014e9c;
}

#news .news-item p {
  font-size: 14px;
  line-height: 1.714em;
  margin: 0 0 10px;
}

#news .news-item .date {
  font-weight: bold;
  color: #777;
  margin: 0 0 25px;
}

#news .news-item .visu img {
  width: 100%;
  height: 215px;
  float: left;
  vertical-align: top;
  object-fit: cover;
}

#news .load-more {
  text-align: center;
}

#news .load-more i {
  font-size: 1.2em;
}

/* BO */
#editNewsForm label {
  margin: 0 0 3px;
  padding: 0;
}

#editNewsForm .checkbox input[type=checkbox] {
  position: static;
  margin: 0;
}

#editNewsForm .preview {
  margin-top: 10px;
}

#editNewsForm .preview img {
  width: 100%;
}

#editNewsForm .date .col-sm-10 {
  padding: 0;
  width: 100%;
}

#editNewsForm .actions {
  text-align: right;
}

#editNewsForm .actions #news_pin {
  border-color: #333;
  background-color: #333;
  color: #dddee1;
}

#editNewsForm .actions #news_publish {
  border-color: #36ae43;
  background-color: #36ae43;
}

#editNewsForm .actions #news_publish.unpublish {
  border-color: #e73d4a;
  background-color: #e73d4a;
}

#editNewsForm .contract, #editNewsForm .building {
  display: flex;
  align-items: center;
}

#editNewsForm .contract .selector, #editNewsForm .contract .switchAll, #editNewsForm .building .selector, #editNewsForm .building .switchAll {
  flex-grow: 1;
}

.newsList table.table .publish .fa-stack.reversed, .newsList table.table .pin .fa-stack.reversed {
  color: #013569;
}

@media (max-width: 991px) {
  #editNewsForm .vich-image {
    margin-bottom: 10px;
  }
  #editNewsForm .vich-image > div {
    width: 100%;
  }
  #editNewsForm .vich-image .preview {
    width: 100%;
    max-width: 250px;
  }
  #editNewsForm .contract .switchAll .form-group, #editNewsForm .building .switchAll .form-group {
    float: right;
  }
}

@media (max-width: 767px) {
  #editNewsForm .contract, #editNewsForm .building {
    display: block;
  }
  #editNewsForm .contract .switchAll .form-group, #editNewsForm .building .switchAll .form-group {
    float: none;
  }
  #editNewsForm .contract .switchAll .form-group .help, #editNewsForm .building .switchAll .form-group .help {
    display: inline;
  }
}

#user-list-profile-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#user-list-profile-list ul li {
  position: relative;
}

#user-list-profile-list ul li a.user-list-user {
  background: #cde1f5;
  position: relative;
  margin-bottom: 10px;
  color: #666666;
  padding: 8px 30px;
  display: block;
}

#user-list-profile-list ul li a.user-list-user:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #014e9c;
}

#user-list-profile-list ul li a.user-list-user:hover, #user-list-profile-list ul li a.user-list-user:active, #user-list-profile-list ul li a.user-list-user:focus {
  text-decoration: none;
}

#user-list-profile-list ul li .fullname, #user-list-profile-list ul li .username {
  text-transform: uppercase;
  color: #014e9c;
}

#user-list-profile-list ul li .registered {
  font-style: italic;
}

#user-list-profile-list ul li .content {
  display: none;
}

#user-list-profile-list ul li .arrow {
  color: #014e9c;
  font-weight: bold;
  transition: .4s all;
  transform-origin: center center;
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
}

#user-list-profile-list ul li.active .content {
  display: block;
}

#user-list-profile-list ul li.active .arrow {
  transform: rotate(180deg);
}

#user-list-profile-list ul li.more {
  text-align: center;
  padding-top: 30px;
}

.searchengine-form-wrapper .help {
  margin-bottom: 8px;
  display: block;
}

.user-list-profile-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-list-profile-container .checkbox-switch {
  display: flex;
  align-items: center;
  margin: 0;
}

.user-list-profile-container .checkbox-switch .help {
  margin: 0;
  text-indent: 20px;
}

.user-list-profile-container .unvalidated-account {
  font-size: 1rem;
  font-style: italic;
  color: #ed4e2a;
}

.user-list-profile-container .actions {
  border-bottom: 1px solid #bec1c4;
  padding: 10px 30px;
}

.user-list-profile-container .actions ul {
  display: flex;
  justify-content: flex-end;
}

.user-list-profile-container .actions ul li:first-child {
  flex: 2;
  display: flex;
  align-items: center;
}

.user-list-profile-container .actions ul li:first-child > *:not(:first-child) {
  margin-left: 15px;
}

.user-list-profile-container .profile {
  padding: 30px;
}

.user-list-profile-container .profile + .profile {
  padding-top: 0;
}

.user-list-profile-container .profile table.user-identity {
  line-height: 1.8;
}

.user-list-profile-container .profile table.user-identity td:first-child {
  text-align: right;
  font-weight: lighter;
}

.user-list-profile-container .profile table.user-identity td:last-child {
  padding-left: 20px;
  color: #000;
}

.user-list-profile-container .profile table.buildings th {
  height: auto;
  vertical-align: top;
  padding-top: 0;
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #bec1c4;
}

.user-list-profile-container .profile table.buildings th:nth-child(2) {
  text-align: center;
}

.user-list-profile-container .profile table.buildings th:last-child {
  text-align: right;
}

.user-list-profile-container .profile table.buildings td {
  border-bottom: 1px solid #bec1c4;
}

.user-list-profile-container .profile table.buildings td:nth-child(2) {
  text-align: center;
}

.user-list-profile-container .profile table.buildings td:last-child {
  text-align: right;
}

@media (max-width: 991px) {
  .user-list-profile-container .profile table.user-identity {
    width: 100%;
  }
  .user-list-profile-container .profile table.user-identity td:first-child {
    width: 50%;
    text-align: left;
  }
  .user-list-profile-container .profile table.buildings {
    margin-top: 20px;
  }
}

ul.contract-list {
  border: 1px solid #dddee1;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
}

ul.contract-list li {
  padding: 10px 30px;
  line-height: 26px;
}

ul.contract-list li:not(:last-child) {
  border-bottom: 1px solid #dddee1;
}

ul.contract-list li.selected {
  color: #fff;
  background: #014e9c !important;
  font-weight: bold;
}

.infosContrat li span {
  display: inline-block;
  padding-right: 5px;
  width: 19%;
  vertical-align: middle;
}

@media (max-width: 992px) {
  .infosContrat li span {
    width: 100%;
    padding-right: 0;
  }
}

.add-contract-button {
  margin-bottom: 20px;
  text-align: center;
}

#customer-list-profile-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#customer-list-profile-list ul li {
  position: relative;
}

#customer-list-profile-list ul li a.customer-list-customer {
  background: #cde1f5;
  position: relative;
  margin-bottom: 10px;
  color: #666666;
  padding: 8px 30px;
  display: block;
}

#customer-list-profile-list ul li a.customer-list-customer:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #014e9c;
}

#customer-list-profile-list ul li a.customer-list-customer:hover, #customer-list-profile-list ul li a.customer-list-customer:active, #customer-list-profile-list ul li a.customer-list-customer:focus {
  text-decoration: none;
}

#customer-list-profile-list ul li .fullname, #customer-list-profile-list ul li .customername {
  text-transform: uppercase;
  color: #014e9c;
}

#customer-list-profile-list ul li .registered {
  font-style: italic;
}

#customer-list-profile-list ul li .content {
  display: none;
}

#customer-list-profile-list ul li .arrow {
  color: #014e9c;
  font-weight: bold;
  transition: .4s all;
  transform-origin: center center;
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
}

#customer-list-profile-list ul li.active .content {
  display: block;
}

#customer-list-profile-list ul li.active .arrow {
  transform: rotate(180deg);
}

#customer-list-profile-list ul li.more {
  text-align: center;
  padding-top: 30px;
}

.searchengine-form-wrapper .help {
  margin-bottom: 8px;
  display: block;
}

.customer-list-profile-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.customer-list-profile-container .checkbox-switch {
  display: flex;
  align-items: center;
  margin: 0;
}

.customer-list-profile-container .checkbox-switch .help {
  margin: 0;
  text-indent: 20px;
}

.customer-list-profile-container .actions {
  border-bottom: 1px solid #bec1c4;
  padding: 10px 30px;
}

.customer-list-profile-container .actions ul {
  display: flex;
  justify-content: flex-end;
}

.customer-list-profile-container .actions ul li {
  display: flex;
}

.customer-list-profile-container .actions ul li:nth-child(2) {
  margin: 0 auto 0 10px;
}

.customer-list-profile-container .profile {
  padding: 30px;
}

.customer-list-profile-container .profile + .profile {
  padding-top: 0;
}

.customer-list-profile-container .profile table.customer-identity {
  line-height: 1.8;
}

.customer-list-profile-container .profile table.customer-identity td:first-child {
  text-align: right;
  font-weight: lighter;
}

.customer-list-profile-container .profile table.customer-identity td:last-child {
  padding-left: 20px;
  color: #000;
}

.customer-list-profile-container .profile table.contracts th {
  height: auto;
  vertical-align: top;
  padding-top: 0;
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #bec1c4;
}

.customer-list-profile-container .profile table.contracts th:nth-child(2) {
  text-align: center;
}

.customer-list-profile-container .profile table.contracts th:last-child {
  text-align: right;
}

.customer-list-profile-container .profile table.contracts td {
  border-bottom: 1px solid #bec1c4;
}

.customer-list-profile-container .profile table.contracts td:nth-child(2) {
  text-align: center;
}

.customer-list-profile-container .profile table.contracts td:last-child {
  text-align: right;
}

@media (max-width: 991px) {
  .customer-list-profile-container .profile table.customer-identity {
    width: 100%;
  }
  .customer-list-profile-container .profile table.customer-identity td:first-child {
    width: 50%;
    text-align: left;
  }
  .customer-list-profile-container .profile table.contracts {
    margin-top: 20px;
  }
}

.infosContrat li span {
  display: inline-block;
  padding-right: 5px;
  width: 19%;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .infosContrat li span {
    width: 100%;
    padding-right: 0;
  }
}

#pref li {
  display: flex;
  justify-content: space-between;
}

#pref li .inputs {
  display: flex;
  margin: 0 -10px;
}

#pref li .inputs .form-group {
  padding: 0 10px;
  margin: 0;
}

#newsletter {
  margin: 30px 0;
}

#newsletter .col-sm-10 {
  padding: 0;
  width: 100%;
}

#newsletter .form-group {
  margin: 0;
}

#newsletter .form-group .checkbox-switch {
  width: 100%;
  display: block;
}

#newsletter .form-group .checkbox-switch label {
  float: right;
}

#newsletter .form-group .checkbox-switch .help {
  display: inline-block !important;
  width: calc(100% - 65px);
}

ul.profile-list {
  border: 1px solid #dddee1;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
}

ul.profile-list li {
  padding: 10px 30px;
  line-height: 26px;
}

ul.profile-list li:not(:last-child) {
  border-bottom: 1px solid #dddee1;
}

.customer-myInformation-address {
  height: 100%;
  display: flex;
}

.customer-myInformation-address-container {
  margin: auto;
}

@media (max-width: 991px) {
  #pref li {
    flex-direction: column;
  }
}

#login {
  margin: auto;
  width: 440px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 30px 60px 60px;
}

#login h2 {
  text-align: center;
  margin: 0 0 30px 0;
}

#login .password-forgotten {
  text-align: right;
  font-size: 13px;
  margin-top: 4px;
}

#login .password-forgotten a {
  color: #222222;
}

#login .input-group {
  width: 100%;
  position: relative;
}

#login .input-group input {
  padding-left: 30px;
}

#login .input-group .input-addon {
  position: absolute;
  z-index: 10;
  top: 1px;
  bottom: 1px;
  left: 1px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
}

#login .input-group .input-addon:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #014e9c;
}

#login button[type="submit"] {
  margin-top: 30px;
}

#login .success-block {
  text-align: center;
  padding: 60px;
}

#login .success-block .icon {
  color: #014e9c;
  font-size: 80px;
}

@media (max-width: 767px) {
  /* 767px */
  #login {
    padding: 30px;
  }
}

#error-content {
  margin: auto;
  color: #666666;
  font-size: 3em;
  font-weight: lighter;
  text-align: center;
}

#emergency {
  background-color: white;
  border: 1px solid #dddee1;
  padding: 50px 50px 30px;
}

#emergency h1 {
  color: #014e9c;
  font-size: 26px;
  font-weight: 300;
  margin: 0 0 10px;
}

#emergency h2 {
  color: black;
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 60px;
}

#emergency form {
  overflow: hidden;
}

@media (max-width: 991px) {
  #emergency {
    padding: 25px 15px 15px;
  }
}

/**
* Users backend
*/
.settings .maintenance .maintenance-btn-block {
  text-align: right;
}

.settings .maintenance .maintenance-btn-block .btn.put-offline {
  background-color: #ed4e2a;
}

.settings .maintenance .maintenance-btn-block .btn.put-offline:hover {
  background-color: #d23512;
}

.settings .maintenance .maintenance-btn-block .btn.put-online {
  background-color: #3cc051;
}

.settings .maintenance .maintenance-btn-block .btn.put-online:hover {
  background-color: #309941;
}

.settings .documentType-setting .ok {
  background-color: #3cc051;
}

.settings .documentType-setting .nok {
  background-color: #ed4e2a;
}

.settings .documentType-setting .grab {
  cursor: move;
  color: #bec1c4;
  margin-right: 15px;
}

.settings .documentType-setting .validity {
  line-height: 35px;
}

.settings .documentType-setting .validity .validity-editBtn {
  visibility: hidden;
  float: right;
}

.settings .documentType-setting .validity:hover .validity-editBtn {
  visibility: visible;
}

.settings .documentType-setting-validity-modal .loader {
  font-size: 40px;
  text-align: center;
  display: block;
}

.settings-export {
  background: #e6e7e8;
  padding: 30px 10px 50px;
  display: block;
}

@media (max-width: 767px) {
  .settings .maintenance-btn-block {
    margin-top: 15px;
    text-align: center;
  }
  .settings .maintenance-btn-block .btn {
    display: block;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .documentType-setting .validity .validity-editBtn {
    visibility: visible;
  }
}

#contact-add-document #upload-zone {
  background: #fff;
  border: 2px dashed silver;
  border-radius: 4px !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 3em;
  line-height: 2em;
  position: relative;
}

#contact-add-document #upload-zone .text {
  text-align: center;
  margin: auto;
}

#contact-add-document #upload-zone .preview-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#contact-add-document #upload-zone .preview-container .dz-preview {
  margin: 10px;
  position: relative;
}

#contact-add-document #upload-zone .preview-container .dz-preview .dz-filename, #contact-add-document #upload-zone .preview-container .dz-preview .dz-size {
  display: none;
}

#contact-add-document #upload-zone .preview-container .dz-preview .dz-success-mark, #contact-add-document #upload-zone .preview-container .dz-preview .dz-error-mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  display: none;
}

#contact-add-document #upload-zone .preview-container .dz-preview.dz-error .dz-error-mark {
  display: block;
}

#contact-add-document #upload-zone .preview-container .dz-preview .dz-progress {
  display: block;
  width: 100%;
  flex: 1 1 auto;
}

#contact-add-document #upload-zone .preview-container .dz-preview .dz-progress .dz-upload {
  width: 0;
  height: 100%;
  display: block;
  background: rgba(1, 78, 156, 0.1);
  transition: .4s width;
}

.content-page .content-block {
  padding: 45px;
}

.content-page .content-block h1 {
  color: #014e9c;
}

.credit-note-form-container h1 {
  margin-bottom: 20px;
}

.credit-note-form-container__body {
  margin-bottom: 20px;
}

.credit-note-form-container__body ul {
  padding-left: 0;
  list-style-type: none;
}

.credit-note-form-container__body ul .item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.credit-note-form-container__body ul .item--disabled > * {
  opacity: .5;
}

.credit-note-form-container__body ul .item__title {
  font-weight: 700;
  margin-bottom: 10px;
}

.credit-note-form-container__body ul .item__content:not(:first-child) {
  margin-left: 10px;
}

.credit-note-form-container__body ul .item__content input {
  font-size: 1.5rem;
}

.credit-note-form-container__body ul .item .partially_consumed, .credit-note-form-container__body ul .item .all_consumed {
  font-size: 1.2rem;
  font-style: italic;
  color: #777;
}

.credit-note-form-container__body ul .item .partially_consumed .amount, .credit-note-form-container__body ul .item .all_consumed .amount {
  font-size: 1.4rem;
}

.credit-note-form-container__body hr {
  border-color: #dddee1;
}

.credit-note-form-container__body #credit_note_total {
  font-weight: 700;
}

.credit-note-form-container__footer {
  display: flex;
  justify-content: flex-end;
}

.credit-note-form-container__footer .btn {
  margin-left: 5px;
}

body[data-ctrl="BillingSynthesis"] .page-content {
  min-height: unset !important;
}

body[data-ctrl="BillingSynthesis"] .page-content #billing-synthesis-search-results {
  min-height: 300px;
}

body[data-ctrl="BillingFile"] .ui-pnotify-text {
  word-break: break-word;
}

.billing-file-form-container .input-icon i.fa {
  display: none;
}

.billing-file-form-container fieldset.fieldset {
  padding-left: 0;
  padding-right: 0;
}

.billing-file-form-container .readonly-info {
  padding: 6px 12px;
  border: 1px solid #c2cad8;
  max-width: 600px;
}

.billing-file-form-container .alert {
  max-width: 600px;
  margin-left: 10px;
}

.billing-file-form-container .h3 {
  margin-left: 20px;
}

.billing-file-form-container fieldset.interverventions {
  padding: 0;
}

.billing-file-form-container fieldset.interverventions .no-intervention .alert {
  margin: 0;
}

.billing-file-form-container fieldset.interverventions .alert {
  max-width: none;
}

.billing-file-form-container .input-group, .billing-file-form-container .form-control {
  max-width: 600px;
}

@media (max-width: 1200px) {
  .billing-file-form-container a.add {
    padding: 5px;
  }
}

@media (max-width: 991px) {
  .billing-file-form-container table th {
    padding: 8px !important;
  }
  .billing-file-form-container a.add {
    font-size: 0;
    padding: 5px;
  }
  .billing-file-form-container a.add:after {
    font-size: 16px;
    font-weight: 600;
    content: "+";
  }
}

.form-field-info {
  font-size: 12px;
  font-style: italic;
}

.invoice-building-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fancybox-wrap .fancybox-inner {
  height: auto !important;
}

.go-back.need-confirm {
  color: red;
}

.billing-files-list-header .up-to-date {
  margin-left: 10px;
}

.billing-files-list-header .up-to-date--link {
  text-decoration: underline;
}

.billing-file-layout-list {
  margin-top: 20px;
}

.billing-file-layout-form-container {
  user-select: none;
}

.billing-file-layout-form-container .checkbox-switch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 7px;
}

.billing-file-layout-form-container .input-icon i.fa {
  display: none;
}

.billing-file-layout-form-container .portlet fieldset {
  margin-top: 10px;
  color: #333333;
  font-size: 14px;
}

.billing-file-layout-form-container .disabled {
  opacity: .3;
}

.billing-file-layout-form-container .pricing.has-success, .billing-file-layout-form-container .pricing.has-success * {
  color: #000 !important;
}

.billing-file-layout-form-container .pricing .error-container {
  display: flex;
  flex-direction: column;
}

.billing-file-layout-form-container .pricing .error-container .help-block {
  text-align: right;
}

.billing-file-layout-form-container .pricing__item {
  display: flex;
  align-items: center;
}

.billing-file-layout-form-container .pricing__item > * {
  flex: 1;
}

.billing-file-layout-form-container .natures-list table th:first-child, .billing-file-layout-form-container .natures-list table td:first-child {
  width: 60%;
}

.billing-file-layout-form-container .natures-list table .form-group {
  margin-bottom: 0;
}

.billing-file-layout-form-container .natures-list table label {
  display: none;
}

.billing-file-layout-form-container .intervention-layouts-list table th:first-child, .billing-file-layout-form-container .intervention-layouts-list table td:first-child {
  width: 60%;
}

.billing-file-layout-form-container .intervention-layouts-list table td.small .input-group {
  width: 150px;
}

.billing-file-layout-form-container .intervention-layouts-list table .pricing__item > .radio {
  flex-grow: 0;
  margin-right: 30px;
}

.billing-file-layout-form-container .fieldset-profiles div.checkbox {
  display: block;
}

.billing-file-layout-form-container .fieldset-profiles div.checkbox:first-child {
  margin-top: 0;
}

.billing-file-layout-form-container .fieldset-profiles select option[value=""] {
  color: #BBB;
}

#billing-synthesis-search-results .table > tfoot > tr > th, #billing-synthesis-search-results .table > tfoot > tr > td {
  padding-right: 8px;
}

.enhanced {
  color: red;
}

.invalid {
  color: red;
}

/**
* Users backend
*/
.dematerialization .documentType-setting .ok {
  background-color: #3cc051;
}

.dematerialization .documentType-setting .nok {
  background-color: #ed4e2a;
}

.dematerialization .documentType-setting .grab {
  cursor: move;
  color: #bec1c4;
  margin-right: 15px;
}

.demat-subscription-history {
  max-height: 60vh;
  overflow-y: auto;
}

body[data-ctrl="GeneralMeeting"] .ui-pnotify {
  background: white;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form > fieldset {
  width: 100%;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .vich-file {
  display: none;
}

@media (min-width: 1200px) {
  body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .comments-group label {
    width: 100%;
    text-align: left;
  }
  body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .comments-group > div {
    width: 100%;
  }
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form label.control-label.required {
  padding-left: 15px;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form i.disabled {
  opacity: 0.5;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .current-file {
  display: inline-block;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .current-file .reset-file {
  cursor: pointer;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .current-file:not(.file-selected) .reset-file {
  display: none;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .file-input-group {
  margin-top: 8px;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .sending-state .status-group {
  padding-top: 8px;
  display: flex;
  gap: 30px;
}

body[data-ctrl="GeneralMeeting"] .general-meeting-form-container form#general-meeting-form .sending-state .status-group .sent-date {
  font-weight: bold;
}

body[data-ctrl="GeneralMeeting"] a.btn.disabled {
  pointer-events: all;
}

body[data-ctrl="GeneralMeeting"] a.btn.disabled:active {
  pointer-events: none;
}

body[data-ctrl="GeneralMeeting"] .action-buttons {
  display: flex;
  justify-content: space-between;
}

body[data-ctrl="GeneralMeeting"] .contracts-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body[data-ctrl="GeneralMeeting"] .dropbtn {
  text-transform: none;
}

body[data-ctrl="GeneralMeeting"] .dropdown {
  position: relative;
  display: inline-block;
}

body[data-ctrl="GeneralMeeting"] .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

body[data-ctrl="GeneralMeeting"] .dropdown-content a {
  color: black;
  padding: 12px 16px;
  font-size: 12px;
  text-decoration: none;
  display: block;
  text-transform: none;
}

body[data-ctrl="GeneralMeeting"] .dropdown-content a:hover {
  background-color: #ddd;
}

body[data-ctrl="GeneralMeeting"] .dropdown:hover .dropdown-content {
  display: block;
}

body[data-ctrl="GeneralMeetingMonitoring"] div#reminders {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 85%;
  margin: 0 auto;
}

body[data-ctrl="GeneralMeetingMonitoring"] div#reminders table {
  margin: 0 30px;
}

body[data-ctrl="GeneralMeetingMonitoring"] div#reminders table th {
  text-align: center;
}

body[data-ctrl="GeneralMeetingMonitoring"] div#reminders table td {
  text-align: center;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li {
  position: relative;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li a.building-list-building {
  background: #cde1f5;
  position: relative;
  margin-bottom: 10px;
  color: #666666;
  padding: 8px 30px;
  display: block;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li a.building-list-building:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #014e9c;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li a.building-list-building:hover, body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li a.building-list-building:active, body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li a.building-list-building:focus {
  text-decoration: none;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li a.building-list-building span.building-name {
  color: #014e9c;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li .arrow {
  color: #014e9c;
  font-weight: bold;
  transition: .4s all;
  transform-origin: center center;
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li .content {
  display: none;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li.active .content {
  display: block;
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li.active .arrow {
  transform: rotate(180deg);
}

body[data-ctrl="GeneralMeetingMonitoring"] .building-list ul li .content {
  text-align: center;
}

/* Specific Pages admin*/
.searchengine-form-wrapper {
  overflow: hidden;
  margin-bottom: 60px;
}

.searchengine-form-wrapper .input-group-addon + .form-control {
  border-left: 0;
}

.searchengine-form-wrapper .form-control {
  padding-left: 0;
}

.searchengine-form-wrapper .form-control:focus {
  border-color: #ccc;
}

#building-list-search-results th:nth-child(1n+2):not(:last-child), #building-list-search-results td:nth-child(1n+2):not(:last-child) {
  text-align: center;
}

#building-list-search-results div.more {
  text-align: center;
  padding-top: 30px;
  font-size: 1.6rem;
  font-weight: 700;
}

#caretaker .resetBtn {
  float: right;
  font-size: 0.8em;
  padding: 3px 5px;
}

#caretaker .resetBtn span {
  display: inline;
  margin-right: 5px;
}

#caretaker .form-group {
  margin-bottom: 0;
}

#caretaker .row + .row {
  margin-top: 15px;
}

#caretaker .caretakerOriginalData {
  margin-top: 6px;
  font-size: 0.8em;
  color: #777;
}

@media (max-width: 991px) {
  #caretaker .resetBtn {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
}

.row.equal {
  display: flex;
  flex-wrap: wrap;
}

.row.equal > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.select2-dropdown {
  z-index: 9000;
}
