.flex {
  display: flex;

  &--end {
    align-self: flex-end;
  }

  &--start {
    align-self: flex-start;
  }

  &--center {
    align-self: center;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--content-end {
    justify-content: flex-end;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }

  &--baseline {
    align-items: baseline;
  }

  &--vertical-align {
    flex-direction: row;
    align-items: center;
  }

  &--grow {
    flex: 1;
  }

  &--small-gap {
    gap: 20px;
  }
}
