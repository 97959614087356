@import 'variables';

@import '../vendor/metronic/sass/global/_components';
@import 'metronic/layout/layout';
@import 'metronic/layout/themes/gtf';

html {
    height: 100%;
}

body.minimalist {
    background: #fff;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 100%;
    }

    min-height: 100%;
    display: flex;
    flex-direction: column;

    header {
        position: static !important;
        display: block;
        background: #000;
        padding: 20px;
        text-align: center;

        img {
            max-height: 50px;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: $default-margin;
        background: $color1;
        background-size: cover;
        padding:40px;
        @media screen and (max-width: 1024px) {
            padding:40px;
        }
    }
}


@media (max-width: $screen-xs-max) { /* 767px */
    body.minimalist {
        .wrapper {
            padding: $default-margin / 2;
        }
    }
}
