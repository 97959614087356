@import '../variables';

.dematerialization {

  .documentType-setting {

    .ok {
      background-color: $setting-ok-color;
    }

    .nok {
      background-color: $setting-nok-color;
    }

    .grab {
      cursor: move;
      color: $color7;
      margin-right: 15px;
    }
  }
}

.demat-subscription-history {
  max-height: 60vh;
  overflow-y: auto;
}
